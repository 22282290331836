import EntityList from '../listPage/EntityList';

export default new EntityList(
  'venueMember',
  'venues/venue-users',
  {
    zidane: true,
  },
  undefined,
  undefined,
  'Venue Users',
);
