/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 25/04/2018
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CancelIcon from 'material-ui/svg-icons/navigation/cancel';
import IconButton from 'material-ui/IconButton';
import Paper from 'material-ui/Paper';
import SearchIcon from 'material-ui/svg-icons/action/search';
import TextField from 'material-ui/TextField';

import InfoIcon from '../InfoIcon';

import './index.scss';

/**
 * @description MatchPint custom search bar.
 * @constructor
 */
class MpSearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: props.query,
    };
    this.onChange = this.onChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.cancelSearch = this.cancelSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleSearch() {
    const { search } = this.props;
    const { query } = this.state;
    search(query);
  }

  onKeyPress(event) {
    const { query } = this.state;
    const { searchInfo } = this.props;

    if (event.key === 'Enter' && (query !== '' || searchInfo.value !== '')) {
      this.handleSearch();
    }
  }

  onChange(event) {
    this.setState({ query: event.target.value });
  }

  cancelSearch() {
    const { cancelSearch } = this.props;
    cancelSearch();
    this.setState({
      query: '',
    });
  }

  render() {
    const { className, show, searchInfo } = this.props;
    const { query } = this.state;
    if (!show) {
      return null;
    }
    return (
      <Paper className={`${className} MpSearchBar`} zDepth={1}>
        <IconButton className="MpSearchBar__button_search" onClick={this.handleSearch}>
          <SearchIcon />
        </IconButton>
        {searchInfo !== null && searchInfo.value !== null && searchInfo.value !== '' ? (
          <IconButton className="MpSearchBar__button_cancel" onClick={this.cancelSearch}>
            <CancelIcon />
          </IconButton>
        ) : null}
        <TextField
          className="MpSearchBar__input"
          hintText={<span className="MpSearchBar__input_text">Search</span>}
          onChange={this.onChange}
          onKeyPress={this.onKeyPress}
          underlineStyle={{
            borderColor: 'transparent',
          }}
          underlineFocusStyle={{
            borderColor: 'transparent',
          }}
          value={query}
        />
        {searchInfo && searchInfo.help ? (
          <InfoIcon tooltip={searchInfo.help} tooltipPosition="bottom-left" />
        ) : null}
      </Paper>
    );
  }
}

MpSearchBar.propTypes = {
  cancelSearch: PropTypes.func.isRequired,
  className: PropTypes.string,
  query: PropTypes.string,
  search: PropTypes.func.isRequired,
  searchInfo: PropTypes.shape({
    help: PropTypes.string,
    value: PropTypes.string,
  }),
  show: PropTypes.bool,
};

MpSearchBar.defaultProps = {
  className: '',
  query: '',
  searchInfo: null,
  show: true,
};

export default MpSearchBar;
