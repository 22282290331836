/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 17/10/2018
 */
import React from 'react';
import PropTypes from 'prop-types';

import MaterialTextField from 'material-ui/TextField';

import InfoIcon from '../../InfoIcon';

import './index.scss';

const verifyTextLength = (event, oldText, newText, maxTextLength, action) => {
  // Allow deletion
  if (!maxTextLength || newText.length <= maxTextLength || oldText.length > newText.length) {
    action(newText);
  }
};

const TextField = ({
  error,
  handleEditField,
  help,
  maxTextLength,
  name,
  placeholder,
  text,
  type,
  containerClassName,
  disabled,
}) => (
  <div className={`${containerClassName} TextField__item`}>
    <div className="TextField__item_content">
      <span>{name}</span>
      <MaterialTextField
        fullWidth
        disabled={disabled}
        className="TextField__item_content_value"
        value={text || ''}
        onChange={(e, newText) =>
          verifyTextLength(e, text, newText, maxTextLength, handleEditField)
        }
        placeholder={placeholder}
        multiLine
        id={name}
        name={name}
        // Error text can be an array if we have several errors for the same field.
        errorText={Array.isArray(error) ? error.join(' | ') : error}
        type={type}
      />
      {maxTextLength && (
        <div
          className="TextField__item_content_chars"
          style={
            maxTextLength && text.length >= maxTextLength
              ? {
                  color: 'red',
                }
              : null
          }
        >
          {`currently ${text.length} / ${maxTextLength} chars`}
        </div>
      )}
    </div>
    {help && (
      <InfoIcon
        className="TextField__item_content_info"
        tooltipPosition="bottom-left"
        tooltip={help}
      />
    )}
  </div>
);

TextField.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  handleEditField: PropTypes.func.isRequired,
  help: PropTypes.string,
  maxTextLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

TextField.defaultProps = {
  error: null,
  help: null,
  maxTextLength: null,
  placeholder: null,
  text: '',
  type: 'text',
  containerClassName: '',
  disabled: false,
};

export default TextField;
