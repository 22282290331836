/**            -                              ___
 *            |.|                          . /\_/\
 *          __]-[_________ /             .  (-<_>-)
 *         /        _____|<_          .   .  \/_\/
 *        / _   &  /               .   .
 *       / / \_ __|            .
 *     _/_/  / X   \
 *    <_/   /   ____\
 *         /___/|  /             zinedine zidane
 *         |  / ( <                --france--
 *         ( <   \ |
 *          \ |   >\
 *          _>|  (_/
 *         (__|
 *
 * MatchPint Ltd
 * @author Andreia Almeida
 * Date: 13/11/2018
 */

import {
  anonymiseMember,
  clearDevicesForMember,
  fetchLocales,
  fetchUserPermissions,
} from '../thunks/api-member';
import * as actions from './actions';

export default function memberClearDevices(memberId) {
  return async function _memberClearDevices(dispatch) {
    dispatch(clearDevicesForMember(memberId));
  };
}

export const memberAnonymise = memberId => dispatch => dispatch(anonymiseMember(memberId));

export const fetchPermissions = memberId => dispatch => {
  dispatch(actions.loadMemberPermissionsPending());
  return dispatch(fetchUserPermissions(memberId)).then(r =>
    dispatch(actions.loadMemberPermissionsSuccess(r.getResult())),
  );
};

export const fetchUserLocales = memberId => dispatch =>
  dispatch(fetchLocales(memberId)).then(r => dispatch(actions.loadUserLocales(r.getResult())));
