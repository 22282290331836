import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { FlatButton } from 'material-ui';
import LoadingWheel from '../../../../../components/LoadingWheel';

const CRMNoteField = ({ onSaveNote, saving }) => {
  const [newNoteText, setNewNoteText] = useState('');

  return (
    <div className="CRMNoteField">
      <textarea
        onChange={e => setNewNoteText(e.target.value)}
        value={newNoteText}
        rows={5}
        className="CRMNoteField_textarea"
        placeholder="Leave useful info for the team here…"
      />
      <div className="CRMNoteField_button_container">
        {!saving ? (
          <FlatButton
            className="CRMNoteField_button"
            onClick={() =>
              onSaveNote(newNoteText).then(() => {
                setNewNoteText('');
              })
            }
          >
            Post note
          </FlatButton>
        ) : (
          <LoadingWheel />
        )}
      </div>
    </div>
  );
};
CRMNoteField.propTypes = {
  onSaveNote: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default CRMNoteField;
