import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import InfoIcon from '../InfoIcon';
import LoadingWheel from '../LoadingWheel';
import PreviewUpload from './PreviewUpload';
import { fileUploadAcceptTypes } from '../../utils/globalVariables';
import './FileUploadStyles.scss';

/* one downfall of dropzone is that the rejected class will not be applied if the file is too large,
   so for now I've only used the rejected as it will be shown in most rejected cases
   see https://github.com/react-dropzone/react-dropzone/issues/604
*/

const fileTypes = {
  [fileUploadAcceptTypes.image]: 'image/jpeg, image/png, image/gif',
  [fileUploadAcceptTypes.video]: 'video/mp4, video/WEB',
  [fileUploadAcceptTypes.file]:
    'image/jpeg, image/png, image/gif,application/pdf, video/mp4, video/WEB',
  [fileUploadAcceptTypes.csv]: 'text/csv',
  [fileUploadAcceptTypes.all]: '',
};

const FileUpload = ({
  filePath,
  multiple,
  processFile,
  limitKB,
  acceptType,
  error,
  help,
  label,
  deleteAction,
  overridePreviewComponent,
}) => {
  const [rejectError, setRejectError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  useEffect(() => {
    setRejectError(Boolean(error));
    if (error) setErrorMessage(error);
  }, [error]);

  const onDropAccepted = files => {
    setIsLoading(true);

    processFile(
      files[0],
      () => setIsLoading(false),
      err => setErrorMessage(err),
    );
  };

  const onDropRejected = () => {
    setRejectError(true);
    setErrorMessage('Wrong file format');
    setTimeout(() => {
      setRejectError(false);
    }, 5000);
  };

  const getPreviewComponent = link => {
    // only render for images or video
    const showPreview =
      acceptType === fileUploadAcceptTypes.image || acceptType === fileUploadAcceptTypes.video;
    return showPreview ? (
      <aside>
        <PreviewUpload type={acceptType} key={link} onClick={deleteAction} src={link} />
      </aside>
    ) : null;
  };

  return (
    <div className="FileUpload">
      {label && <div className="FileUpload_label">{label}</div>}
      <div className="FileUpload_uploadFile">
        {help && <InfoIcon className="fileUpload_info" tooltip={help} />}
        {rejectError && (
          <div className="FileUpload_label">
            <p className="FileUpload_label_errorText">{errorMessage}</p>
          </div>
        )}
        <div className="FileUpload_wrapper">
          <Dropzone
            style={{ backgroundImage: 'url(/assets/upload.png)' }}
            className="FileUpload_dropZone"
            maxSize={limitKB && typeof limitKB === 'number' ? limitKB * 1000 : undefined}
            onDropAccepted={onDropAccepted}
            onDropRejected={onDropRejected}
            rejectClassName="FileUpload_rejected"
            accept={fileTypes[acceptType]}
            multiple={multiple}
          >
            <p className="FileUpload_dropText">
              Drop files here, or click to select files to upload.
            </p>
          </Dropzone>
          {overridePreviewComponent || getPreviewComponent(filePath)}
        </div>
        {isLoading && <LoadingWheel size="full-page" />}
      </div>
    </div>
  );
};

FileUpload.propTypes = {
  filePath: PropTypes.string,
  multiple: PropTypes.bool,
  processFile: PropTypes.func.isRequired,
  limitKB: PropTypes.number,
  acceptType: PropTypes.oneOf(Object.values(fileUploadAcceptTypes)),
  error: PropTypes.string,
  deleteAction: PropTypes.func,
  help: PropTypes.string,
  label: PropTypes.string,
  overridePreviewComponent: PropTypes.node,
};

FileUpload.defaultProps = {
  multiple: false,
  limitKB: undefined,
  acceptType: fileUploadAcceptTypes.image,
  filePath: '',
  error: null,
  help: '',
  label: '',
  deleteAction: () => {},
  overridePreviewComponent: null,
};

export default FileUpload;
