/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 29/06/2018
 */

import * as constants from './constants';

export function changeSportsOrder(sports) {
  return {
    type: constants.CHANGE_ORDER,
    sports,
  };
}

export function discardChanges() {
  return {
    type: constants.DISCARD_CHANGES,
  };
}

export function editEntitySuccessDismiss() {
  return {
    type: constants.SUCCESS_DISMISS,
  };
}

export function loadSports() {
  return {
    type: constants.FETCH_SPORTS_PENDING,
  };
}

export function loadSportsSuccess(sports) {
  return {
    type: constants.FETCH_SPORTS_SUCCESS,
    sports,
  };
}

export function loadSportsError(error) {
  return {
    type: constants.FETCH_SPORTS_ERROR,
    error,
  };
}

export function saveSportsOrder() {
  return {
    type: constants.SAVE_ORDER_PENDING,
  };
}

export function saveSportsOrderSuccess() {
  return {
    type: constants.SAVE_ORDER_SUCCESS,
  };
}

export function saveSportsOrderError(error) {
  return {
    type: constants.SAVE_ORDER_ERROR,
    error,
  };
}
