import createFormPage from '../formPage';

const params = {
  endpoint: 'meta/calendar-content',
  sections: {
    Config: [{ fieldPath: 'relatedType' }, { fieldPath: 'relatedId' }, { fieldPath: 'countryId' }],
    Content: [{ fieldPath: 'emoji' }, { fieldPath: 'title' }, { fieldPath: 'content' }],
  },
  create: {
    fields: ['relatedType', 'relatedId', 'title', 'content', 'countryId'],
  },
};
const calendarContent = createFormPage('calendarContent', params);

export default calendarContent;
