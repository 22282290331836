/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FlatButton from 'material-ui/FlatButton';
import Card from 'material-ui/Card';

import { connect } from 'react-redux';
import FormAlert from '../../components/FormAlert';
import FormEmailInput from '../../components/FormEmailInput';
import FormPasswordInput from '../../components/FormPasswordInput';
import MpLink from '../../components/MpLink/MpLink';

import publicPage from '../../hocs/PublicPage';
import { loginStatus, loginFieldErrors, loginErrorMessage } from '../../store/login/selectors';
import { login as loginThunk } from '../../store/login/thunks/login';
import './index.scss';
import theme from '../../theme/index.scss';

class LoginPage extends Component {
  constructor() {
    super();

    this.state = {
      email: '',
      password: '',
    };

    // Input verification.
    this.verifyInputs = this.verifyInputs.bind(this);
    // Binding
    this.changeEmail = this.changeEmail.bind(this);
    this.changePassword = this.changePassword.bind(this);
    // LoginPage Action.
    this.enterKey = this.enterKey.bind(this);
    this.sendCredentials = this.sendCredentials.bind(this);
  }

  verifyInputs() {
    const { email, password } = this.state;
    return email.length > 0 && password.length > 0;
  }

  changeEmail(event) {
    this.setState({ email: event.target.value });
  }

  changePassword(event) {
    this.setState({ password: event.target.value });
  }

  enterKey(event) {
    const areInputsValid = this.verifyInputs();
    if (event.key === 'Enter' && areInputsValid === true) {
      this.sendCredentials();
    }
  }

  sendCredentials() {
    // changeLocation and login are passed by LoginContainer.
    const { login } = this.props;
    const { email, password } = this.state;

    login({ email, password });
  }

  render() {
    const { errorMessage, fieldErrors, status } = this.props;
    return (
      <div className="LoginPage__main">
        {status === 'error' ? (
          <div className="LoginPage_alert_wrapper">
            <FormAlert errorMessage={errorMessage} />
          </div>
        ) : null}
        <Card className="LoginPage_input_wrapper">
          <div className="LoginPage__form">
            <div className="LoginPage__form_inputs">
              <FormEmailInput
                required
                onChange={this.changeEmail}
                className="LoginPage"
                error={fieldErrors.email}
                id="login-email"
              />
              <FormPasswordInput
                required
                onChange={this.changePassword}
                onKeyPress={this.enterKey}
                className="LoginPage"
                error={fieldErrors.password}
                id="login-password"
              />
            </div>
            <div className="LoginPage__form_submit">
              <FlatButton
                color="primary"
                backgroundColor={theme.primaryYellow}
                className="LoginPage_form_submit_button"
                onClick={this.sendCredentials}
                disabled={!this.verifyInputs()}
                data-test-id="login_form_submit"
              >
                LOGIN
              </FlatButton>
            </div>
            <div className="LoginPage__form_reset_password">
              {'Forgot your password ? '}
              <MpLink to="/reset-password">Click here to reset your password.</MpLink>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

LoginPage.propTypes = {
  errorMessage: PropTypes.string,
  fieldErrors: PropTypes.shape(),
  login: PropTypes.func.isRequired,
  status: PropTypes.string,
};

LoginPage.defaultProps = {
  errorMessage: '',
  fieldErrors: {
    email: {},
    password: {},
  },
  status: '',
};

const mapStateToProps = state => ({
  errorMessage: loginErrorMessage(state),
  fieldErrors: loginFieldErrors(state),
  status: loginStatus(state),
});

const mapDispatchToProps = dispatch => ({
  login: ({ email, password }) => dispatch(loginThunk(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(publicPage(LoginPage));
