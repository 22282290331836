import createFormPage from '../formPage';

const params = {
  endpoint: 'meta/news',
  sections: {
    General: [
      'active',
      'name',
      'image',
      'countryId',
      'showOn',
      'requiresSignIn',
      'button.text',
      'button.link',
      'dateStart',
      'dateEnd',
      'buttonColour',
      'buttonTextColour',
    ],
    'Location setup': ['locationBased', 'radius', 'pillId'],
  },
  create: {
    fields: [
      'name',
      'countryId',
      'button.link',
      'button.text',
      'image',
      'dateStart',
      'dateEnd',
      'showOn',
      'requiresSignIn',
      'buttonColour',
      'buttonTextColour',
    ],
  },
};

export default createFormPage('news', params);
