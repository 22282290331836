/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /                  ..
 *           \          /                  ( '`<
 *            )       /`                    )(
 *           /      /`               ( ----'  '.
 * Author: Marwan                    (         ;
 * Date: 04/10/2018                   (_______,'
 *                           ~^~^~^~^~Ed made it worse^~^~^~^~^~^~
 */
import AutoComplete from 'material-ui/AutoComplete';
import { getServicesEndpoint } from '../../utils/environment';
/**
 * @description Allows to calculate a state dataSource based on a searchText and the AutoComplete
 *  props.
 * @param {string} searchText
 * @param {{
 *   dataSource: *,
 *   formatDataSource: function
 * }} props Props of the AutoComplete component.
 * @return {Promise<*>}
 */

const calculateDataSource = async (
  searchText,
  { dataSource: propsDataSource, formatDataSource: propsFormatDataSource },
) => {
  const formatDataSource = propsFormatDataSource || [];

  if (typeof propsDataSource !== 'function') {
    const dataSource = formatDataSource(propsDataSource);

    return dataSource.filter(data => {
      if (!searchText) {
        return true;
      }

      if (!data.text) {
        return false;
      }

      return AutoComplete.caseInsensitiveFilter(searchText, data.text);
    });
  }
  // Don't search if no text
  if (!searchText) {
    return [];
  }
  const url = `${getServicesEndpoint()}${propsDataSource(encodeURIComponent(searchText))}`;

  const rawResponse = await fetch(url, {
    method: 'GET',
    credentials: 'include',
  });

  if (rawResponse.status !== 200) {
    throw Error(`Error calling ${url} (${rawResponse.status})`);
  }

  const data = await rawResponse.json();
  return formatDataSource(data.result);
};

export default calculateDataSource;
