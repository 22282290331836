export const FETCH_FIXTURE_PENDING = 'fixture/fetch/pending';
export const FETCH_FIXTURE_SUCCESS = 'fixture/fetch/success';
export const FETCH_FIXTURE_FAIL = 'fixture/fetch/fail';

export const UPDATE_RESULT_PENDING = 'score/result/pending';
export const UPDATE_RESULT_SUCCESS = 'score/result/success';
export const UPDATE_RESULT_FAIL = 'score/result/fail';

export const UPDATE_RESULT = 'score/result';

export const UPDATE_SCORE_FAIL = 'score/update/fail';
export const UPDATE_SCORE_PENDING = 'score/update/pending';
export const UPDATE_SCORE_SUCCESS = 'score/update/success';
