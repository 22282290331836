import createFormPage from '../formPage';
import MandateDetails from '../../../components/MandateDetails/MandateDetails';

const params = {
  endpoint: 'venues/mandates',
  sections: {
    GENRAL: [
      {
        fieldPath: 'id',
        Component: MandateDetails,
      },
    ],
  },
  otherSection: false,
  api: {
    getDescriptionAPIEndpoint: item => ({
      url: `venues/mandates/${item}`,
      method: 'GET',
    }),
  },
};

const mandates = createFormPage('mandates', params);

export default mandates;
