import EntityList from '../listPage/EntityList';

export default new EntityList(
  'pills',
  'meta/pills',
  {
    zidane: false,
  },
  undefined,
  undefined,
  'Pills',
);
