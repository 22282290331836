import EntityList from '../listPage/EntityList';

export default new EntityList(
  'tier',
  'meta/pub-portal/tiers',
  {
    zidane: true,
  },
  undefined,
  undefined,
  'Tiers',
);
