import EntityList from '../listPage/EntityList';

export default new EntityList(
  'news',
  'meta/news',
  {
    zidane: true,
  },
  undefined,
  undefined,
  'News',
);
