//      ,---.
//    ,/     \__.'~\
//    |        '  . `===---      -Ed touched this file-
//    `|   ,     ,--'
//     `. /___,-'
//       |    \
//       ,\_  ,`-=

export const getTopFixtures = state => state.topFixtures.topFixtures;

export const getAllFixtures = state => state.topFixtures.allFixtures;

export const getFetchingFixtures = state => state.topFixtures.fetchingFixtures;

export const getFetchingMoreFixtures = state => state.topFixtures.fetchingMoreFixtures;

export const getFetchingTopFixtures = state => state.topFixtures.fetchingTopFixtures;

export const getError = state => state.topFixtures.error;

export const getUpdateTopFixtureError = state => state.topFixtures.updateTopFixtureError;

export const getAddOrRemoveFixtureError = state => state.topFixtures.addOrRemoveFixtureError;

export const getFetchingMPCountries = state => state.topFixtures.fetchingMPCountries;

export const getMatchpintCountries = state => state.topFixtures.matchpintCountries;

export const getMatchpintCountriesError = state => state.topFixtures.matchpintCountriesError;

export const getIsFixtureItemLoading = state => state.topFixtures.fixtureItemLoading;
