export const getAnswersToBeUpdated = ({
  quizAnswersForQuestion: { initialAnswers, updatedAnswers },
}) =>
  updatedAnswers.filter(ans => {
    const initialAnswer = initialAnswers.find(initialAns => initialAns.id === ans.id);
    return (
      initialAnswer && (ans.correct !== initialAnswer.correct || ans.text !== initialAnswer.text)
    );
  });

export const getHasQuizAnswerChanges = state => {
  const {
    quizAnswersForQuestion: { answersToAdd, initialAnswers, updatedAnswers },
  } = state;
  if (answersToAdd.filter(ans => Boolean(ans.text)).length) {
    return true;
  }
  if (updatedAnswers.length !== initialAnswers.length) {
    return true;
  }
  return getAnswersToBeUpdated(state).length > 0;
};

export const getHasAnswersToCreate = state => state.quizAnswersForQuestion.answersToAdd.length > 0;

export const getUpdatedAnswers = state => state.quizAnswersForQuestion.updatedAnswers;

export const getAnswersError = state => state.quizAnswersForQuestion.error;

export const getAnswersToAdd = state => state.quizAnswersForQuestion.answersToAdd;
