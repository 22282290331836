import createFormPage from '../formPage';

const params = {
  endpoint: 'meta/homepage/sections',
  sections: {
    General: ['id', 'name', 'description', 'title', 'type', 'displayHorizontal', 'orientation'],
  },
};

export default createFormPage('homepageSection', params);
