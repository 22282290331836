/**
 *                          _
 *         _        ,-.    / )          .-.
 *        ( `.     // /-._/ /          ((`-) Q U O N K
 *         `\ \   /(_/ / / /             \\
 *           ; `-`  (_/ / /               \\
 *           |       (_/ /         .="""=._))
 *           \          /         /  .,   .'
 *            )       /`         /__(,_.-'
 *           /      /`          `    /|
 * Author: Marwan                   /_|__
 * Date: 17/09/2018                   | `))
 *                                    |
 *                                   -"==
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField as Input } from 'material-ui';
import TextField from '../../../components/FormField/ReadOnly';
import { hasChanged } from '../../../store/paymentInfo/actionCreator';
import { fetchPaymentInfo, fetchPaymentInfoFormShape } from '../../../store/paymentInfo/thunks';
import {
  getPaymentInfo,
  isFetching,
  getPaymentInfoShape,
  getPaymentInfoFieldErrors,
  getInitialPaymentInfo,
} from '../../../store/paymentInfo/selectors';

import DatePickerEdit from '../../../components/DatePickerEdit';
import AutoCompleteTextField from '../../../components/AutoCompleteTextField';
import EnumField from '../../../components/EnumField';

const PaymentInfoSection = ({
  fetchPaymentInformation,
  paymentInfo,
  initialPaymentInfo,
  onChange,
  fetchFormShape,
  formShape,
  errors,
  isPhone,
  children,
}) => {
  useEffect(() => {
    fetchFormShape();
    fetchPaymentInformation();
  }, [fetchFormShape, fetchPaymentInformation]);

  const getXeroField = () => {
    if (initialPaymentInfo && initialPaymentInfo.xeroId) {
      return (
        <TextField
          className="VenueEditPage__paymentInfo_main_link_website"
          name="Xero link"
          text={`${process.env.REACT_APP_XERO_CONTACT_URL}${paymentInfo.xeroId}`}
          isLink={Boolean(initialPaymentInfo && initialPaymentInfo.xeroId)}
        />
      );
    }
    if (formShape && formShape.xeroId && formShape.xeroId.options.readOnly) {
      return <TextField name={formShape.xeroId.label} text="-" />;
    }
    return (
      <>
        <Input
          placeholder="Xero Id"
          onChange={e => onChange('xeroId', e.target.value)}
          errorText={errors && errors.xeroId}
        />
        <div className="TextField__wrapper_content_name">
          Id of the client on Xero.
          <br />
          Find their account on Xero, and copy the ID part from the URL.
          <br />
          eg: for https://go.xero.com/Contacts/View/6e1ac589-5681-429e-a982-06df102ddb27
          <br />
          you want to input 6e1ac589-5681-429e-a982-06df102ddb27
        </div>
      </>
    );
  };

  return (
    <div className="VenueEditPage__paymentInfo_main">
      <div className="VenueEditPage__paymentInfo_main_editSection">
        {paymentInfo && formShape && (
          <>
            <div className="VenueEditPage__paymentInfo_main_editSection_content">
              <DatePickerEdit
                isPhone={isPhone}
                date={paymentInfo.cancelDate}
                error={errors && errors.cancelDate}
                name="Cancel date"
                handleEditField={val => onChange('cancelDate', val)}
              />
              <DatePickerEdit
                isPhone={isPhone}
                date={paymentInfo.resubscriptionDate}
                error={errors && errors.resubscriptionDate}
                name="Resubscription date"
                handleEditField={val => onChange('resubscriptionDate', val)}
              />
              <DatePickerEdit
                isPhone={isPhone}
                date={paymentInfo.nextInvoiceDate}
                error={errors && errors.nextInvoiceDate}
                name="Date for next invoice"
                handleEditField={val => onChange('nextInvoiceDate', val)}
              />
              <DatePickerEdit
                isPhone={isPhone}
                date={paymentInfo.pauseInvoiceUntil}
                error={errors && errors.pauseInvoiceUntil}
                name={formShape?.pauseInvoiceUntil?.label}
                handleEditField={val => onChange('pauseInvoiceUntil', val)}
              />
              <AutoCompleteTextField
                dataSource={searchText =>
                  `venues/pub-portal-code?search=${searchText}&limit=10&offset=0`
                }
                errorMessage={errors && errors.codeId}
                formatDataSource={promocodes =>
                  promocodes.map(code => ({
                    text: `${code.code} (${code.id})`,
                    value: code.id,
                  }))
                }
                onValueChange={val => onChange('codeId', val)}
                label="New style promocode"
                searchText={paymentInfo.codeId}
              />

              {formShape.type && formShape.type.options && (
                <EnumField
                  label="Type"
                  value={paymentInfo.type && paymentInfo.type.toString()}
                  availableValues={Object.keys(formShape.type.options.enum).map(val => ({
                    key: val,
                    value: val,
                  }))}
                  onChange={selected => onChange('type', selected)}
                />
              )}
              {formShape.frequency && formShape.frequency.options && (
                <EnumField
                  label="Frequency"
                  value={paymentInfo.frequency && paymentInfo.frequency.toString()}
                  availableValues={Object.keys(formShape.frequency.options.enum).map(val => ({
                    key: val,
                    value: val.toString(),
                  }))}
                  onChange={selected => onChange('frequency', selected)}
                />
              )}
              <AutoCompleteTextField
                dataSource={searchText =>
                  `venues/group-codes?search=${searchText}&limit=10&offset=0`
                }
                errorMessage={errors && errors.groupCode}
                formatDataSource={promocodes =>
                  promocodes.map(code => ({
                    text: `${code.autocompleteValue} (${code.id})`,
                    value: code.autocompleteValue,
                  }))
                }
                onValueChange={val => onChange('groupCode', val)}
                label="Group code"
                searchText={paymentInfo.groupCode}
              />
              {children}
            </div>
            <div className="VenueEditPage__paymentInfo_main_editSection_content">
              {Object.keys(formShape)
                .filter(key => formShape[key].options.readOnly)
                .map(f => (
                  <TextField key={f} name={formShape[f].label} text={paymentInfo[f] || '-'} />
                ))}
              {getXeroField()}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  paymentInfo: getPaymentInfo(state),
  initialPaymentInfo: getInitialPaymentInfo(state),
  fetching: isFetching(state),
  formShape: getPaymentInfoShape(state),
  errors: getPaymentInfoFieldErrors(state),
});

const mapDispatchToProps = dispatch => ({
  fetchPaymentInformation: () => dispatch(fetchPaymentInfo()),
  onChange: (field, val) => dispatch(hasChanged(field, val)),
  fetchFormShape: () => dispatch(fetchPaymentInfoFormShape()),
});

PaymentInfoSection.propTypes = {
  fetchPaymentInformation: PropTypes.func.isRequired,
  initialPaymentInfo: PropTypes.shape().isRequired,
  paymentInfo: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  fetchFormShape: PropTypes.func.isRequired,
  formShape: PropTypes.shape().isRequired,
  errors: PropTypes.shape(),
  children: PropTypes.node.isRequired,
  isPhone: PropTypes.bool.isRequired,
};

PaymentInfoSection.defaultProps = {
  errors: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInfoSection);
