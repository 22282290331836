/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */

import { formRequest } from './requests';

export function login(email, password) {
  return async function _login(dispatch) {
    return dispatch(
      formRequest('auth/signin', {
        method: 'POST',
        body: {
          email,
          password,
        },
      }),
    );
  };
}

export function resetPassword(email) {
  return async function _changePassword(dispatch) {
    return dispatch(
      formRequest('auth/resetpasswd', {
        method: 'POST',
        body: {
          email,
        },
      }),
    );
  };
}
