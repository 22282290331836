/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 04/05/2018
 */
import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';

const LoadMoreButton = ({ onClick, className }) => (
  <FlatButton onClick={onClick} label="LOAD MORE" primary className={className} />
);

LoadMoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

LoadMoreButton.defaultProps = {
  className: '',
};

export default LoadMoreButton;
