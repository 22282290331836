/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import AddTile from 'material-ui/svg-icons/action/note-add';
import RemoveTile from 'material-ui/svg-icons/action/delete-forever';
import FlatButton from 'material-ui/FlatButton';
import './style.scss';
import OnBoardingTileImage from './OnBoardingTileImage';
import MultilineTextFieldEdit from '../MultilineTextFieldEdit';
import OnBoardingTilePreview from '../previews/OnBoardingTile';
import colors from '../../theme/index.scss';

const OnboardingTile = ({ tiles, entity, entityId, onValueChange }) => {
  const handleEditField = (tileIndex, fieldData, fieldToEdit) => {
    const newOnboardingTiles = [...tiles];

    const newOnbordingTile = {
      ...tiles[tileIndex],
    };

    newOnbordingTile[fieldToEdit] = fieldData;

    if (fieldToEdit === 'title') {
      newOnbordingTile.id = fieldData.replace(' ', '-').toLowerCase();
    }

    newOnboardingTiles[tileIndex] = newOnbordingTile;
    onValueChange(newOnboardingTiles);
  };

  const newTile = () => {
    const newEmptyTile = { image: '', title: '', description: '' };
    if (tiles) {
      const newOnboardingTile = [...tiles];

      newOnboardingTile.push(newEmptyTile);

      onValueChange(newOnboardingTile);
    } else {
      onValueChange([newEmptyTile]);
    }
  };

  const removeTile = () => {
    if (tiles) {
      const newOnboardingTile = [...tiles];

      newOnboardingTile.pop();

      onValueChange(newOnboardingTile);
    }
  };

  return (
    <>
      {tiles &&
        tiles.map((tile, i) => (
          <div key={i} className="OnboardingTile__wrapper">
            <div className="OnboardingTile__wrapper_content">
              <OnBoardingTileImage
                help="Select an image for tile"
                index={i}
                entity={entity}
                entityId={entityId}
                handleEditField={content => handleEditField(i, content, 'image')}
                imageLink={tile.image}
              />
              <div className="OnboardingTile__wrapper_textField">
                <MultilineTextFieldEdit
                  handleEditField={content => handleEditField(i, content, 'title')}
                  help="Main tile text"
                  name="Title"
                  text={tile.title}
                />
                <MultilineTextFieldEdit
                  handleEditField={content => handleEditField(i, content, 'description')}
                  help="Secondary text of the tile"
                  name="Content"
                  text={tile.description}
                />
              </div>
            </div>
            <div className="OnboardingTile__wrapper_preview">
              <OnBoardingTilePreview
                descriptionContent={tile.description}
                imageLink={tile.image}
                titleContent={tile.title}
              />
            </div>
          </div>
        ))}

      <div className="CampaignEditPage__onboardingTile_button">
        <FlatButton
          className="CampaignEditPage__onboardingTile_button_add"
          label="Add Tile"
          icon={<AddTile color={colors.black} />}
          onClick={() => newTile(tiles, onValueChange)}
        />
        {tiles && tiles.length !== 0 && (
          <FlatButton
            className="CampaignEditPage__onboardingTile_button_remove"
            label="Remove Tile"
            icon={<RemoveTile color={colors.darkRed} />}
            onClick={() => removeTile(tiles, onValueChange)}
          />
        )}
      </div>
    </>
  );
};

OnboardingTile.propTypes = {
  tiles: PropTypes.arrayOf(PropTypes.shape()),
  entity: PropTypes.string.isRequired,
  entityId: PropTypes.number.isRequired,
  onValueChange: PropTypes.func.isRequired,
};
OnboardingTile.defaultProps = {
  tiles: null,
};

export default OnboardingTile;
