/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 27/06/2018
 */
import * as actions from './actions';
import { fetchHomepageSections, saveHomepageSections } from '../thunks/api-meta';

import { getSelectedSections } from './selectors';
import { UKcountryID } from '../../utils/globalVariables';

/**
 * @description Allows to make un-staged changes to the homepage.
 * @param {Array} selected
 * @param {Array} notSelected
 * @returns {function}
 */
export function changeSections(selected, notSelected) {
  return function _changeSections(dispatch) {
    const newSelected = selected.map((section, index) => ({
      ...section,
      homepageOrder: index + 1,
    }));

    const newNotSelected = notSelected.map(section => ({
      ...section,
      homepageOrder: null,
    }));

    dispatch(actions.changeHomepageSections(newSelected, newNotSelected));
  };
}

/**
 * @description Allows to discard un-staged changes.
 * @returns {function}
 */
export function discardChanges() {
  return function _discardChanges(dispatch) {
    dispatch(actions.discardChanges());
  };
}

/**
 * @description Allows to fetch the Homepage sections from the micro-services.
 * @returns {function}
 */
export function fetchSections(countryID) {
  return function _fetchHomepageSections(dispatch) {
    dispatch(actions.loadHomepageSections());

    return dispatch(fetchHomepageSections(countryID))
      .then(response => response.getResult())
      .then(sections => {
        dispatch(actions.loadHomepageSuccess(sections));
        return sections;
      })
      .catch(error => {
        dispatch(actions.loadHomepageError(error.message));
        return error.message;
      });
  };
}

/**
 * @description Allows to save un-staged changes.
 * @returns {function(*, *): (Promise<T> | * | TypeError)}
 */
export function saveSectionsOrder(countryID) {
  return function _savePriority(dispatch, getState) {
    const sections = getSelectedSections(getState());

    dispatch(actions.saveHomepageOrder());

    const sectionIds = sections.map(section => section.id);

    return dispatch(saveHomepageSections(countryID, sectionIds))
      .then(response => response.getResult())
      .then(result => {
        dispatch(actions.saveHomepageOrderSuccess());
        return result;
      })
      .catch(error => {
        dispatch(actions.saveHomepageOrderError(error.message));
        return error.message;
      });
  };
}

export function copyFromUK(countryID) {
  return function _copyFromUK(dispatch) {
    return dispatch(fetchHomepageSections(UKcountryID))
      .then(response => response.getResult())
      .then(r => r.filter(c => c.homepageOrder).map(c => c.id))
      .then(data =>
        dispatch(saveHomepageSections(countryID, data))
          .then(response => response.getResult())
          .then(result => {
            dispatch(actions.saveHomepageOrderSuccess());
            return result;
          }),
      )
      .catch(error => {
        dispatch(actions.saveHomepageOrderError(error.message));
        return error.message;
      });
  };
}
