import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import LockIcon from 'material-ui/svg-icons/action/lock';
import FormPasswordInput from '../FormPasswordInput';
import FormAlert from '../FormAlert';
import AnimatedCheckBox from '../AnimatedCheckbox';
import colors from '../../theme/index.scss';
import './styles.scss';

const PasswordReset = ({ updatePassword, isDesktop }) => {
  const [state, setState] = React.useState({ password: '', passwordConfirmation: '' });
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [success, setSuccess] = React.useState('');

  const handleClose = () => {
    setSuccess(false);
    setErrorMessage('');
    setState({ password: '', passwordConfirmation: '' });
    setSuccess(false);
    setOpen(false);
  };

  const sendCredentials = () => {
    const { password, passwordConfirmation } = state;
    if (password !== passwordConfirmation) {
      setErrorMessage('The Password and the Password Confirmation must be equal.');
      return;
    }

    if (!(password.length > 0 && passwordConfirmation.length > 0)) {
      setErrorMessage('You must fill all fields.');
      return;
    }
    updatePassword(password, passwordConfirmation)
      .then(r => {
        if (r?.data?.error) {
          throw new Error(
            `${r?.data?.error.message} ${Object.values(r?.data?.error?.fields).join(' ')}`,
          );
        }
        setSuccess(true);
      })
      .catch(e => {
        setErrorMessage(e.message);
      });
  };

  const actions = [
    <FlatButton label="Cancel" primary onClick={handleClose} />,
    <FlatButton
      className="PasswordReset_submit"
      label="Submit"
      primary
      onClick={sendCredentials}
    />,
  ];
  return (
    <>
      <FlatButton
        backgroundColor={colors.standardGrey}
        icon={<LockIcon color={colors.white} />}
        label={isDesktop ? 'Reset Password' : null}
        labelStyle={{ color: colors.white }}
        onClick={() => setOpen(true)}
      />

      <Dialog
        actions={actions}
        className="PasswordReset_main"
        modal={false}
        open={open}
        onRequestClose={handleClose}
        title="RESET PASSWORD"
      >
        <div className="PasswordReset_main_background">
          <LockIcon />
        </div>
        <form className="PasswordReset_main_inputs">
          {!success ? (
            <>
              <FormPasswordInput
                key="password"
                className="ResetPassword"
                id="password"
                label="New Password"
                onChange={e => setState({ ...state, password: e.target.value })}
                required
              />
              <FormPasswordInput
                key="passwordConfirmation"
                className="ResetPassword"
                id="passwordConfirmation"
                label="Confirm Password"
                onChange={e => setState({ ...state, passwordConfirmation: e.target.value })}
                required
              />
            </>
          ) : (
            <div className="PasswordReset_main_inputs_success">
              <span>The Password Was Updated</span>
              <AnimatedCheckBox onAnimationEnd={handleClose} />
            </div>
          )}
        </form>
        {errorMessage !== '' ? <FormAlert errorMessage={errorMessage} /> : null}
      </Dialog>
    </>
  );
};

PasswordReset.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  updatePassword: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isDesktop: state.media.isDesktop,
});

export default connect(mapStateToProps)(PasswordReset);
