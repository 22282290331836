import createFormPage from '../formPage';

const params = {
  endpoint: 'venues/group-code',
  sections: {
    General: ['id', 'name', 'groupCode', 'status', 'notes'],
    Address: ['address1', 'address2', 'postcode'],
    Contact: ['contactFname', 'contactEmail', 'contactNumber'],
  },
  create: {
    fields: ['name', 'groupCode'],
  },
};

const GroupCode = createFormPage('groupCodes', params);

export default GroupCode;
