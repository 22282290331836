import FlatButton from 'material-ui/FlatButton';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import colors from '../../../theme/index.scss';
import request from '../../../utils/apiRequest/requests';

const UpdateSearch = ({ editedValue }) => {
  const [message, setMessage] = useState(null);
  return (
    <>
      <FlatButton
        backgroundColor={colors.primaryRed}
        onClick={() => {
          setMessage(null);
          return request('search/force-update', {
            method: 'POST',
            body: { type: 'fixture', entityId: editedValue },
          })
            .then(r => setMessage(r.getResult().message))
            .catch(() => setMessage('Something went wrong'));
        }}
      >
        UPDATE SEARCH
      </FlatButton>
      <p>{message}</p>
    </>
  );
};

UpdateSearch.propTypes = {
  editedValue: PropTypes.number.isRequired,
};

export default UpdateSearch;
