export const swapVariableForNumber = (endpoint, url) => {
  if (endpoint.indexOf(':') > -1) {
    const indexOfVariable = endpoint.indexOf(':');
    const subString = endpoint.substring(indexOfVariable);
    const variableName = subString.substring(0, subString.indexOf('/'));
    const afterVariable = url.substring(indexOfVariable);
    const variableValue = afterVariable.substring(0, afterVariable.indexOf('/'));
    const replacedEndpoint = endpoint.replace(variableName, variableValue);

    return replacedEndpoint;
  }

  return endpoint;
};

/*
 * The idea of this method is to allow listing and form updates to resources that belong
 * to resources for example game/2/leagues/1/players, the endpoint for the list page would
 * be /game/:gameId/leagues/:leagueId/players which we will then need to update the variables
 * for the current route. the first section of the endpoint must not be a variable
 */
export const getEndpointFromDynamic = (endpoint, currentURL) => {
  if (endpoint.indexOf(':') > -1) {
    const match = currentURL.indexOf(endpoint.substring(0, endpoint.indexOf('/')));
    if (match > 0) {
      const shortURL = currentURL.substring(match);
      let newEnd = swapVariableForNumber(endpoint, shortURL);
      while (newEnd.indexOf(':') > -1) {
        newEnd = swapVariableForNumber(newEnd, shortURL);
      }
      return newEnd;
    }
  }

  return endpoint;
};

export const getBase64FromFile = file => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = readerEvent => resolve(readerEvent.target.result);
    reader.onerror = () => reject(new Error('SOMETHING WENT WRONG'));
    reader.readAsDataURL(file);
  });
};
