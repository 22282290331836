/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 13/11/2018
 */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// UI Component.
import LoadingWheel from '../../../../components/LoadingWheel';

// Store functions.
import * as select from '../store/selectorFactory';

// Utils.
import { PAGE_TYPE_CREATE, PAGE_TYPE_EDIT } from '../utils/constants';
import * as customPropTypes from '../utils/propTypes';

/**
 * @description Returns the selector that should be used to get if the form is saving.
 * @param entityName
 * @param parameters
 * @param type
 * @return {function}
 */
function getIsSavingSelector(entityName, parameters, type) {
  switch (type) {
    case PAGE_TYPE_CREATE:
      return select.createIsCreationSaving(entityName, parameters);
    case PAGE_TYPE_EDIT:
      return select.createIsEditionSaving(entityName, parameters);
    default:
      return () => undefined;
  }
}

const mapStateToProps = (state, { entityName, parameters, type }) => {
  const isFetching = select.createIsDescriptionFetching(entityName)(state);
  const isSaving = getIsSavingSelector(entityName, parameters, type)(state);

  return {
    show: isFetching || isSaving,
  };
};

const ConnectedLoadingWheel = connect(mapStateToProps)(LoadingWheel);

ConnectedLoadingWheel.propTypes = {
  entityName: PropTypes.string.isRequired,
  parameters: customPropTypes.parameters.isRequired,
  type: customPropTypes.pageType.isRequired,
};

export default ConnectedLoadingWheel;
