/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 02/11/2018
 */
import React from 'react';
import PropTypes from 'prop-types';

import ExpendableCard from '../../../../components/ExpendableCard';

// Utils.
import * as customPropTypes from '../utils/propTypes';
import { generateKeyFromField, getFieldPath } from '../utils/paramParsers/sections';
import { connectWithFormStore } from '../utils/hoc';
import FormField from '../../../../components/FormField';

const FormSection = ({ entityName, parameters, section, title, type, expandable }) => (
  <ExpendableCard title={title} expandable={expandable}>
    {section.map(field => {
      const key = generateKeyFromField(entityName, field);

      const fieldPath = getFieldPath(entityName, field);

      const ComponentRendered = connectWithFormStore(
        typeof field !== 'object' || !field.Component ? FormField : field.Component,
      );

      return (
        <ComponentRendered
          key={key}
          entityName={entityName}
          fieldPath={fieldPath}
          parameters={parameters}
          type={type}
        />
      );
    })}
  </ExpendableCard>
);

FormSection.propTypes = {
  expandable: PropTypes.bool,
  entityName: PropTypes.string.isRequired,
  parameters: customPropTypes.parameters.isRequired,
  section: customPropTypes.section.isRequired,
  title: PropTypes.string.isRequired,
  type: customPropTypes.pageType.isRequired,
};

FormSection.defaultProps = {
  expandable: false,
};

export default FormSection;
