import { getURLQuery } from '@matchpint/react-common';
import { request } from './requests';

// eslint-disable-next-line import/prefer-default-export
export function getReport(id, query) {
  return async function _getReportsList(dispatch) {
    return dispatch(
      request(`meta/reports/${id}${query ? `${getURLQuery(query)}` : ''}`, {
        method: 'GET',
      }),
    );
  };
}
