import React from 'react';
import quizQuestion from './entityList';
import createListPage from '../listPage';
import { STRING } from '../listPage/fieldTypes';
import tabs from '../../../routes/tabs';
import './styles.scss';

const columns = {
  image: {
    label: 'Image',
    renderValue: value => <img className="QuizQuestionImage" src={value} alt="" />,
  },
  id: {
    label: 'Id',
    type: STRING,
    style: {
      width: '5%',
    },
  },
  roundId: {
    label: 'Round ID',
    type: STRING,
    style: {
      width: '5%',
    },
  },
  text: {
    label: 'Text',
    type: STRING,
  },
};

export default quizQuestion.map(entity =>
  createListPage(entity, columns, {
    navTabs: tabs[entity.entityName],
  }),
);
