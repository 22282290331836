/**            -                              ___
 *            |.|                          . /\_/\
 *          __]-[_________ /             .  (-<_>-)
 *         /        _____|<_          .   .  \/_\/
 *        / _   &  /               .   .
 *       / / \_ __|            .
 *     _/_/  / X   \
 *    <_/   /   ____\
 *         /___/|  /             zinedine zidane
 *         |  / ( <                --france--
 *         ( <   \ |
 *          \ |   >\
 *          _>|  (_/
 *         (__|
 *
 * MatchPint Ltd
 * @author Andreia Almeida
 * Date: 18/12/2018
 */
import { games } from '../../games';
import createFormPage from '../formPage';

const params = gameId => ({
  endpoint: `games/${gameId}/prizes`,
  create: {
    fields: ['active', 'gameId', 'name', 'description', 'type', 'video'],
  },
});

export default games.map(({ game }) => createFormPage('gamePrize', params(game)));
