import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MpModal from '../../../../components/MpModal/mpModal';
import { createGetShowSaveConfirmation, createGetSaveConfirmText } from '../store/selectorFactory';
import { createSaveCreation } from '../store/thunksFactory';
import { createHideShowSaveConfirmation } from '../store/actionCreatorFactory';

const SaveConfirmDialog = ({ show, text, save, discardModal }) => (
  <MpModal visible={show} onConfirm={() => save()} onCancel={discardModal}>
    {/* eslint-disable-next-line react/no-danger */}
    <div style={{ paddingTop: 15, paddingBottom: 5 }} dangerouslySetInnerHTML={{ __html: text }} />
  </MpModal>
);

const mapStateToProps = (state, { entityName }) => ({
  show: createGetShowSaveConfirmation(entityName)(state),
  text: createGetSaveConfirmText(entityName)(state),
});

const mapDispatchToProps = (dispatch, { entityName, parameters }) => ({
  save: () => dispatch(createSaveCreation(entityName, parameters)()),
  discardModal: () => dispatch(createHideShowSaveConfirmation(entityName)()),
});

SaveConfirmDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
  discardModal: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveConfirmDialog);
