/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 08/06/2018
 */

import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';

import './index.scss';

/* eslint-disable jsx-a11y/click-events-have-key-events */

const MpLink = ({ children, className, to, target }) => {
  if (to.charAt(0) === '/') {
    return (
      <Link className={`${className} MpLink`} target={target} to={to}>
        {children}
      </Link>
    );
  }
  return (
    <a className={`${className} MpLink`} href={to} target={target}>
      {children}
    </a>
  );
};

MpLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  target: PropTypes.string,
  to: PropTypes.string.isRequired,
};

MpLink.defaultProps = {
  children: null,
  className: '',
  target: null,
};

export default MpLink;
