/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 17/10/2018
 */
import React from 'react';
import PropTypes from 'prop-types';

import TextField from 'material-ui/TextField';

import InfoIcon from '../../InfoIcon';

import './index.scss';

const verifyTextLength = (text, maxTextLength, handleEditField) => {
  if (!maxTextLength || text.length <= maxTextLength) {
    handleEditField(text);
  }
};

const LongTextField = ({
  error,
  handleEditField,
  help,
  name,
  maxTextLength,
  previousText,
  text,
}) => (
  <div className="MultilineTextFieldEdit">
    <div className="MultilineTextFieldEdit_left">
      <div className="MultilineTextFieldEdit_left_new">
        <TextField
          fullWidth
          className="MultilineTextFieldEdit_left_new_text"
          errorText={error}
          floatingLabelText={name}
          multiLine
          onChange={(e, newText) => verifyTextLength(newText, maxTextLength, handleEditField)}
          value={text}
        />
        {maxTextLength && (
          <div className="MultilineTextFieldEdit_left_new_chars">
            {`Number of characters: ${text.length} / ${maxTextLength} chars`}
          </div>
        )}
      </div>
      {help && (
        <div className="MultilineTextFieldEdit_left_info_wrapper">
          <InfoIcon tooltip={help} tooltipPosition="bottom-left" />
        </div>
      )}
    </div>
    <div className="MultilineTextFieldEdit_previous">
      <h5 className="MultilineTextFieldEdit_previous_title">Previous :</h5>
      <span className="MultilineTextFieldEdit_previous_text">
        {previousText &&
          previousText.split('\n').map((item, i) => {
            // We disable the eslint rule react/no-array-index-key as there are no key for those
            // elements and it doesn't make sense
            if (item === '') {
              // eslint-disable-next-line react/no-array-index-key
              return <br key={i} />;
            }
            // eslint-disable-next-line react/no-array-index-key
            return <p key={i}>{item}</p>;
          })}
      </span>
    </div>
  </div>
);

LongTextField.propTypes = {
  error: PropTypes.string,
  handleEditField: PropTypes.func.isRequired,
  help: PropTypes.string,
  name: PropTypes.string,
  maxTextLength: PropTypes.number,
  previousText: PropTypes.string,
  text: PropTypes.string,
};

LongTextField.defaultProps = {
  error: null,
  help: null,
  name: '',
  maxTextLength: null,
  previousText: '',
  text: '',
};

export default LongTextField;
