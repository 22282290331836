import EntityList from '../listPage/EntityList';
import { games } from '../../games';

export default games.map(game => ({
  entity: new EntityList(
    'gameLeagueMonthPrize',
    `games/${game.game}/${game.leagueType}-league-prize`,
    {
      zidane: true,
    },
    undefined,
    undefined,
    `${game.leagueType} league prizes`,
  ),
  game,
}));
