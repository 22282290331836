import React from 'react';
import { PropTypes } from 'prop-types';
import './index.scss';
import { Snackbar } from 'material-ui';
import { connect } from 'react-redux';
import { getIsToastOpen } from '../../store/toast/selectors';
import { setShowToast } from '../../store/toast/actions';

const hideAfterMs = 4000;

const SuccessToastWrapper = ({ open, setOpen }) => (
  <Snackbar
    autoHideDuration={hideAfterMs}
    className="SuccessSnackBar"
    message="Changes successfully saved!"
    open={open}
    onRequestClose={() => setOpen(false)}
  />
);

SuccessToastWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  open: getIsToastOpen(state),
});

const mapDispatchToProps = dispatch => ({
  setOpen: val => dispatch(setShowToast(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuccessToastWrapper);
