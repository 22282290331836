/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 30/04/2018
 */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import checkAuthentication from '../store/member/thunks/checkAuthentication';
import {
  isAuthenticated,
  isLoadingAuthentication,
  getAuthenticatedMember,
  getPermissions,
  getPermissionsPending,
} from '../store/member/selectors';
import { getCurrentLocation } from '../store/navigation/selectors';
import LoadingPage from '../pages/LoadingPage';

import Navigation from '../components/Navigation';
import { fetchPermissions, fetchUserLocales } from '../store/member/thunks';
import canUserAccessPage from '../utils/permissions';
import AccessRestrictedPage from '../pages/AccessRestrictedPage/AccessRestrictedPage';
import LoginPage from '../pages/LoginPage';
/**
 * @description High Order Component that enables to access of a component based on authentication.
 * @param Page to render in the content container.
 * @param tabs that will appear as headers.
 * @returns {*}
 */
export default (Page, tabs = {}, allowAccess = false) => {
  const AuthenticatedComponent = props => {
    const {
      checkAuth,
      isAuth,
      userPermissions,
      fetchUserPermissions,
      member,
      isLoading,
      fetchLocales,
    } = props;
    const memberId = member?.id;
    const [loadingLoc, setLoadingLoc] = useState(true);
    useEffect(() => {
      if (!isAuth) checkAuth().catch(() => setLoadingLoc(false));
      // isAuth dependency re-fetches on log out - makes it impossible to sign out
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkAuth]);

    useEffect(() => {
      if (memberId) fetchUserPermissions(memberId);
    }, [fetchUserPermissions, memberId]);

    useEffect(() => {
      if (memberId) {
        fetchLocales(memberId).finally(() => setLoadingLoc(false));
      }
    }, [fetchLocales, memberId, setLoadingLoc]);

    if (isLoading || loadingLoc) {
      return <LoadingPage />;
    }

    if (isAuth && userPermissions) {
      return (
        <Navigation tabs={tabs}>
          {canUserAccessPage(userPermissions, allowAccess) ? (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Page {...props} />
          ) : (
            <AccessRestrictedPage />
          )}
        </Navigation>
      );
    }

    return <LoginPage />;
  };

  AuthenticatedComponent.propTypes = {
    checkAuth: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    isAuth: PropTypes.bool.isRequired,
    member: PropTypes.shape(),
    userPermissions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
      }),
    ),
    fetchUserPermissions: PropTypes.func.isRequired,
    fetchLocales: PropTypes.func,
  };

  AuthenticatedComponent.defaultProps = {
    isLoading: false,
    member: null,
    userPermissions: null,
    fetchLocales: () => {},
  };

  const mapStateToProps = state => ({
    currentLocation: getCurrentLocation(state),
    isAuth: isAuthenticated(state),
    isLoading: isLoadingAuthentication(state) || getPermissionsPending(state),
    member: getAuthenticatedMember(state),
    userPermissions: getPermissions(state),
    permissionsPending: getPermissionsPending(state),
  });

  const mapDispatchToProps = {
    checkAuth: checkAuthentication,
    fetchUserPermissions: fetchPermissions,
    fetchLocales: memberID => fetchUserLocales(memberID),
  };

  return connect(mapStateToProps, mapDispatchToProps)(AuthenticatedComponent);
};
