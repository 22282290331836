/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 24/04/2018
 */
import React from 'react';
import PropTypes from 'prop-types';

import AppBar from 'material-ui/AppBar';
import IconButton from 'material-ui/IconButton';
import { Tabs, Tab } from 'material-ui/Tabs';

import MenuIcon from 'material-ui/svg-icons/navigation/menu';

import theme from '../../theme/index.scss';
import './index.scss';
import MpLink from '../MpLink/MpLink';

const trimRouteSlashes = str =>
  str.length && str[str.length - 1] === '/' ? str.slice(0, -1) : str;

/**
 * Header of a feature in Mobile/Tablet mode.
 */
export const MpHeader = ({ currentLocation, navigate, onIconClick, tabs }) => (
  <>
    <div className="MpHeader">
      <AppBar
        className="MpHeader__appbar"
        onLeftIconButtonClick={onIconClick}
        iconElementLeft={
          <IconButton onClick={onIconClick}>
            <MenuIcon color={theme.black} />
          </IconButton>
        }
        title={
          <MpLink className="flex h-full items-center justify-center" to="/">
            <img src="https://cdn.internal.matchpint.cloud/Logo.png" className="h-10" alt="logo" />
          </MpLink>
        }
      />
    </div>
    {Object.keys(tabs).length > 0 ? (
      <Tabs onChange={location => navigate(location)} value={trimRouteSlashes(currentLocation)}>
        <Tab key={tabs.default.name} label={tabs.default.name} value={tabs.default.location} />
        {tabs.others.map(tab => (
          <Tab key={tab.name} label={tab.name} value={tab.location} />
        ))}
      </Tabs>
    ) : null}
  </>
);

MpHeader.propTypes = {
  currentLocation: PropTypes.string.isRequired, // Current Location for the tabs value
  navigate: PropTypes.func.isRequired, // Tabs navigation
  onIconClick: PropTypes.func.isRequired, // Triggered when the user expands the drawer icon.
  tabs: PropTypes.shape({
    default: PropTypes.shape({
      name: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
    }),
    others: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};

export default MpHeader;
