/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 26/10/2018
 */

import * as constants from './constantFactory';

// Form functionality.
export function createDiscardChanges(entityName) {
  return function discardChanges() {
    return {
      type: constants.DISCARD_CHANGES(entityName),
    };
  };
}

// Edit values.
export function createEditCurrentEntity(entityName) {
  return function editCurrentEntity(pathList, value) {
    return {
      type: constants.EDIT_CURRENT(entityName),
      payload: {
        pathList,
        value,
      },
    };
  };
}

export function createEditNewEntity(entityName) {
  return function editNewEntity(pathList, value) {
    return {
      type: constants.EDIT_NEW(entityName),
      payload: {
        pathList,
        value,
      },
    };
  };
}

// Fetch data.
export function createFetchPending(entityName) {
  return function fetchPending(id) {
    return {
      type: constants.FETCH_PENDING(entityName),
      payload: {
        id,
      },
    };
  };
}

export function createFetchSuccess(entityName) {
  return function fetchSuccess(id, data) {
    return {
      type: constants.FETCH_SUCCESS(entityName),
      payload: {
        id,
        data,
      },
    };
  };
}

export function createFetchError(entityName) {
  return function fetchError(id, error) {
    return {
      type: constants.FETCH_ERROR(entityName),
      payload: {
        id,
        error,
      },
    };
  };
}

// Fetch description.
export function createFetchDescriptionPending(entityName) {
  return function fetchDescriptionPending() {
    return {
      type: constants.FETCH_DESCRIPTION_PENDING(entityName),
    };
  };
}

export function createFetchDescriptionSuccess(entityName) {
  return function fetchDescriptionSuccess(data) {
    return {
      type: constants.FETCH_DESCRIPTION_SUCCESS(entityName),
      payload: {
        data,
      },
    };
  };
}

export function createFetchDescriptionError(entityName) {
  return function fetchDescriptionError(error) {
    return {
      type: constants.FETCH_DESCRIPTION_ERROR(entityName),
      payload: {
        error,
      },
    };
  };
}

// Send directly data to the server without changing the form.
export function createSendDirectEditionPending(entityName) {
  return function sendDirectEditionPending(pathList, value) {
    return {
      type: constants.SEND_DIRECT_EDITION_PENDING(entityName),
      payload: {
        pathList,
        value,
      },
    };
  };
}

export function createSendDirectEditionSuccess(entityName) {
  return function sendDirectEditionSuccess(pathList, data) {
    return {
      type: constants.SEND_DIRECT_EDITION_SUCCESS(entityName),
      payload: {
        pathList,
        data,
      },
    };
  };
}

export function createSendDirectEditionError(entityName) {
  return function sendDirectEditionError(error, fieldErrors = null) {
    return {
      type: constants.SEND_DIRECT_EDITION_ERROR(entityName),
      payload: {
        error,
        fieldErrors,
      },
    };
  };
}

// Save edition.
export function createSaveEditionPending(entityName) {
  return function saveEditionPending() {
    return {
      type: constants.SAVE_EDITION_PENDING(entityName),
    };
  };
}

export function createSaveEditionSuccess(entityName) {
  return function saveEditionSuccess(data) {
    return {
      type: constants.SAVE_EDITION_SUCCESS(entityName),
      payload: {
        data,
      },
    };
  };
}

export function createSaveEditionError(entityName) {
  return function saveEditionError(error, fieldErrors = null) {
    return {
      type: constants.SAVE_EDITION_ERROR(entityName),
      payload: {
        error,
        fieldErrors,
      },
    };
  };
}

// Save creation.
export function createSaveCreationPending(entityName) {
  return function saveCreationPending() {
    return {
      type: constants.SAVE_CREATION_PENDING(entityName),
    };
  };
}

export function createSaveCreationSuccess(entityName) {
  return function saveCreationSuccess(result) {
    return {
      type: constants.SAVE_CREATION_SUCCESS(entityName),
      payload: { result },
    };
  };
}

export function createSaveCreationSuccessPreConfirm(entityName) {
  return function saveCreationSuccessPreConfirm() {
    return {
      type: constants.SAVE_CREATION_SUCCESS_PRECONFIRM(entityName),
    };
  };
}

export function createSaveCreationError(entityName) {
  return function saveCreationError(error, fieldErrors = null) {
    return {
      type: constants.SAVE_CREATION_ERROR(entityName),
      payload: {
        error,
        fieldErrors,
      },
    };
  };
}

export function createShowShowSaveConfirmation(entityName) {
  return function showShowSaveConfirmation() {
    return {
      type: constants.SHOW_SHOW_CONFIRM(entityName),
    };
  };
}

export function createHideShowSaveConfirmation(entityName) {
  return function hideShowSaveConfirmation() {
    return {
      type: constants.HIDE_SHOW_CONFIRM(entityName),
    };
  };
}

export function createSetInitialDefaultValues(entityName) {
  return function setInitialDefaultValues() {
    return {
      type: constants.SET_DEFUALT_VALUES(entityName),
    };
  };
}

export function createSetConfirmText(entityName) {
  return function setConfirmText(text) {
    return {
      type: constants.SET_CONFIRM_TEXT(entityName),
      payload: text,
    };
  };
}

export function createSetSaveSuccessText(entityName) {
  return function setSaveSuccessText(text) {
    return {
      type: constants.SET_SAVE_SUCCESS_TEXT(entityName),
      payload: text,
    };
  };
}

export function createClearValues(entityName) {
  return function clearValues() {
    return {
      type: constants.CLEAR_VALUES(entityName),
    };
  };
}
