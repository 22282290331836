import VenueDataScope from './entityList';
import createListPage from '../listPage';
import tabs from '../../../routes/tabs';

export default createListPage(
  VenueDataScope,
  {
    id: {
      label: 'id',
    },
    dataScopeName: {
      label: 'name',
    },
  },
  {
    navTabs: tabs.venueFinderDataScopes,
  },
);
