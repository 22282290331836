import React from 'react';
import createFormPage from '../formPage';
import MembersInLeague from './component';
import Code from './component/CodeTextField';

import { games } from '../../games';
import StarMembers from './component/StarMembers';

const params = gameId => ({
  endpoint: `games/${gameId}/leagues`,
  sections: {
    General: [
      'name',
      {
        fieldPath: 'code',
        Component: Code,
      },
      'type',
    ],
    Members: [
      {
        Component: () => <MembersInLeague gameId={gameId} />,
      },
    ],
    'Star Members': [
      {
        Component: () => <StarMembers gameId={gameId} />,
      },
    ],
  },
  create: {
    fields: ['name'],
  },
});

export default games.map(({ game }) => createFormPage('league', params(game)));
