/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 01/10/2018
 */

import PropTypes from 'prop-types';

/* eslint-disable consistent-return */

// We want in this file to disable the eslint rule "consistent-return" as it goes against the
// React specifications on custom prop types.
// See prop-types README here: https://www.npmjs.com/package/prop-types#usage

/**
 * @description Custom PropTypes function that validates the format of the dataSource field.
 * @param {Object} props
 * @param {string} propName
 * @param {string} componentName
 * @return {void|Error}
 */
export const dataSourcePropTypes = PropTypes.oneOfType([
  PropTypes.array,
  PropTypes.object,
  PropTypes.func,
]);

/**
 * @description Custom PropTypes function that validates the format of the dataSource field.
 * @param props
 * @param propName
 * @param componentName
 */
export function formatDataSourcePropTypes(props, propName, componentName) {
  const baseErrorMessage = `Invalid prop supplied to ${componentName}`;

  if (props[propName] === null || props[propName] === undefined) {
    return;
  }

  if (typeof props[propName] !== 'function') {
    return new Error(`${baseErrorMessage}: ${propName} must be a function.`);
  }

  const { dataSource } = props;

  if (typeof props.dataSource !== 'function') {
    const newDataSource = props[propName](dataSource);

    if (!newDataSource || newDataSource.constructor !== Array) {
      return new Error(`${baseErrorMessage}: ${propName} must return an array.`);
    }

    if (newDataSource.length === 0) {
      return;
    }

    if (
      typeof newDataSource[0] !== 'object' ||
      typeof newDataSource[0].text !== 'string' ||
      typeof newDataSource[0].value !== 'string'
    ) {
      return new Error(
        `${baseErrorMessage}: ${propName} must return an array of {text: string, value: string}`,
      );
    }
  }
}
