/**            -                              ___
 *            |.|                          . /\_/\
 *          __]-[_________ /             .  (-<_>-)
 *         /        _____|<_          .   .  \/_\/
 *        / _   &  /               .   .
 *       / / \_ __|            .
 *     _/_/  / X   \
 *    <_/   /   ____\
 *         /___/|  /             zinedine zidane
 *         |  / ( <                --france--
 *         ( <   \ |
 *          \ |   >\
 *          _>|  (_/
 *         (__|
 *
 * MatchPint Ltd
 * @author Andreia Almeida
 * Date: 18/12/2018
 */

import gamePrizeList from './entityList';

import createListPage from '../listPage';
import { NUMBER, STRING } from '../listPage/fieldTypes';
import tabs from '../../../routes/tabs';

const createPage = ({ entity, game }) =>
  createListPage(
    entity,
    {
      id: {
        label: 'id',
        type: NUMBER,
      },
      name: {
        label: 'Prize',
        type: STRING,
      },
    },
    {
      navTabs: tabs[`${game.entityName}Prizes`],
    },
  );

export default gamePrizeList.map(createPage);
