/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 18/04/2018
 */

export const LOGIN_FAILURE = 'login/failure';
export const LOGIN_SUCCESS = 'login/success';
export const LOGIN_PENDING = 'login/pending';

export const LOGOUT = 'LOGOUT';
