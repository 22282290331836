import EntityList from '../listPage/EntityList';
import { games } from '../../games';

export default games.map(game => ({
  entity: new EntityList(
    'gameShareCopy',
    `games/${game.game}/share-copy`,
    {
      zidane: true,
    },
    undefined,
    undefined,
    'Game Share Copy',
  ),
  game,
}));
