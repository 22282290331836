import React from 'react';
import ImportItems from '../../components/ImportItems/ImportItems';

const ImportPubsPage = () => (
  <ImportItems
    endpoint="/meta/import?type=venues"
    templateEndpoint="/meta/import/template?type=venues"
    description="Upload CSV file here to import pubs"
    deleteEmptyCellsToggle
    tooltip="If you want to perform an update, use the “id” column.You can reorder columns, and
    ignore the ones you don’t want (eg: You can import a file with only the following
    columns: “id,Master Pub”)."
  />
);

export default ImportPubsPage;
