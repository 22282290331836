/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 21/06/2018
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FlatButton } from 'material-ui';

import { connect } from 'react-redux';
import FormAlert from '../../components/FormAlert';
import HomepageSection from '../../components/HomePageSection';
import LoadingWheel from '../../components/LoadingWheel';
import MpSectionHeader from '../../components/MpSectionHeader';
import SaveBar from '../../components/SaveBar';

import * as DnD from '../../components/DragAndDrop';

import './index.scss';
import { UKcountryID } from '../../utils/globalVariables';
import MatchPintCountrySelector from '../../components/MatchPintCountrySelector/MatchPintCountrySelector';

import * as homepage from '../../store/homepage/selectors';
import {
  changeSections as changeSectionsAction,
  discardChanges as discardChangesAction,
  fetchSections,
  saveSectionsOrder,
  copyFromUK as copyFromUKThunk,
} from '../../store/homepage/thunks';

const renderHomepageSection = (item, selected, isPhone) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <HomepageSection active={selected} isPhone={isPhone} {...item} />
);

const AppsHomepage = ({
  copyDataFromUK,
  changeSections,
  selectedSections,
  notSelectedSections,
  discardChanges,
  hasUnStagedChanges,
  isLoading,
  isPhone,
  saveOrder,
  fetchHomepageSections,
  errorFetch,
  errorSave,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(null);

  const onReorder = (listId, indexStart, indexEnd) => {
    const state = DnD.Context.onReOrderDefault(
      {
        selected: selectedSections,
        notSelected: notSelectedSections,
      },
      listId,
      indexStart,
      indexEnd,
    );

    changeSections(state.selected, state.notSelected);
  };

  const onMove = (sourceId, destinationId, sourceIndex, destinationIndex) => {
    const state = DnD.Context.onMoveDefault(
      {
        selected: selectedSections,
        notSelected: notSelectedSections,
      },
      sourceId,
      destinationId,
      sourceIndex,
      destinationIndex,
    );

    changeSections(state.selected, state.notSelected);
  };

  const copyFromUK = () => {
    copyDataFromUK(selectedCountry.id).then(() => fetchHomepageSections(selectedCountry.id));
  };

  return (
    <div className="HomepageApps">
      <h1 className="HomepageApps_title">Homepage</h1>
      {isLoading ? <LoadingWheel size="full-page" /> : null}
      {(errorSave || errorFetch) && (
        <FormAlert className="Homepage__error" errorMessage={errorSave || errorFetch} />
      )}

      <div className="HomepageApps_countrySelect_container">
        <MatchPintCountrySelector
          displayNI
          onSelect={c => {
            setSelectedCountry(c);
            fetchHomepageSections(c.id);
          }}
          selectedCountry={selectedCountry}
        />
        {selectedCountry && selectedCountry.id !== UKcountryID && (
          <FlatButton onClick={() => copyFromUK()} className="HomepageApps_countrySelect">
            Copy from UK
          </FlatButton>
        )}
      </div>
      <div className="HomepageApps_columns">
        <DnD.Context onMove={onMove} onReorder={onReorder} className="HomepageApps_dnd">
          <DnD.List
            droppableId="notSelected"
            header={
              <div>
                <MpSectionHeader title="Unused sections" />
              </div>
            }
            key="notSelected"
            items={notSelectedSections}
            renderItem={item => renderHomepageSection(item, false, isPhone)}
          />
          <DnD.List
            droppableId="selected"
            header={<MpSectionHeader title="Current Homepage" />}
            key="selected"
            items={selectedSections}
            renderItem={item => renderHomepageSection(item, true, isPhone)}
          />
        </DnD.Context>
        <SaveBar
          isPhone={isPhone}
          onDiscardAction={discardChanges}
          onSaveAction={() => saveOrder(selectedCountry.id)}
          open={hasUnStagedChanges}
        />
      </div>
    </div>
  );
};

AppsHomepage.propTypes = {
  changeSections: PropTypes.func.isRequired,
  copyDataFromUK: PropTypes.func.isRequired,
  discardChanges: PropTypes.func.isRequired,
  errorFetch: PropTypes.string,
  errorSave: PropTypes.string,
  fetchHomepageSections: PropTypes.func.isRequired,
  hasUnStagedChanges: PropTypes.bool,
  isLoading: PropTypes.bool,
  isPhone: PropTypes.bool,
  saveOrder: PropTypes.func.isRequired,
  selectedSections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      title: PropTypes.string,
      type: PropTypes.string.isRequired,
      endPoint: PropTypes.string,
      loadMore: PropTypes.bool.isRequired,
      homepageOrder: PropTypes.number,
    }),
  ),
  notSelectedSections: PropTypes.arrayOf(PropTypes.shape()),
};

AppsHomepage.defaultProps = {
  errorFetch: null,
  errorSave: null,
  hasUnStagedChanges: false,
  isLoading: false,
  isPhone: false,
  notSelectedSections: null,
  selectedSections: null,
};

const mapStateToProps = state => ({
  selectedSections: homepage.getSelectedSections(state),
  errorFetch: homepage.getFetchSectionsError(state),
  errorSave: homepage.getSaveSectionsOrderError(state),
  hasUnStagedChanges: homepage.hasUnStagedChanges(state),
  isLoading: homepage.isSectionsOrderSaving(state) || homepage.areSectionsFetching(state),
  isPhone: state.media.isPhone,
  notSelectedSections: homepage.getNotSelectedSections(state),
});
const mapDispatchToProps = {
  changeSections: changeSectionsAction,
  discardChanges: discardChangesAction,
  fetchHomepageSections: countryID => fetchSections(countryID),
  saveOrder: countryID => saveSectionsOrder(countryID),
  copyDataFromUK: countryID => copyFromUKThunk(countryID),
};
export default connect(mapStateToProps, mapDispatchToProps)(AppsHomepage);
