import createFormPage from '../formPage';

const params = {
  endpoint: 'games/quizzes/rounds/themes',
  create: {
    allFields: true,
  },
};
const quizThemes = createFormPage('quizRoundTheme', params);

export default quizThemes;
