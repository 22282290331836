/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 21/05/2018
 */

/**
 * @description This file contains all the actions required by the EntityList
 *  to register each action in the Redux store.
 */

import * as constants from './constants';

export function fetchListPending(name) {
  return () => ({
    type: `${name}/${constants.FETCH_LIST_PENDING}`,
  });
}

export function fetchListSuccess(name) {
  return ({ result, count }) => ({
    type: `${name}/${constants.FETCH_LIST_SUCCESS}`,
    payload: { result, count },
  });
}

export function fetchListFailure(name) {
  return error => ({
    type: `${name}/${constants.FETCH_LIST_FAILURE}`,
    error,
  });
}

export function fetchLoadMore(name) {
  return list => ({
    type: `${name}/${constants.LOAD_MORE}`,
    list,
  });
}

export function fetchFiltersPending(name) {
  return () => ({
    type: `${name}/${constants.FETCH_FILTERS_PENDING}`,
  });
}

export function fetchFiltersSuccess(name) {
  return ({ filters, sortColumns }) => ({
    type: `${name}/${constants.FETCH_FILTERS_SUCCESS}`,
    payload: {
      filters,
      sortColumns,
    },
  });
}

export function fetchFiltersFailure(name) {
  return error => ({
    type: `${name}/${constants.FETCH_FILTERS_ERROR}`,
    error,
  });
}

export function addFilterAction(name) {
  return (id, value) => ({
    type: `${name}/${constants.ADD_FILTER}`,
    filter: { id, value },
  });
}

export function addMultiFilterAction(name) {
  return (id, value) => ({
    type: `${name}/${constants.ADD_MULTI_FILTER}`,
    filter: { id, value },
  });
}

export function removeFilterAction(name) {
  return filterId => ({
    type: `${name}/${constants.REMOVE_FILTER}`,
    filterId,
  });
}

export function addSortByAction(name) {
  return (sortColumn, sortOrder) => ({
    type: `${name}/${constants.ADD_SORT_BY}`,
    sortColumn,
    sortOrder,
  });
}
