import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RaisedButton from 'material-ui/RaisedButton';
import Block from 'material-ui/svg-icons/content/block';
import colors from '../../../theme/index.scss';
import MpModal from '../../../components/MpModal/mpModal';
import { deleteChannelGroup } from '../../../store/thunks/api-channelGroups';
import { navigate } from '../../../store/navigation/thunks';
import { setShowToast } from '../../../store/toast/actions';

const DeleteButton = ({ id, remove }) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <div>
      <RaisedButton
        backgroundColor={colors.darkRed}
        icon={<Block color={colors.white} />}
        label="DELETE"
        onClick={() => setModalOpen(true)}
        labelStyle={{ color: colors.white }}
      />
      <MpModal
        mainText="Are you sure?"
        visible={modalOpen}
        onCancel={() => setModalOpen(false)}
        onConfirm={() => remove(id)}
      />
    </div>
  );
};

DeleteButton.propTypes = {
  id: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  remove: id =>
    dispatch(deleteChannelGroup(id)).then(() => {
      dispatch(setShowToast(true));
      dispatch(navigate(`/meta/country-channels`));
    }),
});

export default connect(null, mapDispatchToProps)(DeleteButton);
