//                                   ___
//                                ,-""   `.
//                              ,'  _   e )`-._
//                             /  ,' `-._<.===-'  H E N L O
//                            /  /
//                           /  ;
//               _.--.__    /   ;
//  (`._    _.-""       "--'    |
//  <_  `-""                     \
//   <`-                          :
//    (__   <__.                  ;
//      `-.   '-.__.      _.'    /
//         \      `-.__,-'    _,'
//          `._    ,    /__,-'
//             ""._\__,'< <____
//                  | |  `----.`.
//                  | |        \ `.
//                  ; |___      \-``
//                  \   --<
//                   `.`.<
//                    `-'

import createFormPage from '../formPage';
import { games } from '../../games';

const params = (game, fields) => ({
  endpoint: `games/${game.game}/${game.leagueType}-league-prize`,
  sections: {
    General: fields,
  },
  create: {
    fields,
  },
});

export default games.map(g =>
  createFormPage(
    'gameLeagueRoundPrize',
    params(g, ['gameId', 'leagueId', 'gamePrizeId', 'roundId', 'winnerId']),
  ),
);
