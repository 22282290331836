/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 18/04/2018
 */

export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
export const DEFAULT_NAVIGATION_KEY = 'default-navigation';
export const UPDATE_NAV_SCROLL = 'UPDATE_NAV_SCROLL';
