import EntityList from '../listPage/EntityList';

export default new EntityList(
  'quizTheme',
  'games/quizzes/rounds/themes',
  null,
  undefined,
  undefined,
  'Themes',
);
