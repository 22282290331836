import { quizzes } from '../../games';
import createFormPage from '../formPage';

const getParams = id => ({
  endpoint: `games/quizzes/${id}/rounds`,
  sections: {
    General: ['name', 'themeId', 'active', 'startTimeUtc', 'endTimeUtc'],
    Prize: ['offerId', 'winThreshold', 'physicalPrize', 'prizeLimit'],
  },
  create: {
    fields: ['name', 'quizId', 'startTimeUtc', 'endTimeUtc', 'active'],
  },
});
export default quizzes.map(q => createFormPage('quizRound', getParams(q.id)));
