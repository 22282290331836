import React, { useState } from 'react';
import CopyIcon from 'material-ui/svg-icons/content/content-copy';
import CheckIcon from 'material-ui/svg-icons/action/check-circle';
import styles from './Deeplinks.module.scss';

const links = [
  { link: 'matchpint://matchpint' },
  { link: 'matchpint://matchpint/game/{{gameID}}/' },
  { link: 'matchpint://matchpint/minute-game/{{gameID}}/' },
  { link: 'matchpint://matchpint/checkingame/{{gameID}}/' },
  { link: 'matchpint://matchpint/game/{{gameID}}/showOnboarding' },
  { link: 'matchpint://matchpint/game/{{gameID}}/leagues' },
  { link: 'matchpint://matchpint/game/{{gameID}}/prizes' },
  { link: 'matchpint://matchpint/game/{{gameID}}/profile' },
  { link: 'matchpint://matchpint/game/{{gameID}}/hideOnboarding' },
  { link: 'matchpint://matchpint/game/{{gameID}}/leagues/hideOnboarding' },
  { link: 'matchpint://matchpint/game/{{gameID}}/prizes/hideOnboarding' },
  { link: 'matchpint://matchpint/game/{{gameID}}/profile/hideOnboarding' },
  { link: 'matchpint://matchpint/game/{{gameID}}/round/{{roundID}} ' },
  { link: 'matchpint://matchpint/game/{{gameID}}/league/{{leagueID}}' },
  {
    link: 'matchpint://matchpint/game/{{gameID}}/league/{{leagueID}}/code/{{leagueCode}}/join',
  },
  { link: 'matchpint://matchpint/favourites' },
  { link: 'matchpint://matchpint/venueTabs/information/?venueId={{venueID}}' },
  { link: 'matchpint://matchpint/venueTabs/fixtures/?venueId={{venueID}}' },
  { link: 'matchpint://matchpint/venueTabs/offers/?venueId={{venueID}} ' },
  { link: 'matchpint://matchpint/root/fixtureDetail/?fixtureId={{fixtureID}}' },
  { link: 'matchpint://matchpint/root/articleDetail/?articleId={{articleID}} ' },
  { link: 'matchpint://matchpint/app/contact' },
  { link: 'matchpint://matchpint/offer/{{offerID}} ' },
  { link: 'matchpint://matchpint/quiz/{{quizID}}' },
  { link: 'matchpint://matchpint/quiz/{{quizID}}/inviteMate' },
  { link: 'matchpint://matchpint/quiz/{{quizID}}/useCode/blyat ' },
  { link: 'matchpint://matchpint/profile' },
  { link: 'matchpint://matchpint/profile/calendarSync' },
  { link: 'matchpint://matchpint/app/fixtures' },
  { link: 'matchpint://matchpint/app/venues' },
  { link: 'matchpint://matchpint/rating/rateLastVenue' },
  { link: 'https://www.fanzo.com/en/bar/{{venueID}}/platform ' },
  {
    link: 'https://www.fanzo.com/en/bars-showing/{{fixtureID}}/real-madrid-vs-man-city',
  },
  { link: 'https://www.fanzo.com/en/blog/{{articleID}}/womens-six-nations-events' },
  { link: 'https://www.fanzo.com/en/app' },
];

const Deeplinks = () => {
  const [linkCopied, setLinkCopied] = useState(false);
  const [state, setState] = useState({
    gameID: '',
    roundID: '',
    leagueID: '',
    leagueCode: '',
    fixtureID: '',
    articleID: '',
    offerID: '',
    venueID: '',
    quizID: '',
  });

  const replaceMatches = (link, matches) => {
    let string = link;
    matches.forEach(m => {
      // get gameID from {{gameID}} so we can access that state variable
      const stateKey = m[0].replace(/{|}/g, '');
      // switch the placeholder in the URL with the value from state
      if (state[stateKey]) {
        string = string.replace(m[0], state[stateKey]);
      }
    });
    return string;
  };

  const copyToClipboard = text => {
    navigator.clipboard.writeText(text);
    setLinkCopied(text);
    setTimeout(() => setLinkCopied(false), 2000);
  };

  return (
    <div className={styles.container}>
      <h2>Enter Values here</h2>
      <div className={styles.inputs}>
        {Object.keys(state).map(key => (
          <input
            key={key}
            className={styles.input}
            placeholder={key}
            value={state[key]}
            onChange={val => setState({ ...state, [key]: val.target.value })}
          />
        ))}
      </div>
      <h2>Links</h2>
      {links.map(({ link }) => {
        const matches = [...link.matchAll('{{.*?}}')];
        const replaced = replaceMatches(link, matches);
        return (
          <div key={link} className={styles.linkContainer}>
            <a target="_blank" className={styles.link} href={replaced} rel="noreferrer">
              {replaced}
            </a>
            <button
              className={styles.btn_copy}
              type="button"
              onClick={() => copyToClipboard(replaced)}
            >
              {linkCopied !== replaced ? <CopyIcon /> : <CheckIcon />}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default Deeplinks;
