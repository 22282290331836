import EntityList from '../listPage/EntityList';

const eventsList = new EntityList(
  'consumerEvents',
  'sports/service-events/mismatch',
  {
    zidane: true,
  },
  undefined,
  undefined,
  'Consumer Events',
);

export default eventsList;
