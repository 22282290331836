/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 26/10/2018
 */
import * as actions from './actionCreatorFactory';

import {
  createGetDataToCreate,
  createGetFormChanges,
  createGetInitialData,
} from './selectorFactory';
import * as api from './apiCalls';

import { get, set } from '../../../../utils/object';
import { changeLocationWithId } from '../../../../store/navigation/thunks';

/**
 * @description Allows a selector to consider an extension (param.reduxExtension[extensionName]).
 * @param {string} extensionName Field name of the redux extension.
 * @param {function} thunkFactory Thunk factory that can be extended.
 * @return {function}
 */
function allowExtension(extensionName, thunkFactory) {
  return (entityName, parameters) => {
    const extension = get(['reduxExtension', extensionName], parameters);

    if (!extension) {
      return thunkFactory(entityName, parameters);
    }

    return () => (dispatch, getState) =>
      extension(
        (...params) => thunkFactory(entityName, parameters)(...params)(dispatch, getState),
        dispatch,
        getState,
      );
  };
}

/**
 * @description Creates the thunk that will fetch the initialData of the form page.
 * @param {string} entityName
 * @param {Object} parameters
 * @return {function(string, Object): function(number): (Promise<Object|null>)}
 */
function createFetchInitialData(entityName, parameters) {
  return function fetchData(id, queryParams) {
    return async function _fetchData(dispatch) {
      dispatch(actions.createFetchPending(entityName)(id));

      const fetchAPIData = api.createFetchDataAPI(entityName, parameters);

      return dispatch(fetchAPIData(id, queryParams))
        .then(response => {
          const { result } = response.data;
          dispatch(actions.createFetchSuccess(entityName)(id, result));
          return result;
        })
        .catch(error => {
          if (error.isServerError) {
            dispatch(actions.createFetchError(entityName)(id, error));
            return error;
          }

          throw error;
        });
    };
  };
}

/**
 * @description Creates the thunk that will fetch the description data to generate the form page.
 * @param {string} entityName
 * @param {Object} parameters
 * @return {function(string, Object): function(*): (Promise<Object|null>)}
 */
export function createFetchDescription(entityName, parameters) {
  return function fetchDescription(id = 0, queryParams = {}) {
    return async function _fetchDescription(dispatch) {
      dispatch(actions.createFetchDescriptionPending(entityName)());
      const fetchAPIFormDescription = api.createFetchFormDescriptionAPI(
        entityName,
        parameters,
        id,
        queryParams,
      );

      return dispatch(fetchAPIFormDescription())
        .then(response => {
          dispatch(actions.createFetchDescriptionSuccess(entityName)(response.data));
          return response.data;
        })
        .catch(error => {
          if (error.isServerError) {
            dispatch(actions.createFetchDescriptionError(entityName)(error.message));
            return error;
          }

          throw error;
        });
    };
  };
}

/**
 * @description Creates a thunk that will globally fetch the data used in the form page
 *  (description and data).
 * @param {string} entityName
 * @param {Object} parameters
 * @return {function(string, Object): function(*): (Promise<null>)}
 */
export function createFetchData(entityName, parameters) {
  return function fetch(id) {
    return async function _fetch(dispatch) {
      const promises = [];
      promises.push(dispatch(createFetchDescription(entityName, parameters)(id)));
      promises.push(dispatch(createFetchInitialData(entityName, parameters)(id)));
      return Promise.all(promises);
    };
  };
}

/**
 * @description Creates the thunk that will allow to save the edition of a current entity.
 * @param {string} entityName
 * @param {Object} parameters
 * @return {function(string, Object): function(*): (Promise<Object>)}
 */
export const createSaveEdition = allowExtension(
  'saveForm',
  (entityName, parameters) =>
    function saveEdition() {
      return async function _saveEdition(dispatch, getState) {
        const saveAPIForm = api.createSaveFormAPI(entityName, parameters);
        const fieldsToSave = createGetFormChanges(entityName)(getState());
        const initialData = createGetInitialData(entityName)(getState());

        dispatch(actions.createSaveEditionPending(entityName)());

        // Trick to be sure we don't send a request with an empty body.
        if (Object.keys(fieldsToSave).length === 0) {
          return Promise.resolve().then(() => {
            dispatch(actions.createSaveEditionSuccess(entityName)(initialData));
            return initialData;
          });
        }

        return dispatch(saveAPIForm(initialData.id, fieldsToSave))
          .then(response => {
            const res = response.getResult();
            dispatch(actions.createSaveEditionSuccess(entityName)(res));
            return res;
          })
          .catch(error => {
            if (error.isFormError) {
              dispatch(
                actions.createSaveEditionError(entityName)(error.message, error.fieldsError),
              );
              return;
            }

            if (error.isServerError) {
              dispatch(actions.createSaveEditionError(entityName)(error.message));
              return;
            }

            throw error;
          });
      };
    },
);

/**
 * @description Create a thunk that will allow to send data directly to the server without saving
 *  the form.
 * @param {string} entityName
 * @param {Object} parameters
 * @return {function(string, Object): function(string[], any): (Promise<Object|null>)}
 */
export function createSendDirectEdition(entityName, parameters) {
  return function sendDirectEdition(pathList, value) {
    return async function _sendDirectEdition(dispatch, getState) {
      const fieldToSave = set(pathList, value, {});
      const initialData = createGetInitialData(entityName)(getState());

      dispatch(actions.createSendDirectEditionPending(entityName)(pathList, value));

      return dispatch(api.createSaveFormAPI(entityName, parameters)(initialData.id, fieldToSave))
        .then(response => {
          dispatch(
            actions.createSendDirectEditionSuccess(entityName)(pathList, response.getResult()),
          );
          return response.getResult();
        })
        .catch(error => {
          if (error.isError) {
            dispatch(
              actions.createSendDirectEditionError(entityName)(error.message, error.fieldsError),
            );
            return;
          }

          throw error;
        });
    };
  };
}

/**
 * @description Creates the thunk that will allow to discard changes from the form.
 * @param {string} entityName
 * @param {Object} parameters
 * @return {function(string, Object): function(*): undefined}
 */
export const createDiscardChanges = allowExtension(
  'discardChanges',
  entityName =>
    function discardChanges() {
      return function _discardChanges(dispatch) {
        dispatch(actions.createDiscardChanges(entityName)());
      };
    },
);

/**
 * @description Creates the thunk that will allow to save the creation of a new entity.
 * @param {string} entityName
 * @param {Object} parameters
 * @return {function(string, Object): function(*): (Promise<Object|null>)}
 */
export const createSaveCreation = allowExtension(
  'saveCreation',
  (entityName, parameters) =>
    function saveCreation(skipNav = false, skipClear = false, addConfirm = false) {
      return async function _saveCreation(dispatch, getState) {
        const createFormAPI = api.createCreateNewEntityAPI(entityName, parameters);
        const dataToCreate = createGetDataToCreate(entityName)(getState());

        dispatch(actions.createSaveCreationPending(entityName)());

        return dispatch(createFormAPI(dataToCreate, addConfirm))
          .then(response => {
            const res = response.getResult();

            if (skipClear) {
              dispatch(actions.createSaveCreationSuccessPreConfirm(entityName)(res));
            } else {
              dispatch(actions.createSaveCreationSuccess(entityName)(res));
              // once we have created the entity, reset the state to the intial values
              dispatch(actions.createSetInitialDefaultValues(entityName)());
            }
            if (!skipNav) {
              dispatch(changeLocationWithId(parameters.endpoint, 'edit', res.id, res));
            }
            return res;
          })
          .catch(error => {
            if (error.isFormError) {
              dispatch(
                actions.createSaveCreationError(entityName)(error.message, error.fieldsError),
              );
              return;
            }

            if (error.isServerError) {
              dispatch(actions.createSaveCreationError(entityName)(error.message));
            }
          });
      };
    },
);
