//         .-.
//         ((`-) Q U O N K
//          \\
//           \\
//    .="""=._))
//   /  .,   .'
//  /__(,_.-'
// `    /|
//     /_|__
//       | `))
//       |
//      -"==

export function getInitialPaymentInfo(state) {
  return state.paymentInfo.paymentInfo.current;
}

export function getPaymentInfo(state) {
  return state.paymentInfo.paymentInfo.edited;
}

export function getPaymentInfoShape(state) {
  return state.paymentInfo.shape;
}

export function getHasChanged(state) {
  return state.paymentInfo.hasChanged;
}

export function isFetching(state) {
  return state.paymentInfo.isFetching;
}

export function getPaymentInfoFieldErrors(state) {
  return state.paymentInfo.error && state.paymentInfo.error.fields;
}
