/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */

export function getQueryId(state) {
  if (Object.prototype.hasOwnProperty.call(state.routing.locationBeforeTransitions.query, 'id')) {
    return state.routing.locationBeforeTransitions.query.id;
  }
  return null;
}

export function getCurrentLocation(state) {
  return state.routing.locationBeforeTransitions.pathname.length > 0
    ? state.routing.locationBeforeTransitions.pathname
    : '';
}

export function getQuery(state) {
  return state.routing.locationBeforeTransitions && state.routing.locationBeforeTransitions.query;
}

export const getNavbarOffset = state => state.sidebar.offsetTop;
