import { quizzes } from '../../games';
import EntityList from '../listPage/EntityList';

export default quizzes.map(
  q =>
    new EntityList(
      'quizMember',
      `games/quizzes/${q.id}/members`,
      {
        zidane: true,
      },
      undefined,
      undefined,
      'Quiz members',
    ),
);
