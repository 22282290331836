/**            -                              ___
 *            |.|                          . /\_/\
 *          __]-[_________ /             .  (-<_>-)
 *         /        _____|<_          .   .  \/_\/
 *        / _   &  /               .   .
 *       / / \_ __|            .
 *     _/_/  / X   \
 *    <_/   /   ____\
 *         /___/|  /             zinedine zidane
 *         |  / ( <                --france--
 *         ( <   \ |
 *          \ |   >\
 *          _>|  (_/
 *         (__|
 *
 * MatchPint Ltd
 * @author Andreia Almeida
 * Date: 29/11/2018
 */

import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../../components/FormField/Text';

const FormField = ({ editedValue, onValueChange }) => (
  <Text name="Code" text={editedValue} handleEditField={onValueChange} />
);

FormField.propTypes = {
  editedValue: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
};

FormField.defaultProps = {
  editedValue: '',
};

export default FormField;
