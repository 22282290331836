import quizzes from './entityList';
import { STRING, BOOLEAN } from '../listPage/fieldTypes';

import createListPage from '../listPage';
import tabs from '../../../routes/tabs';

const quizzesPage = createListPage(
  quizzes,
  {
    id: {
      label: 'Id',
      type: STRING,
    },
    name: {
      label: 'Name',
      type: STRING,
    },
    active: {
      label: 'Active',
      type: BOOLEAN,
    },
  },
  {
    navTabs: tabs.quizzes,
  },
);

export default quizzesPage;
