import React from 'react';
import { Provider } from 'react-redux';
import { syncHistoryWithStore } from 'react-router-redux';
import { Router, browserHistory } from 'react-router';
import { mediaQueryTracker } from 'redux-mediaquery';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import errorHandler from '../../hocs/errorHandler';

import { muiTheme } from '../../theme';
import media from '../../theme/media.scss';

import './index.scss';

import configureStore from '../../store';
import routes from '../../routes';
import SuccessToastWrapper from '../../components/SaveBar/SuccessToastWrapper';
import ErrorModal from '../../components/ErrorModal';

const store = configureStore();

mediaQueryTracker(
  {
    // Issue when exporting, media.phoneMedia is still surrounded with ".
    isPhone: media.phoneMedia.replace(/"/g, ''),
    isTablet: media.tabletMedia.replace(/"/g, ''),
    isDesktop: media.desktopMedia.replace(/"/g, ''),
  },
  store.dispatch,
);

const history = syncHistoryWithStore(browserHistory, store);

const AppToRender = errorHandler(() => <Router routes={routes} history={history} />);

const App = () => (
  <Provider store={store}>
    <MuiThemeProvider muiTheme={muiTheme}>
      <>
        <SuccessToastWrapper />
        <AppToRender />
        <ErrorModal />
      </>
    </MuiThemeProvider>
  </Provider>
);

export default App;
