import React from 'react';
import venueCompleterVenues from './entityList';
import createListPage from '../listPage';

const getURL = entity =>
  `/venues/venue-completer/edit/${entity.id}?batchId=${entity.venueCompleterBatchId}`;

export default createListPage(
  venueCompleterVenues,
  {
    id: {
      label: 'id',
    },
    venueCompleterBatchId: {
      label: 'batch ID',
    },
    venue: {
      label: 'Venue',
      renderValue: v => (
        <p>
          {v.id} - {v.name}
        </p>
      ),
    },
    status: {
      label: 'status',
    },
  },
  { getOnClickLink: getURL },
);
