/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 24/05/2018
 */
import { TYPE_UNAUTHORIZED, TYPE_SERVER_ERROR } from '../../components/ErrorModal';

// Global error types.
export { TYPE_UNAUTHORIZED, TYPE_SERVER_ERROR };

export const DISMISS_GLOBAL_ERROR = 'globalError/dismiss';
export const RECEIVE_GLOBAL_ERROR = 'globalError/receive_error';
export const DISPLAY_STICKY_GLOBAL_ERROR = 'globalError/displaySticky';
export const DISPLAY_INLINE_GLOBAL_ERROR = 'globalError/displayInline';
