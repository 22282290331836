//      ,---.
//    ,/     \__.'~\
//    |        '  . `===---      -Ed touched this file-
//    `|   ,     ,--'
//     `. /___,-'
//       |    \
//       ,\_  ,`-=

import { getURLQuery } from '@matchpint/react-common';
import { request } from './requests';

export const fetchTopFixtures = countryId => async dispatch =>
  dispatch(
    request(
      `sports/big-fixtures${getURLQuery({
        countryId,
        limit: -1,
        numberOfMatches: 1,
      })}`,
    ),
  );

export const getMatchPintCountries = () => async dispatch =>
  dispatch(request('meta/countries?&matchPintCountries=1&limit=-1'));

export const getFixtures = (search, countryId, limit, offset, includePast) => async dispatch => {
  const params = { search, countryId, limit, offset, includePast };
  if (params.search) {
    // The search resets the order by, so we force it to be by date
    params.sortColumn = 'startTimeUtc';
    params.sortOrder = 'asc';
  }
  return dispatch(request(`sports/fixtures${getURLQuery(params)}`));
};

export const saveTopFixtures = (fixtures, countryID) => async dispatch =>
  dispatch(
    request(`sports/big-fixtures/country/${countryID}/save-order`, {
      method: 'PUT',
      body: { ids: fixtures },
    }),
  );

export const addFixtureToTops = fixtureObj => async dispatch =>
  dispatch(
    request('sports/big-fixtures/add', {
      method: 'POST',
      body: {
        bigFixtures: fixtureObj,
      },
    }),
  );
export const removeFixtureFromTops = bigFixtureID => async dispatch =>
  dispatch(
    request(`sports/big-fixtures/${bigFixtureID}/delete`, {
      method: 'DELETE',
    }),
  );

export const updateTopFixture = bigFixtures => async dispatch =>
  dispatch(
    request(`sports/big-fixtures/${bigFixtures.id}/update`, {
      method: 'PUT',
      body: { bigFixtures },
    }),
  );
