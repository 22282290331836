import EntityList from '../listPage/EntityList';

export default new EntityList(
  'sports',
  'sports/',
  {
    zidane: true,
  },
  undefined,
  undefined,
  'Sports',
);
