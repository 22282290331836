/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 25/04/2018
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getURLQuery } from '@matchpint/react-common';
import { withRouter } from 'react-router';
import ActiveFilters from './ActiveFiltersSection';
import AvailableFilters from './AvailableFilters';
import EntityList from '../../EntityList';
import './index.scss';
import { filterTypes } from '../../../../forms/formPage/utils/propTypes';

/**
 * @description Full Filter section component.
 */
const FilterSection = ({
  addFilter,
  activeFilters,
  availableFilters,
  isLoading,
  isPhone,
  removeFilter,
  location,
}) => {
  useEffect(() => {
    // check if any params in the url match filters and apply them if so
    if (Object.keys(availableFilters).length > 0) {
      Object.keys(location.query).forEach(key => {
        if (key in availableFilters) {
          addFilter(key, location.query[key], availableFilters[key].allowsList);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.keys(availableFilters).join(), location.query]);
  useEffect(() => {
    // this is a bit hacky but a simple way to achieve a URL that people can share
    window.history.pushState(null, '', `${location.pathname}${getURLQuery(activeFilters)}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters]);

  if (Object.keys(availableFilters).length === 0 && Object.keys(activeFilters).length === 0) {
    return null;
  }

  return (
    <div className="FilterSection">
      <AvailableFilters
        addFilter={addFilter}
        activeFilters={activeFilters}
        availableFilters={availableFilters}
        isLoading={isLoading}
      />
      <ActiveFilters
        activeFilters={activeFilters}
        availableFilters={availableFilters}
        removeFilter={removeFilter}
        isPhone={isPhone}
      />
    </div>
  );
};

FilterSection.propTypes = {
  activeFilters: PropTypes.shape(),
  addFilter: PropTypes.func.isRequired,
  availableFilters: PropTypes.objectOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      type: PropTypes.oneOf(filterTypes).isRequired,
      allowsList: PropTypes.bool,
    }),
  ).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  entity: PropTypes.instanceOf(EntityList).isRequired,
  isLoading: PropTypes.bool,
  isPhone: PropTypes.bool.isRequired,
  removeFilter: PropTypes.func.isRequired,
  location: PropTypes.shape().isRequired,
};

FilterSection.defaultProps = {
  activeFilters: {},
  isLoading: false,
};

const mapStateToProps = (state, { entity }) => ({
  activeFilters: entity.getActiveFilters(state),
  availableFilters: entity.getAvailableFilters(state),
  isLoading: entity.areFiltersLoading(state),
  isPhone: state.media.isPhone,
});

const mapDispatchToProps = (dispatch, { entity }) => ({
  addFilter: (id, value, isMulti = false) => dispatch(entity.filterBy(id, value, isMulti)),
  removeFilter: id => dispatch(entity.removeFilter(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterSection));
