export const GET_ANSWERS_FOR_QUESTION_SUCCESS = 'GET_ANSWERS_FOR_QUESTION_SUCCESS';
export const SET_ANSWERS_FOR_QUESTION_ERROR = 'SET_ANSWERS_FOR_QUESTION_ERROR';
export const CHANGE_VALUE_FOR_ANSWER = 'CHANGE_VALUE_FOR_ANSWER';
export const UNDO_CHANGES_FOR_ANSWER = 'UNDO_CHANGES_FOR_ANSWER';
export const UNDO_ALL_CHANGES_FOR_ANSWERS = 'UNDO_ALL_CHANGES_FOR_ANSWERS';
export const REMOVE_ANSWER = 'REMOVE_ANSWER';
export const REMOVE_UNSAVED_ANSWER = 'REMOVE_UNSAVED_ANSWER';
export const ADD_ANOTHER_ANSWER = 'ADD_ANOTHER_ANSWER';
export const CREATE_ANSWERS_SUCCESS = 'CREATE_ANSWERS_SUCCESS';
export const UPDATE_ANSWERS_SUCCESS = 'UPDATE_ANSWERS_SUCCESS';
