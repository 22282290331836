//              __
//             /(`o
//       ,-,  //  \\
//      (,,,) ||   V
//     (,,,,)\//
//     (,,,/w)-'
//     \,,/w)
//     `V/uu
//       / |
//       | |
//       o o
//       \ |
//  \,/  ,\|,.  \,/
//
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Avatar } from 'material-ui';

import AutoInAndOutSelctor from '../../../../components/AutoInAndOutSelector';
import { getQueryId } from '../../../../store/navigation/selectors';

const StarMembers = ({ gameId, leagueId }) => (
  <AutoInAndOutSelctor
    activeItemsString="Already shiny people"
    availableItemsString="Make someone a star"
    formatName={data =>
      data.map(item => ({
        id: item.id,
        name: `${item.firstName} ${item.lastName}`,
        leftAvatar: <Avatar size={30}>{item.id}</Avatar>,
      }))
    }
    endpoints={{
      fetchActive: `games/${gameId}/leagues/${leagueId}/stars?`,
      fetchAvailable: `games/${gameId}/leagues/${leagueId}/members?inLeague=1&memberType=member&`,
      add: `games/${gameId}/leagues/${leagueId}/stars/add-members`,
      remove: `games/${gameId}/leagues/${leagueId}/stars/remove-members`,
    }}
  />
);

const mapStateToProps = state => ({
  leagueId: Number.parseInt(getQueryId(state), 10),
});

StarMembers.propTypes = {
  leagueId: PropTypes.number.isRequired,
  gameId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(StarMembers);
