import EntityList from '../listPage/EntityList';

export default new EntityList(
  'venueCompleter',
  'venues/venue-completer',
  undefined,
  undefined,
  undefined,
  'Venue Completer',
);
