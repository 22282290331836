import * as c from './constants';

export const initialState = {
  loadingInitialTranslations: false,
  initialTranslations: null,
  updatingTranslations: null,
  error: null,
};

const topFixturesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case c.CLEAR_TRANSLATIONS:
      return initialState;
    case c.FETCH_TRANSLATIONS_PENDING:
      return {
        ...state,
        loadingInitialTranslations: true,
      };
    case c.FETCH_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        loadingInitialTranslations: false,
        initialTranslations: action.payload,
      };
    case c.FETCH_TRANSLATIONS_ERROR:
      return {
        ...state,
        loadingInitialTranslations: false,
        error: action.payload,
      };

    case c.UPDATE_TRANSLATIONS_PENDING:
      return {
        ...state,
        updatingTranslations: true,
      };
    case c.UPDATE_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        updatingTranslations: false,
      };
    case c.UPDATE_TRANSLATIONS_ERROR:
      return {
        ...state,
        error: action.payload,
        updatingTranslations: false,
      };
    default:
      return state;
  }
};

export default topFixturesReducer;
