import React, { useState } from 'react';
import { FlatButton, Checkbox } from 'material-ui';
import PropTypes from 'prop-types';
import AutoCompleteTextField from '../../components/AutoCompleteTextField';
import ExpendableCard from '../../components/ExpendableCard';
import colors from '../../theme/index.scss';
import request from '../../utils/apiRequest/requests';
import './styles.scss';
import DateTime from '../../components/FormField/DateTime/dateTime';
import LoadingWheel from '../../components/LoadingWheel';

const ReactivePrizeForm = ({ params }) => {
  const [values, setValues] = useState({
    teamId: null,
    leagueId: null,
    prizeId: null,
    redeemableUntil: null,
    joinedGameFrom: null,
    joinedGameTo: null,
    allowDuplicateReward: false,
  });
  const [errors, setErrors] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const save = () => {
    const setErr = err =>
      err && err.data && err.data.error && err.data.error.message
        ? setErrors(err.data.error.message)
        : setErrors('Sorry, an error occurred');
    setErrors(null);
    setSuccess(null);
    setLoading(true);
    return request(`games/${params.gameId}/prize/reactive`, {
      method: 'POST',
      body: { ...values },
    })
      .then(r => {
        setLoading(false);
        if (r.status === 202 || r.status === 400) {
          return setErr(r);
        }
        return setSuccess(r.data && r.data.result && r.data.result.message);
      })
      .catch(err => {
        setLoading(false);
        return setErr(err);
      });
  };

  return (
    <div className="ReactivePrize_container">
      <ExpendableCard title="Reactive Prize" expanded>
        {`This allows you to give pints to players of the game. If you don't specify a team or a league, everybody
        is receiving the prize.`}
        <AutoCompleteTextField
          dataSource={search => `games/${params.gameId}/teams?limit=10&search=${search}`}
          label="Team"
          onValueChange={teamId => setValues({ ...values, teamId })}
          searchText={values.teamId}
        />
        <AutoCompleteTextField
          dataSource={search => `games/${params.gameId}/leagues?limit=10&search=${search}`}
          label="League"
          onValueChange={leagueId => setValues({ ...values, leagueId })}
          searchText={values.leagueId}
        />
        <div className="flex pt-2">
          <p>Allow duplicate rewards?</p>
          <div className="ml-3">
            <Checkbox
              checked={values.allowDuplicateReward}
              onClick={() =>
                setValues({ ...values, allowDuplicateReward: !values.allowDuplicateReward })
              }
            />
          </div>
        </div>

        <AutoCompleteTextField
          dataSource={search => `games/${params.gameId}/prizes?limit=10&type=pint&search=${search}`}
          label="Prize"
          onValueChange={prizeId => setValues({ ...values, prizeId })}
          searchText={values.prizeId}
        />
        <DateTime
          dateTime={values.joinedGameFrom}
          handleEditField={joinedGameFrom => setValues({ ...values, joinedGameFrom })}
          name="Joined game from"
        />
        <DateTime
          dateTime={values.joinedGameTo}
          handleEditField={joinedGameTo => setValues({ ...values, joinedGameTo })}
          name="Joined game to"
        />
        <DateTime
          dateTime={values.redeemableUntil}
          handleEditField={redeemableUntil => setValues({ ...values, redeemableUntil })}
          name="Redeemable until"
        />
        <div>
          {errors && <p className="ReactivePrize_errorText">{errors}</p>}
          {success && <p className="ReactivePrize_successText">{success}</p>}
          {loading ? (
            <LoadingWheel size="medium" />
          ) : (
            <FlatButton
              className="NewFormGen_button"
              backgroundColor={colors.primaryYellow}
              disabled={
                !values.redeemableUntil ||
                !values.prizeId ||
                (values.joinedGameFrom && !values.joinedGameTo) ||
                (values.joinedGameTo && !values.joinedGameFrom)
              }
              onClick={() => save(values)}
            >
              Save
            </FlatButton>
          )}
        </div>
      </ExpendableCard>
    </div>
  );
};

ReactivePrizeForm.propTypes = {
  params: PropTypes.shape({
    gameId: PropTypes.number.isRequired,
  }).isRequired,
};

export default ReactivePrizeForm;
