//              __
//             /(`o
//       ,-,  //  \\
//      (,,,) ||   V
//     (,,,,)\//
//     (,,,/w)-'
//     \,,/w)
//     `V/uu
//       / |
//       | |
//       o o
//       \ |
//  \,/  ,\|,.  \,/

export const FETCH_ACTIVE_PENDING = 'FETCH_ACTIVE_PENDING';
export const FETCH_AVAILABLE_PENDING = 'FETCH_AVAILABLE_PENDING';
export const FETCH_ERROR = 'FETCH_ERROR';
export const FETCH_AVAILABLE_SUCCESS = 'FETCH_AVAILABLE_SUCCESS';
export const FETCH_ACTIVE_SUCCESS = 'FETCH_ACTIVE_SUCCESS';
export const ADD = 'ADD';
export const REMOVE = 'REMOVE';
export const DISCARD_CHANGES = 'DISCARD_CHANGES';
export const SAVE_CHANGES_PENDING = 'SAVE_CHANGES_PENDING';
export const SAVE_CHANGES_SUCCESS = 'SAVE_CHANGES_SUCCESS';
export const SAVE_CHANGES_ERROR = 'SAVE_CHANGES_ERROR';
export const SET_ACTIVE_SEARCH_TERM = 'SET_ACTIVE_SEARCH_TERM';
export const SET_AVAILABLE_SEARCH_TERM = 'SET_AVAILABLE_SEARCH_TERM';
export const LOAD_MORE_AVAILABLE = 'LOAD_MORE_AVAILABLE';
export const LOAD_MORE_ACTIVE = 'LOAD_MORE_ACTIVE';
