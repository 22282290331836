import { STRING, DATE } from '../listPage/fieldTypes';

import createListPage from '../listPage';
import EntityList from '../listPage/EntityList';

const mandates = createListPage(
  new EntityList(
    'mandates',
    'venues/mandates',
    {
      zidane: true,
    },
    undefined,
    undefined,
    'mandates',
  ),
  {
    id: {
      label: 'Id',
      type: STRING,
    },
    venueId: {
      label: 'Venue ID',
      type: STRING,
    },
    goCardlessManadateCreationDate: {
      label: 'Mandate Created on',
      type: DATE,
    },
    updatedAt: {
      label: 'Last updated',
      type: DATE,
    },
  },
);

export default mandates;
