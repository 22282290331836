import channel from './entityList';
import { STRING } from '../listPage/fieldTypes';

import createListPage from '../listPage';

const channels = createListPage(channel, {
  id: {
    label: 'Id',
    type: STRING,
  },
  cnumber: {
    label: 'Channel Number',
    type: STRING,
  },
  name: {
    label: 'name',
    type: STRING,
  },
});

export default channels;
