/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 13/11/2018
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FlatButton from 'material-ui/FlatButton';
import SaveIcon from 'material-ui/svg-icons/content/save';

import { createSaveCreation } from '../store/thunksFactory';

// Utils.
import * as customPropTypes from '../utils/propTypes';
import { getButtonLabel } from '../utils/paramParsers/create';

const SaveCreationButton = ({ entityName, parameters, save }) => {
  const saveButtonLabel = getButtonLabel(entityName, parameters);
  return (
    <FlatButton
      label={saveButtonLabel}
      labelPosition="after"
      secondary
      onClick={save}
      icon={<SaveIcon />}
    />
  );
};

SaveCreationButton.propTypes = {
  entityName: PropTypes.string.isRequired,
  parameters: customPropTypes.parameters.isRequired,
  save: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { entityName, parameters }) => ({
  save: () => dispatch(createSaveCreation(entityName, parameters)()),
});

const ConnectedButton = connect(null, mapDispatchToProps)(SaveCreationButton);

ConnectedButton.propTypes = {
  entityName: PropTypes.string.isRequired,
  parameters: customPropTypes.parameters.isRequired,
};

export default ConnectedButton;
