import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import ReadOnly from '../../../components/FormField/ReadOnly';
import ImportItems from '../../../components/ImportItems/ImportItems';

export const completerURL = '/venues/venue-completer';

const UploadBatch = ({ fullResource: { createdByMember, client, id, totalVenues }, fetch }) => (
  <>
    <ReadOnly name="client" text={client} />
    {createdByMember && (
      <ReadOnly
        name="Creator"
        text={`${createdByMember?.firstName} ${createdByMember?.lastName}`}
      />
    )}
    {totalVenues === 0 ? (
      <ImportItems
        endpoint={`${completerURL}/import`}
        templateEndpoint={`${completerURL}/template`}
        description="Upload CSV file here to import Venues"
        tooltip=""
        additionalPostData={{ id }}
        onSuccess={() => fetch(id)}
      />
    ) : (
      <>
        <ReadOnly name="Batch Size" text={totalVenues} />
        <Link
          className="ml-3 rounded bg-primaryYellow px-4 py-2"
          to={`${completerURL}/venues?batchId=${id}`}
        >
          Open Batch
        </Link>
      </>
    )}
  </>
);

UploadBatch.propTypes = {
  fullResource: PropTypes.shape({
    createdByMember: PropTypes.shape(),
    client: PropTypes.string,
    id: PropTypes.number,
    totalVenues: PropTypes.number,
  }).isRequired,
  fetch: PropTypes.func.isRequired,
};

export default UploadBatch;
