/* eslint-disable react/prop-types */
import React from 'react';
import createFormPage from '../formPage';
import colors from '../../../theme/index.scss';
import AutoInAndOutSelector from '../../../components/AutoInAndOutSelector';

export const defaultColors = [
  colors.primaryYellow,
  colors.primaryRed,
  colors.primaryGreen,
  colors.primaryOrange,
  colors.primaryPurple,
  colors.primaryLightBlue,
  colors.veryLightGrey,
  colors.standardGrey,
  colors.darkGrey,
  colors.black,
];

const params = {
  endpoint: 'meta/pills',
  sections: {
    Pill: ['active', 'name', 'backgroundColour', 'textColour', 'image', 'order', 'type'],
    Adverts: ['bannerImage', 'bannerActionDeeplink', 'advertImage', 'advertDeeplink'],
    Venues: [
      {
        fieldPath: 'id',
        Component: ({ editedValue, fullResource }) =>
          fullResource.type === 'manual' ? (
            <AutoInAndOutSelector
              endpoints={{
                fetchActive: `venues/?manualPillId=${editedValue}&`,
                fetchAvailable: `venues/?withoutManualPillId=${editedValue}&`,
                add: `meta/venue-pills/${editedValue}/add-venue`,
                remove: `meta/venue-pills/${editedValue}/remove-venue`,
              }}
              activeItemsString="Venues Attached"
              availableItemsString="Available venues"
            />
          ) : (
            <p>
              Pill has to be manual to be able to use this, if you have just changed it try saving
              first
            </p>
          ),
      },
    ],
  },
  create: {
    fields: ['name', 'backgroundColour', 'textColour', 'order', 'image'],
  },
};
const pills = createFormPage('pill', params);

export default pills;
