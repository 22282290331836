import React from 'react';
import PropTypes from 'prop-types';
import { FlatButton } from 'material-ui';
import Wallpaper from './wallpaper';
import theme from '../../theme/index.scss';
import { fileUploadAcceptTypes } from '../../utils/globalVariables';
import './PreviewUploadStyles.scss';

const PreviewUpload = ({ onClick, src, type }) => {
  const VideoPreview = (
    <video className="FileUpload_videoPlayer" width="80%" controls>
      <track kind="captions" />
      <source src={src} />
      Your browser does not support HTML5 video.
    </video>
  );

  const ImagePreview = (
    <>
      {src ? (
        <img className="PreviewUpload_image" src={src} alt="" />
      ) : (
        <div className="PreviewUpload_noImage">
          <Wallpaper className="PreviewUpload_background" />
          <p className="PreviewUpload_noImageText"> No Image Selected </p>
        </div>
      )}
      <div className="PreviewUpload_links">
        {src && (
          <>
            <FlatButton backgroundColor={theme.primaryRed} href={src}>
              DOWNLOAD
            </FlatButton>
            {onClick && (
              <FlatButton backgroundColor={theme.white} onClick={() => onClick(null)}>
                DELETE
              </FlatButton>
            )}
          </>
        )}
      </div>
    </>
  );

  return (
    <div className="PreviewUpload">
      {type === fileUploadAcceptTypes.image ? ImagePreview : VideoPreview}
    </div>
  );
};

PreviewUpload.propTypes = {
  onClick: PropTypes.func.isRequired,
  src: PropTypes.string,
  type: PropTypes.oneOf([fileUploadAcceptTypes.image, fileUploadAcceptTypes.video]),
};

PreviewUpload.defaultProps = {
  src: '',
  type: fileUploadAcceptTypes.image,
};

export default PreviewUpload;
