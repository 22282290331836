/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

import Wrapper from './PublicPageWrapper';

export default ComponentVar =>
  function publicPage(props) {
    return (
      <Wrapper>
        <ComponentVar {...props} />
      </Wrapper>
    );
  };
