/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 17/10/2018
 */

import { connect } from 'react-redux';

export default Component => {
  const mapStateToProps = state => ({
    isPhone: state.media.isPhone,
    isTablet: state.media.isTablet,
    isDesktop: state.media.isDesktop,
  });
  return connect(mapStateToProps)(Component);
};
