import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { fade } from 'material-ui/utils/colorManipulator';
import { spacing } from 'material-ui/styles/index';
import theme from './index.scss';

// Material UI theme.
export const muiTheme = getMuiTheme({
  spacing,
  fontFamily: 'MierB ,Roboto, sans-serif',
  palette: {
    primary1Color: theme.primaryYellow,
    primary2Color: theme.primaryPurple,
    primary3Color: theme.primaryPink,
    accent1Color: theme.primaryPink,
    accent2Color: theme.lightGrey,
    accent3Color: theme.standardGrey,
    textColor: theme.black,
    alternateTextColor: theme.black,
    canvasColor: theme.white,
    borderColor: theme.standardGrey,
    disabledColor: fade(theme.black, 0.3),
    pickerHeaderColor: theme.primaryYellow,
    clockCircleColor: fade(theme.black, 0.07),
    shadowColor: theme.black,
  },
});

export default { muiTheme };
