import React from 'react';
import PropTypes from 'prop-types';
import AutoInAndOutSelector from '../../../../components/AutoInAndOutSelector';

const TeamsSelector = ({ editedValue }) => (
  <AutoInAndOutSelector
    activeItemsString="Active teams"
    availableItemsString="Add teams"
    formatName={data => data.map(item => ({ id: item.id, name: item.name }))}
    endpoints={{
      fetchActive: `/sports/teams?inCompetitionId=${editedValue}&`,
      fetchAvailable: `/sports/teams?outCompetitionId=${editedValue}&`,
      add: `sports/competitions/${editedValue}/teams/add`,
      remove: `sports/competitions/${editedValue}/teams/remove`,
    }}
  />
);

TeamsSelector.propTypes = {
  editedValue: PropTypes.number.isRequired,
};

export default TeamsSelector;
