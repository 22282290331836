/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 27/06/2018
 */
import { request } from './requests';

export function saveHomepageSections(countryID, sectionIds) {
  return async function _saveHomepageSections(dispatch) {
    return dispatch(
      request(`meta/homepage/${countryID}/save`, {
        method: 'PUT',
        body: {
          ids: sectionIds,
        },
      }),
    );
  };
}

export function fetchHomepageSections(countryID) {
  return async function _fetchHomepageSections(dispatch) {
    return dispatch(
      request(`meta/homepage/${countryID}/sections`, {
        method: 'GET',
      }),
    );
  };
}
