import React from 'react';
import './styles.scss';
import { TextField, Toggle } from 'material-ui';
import Delete from 'material-ui/svg-icons/action/delete-forever';
import PropTypes from 'prop-types';
import Undo from 'material-ui/svg-icons/content/undo';
import Text from '../FormField/ReadOnly';

const EditAnswerField = ({ answer: { id, correct, text }, onChange, onRemove, onUndo }) => (
  <div className="EditAnswerField">
    <div className="EditAnswerField_section">
      <Text text={id || 'To be saved'} name={id && 'ID'} />
    </div>
    <div className="EditAnswerField_section">
      <TextField
        onChange={(_e, val) => onChange('text', val)}
        value={text}
        floatingLabelText="Text"
      />
    </div>
    <div className="EditAnswerField_section">
      <span>Correct</span>
      <Toggle toggled={correct} onToggle={() => onChange('correct', !correct)} />
    </div>
    <div className="EditAnswerField_section">
      <button type="button" onClick={onRemove}>
        <Delete />
      </button>
      {onUndo && (
        <button type="button" onClick={onUndo}>
          <Undo />
        </button>
      )}
    </div>
  </div>
);

EditAnswerField.propTypes = {
  answer: PropTypes.shape({
    id: PropTypes.number,
    correct: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onUndo: PropTypes.func,
};

EditAnswerField.defaultProps = {
  onUndo: null,
};

export default EditAnswerField;
