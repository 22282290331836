import quiz from './entityList';
import { STRING, BOOLEAN } from '../listPage/fieldTypes';

import createListPage from '../listPage';
import tabs from '../../../routes/tabs';

const columns = {
  id: {
    label: 'Id',
    type: STRING,
  },
  questionId: {
    label: 'questionId',
    type: STRING,
  },
  text: {
    label: 'Text',
    type: STRING,
  },
  correct: {
    label: 'Correct',
    type: BOOLEAN,
  },
};

export default quiz.map(entity =>
  createListPage(entity, columns, {
    navTabs: tabs[entity.entityName],
  }),
);
