import EntityList from '../listPage/EntityList';

export default new EntityList(
  'permission',
  'meta/pub-portal/permissions',
  {
    zidane: true,
  },
  undefined,
  undefined,
  'Permissions',
);
