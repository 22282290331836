/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 21/05/2018
 */
import EntityList from '../listPage/EntityList';

/**
 * @description Offers list Entity.
 * @type {EntityList}
 */
const offersList = new EntityList(
  'offer',
  'offers/',
  {
    withVenuesNumber: true,
    withinPrizePool: true,
  },
  null,
  {
    withVenuesNumber: true,
  },
  'Offers',
);

export default offersList;
