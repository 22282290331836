import EntityList from '../listPage/EntityList';

export default new EntityList(
  'homepageSections',
  'meta/homepage/sections',
  {},
  undefined,
  undefined,
  'Homepage sections',
);
