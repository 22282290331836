import React from 'react';
import { PropTypes } from 'prop-types';
import './styles.scss';

import moment from 'moment';
import SortAscIcon from 'material-ui/svg-icons/navigation/arrow-drop-up';
import SortDescIcon from 'material-ui/svg-icons/navigation/arrow-drop-down';

import colors from '../../../../../theme/index.scss';
import MpSearchBar from '../../../../../components/MpSearchBar';
import LoadingWheel from '../../../../../components/LoadingWheel';

const CRMNotes = ({ notes, loading, sortingBy, setSortingBy, search, setSearch }) => {
  const columns = [
    { name: 'id', param: 'id' },
    { name: 'date', param: 'createdAt' },
    { name: 'author', param: '_m__firstName' },
    { name: 'message', param: 'message' },
  ];

  return (
    <>
      <h3>Notes</h3>
      <MpSearchBar
        search={query => setSearch(query)}
        cancelSearch={() => setSearch('')}
        searchInfo={{ value: search }}
        className="CRMNotes_searchBar"
      />
      <div className="CRMNotes">
        <div className="CRMNotes_row CRMNotes_header">
          {columns.map(type => (
            <div key={type.name} className={`CRMNotes_${type.name}`}>
              {type.name}
              <div className="CRMNotes_arrowContainer">
                <SortAscIcon
                  className="TableHeaderColumnWithSort__sort_asc"
                  color={
                    sortingBy && sortingBy.direction === 'asc' && sortingBy.type === type.param
                      ? colors.lightGrey
                      : colors.black
                  }
                  onClick={() => setSortingBy({ type: type.param, direction: 'asc' })}
                />
                <SortDescIcon
                  className="TableHeaderColumnWithSort__sort_desc"
                  onClick={() => setSortingBy({ type: type.param, direction: 'desc' })}
                  color={
                    sortingBy && sortingBy.direction === 'desc' && sortingBy.type === type.param
                      ? colors.lightGrey
                      : colors.black
                  }
                />
              </div>
            </div>
          ))}
        </div>
        {loading && <LoadingWheel />}
        {!loading &&
          notes.map(note => (
            <div key={note.id} className="CRMNotes_row">
              <p className="CRMNotes_id">{note.id}</p>
              <p className="CRMNotes_date">
                {moment(note.createdAt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')}
              </p>
              <p className="CRMNotes_author">
                {note.member ? `${note.member.firstName} ${note.member.lastName}` : '---'}
              </p>
              <p className="CRMNotes_message">{note.message} </p>
            </div>
          ))}
      </div>
    </>
  );
};

CRMNotes.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool,
  sortingBy: PropTypes.shape().isRequired,
  setSortingBy: PropTypes.func.isRequired,
  search: PropTypes.string,
  setSearch: PropTypes.func.isRequired,
};

CRMNotes.defaultProps = {
  notes: [],
  loading: false,
  search: '',
};

export default CRMNotes;
