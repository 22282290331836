import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RaisedButton from 'material-ui/RaisedButton';
import Block from 'material-ui/svg-icons/content/block';
import colors from '../../../../theme/index.scss';
import MpModal from '../../../../components/MpModal/mpModal';
import memberClearDevices, { memberAnonymise } from '../../../../store/member/thunks';

class memberActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalAction: null,
      isSuccess: false,
      errorMessage: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(action) {
    this.setState(prevState => ({ showModal: !prevState.showModal, modalAction: action }));
  }

  render() {
    const { isSuccess, errorMessage, showModal, modalAction } = this.state;
    const { sendClearDevices, memAnonymise, editedValue } = this.props;
    return (
      <div style={{ marginBottom: 10 }}>
        {isSuccess ? <div style={{ color: colors.primaryGreen }}>Success</div> : null}
        {errorMessage ? (
          <div style={{ color: colors.primaryRed }}>Error: {errorMessage}</div>
        ) : null}
        <RaisedButton
          backgroundColor={colors.darkRed}
          icon={<Block color={colors.white} />}
          label="CLEAR DEVICES"
          onClick={() => this.toggleModal('clearDevices')}
          labelStyle={{ color: colors.white }}
        />
        &nbsp;
        <RaisedButton
          backgroundColor={colors.darkRed}
          icon={<Block color={colors.white} />}
          label="ANONYMISE USER"
          onClick={() => this.toggleModal('anonymise')}
          labelStyle={{ color: colors.white }}
        />
        <MpModal
          mainText="Are you sure?"
          visible={showModal}
          onCancel={this.toggleModal}
          onConfirm={() => {
            let targetAction = false;
            this.toggleModal();
            if (modalAction === 'clearDevices') {
              targetAction = sendClearDevices;
            } else if (modalAction === 'anonymise') {
              targetAction = memAnonymise;
            }

            if (targetAction) {
              targetAction(editedValue)
                .then(() => {
                  this.setState({ isSuccess: true, errorMessage: false });
                })
                .catch(err => {
                  this.setState({
                    isSuccess: false,
                    errorMessage: err.message ? err.message : 'Unknown error',
                  });
                });
            }
          }}
        />
      </div>
    );
  }
}

memberActions.propTypes = {
  sendClearDevices: PropTypes.func.isRequired,
  memAnonymise: PropTypes.func.isRequired,
  editedValue: PropTypes.number,
};

memberActions.defaultProps = {
  editedValue: null,
};

const mapDispatchToProps = dispatch => ({
  sendClearDevices: memberId => dispatch(memberClearDevices(memberId)),
  memAnonymise: memberId => dispatch(memberAnonymise(memberId)),
});

export default connect(null, mapDispatchToProps)(memberActions);
