/**                                         .-.
 *                          _              ((`-)
 *         _        ,-.    / )              \\    Ed made
 *        ( `.     // /-._/ /                \\       doozies here
 *         `\ \   /(_/ / / /          .="""=._))           too on 17/10/2019
 *           ; `-`  (_/ / /          /  .,   .'
 *           |       (_/ /          /__(,_.-'
 *           \          /          `    /|
 *            )       /`               /_|__
 *           /      /`                   | `))
 * Author: Marwan                        |
 * Date: 01/11/2018                     -"==
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils.
import FormError from '../utils/FormError';
import { PAGE_TYPE_CREATE } from '../utils/constants';
import { getFieldsToDisplay, getSectionTitle } from '../utils/paramParsers/create';
import * as customPropTypes from '../utils/propTypes';
import { getDescriptionFieldPathLists } from '../../../../utils/form';

// UI Component.
import FormSection from './FormSection';
import { createGetDescription } from '../store/selectorFactory';
import { fetchTranslationsForEntity } from '../../../../store/translations/thunks';
import {
  getInitialTranslations,
  getLoadingInitialTranslations,
  getTranslationsError,
} from '../../../../store/translations/selectors';
import { getQueryId } from '../../../../store/navigation/selectors';
import { clearTranslations as clearTranslationsAction } from '../../../../store/translations/actions';

/**
 * @description Renders the different sections of the form page given a set of parameters.
 * @param {string} entityName
 * @param {Object} parameters
 * @param {string} type
 * @param {Array} formDescription
 * @return {*}
 */
const FormSections = ({
  entityName,
  parameters,
  type,
  formDescription,
  initialTranslations,
  loadingInitial,
  fetchTranslations,
  id,
  clearTranslations,
  translationsError,
}) => {
  React.useEffect(() => {
    // Only fetch the translations if the form has at least 1 translateable field
    if (!initialTranslations && !translationsError && formDescription && !loadingInitial && id) {
      Object.keys(formDescription).find(key => {
        if (formDescription[key]?.options?.translatable) {
          return fetchTranslations(parameters.endpoint, id);
        }
        return false;
      });
    }
  }, [
    fetchTranslations,
    formDescription,
    id,
    initialTranslations,
    loadingInitial,
    parameters.endpoint,
    translationsError,
  ]);

  React.useEffect(() => () => clearTranslations(), [clearTranslations]);

  if (type === PAGE_TYPE_CREATE && parameters.create.allFields !== true) {
    const { create } = parameters;

    if (!create) {
      throw new FormError('Invalid empty field "create": must be a object.');
    }

    if (typeof create !== 'object') {
      throw new FormError(
        `Invalid type ${typeof create} for the field "create": must be a object.`,
      );
    }

    const titleToDisplay = getSectionTitle(entityName, parameters);
    const fields = getFieldsToDisplay(entityName, parameters);

    return (
      <FormSection
        section={fields}
        title={titleToDisplay}
        entityName={entityName}
        parameters={parameters}
        type={type}
      />
    );
  }

  const { sections } = parameters;
  const descriptionPathLists = getDescriptionFieldPathLists(formDescription);

  if (!sections) {
    return (
      <FormSection
        entityName={entityName}
        parameters={parameters}
        title="GENERAL"
        section={descriptionPathLists}
        type={type}
      />
    );
  }

  if (typeof sections !== 'object') {
    throw new FormError(
      `Invalid type ${typeof sections} for the field "sections": must be an object.`,
    );
  }

  const getOtherSection = () => {
    // section fields defined as objects
    /*
      general:[
       {
         fieldPath: 'gameId',
         Component: () => <GamePrizeAutoComplete gameId={gameId} />,
       },
      ]
    */
    const objectFields = Object.keys(sections)
      .map(section => sections[section].filter(field => typeof field === 'object'))
      .flat()
      .map(fieldObj => fieldObj && fieldObj.fieldPath);
    // existing auto section fields
    /*
      general:[
       "gameId",
      ]
    */
    const stringFields = Object.keys(sections)
      .map(section => sections[section].filter(field => typeof field === 'string'))
      .flat();

    return descriptionPathLists
      .filter(section => ![...objectFields, ...stringFields].includes(section[0]))
      .flat();
  };

  return (
    <>
      {Object.keys(sections).map((sectionTitle, i) => (
        <FormSection
          expandable={i !== 0}
          key={sectionTitle}
          entityName={entityName}
          parameters={parameters}
          section={sections[sectionTitle]}
          title={sectionTitle}
          type={type}
        />
      ))}

      {parameters.otherSection !== false && (
        <FormSection
          key="OTHER"
          expandable
          entityName={entityName}
          parameters={parameters}
          section={getOtherSection()}
          title="OTHER"
          type={type}
        />
      )}
    </>
  );
};

FormSections.propTypes = {
  entityName: PropTypes.string.isRequired,
  parameters: customPropTypes.parameters.isRequired,
  type: customPropTypes.pageType.isRequired,
  formDescription: PropTypes.shape().isRequired,
  initialTranslations: PropTypes.arrayOf(PropTypes.shape()),
  loadingInitial: PropTypes.bool.isRequired,
  fetchTranslations: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clearTranslations: PropTypes.func.isRequired,
  translationsError: PropTypes.string,
};

FormSections.defaultProps = {
  id: null,
  initialTranslations: null,
  translationsError: null,
};

const mapStateToProps = (state, { entityName, parameters }) => ({
  formDescription: createGetDescription(entityName, parameters)(state),
  loadingInitial: getLoadingInitialTranslations(state),
  initialTranslations: getInitialTranslations(state),
  id: getQueryId(state),
  translationsError: getTranslationsError(state),
});

const mapDispatchToProps = dispatch => ({
  fetchTranslations: (endpoint, id) => dispatch(fetchTranslationsForEntity(endpoint, id)),
  clearTranslations: () => dispatch(clearTranslationsAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormSections);
