import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearCheckinPlay } from '../../../../store/thunks/api-member';

const PasswordResetComp = ({ editedValue, submit }) => {
  const [value, setValue] = useState('');
  const [deleteCheckin, setDeleteCheckin] = useState(false);
  const [loading, setLoading] = useState(false);
  return (
    <>
      <p className="my-2">Reset Check in challenge state</p>
      <div className="flex items-center">
        <input
          className="mr-4 rounded-lg border border-primaryYellow px-4 py-1"
          value={value}
          placeholder="Game ID"
          onChange={t => setValue(t.target.value)}
        />

        <p>Remove checkin?</p>
        <input
          className="ml-2"
          type="checkbox"
          value={deleteCheckin}
          onChange={t => setDeleteCheckin(t.target.value)}
        />

        <button
          className="ml-3 rounded-lg bg-primaryRed px-4 py-2 font-semibold text-white disabled:bg-darkGrey"
          type="button"
          disabled={loading}
          onClick={() => {
            setLoading(true);
            submit(editedValue, value, deleteCheckin).finally(() => setLoading(false));
          }}
        >
          Submit
        </button>
      </div>
    </>
  );
};

const mapDispatch = {
  submit: clearCheckinPlay,
};

PasswordResetComp.propTypes = {
  editedValue: PropTypes.number,
  submit: PropTypes.func.isRequired,
};

PasswordResetComp.defaultProps = {
  editedValue: null,
};

export default connect(null, mapDispatch)(PasswordResetComp);
