import * as api from '../thunks/api-quizQuestion';
import * as actions from './actions';
import { getErrorMessage } from '../../utils/apiRequest/requests';
import { getAnswersToAdd, getAnswersToBeUpdated } from './selectors';

export const getAnswersForQuestion = (questionId, quizId) => dispatch =>
  dispatch(api.getAnswersForQuestion(questionId, quizId))
    .then(r => dispatch(actions.getAnswersForQuesionSuccess(r.getResult())))
    .catch(err => dispatch(actions.setAnswersForQuesionError(getErrorMessage(err))));

export const saveAnswersForQuestion = (quizId, questionId) => (dispatch, getState) => {
  const answers = getAnswersToAdd(getState()).filter(ans => Boolean(ans.text));
  return Promise.all(
    answers.map(ans =>
      dispatch(api.createQuizAnswer({ ...ans, questionId }, quizId)).then(r => r.getResult()),
    ),
  )
    .then(r => dispatch(actions.answersCreatedSuccess(r)))
    .catch(err => dispatch(actions.setAnswersForQuesionError(getErrorMessage(err))));
};

export const updateAnswersForQuestion = quizId => (dispatch, getState) => {
  const answers = getAnswersToBeUpdated(getState());
  return Promise.all(
    answers.map(ans => dispatch(api.updateQuizAnswer(ans, quizId)).then(r => r.getResult())),
  )
    .then(() => dispatch(actions.answersUpdatedSuccess()))
    .catch(err => dispatch(actions.setAnswersForQuesionError(getErrorMessage(err))));
};

export const deleteAnswersForQuestion = (answerId, quizId) => dispatch =>
  dispatch(api.updateQuizAnswer({ id: answerId, active: false }, quizId)).then(() =>
    dispatch(actions.removeAnswer(answerId)),
  );
