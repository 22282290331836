/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * MatchPint Ltd
 * @author Marwan
 * Date: 19/04/2018
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Drawer from 'material-ui/Drawer';

import { connect } from 'react-redux';
import SideBarContent from './SideBarContent';

import { MpHeader } from '../MpHeader';

import color from './index.scss';
import { getAuthenticatedMember } from '../../store/member/selectors';
import { getCurrentLocation } from '../../store/navigation/selectors';
import logout from '../../store/login/thunks/logout';
import { changeLocation } from '../../store/navigation/thunks';

/**
 * Wrapper for the entire navigation of the feature.
 */
class Navigation extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }

  render() {
    const { currentLocation, isDesktop, children, member, navigate, signOut, tabs } = this.props;
    const { isOpen } = this.state;

    const nav = isDesktop ? (
      <SideBarContent member={member} signOut={signOut} />
    ) : (
      <Drawer
        containerStyle={{ backgroundColor: color.backgroundColor }}
        docked={false}
        open={isOpen}
        onRequestChange={open => this.setState({ isOpen: open })}
      >
        <SideBarContent member={member} signOut={signOut} />
      </Drawer>
    );

    const header = (
      <MpHeader
        currentLocation={currentLocation}
        navigate={navigate}
        tabs={tabs}
        onIconClick={() => this.setState(prevState => ({ isOpen: !prevState.isOpen }))}
      />
    );

    return (
      <div id="NavSideBar__container" className="NavSideBar__container">
        {nav}
        <div id="NavSideBar__content_container" className="NavSideBar__content_container">
          {header}
          {children}
        </div>
      </div>
    );
  }
}

Navigation.propTypes = {
  children: PropTypes.node.isRequired,
  currentLocation: PropTypes.string,
  isDesktop: PropTypes.bool.isRequired,
  member: PropTypes.shape().isRequired,
  navigate: PropTypes.func,
  signOut: PropTypes.func,
  tabs: PropTypes.shape(),
};

Navigation.defaultProps = {
  currentLocation: '',
  navigate: () => {},
  signOut: () => {},
  tabs: {},
};

const mapsStateToProps = state => ({
  isDesktop: state.media.isDesktop,
  member: getAuthenticatedMember(state),
  currentLocation: getCurrentLocation(state),
});

const mapDispatchToProps = dispatch => ({
  signOut: () => dispatch(logout()),
  navigate: url => dispatch(changeLocation(url)),
});

export default connect(mapsStateToProps, mapDispatchToProps)(Navigation);
