import quizMembers from './entityList';
import { STRING, NUMBER } from '../listPage/fieldTypes';

import createListPage from '../listPage';

const fields = {
  id: {
    label: 'Id',
    type: STRING,
  },
  memberId: {
    label: 'Member ID',
    type: STRING,
  },
  lives: {
    label: 'Lives',
    type: NUMBER,
  },
  inviteCode: {
    label: 'Invite code',
    type: STRING,
  },
};

export default quizMembers.map(e => createListPage(e, fields));
