/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 01/10/2018
 */
import React from 'react';
import moment from 'moment';

import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';
import TextField from 'material-ui/TextField';
import DatePicker from 'material-ui/DatePicker';

import PropTypes from 'prop-types';

import AutoCompleteTextField from '../AutoCompleteTextField';
import InfoIcon from '../InfoIcon';

import './style.scss';
import { filterOptions } from '../../utils/customPropTypes';

/**
 * @description Button used to select a filter.
 * @constructor
 */

class FilterField extends React.Component {
  /**
   * @description Renders an AutoComplete filter.
   * @return {*}
   */
  renderAutoComplete() {
    const { addFilter, filter, id, clearSearchTextOnSelect, value } = this.props;

    const formatAutoCompleteText = entity => {
      if (entity.name) {
        return {
          text: `${entity.name}: (${entity.id})`,
          value: entity.id,
        };
      }
      if (entity.firstName && entity.lastName) {
        return {
          text: `${entity.firstName} ${entity.lastName} (${entity.id})`,
          value: entity.id,
        };
      }
      return {
        text: `ID: ${entity.id}`,
        value: entity.id,
      };
    };

    if (!filter.options.possibleValues && !filter.options.serviceUrl) {
      return null;
    }

    if (filter.options.serviceUrl) {
      const append = `limit=10&search=`;
      const url =
        filter.options.serviceUrl.indexOf('?') === -1
          ? `${filter.options.serviceUrl}?${append}`
          : `${filter.options.serviceUrl}&${append}`;
      return (
        <AutoCompleteTextField
          searchText={clearSearchTextOnSelect ? null : value}
          dataSource={search => `${url}${search}`}
          formatDataSource={entities => entities.map(entity => formatAutoCompleteText(entity))}
          label={filter.title}
          onValueChange={selectedValue => addFilter(id, selectedValue, filter.allowsList)}
          clearOnSubmit
        />
      );
    }

    return (
      <AutoCompleteTextField
        searchText={clearSearchTextOnSelect ? null : value}
        dataSource={filter.options.possibleValues}
        label={filter.title}
        onValueChange={selectedValue => addFilter(id, selectedValue, filter.allowsList)}
        clearOnSubmit
      />
    );
  }

  renderTextField() {
    const { addFilter, filter, id } = this.props;

    return (
      <TextField
        floatingLabelText={filter.title}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            addFilter(id, e.target.value);
          }
        }}
      />
    );
  }

  renderNumberField() {
    const { addFilter, filter, id } = this.props;

    return (
      <TextField
        style={{ height: '48px' }}
        floatingLabelStyle={{ top: '13px' }}
        inputStyle={{ marginTop: '0px' }}
        floatingLabelText={filter.title}
        onKeyPress={e => {
          const onlyNums = e.target.value.replace(/[^0-9]/g, '');
          if (e.key === 'Enter') {
            addFilter(id, onlyNums);
          }
        }}
      />
    );
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  renderSelect() {
    const { value, filter, addFilter, id, multiple } = this.props;

    if (filter.type !== 'boolean' && !filter.options.possibleValues) {
      return null;
    }

    return (
      <>
        <SelectField
          key="filter"
          autoWidth
          multiple={multiple}
          floatingLabelText={filter.title}
          className="FilterSelect_select"
          value={value}
          onChange={(_, __, selectedValue) => addFilter(id, selectedValue, filter.allowsList)}
        >
          {this.renderMenuItems()}
        </SelectField>
        <InfoIcon key="tooltip" tooltip={filter.help} tooltipPosition="bottom-right" />
      </>
    );
  }

  /**
   * @description Renders the items of a DropDown filter.
   * @return {*}
   */
  renderDatePicker() {
    const { filter, value, addFilter, id } = this.props;

    return (
      <DatePicker
        value={value ? new Date(value) : null}
        hintText={filter.title}
        autoOk
        onChange={(_, newDate) => addFilter(id, moment(newDate).format('YYYY-MM-DD'))}
        id={filter.title}
      />
    );
  }

  /**
   * @description Renders the items of a DropDown filter.
   * @return {*}
   */
  renderDefault() {
    const { filter, addFilter, id, value, multiple } = this.props;

    const menuItems = [];

    switch (filter.type) {
      case 'boolean': {
        menuItems.push(<MenuItem key="blah" value primaryText="Yes" />);
        menuItems.push(<MenuItem key={false} value={false} primaryText="No" />);
        break;
      }
      default: {
        const { possibleValues } = filter.options;

        possibleValues.forEach(possibleValue =>
          menuItems.push(
            <MenuItem
              key={possibleValue.key}
              primaryText={possibleValue.value}
              value={possibleValue.key}
            />,
          ),
        );
        break;
      }
    }

    return (
      <SelectField
        key="filter"
        value={value}
        autoWidth
        multiple={multiple}
        floatingLabelText={filter.title}
        className="FilterSelect_select"
        onChange={(_, __, selectedValue) => addFilter(id, selectedValue, filter.allowsList)}
      >
        {menuItems}
      </SelectField>
    );
  }

  renderFilter(type) {
    switch (type) {
      case 'auto-complete':
        return this.renderAutoComplete();
      case 'date':
        return this.renderDatePicker();
      case 'string':
        return this.renderTextField();
      case 'integer':
        return this.renderNumberField();
      default:
        return this.renderDefault();
    }
  }

  render() {
    const { filter } = this.props;

    return <div className="FilterSelect">{this.renderFilter(filter.type)}</div>;
  }
}

// The objects have named keys which doesn't work well with JS and PropTypes
// [{ 11108: {...}, 1110: {...}, 1112: {...} }]
FilterField.propTypes = {
  clearSearchTextOnSelect: PropTypes.bool,
  addFilter: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    options: filterOptions,
    allowsList: PropTypes.bool,

    title: PropTypes.string,
    type: PropTypes.string,
    help: PropTypes.string,
    option: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape()), PropTypes.shape()]),
  }).isRequired,
  id: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

FilterField.defaultProps = {
  clearSearchTextOnSelect: false,
  value: null,
  multiple: false,
};

export default FilterField;
