/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 26/10/2018
 */

import { get } from '../../../../utils/object';

/**
 * @description Path to the entity's redux store.
 * @param entityName
 */
function baseSelector(entityName) {
  return store => store.form[entityName];
}

/**
 * @description Allows a selector to consider an extension (param.reduxExtension[extensionName]).
 * @param {string} extensionName Field name of the redux extension.
 * @param {function} selectorFactory Selector factory that can be extended.
 * @return {function}
 */
function allowReduxExtension(extensionName, selectorFactory) {
  return (entityName, parameters) => {
    const extension = get(['reduxExtension', extensionName], parameters);

    return state => {
      const formValue = selectorFactory(entityName, parameters)(state);
      return extension ? extension(formValue, state) : formValue;
    };
  };
}

// Description selectors.
export function createGetDescription(entityName) {
  return function getDescription(state) {
    return baseSelector(entityName)(state).data.description;
  };
}

export function createGetSaveSuccessText(entityName) {
  return function getDescription(state) {
    return baseSelector(entityName)(state).data.createSuccessText;
  };
}

export function createGetDescriptionFetchError(entityName) {
  return function getDescriptionFetchError(state) {
    return baseSelector(entityName)(state).errors.fetchDescription;
  };
}

export function createIsDescriptionFetching(entityName) {
  return function isDescriptionFetching(state) {
    return baseSelector(entityName)(state).isLoading.fetchDescription;
  };
}

// Initial data selectors.
export function createGetInitialData(entityName) {
  return function getInitialData(state) {
    return baseSelector(entityName)(state).data.initialData;
  };
}

export function createGetRenderKey(entityName) {
  return function getRenderKey(state) {
    return baseSelector(entityName)(state).data.renderKey;
  };
}

export function createGetFetchDataError(entityName) {
  return function getFetchDataError(state) {
    return baseSelector(entityName)(state).errors.fetchData;
  };
}

// Edited data selectors.
export function createGetEditedData(entityName) {
  return function getEditedData(state) {
    return baseSelector(entityName)(state).data.editedData;
  };
}

export function createGetFormChanges(entityName) {
  return function getFormChanges(state) {
    return baseSelector(entityName)(state).data.changes;
  };
}

export function createGetShowSaveConfirmation(entityName) {
  return function getShowSaveConfirmation(state) {
    return baseSelector(entityName)(state).showSaveConfirmation;
  };
}

export function createGetSaveConfirmText(entityName) {
  return function getSaveConfirmText(state) {
    return baseSelector(entityName)(state).saveConfirmationText;
  };
}

export const createHasUnStagedChanges = allowReduxExtension(
  'hasUnStagedChanges',
  entityName =>
    function hasUnStagedChanges(state) {
      return baseSelector(entityName)(state).data.hasUnStagedChanges;
    },
);

// Create form selectors.
export function createGetDataToCreate(entityName) {
  return function getDataToCreate(state) {
    return baseSelector(entityName)(state).data.createData;
  };
}

// Save Creation selectors.
export const createIsCreationSaving = allowReduxExtension(
  'isCreationSaving',
  entityName =>
    function isCreationSaving(state) {
      return baseSelector(entityName)(state).isLoading.create;
    },
);

export function createGetCreationError(entityName) {
  return function getCreationError(state) {
    return baseSelector(entityName)(state).errors.create;
  };
}

export const createGetCreationFieldErrors = allowReduxExtension(
  'creationFieldErrors',
  entityName =>
    function getCreationFieldErrors(state) {
      return baseSelector(entityName)(state).errors.createFieldErrors;
    },
);

// Save Edition selectors.
export const createIsEditionSaving = allowReduxExtension(
  'isFormSaving',
  entityName =>
    function isEditionSaving(state) {
      return baseSelector(entityName)(state).isLoading.save;
    },
);

export function createGetEditionError(entityName) {
  return function getSaveError(state) {
    return baseSelector(entityName)(state).errors.save;
  };
}

export const createGetEditionFieldErrors = allowReduxExtension(
  'editionFieldErrors',
  entityName =>
    function getFieldsErrors(state) {
      return baseSelector(entityName)(state).errors.saveFieldErrors;
    },
);
