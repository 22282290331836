import createFormPage from '../formPage';

const params = {
  endpoint: 'venues/pub-portal-code',
  create: {
    title: 'New Promo Code',
    fields: ['code', 'description', 'unlockTierId', 'skipPayment'],
  },
};

export default createFormPage('pubPortalCode', params);
