/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 21/05/2018
 */
import { combineReducers } from 'redux';

import * as constants from './constants';

/**
 * @description Template of sub-state on which the EntityList registers the different actions.
 */
export const initialState = {
  fetch: {
    status: null,
    error: null,
    result: null,
    pageSize: 10,
    offset: 0,
    count: null,
  },
  filters: {
    active: {},
    available: {},
    status: null,
    error: null,
  },
  sortBy: {
    active: {},
    available: {},
    status: null,
    error: null,
  },
};

/**
 * @description Returns the reducer associated the "list" part of the EntityList reducer.
 * @param {string} entityName Name of the list.
 */
function fetchReducer(entityName) {
  return function _fetchReducer(state = initialState.fetch, action = {}) {
    switch (action.type) {
      case `${entityName}/${constants.FETCH_LIST_PENDING}`:
        return {
          ...state,
          status: constants.FETCH_LIST_PENDING,
        };
      case `${entityName}/${constants.LOAD_MORE}`:
        return {
          ...state,
          error: null,
          status: constants.FETCH_LIST_SUCCESS,
          offset: state.offset + state.pageSize,
          result: [...state.result, ...action.list],
        };
      case `${entityName}/${constants.FETCH_LIST_SUCCESS}`:
        return {
          ...state,
          error: null,
          status: constants.FETCH_LIST_SUCCESS,
          offset: 0,
          result: action.payload.result,
          count: action.payload.count,
        };
      case `${entityName}/${constants.FETCH_LIST_FAILURE}`:
        return {
          ...state,
          status: constants.FETCH_LIST_FAILURE,
          error: action.error,
        };
      default:
        return state;
    }
  };
}

/**
 * @description Returns the reducer associated the "filters" part of the EntityList reducer.
 * @param {string} entityName Name of the list.
 */
function filtersReducer(entityName) {
  return function _filtersReducer(state = initialState.filters, action = {}) {
    const activeFilters = { ...state.active };
    switch (action.type) {
      // Active filters.
      case `${entityName}/${constants.ADD_FILTER}`:
        activeFilters[action.filter.id] = action.filter.value;
        return {
          ...state,
          active: activeFilters,
        };
      case `${entityName}/${constants.ADD_MULTI_FILTER}`:
        if (!activeFilters[action.filter.id]) {
          activeFilters[action.filter.id] = [action.filter.value];
        } else {
          activeFilters[action.filter.id] = [
            ...activeFilters[action.filter.id],
            action.filter.value,
          ];
        }

        return {
          ...state,
          active: activeFilters,
        };
      case `${entityName}/${constants.REMOVE_FILTER}`:
        activeFilters[action.filterId] = null;
        return {
          ...state,
          active: activeFilters,
        };
      // Get available filters.
      case `${entityName}/${constants.FETCH_FILTERS_PENDING}`:
        return {
          ...state,
          status: constants.FETCH_FILTERS_PENDING,
        };
      case `${entityName}/${constants.FETCH_FILTERS_SUCCESS}`: {
        const { filters } = action.payload;

        // Available filters.
        const available = filters;

        // Active filters.
        const active = {};

        // Iterates on the description of the available filters
        // to know which are the default filters by default.
        Object.keys(filters).forEach(key => {
          // Only register a filter if it's default value is not null.c
          const val = filters[key].defaultValue;
          if (val) {
            if (Array.isArray(val)) {
              if (val.length > 0) {
                active[key] = filters[key].defaultValue;
              }
            } else {
              active[key] = filters[key].defaultValue;
            }
          }
        });

        return {
          ...state,
          status: constants.FETCH_FILTERS_SUCCESS,
          error: null,
          available,
          active,
        };
      }
      case `${entityName}/${constants.FETCH_FILTERS_ERROR}`:
        return {
          ...state,
          status: constants.FETCH_FILTERS_ERROR,
          error: action.error,
        };
      default:
        return state;
    }
  };
}

function sortByReducer(entityName) {
  return function _sortByReducer(state = initialState.sortBy, action = {}) {
    // There can be only one active sort by at the time.
    const activeSortBy = {};
    switch (action.type) {
      case `${entityName}/${constants.ADD_SORT_BY}`:
        activeSortBy[action.sortColumn] = action.sortOrder;
        return {
          ...state,
          active: activeSortBy,
        };
      case `${entityName}/${constants.FETCH_FILTERS_PENDING}`:
        return {
          ...state,
          status: constants.FETCH_FILTERS_PENDING,
        };
      case `${entityName}/${constants.FETCH_FILTERS_SUCCESS}`:
        return {
          ...state,
          error: null,
          status: constants.FETCH_FILTERS_SUCCESS,
          available: action.payload.sortColumns,
        };
      case `${entityName}/${constants.FETCH_FILTERS_ERROR}`:
        return {
          ...state,
          error: action.error,
          status: constants.FETCH_FILTERS_ERROR,
        };
      default:
        return state;
    }
  };
}

/**
 * @description Returns a generated reducer for the EntityList.
 * @param entityName Name of the list.
 * @returns {Reducer<any>}
 */
export default function listReducer(entityName) {
  return combineReducers({
    fetch: fetchReducer(entityName),
    filters: filtersReducer(entityName),
    sortBy: sortByReducer(entityName),
  });
}
