import { SHOW_TOAST } from './constants';

const toastReducer = (
  state = {
    open: false,
  },
  action = {},
) => {
  switch (action.type) {
    case SHOW_TOAST:
      return {
        ...state,
        open: action.payload,
      };
    default:
      return state;
  }
};

export default toastReducer;
