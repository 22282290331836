import React from 'react';
import AutoInAndOutSelector from '../../../components/AutoInAndOutSelector';
import tabs from '../../../routes/tabs';
import createFormPage from '../formPage';

const params = {
  endpoint: 'offers',
  sections: {
    General: [
      'active',
      'title',
      'internalTitle',
      'description',
      'successMessage',
      'isIndependent',
      'isVisible',
      'target',
      'needsConfirmation',
    ],
    Availability: [
      'fromDateUtc',
      'toDateUtc',
      'availabilityType',
      'allWeek',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
      'allDay',
      'fromTimeUtc',
      'toTimeUtc',
    ],
    'Customise App Look': ['backgroundImage', 'textColour'],
    Venues: [
      {
        fieldPath: 'id',
        // eslint-disable-next-line react/prop-types
        Component: ({ editedValue }) => (
          <AutoInAndOutSelector
            enableCSVUpload
            activeItemsString="Venues Running the offer"
            availableItemsString="Available Venues"
            formatName={data =>
              data.map(item => ({
                id: item.id,
                name: item.name,
              }))
            }
            endpoints={{
              fetchActive: `/venues/running-offer/${editedValue}?status=1&`,
              fetchAvailable: `venues/not-running-offer/${editedValue}?status=1&`,
              add: `offers/${editedValue}/venues/add`,
              remove: `offers/${editedValue}/venues/remove`,
            }}
          />
        ),
      },
    ],
  },
  create: {
    fields: ['title', 'internalTitle', 'active'],
  },
  tabs: tabs.offer,
  api: {
    getDescriptionAPIEndpoint: item => ({
      url: `offers/update/${item}?formDescription=1`,
      method: 'PUT',
    }),
    getEditAPIEndpoint: (id, body) => ({
      url: `offers/update/${id}?zidane=1`,
      method: 'PUT',
      body: {
        offer: {
          id,
          ...body,
        },
      },
    }),
  },
};

export default createFormPage('offer', params);
