//      ,---.
//    ,/     \__.'~\
//    |        '  . `===---      -Ed touched this file-
//    `|   ,     ,--'
//     `. /___,-'
//       |    \
//       ,\_  ,`-=

import React from 'react';
import './styles.scss';
import { Dialog, FlatButton } from 'material-ui';
import PropTypes from 'prop-types';
import RemoveTile from 'material-ui/svg-icons/action/delete-forever';
import Close from 'material-ui/svg-icons/navigation/close';
import DateTime from '../../components/FormField/DateTime/dateTime';
import AutoInAndOutSelector from '../../components/AutoInAndOutSelector';
import LoadingWheel from '../../components/LoadingWheel';

const moment = require('moment');

const TopFixtureEdit = ({
  selectedCountry,
  editingFixture,
  updateFixture,
  setEditing,
  error,
  onClose,
  isRemovingFixture,
  onDelete,
  enableSave,
}) => (
  <Dialog
    style={{ zIndex: 1 }}
    autoScrollBodyContent
    open={Boolean(editingFixture)}
    onRequestClose={onClose}
  >
    <div className="FixtureEdit_close">
      <Close onClick={onClose} />
    </div>
    <div className={error && 'FixtureEdit_error'}>
      {error && <p className="TopFixturesList_error_message">{error}</p>}
      <p>
        {selectedCountry &&
          selectedCountry.name &&
          `Select times the fixture is displayed in ${selectedCountry.name}`}
      </p>
      <DateTime
        name="Display start time"
        handleEditField={val =>
          setEditing({
            ...editingFixture,
            startTimeUtc: moment(val).format(),
          })
        }
        className="TopFixturesList_dnd_item"
        dateTime={moment(editingFixture.startTimeUtc).format()}
      />
      <DateTime
        name="Display end time"
        handleEditField={val =>
          setEditing({
            ...editingFixture,
            endTimeUtc: moment(val).format(),
          })
        }
        dateTime={moment(editingFixture.endTimeUtc).format()}
        className="TopFixturesList_dnd_item"
      />
      {moment(editingFixture.startTimeUtc) > moment(editingFixture.endTimeUtc) && (
        <p className="TopFixturesList_error_message">Start time must be before end time!</p>
      )}
      <div className="TopFixturesList_selector">
        <h3 className="TopFixturesList_selector_title">
          Select countries to display the fixture in
        </h3>
        <AutoInAndOutSelector
          disableSearch
          activeItemsString="Remove Countries"
          availableItemsString="Add countries"
          endpoints={{
            fetchActive: `sports/big-fixtures/fixture/${editingFixture.fixtureId}/countries-showing?`,
            fetchAvailable: `sports/big-fixtures/fixture/${editingFixture.fixtureId}/countries-not-showing?`,
            add: `/sports/big-fixtures/fixture/${editingFixture.fixtureId}/add-countries`,
            remove: `/sports/big-fixtures/fixture/${editingFixture.fixtureId}/delete-countries`,
          }}
        />
      </div>
      <div className="TopFixturesList_button_container">
        <div>
          <FlatButton
            className="FixtureList_button"
            onClick={val => updateFixture(val)}
            disabled={!enableSave}
          >
            Save
          </FlatButton>
          <FlatButton onClick={onClose}>{enableSave ? 'Cancel' : 'Exit'}</FlatButton>
        </div>
        <FlatButton onClick={() => onDelete(editingFixture)}>
          {!isRemovingFixture ? <RemoveTile /> : <LoadingWheel />}
        </FlatButton>
      </div>
    </div>
  </Dialog>
);

TopFixtureEdit.propTypes = {
  selectedCountry: PropTypes.shape().isRequired,
  onDelete: PropTypes.func.isRequired,
  editingFixture: PropTypes.shape().isRequired,
  updateFixture: PropTypes.func.isRequired,
  setEditing: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClose: PropTypes.func.isRequired,
  enableSave: PropTypes.bool,
  isRemovingFixture: PropTypes.bool.isRequired,
};

TopFixtureEdit.defaultProps = {
  error: null,
  enableSave: false,
};

export default TopFixtureEdit;
