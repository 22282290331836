/**            -                              ___
 *            |.|                          . /\_/\
 *          __]-[_________ /             .  (-<_>-)
 *         /        _____|<_          .   .  \/_\/
 *        / _   &  /               .   .
 *       / / \_ __|            .
 *     _/_/  / X   \
 *    <_/   /   ____\
 *         /___/|  /             zinedine zidane
 *         |  / ( <                --france--
 *         ( <   \ |
 *          \ |   >\
 *          _>|  (_/
 *         (__|
 *
 * MatchPint Ltd
 * @author Andreia Almeida
 * Date: 19/12/2018
 */
import React from 'react';
import createFormPage from '../formPage';
import tabs from '../../../routes/tabs';
import AutoInAndOutSelector from '../../../components/AutoInAndOutSelector';

const params = {
  endpoint: 'campaigns',
  create: {
    fields: ['title', 'name'],
  },
  sections: {
    General: [
      'active',
      'title',
      'name',
      'startDate',
      'endDate',
      'logoParticipatingVenue',
      'pillId',
    ],
    Venues: [
      {
        fieldPath: 'id',
        // eslint-disable-next-line react/prop-types
        Component: ({ editedValue }) => (
          <AutoInAndOutSelector
            enableCSVUpload
            activeItemsString="Venues Running the offer"
            availableItemsString="Available Venues"
            formatName={data =>
              data.map(item => ({
                id: item.id,
                name: item.name,
              }))
            }
            endpoints={{
              fetchActive: `venues/?status=1&inCampaignId=${editedValue}&`,
              fetchAvailable: `venues/?status=1&notInCampaignId=${editedValue}&`,
              add: `/campaigns/${editedValue}/venues/add`,
              remove: `/campaigns/${editedValue}/venues/remove`,
            }}
          />
        ),
      },
    ],
    Offers: [
      {
        fieldPath: 'id',
        // eslint-disable-next-line react/prop-types
        Component: ({ editedValue }) => (
          <AutoInAndOutSelector
            enableCSVUpload
            activeItemsString="Offers on this campaign"
            availableItemsString="Available Offers"
            formatName={data =>
              data.map(item => ({
                id: item.id,
                name: item.title,
              }))
            }
            endpoints={{
              fetchActive: `offers/?campaignId=${editedValue}&`,
              fetchAvailable: `offers/?status=1&notCampaignId=${editedValue}&`,
              add: `/campaigns/${editedValue}/offers/add`,
              remove: `/campaigns/${editedValue}/offers/remove`,
            }}
          />
        ),
      },
    ],
    Pills: [
      {
        fieldPath: 'id',
        // eslint-disable-next-line react/prop-types
        Component: ({ editedValue }) => (
          <AutoInAndOutSelector
            enableCSVUpload
            activeItemsString="Pills on this campaign"
            availableItemsString="Available Pills"
            formatName={data =>
              data.map(item => ({
                id: item.id,
                name: item.name,
              }))
            }
            endpoints={{
              fetchActive: `meta/pills?campaignId=${editedValue}&`,
              fetchAvailable: `meta/pills?status=1&notCampaignId=${editedValue}&`,
              add: `/campaigns/${editedValue}/pills/add`,
              remove: `/campaigns/${editedValue}/pills/remove`,
            }}
          />
        ),
      },
    ],
    'Redemption mechanic': ['redemptionType', 'redemptionCode'],
  },
  tabs: tabs.campaign,
};

const campaign = createFormPage('campaign', params);

export default campaign;
