import EntityList from '../listPage/EntityList';

export default new EntityList(
  'parameter',
  'meta/parameters',
  {
    zidane: true,
  },
  undefined,
  undefined,
  'Parameters',
);
