import { request } from './requests';

export const submitPassword =
  (venueId, { password, passwordConfirmation }) =>
  dispatch =>
    dispatch(
      request(`venues/reset-password/${venueId}`, {
        method: 'PUT',
        body: {
          password,
          passwordConfirmation,
        },
      }),
    );

export const fetchPaymentInformation = venueID => async dispatch =>
  dispatch(request(`venues/${venueID}/payment-info`, { method: 'GET' }));

export const getPaymentInfoShape = venueID => async dispatch =>
  dispatch(request(`venues/${venueID}/payment-info?formDescription=1`));

export const savePaymentInformation = (venueID, paymentInfo) => async dispatch =>
  dispatch(
    request(`venues/${venueID}/payment-info/update`, {
      method: 'PUT',
      body: { paymentInfo },
    }),
  );

export const getVenueCompleterData = id => dispatch =>
  dispatch(request(`venues/venue-completer/venues/${id}/venue-core-api-data`));

export const updateVenueCompleterData = (id, data) => dispatch =>
  dispatch(request(`venues/venue-completer/venues/${id}/update`, { method: 'PUT', body: data }));
