import MpResponse from './MpResponse';
import { getAdvertServiceEndpoint, getServicesEndpoint } from '../environment';
import ServerError from './Errors/ServerError';
import FormError from './Errors/FormError';

/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 24/09/2018
 */

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const defaultOptions = {
  method: 'GET',
  body: null,
  headers: {},
};

/**
 * @description Gets the error message from an error object.
 * @param error
 * @return {string}
 */
export function getErrorMessage(error) {
  if (error instanceof MpResponse) {
    if (typeof error.data.error === 'string') {
      return error.data.error;
    }
    return error.data.error.message;
  }
  if (error.response instanceof MpResponse) {
    if (typeof error.response.data.error === 'string') {
      return error.response.data.error;
    }
    return error.response.data.error.message;
  }
  if (typeof error.message === 'string') {
    return error.message;
  }
  if (typeof error.response === 'string') {
    return error.response;
  }
  return error.response.message;
}

/**
 * @description Handles response for a form request.
 * @param {MpResponse} mpResponse
 * @return {Promise<MpResponse|Error|ServerError|FormError>}
 */
export async function handleFormRequest(mpResponse) {
  // We only considers 200 response as a success.
  if (mpResponse.status === 200) {
    return Promise.resolve(mpResponse);
  }

  if (!Object.hasOwnProperty.call(mpResponse.data, 'error')) {
    return Promise.reject(
      new Error(`Unknown response from server: ${JSON.stringify(mpResponse.data)}`),
    );
  }

  const { error } = mpResponse.data;
  const message = getErrorMessage(mpResponse);

  // If the error has some field errors.
  if (Object.hasOwnProperty.call(error, 'fields')) {
    return Promise.reject(new FormError(error.fields, message, mpResponse.status));
  }

  return Promise.reject(new ServerError(error.message, mpResponse.status));
}

/**
 * @description Handles response for a classic request.
 * @param {MpResponse} mpResponse
 * @return {Promise<MpResponse|Error|ServerError>}
 */
export async function handleRequest(mpResponse) {
  if (mpResponse.isOK()) {
    return Promise.resolve(mpResponse);
  }

  if (!Object.hasOwnProperty.call(mpResponse.data, 'error')) {
    return Promise.reject(
      new Error(`Unknown response from server: ${JSON.stringify(mpResponse.data)}`),
    );
  }

  return Promise.reject(new ServerError(getErrorMessage(mpResponse), mpResponse.status));
}

/**
 * @description Default requests send to the server.
 * @param {string} path
 * @param {Object} options
 * @return {Promise<MpResponse>}
 */
export default async function request(
  path,
  options = defaultOptions,
  host = '',
  credentials = 'include',
) {
  // TODO come up with a better way to hit different services for different entities
  let h = host;
  if (!h) {
    h = !path.includes('advert') ? getServicesEndpoint() : getAdvertServiceEndpoint();
  }

  const response = await fetch(`${h}${path}`, {
    body: options.body ? JSON.stringify(options.body) : null,
    headers: {
      ...defaultHeaders,
      ...options.headers,
    },
    method: options.method,
    // Option to send / retrieve cookies.
    credentials,
  });

  // Transforms the raw response to a MpResponse instance.
  const data = await response.json();
  return new MpResponse(response.status, data);
}
