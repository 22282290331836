/**            -                              ___
 *            |.|                          . /\_/\
 *          __]-[_________ /             .  (-<_>-)
 *         /        _____|<_          .   .  \/_\/
 *        / _   &  /               .   .
 *       / / \_ __|            .
 *     _/_/  / X   \
 *    <_/   /   ____\
 *         /___/|  /             zinedine zidane
 *         |  / ( <                --france--
 *         ( <   \ |
 *          \ |   >\
 *          _>|  (_/
 *         (__|
 *
 * MatchPint Ltd
 * @author Andreia Almeida
 * Date: 07/11/2018
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Card } from 'material-ui/Card';

import './style.scss';
import FlatButton from 'material-ui/FlatButton';
import colors from '../../theme/index.scss';

const ErrorPage = ({ router }) => (
  <Card className="ErrorPage">
    <div className="ErrorPage_content">
      <div className="ErrorPage_content_main">
        <h1 className="ErrorPage_content_main_code">404</h1>
        <h2 className="ErrorPage_content_main_message">ZIDANE WE HAVE A PROBLEM!</h2>
        <FlatButton
          backgroundColor={colors.primaryYellow}
          className="ErrorPage_content_main_button"
          label="Homepage"
          onClick={() => {
            router.push('/');
          }}
        />
      </div>
    </div>
  </Card>
);

ErrorPage.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default ErrorPage;
