/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 26/10/2018
 */

import React from 'react';
import PropTypes from 'prop-types';

// Parameter parser.
import FormSections from '../FormSections';

// UI Component.
import FormAlert from '../FormAlert';
import LoadingWheel from '../LoadingWheel';
import SaveBar from '../SaveBar';
import SaveCreationButton from '../SaveCreationButton';

// Utils.
import { PAGE_TYPE_CREATE, PAGE_TYPE_EDIT } from '../../utils/constants';
import * as customPropTypes from '../../utils/propTypes';

// Style.
import './style.scss';

/**
 * @description Form wrapper that will render error messages, the save snack bar and any components
 *  that are common to any form.
 */
const Form = ({ entityName, parameters, type }) => (
  <div className="Form">
    <LoadingWheel size="full-page" entityName={entityName} parameters={parameters} type={type} />
    <FormAlert
      className="Form__error"
      entityName={entityName}
      parameters={parameters}
      type={type}
    />
    {parameters.information}
    <FormSections entityName={entityName} parameters={parameters} type={type} />
    {type === PAGE_TYPE_EDIT && <SaveBar entityName={entityName} parameters={parameters} />}
    {type === PAGE_TYPE_CREATE && (
      <div className="Form__save_button">
        <SaveCreationButton entityName={entityName} parameters={parameters} />
      </div>
    )}
  </div>
);

Form.propTypes = {
  entityName: PropTypes.string.isRequired,
  parameters: customPropTypes.parameters.isRequired,
  type: customPropTypes.pageType.isRequired,
};

export default Form;
