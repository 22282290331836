/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 31/07/2018
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { IconButton } from 'material-ui';

import './index.scss';

const ListPageIconButton = ({ icon, link, target }) => {
  const iconButton = <IconButton>{icon}</IconButton>;
  return target === null ? (
    <Link to={link} className="ListPage__list_row_buttons">
      {iconButton}
    </Link>
  ) : (
    <a href={link} className="ListPage__list_row_buttons" target={target}>
      {iconButton}
    </a>
  );
};

ListPageIconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
  target: PropTypes.oneOf(['_blank']),
};

ListPageIconButton.defaultProps = {
  target: null,
};

export default ListPageIconButton;
