import EntityList from '../listPage/EntityList';

export default new EntityList(
  'importVenues',
  'meta/import-status',
  {
    zidane: false,
  },
  undefined,
  undefined,
  'Import Status',
);
