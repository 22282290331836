import parameterList from './entityList';

import createListPage from '../listPage';
import { STRING, NUMBER } from '../listPage/fieldTypes';

import tabs from '../../../routes/tabs';

const parameterListPage = createListPage(
  parameterList,
  {
    id: {
      label: 'ID',
      type: NUMBER,
    },
    name: {
      label: 'Name',
      type: STRING,
    },
  },
  {
    navTabs: tabs.parameters,
  },
);

export default parameterListPage;
