import React from 'react';
import { quizzes } from '../../games';
import createFormPage from '../formPage';
import ResetAnswers from './components/resetAnswers';

const getParams = id => ({
  endpoint: `games/quizzes/${id}/members`,
  sections: {
    GENERAL: ['memberId', 'quizId', 'lives', 'inviteCode', 'agreeToShareData'],
    'Reset answers': [
      // eslint-disable-next-line react/jsx-props-no-spreading
      { fieldPath: 'id', Component: props => <ResetAnswers {...props} quizId={id} /> },
    ],
  },
  otherSection: false,
});

export default quizzes.map(q => createFormPage('quizMember', getParams(q.id)));
