import { getURLQuery } from '@matchpint/react-common';
import createFormPage from '../formPage';
import {
  createShowShowSaveConfirmation,
  createSetConfirmText,
  createHideShowSaveConfirmation,
  createSetSaveSuccessText,
} from '../formPage/store/actionCreatorFactory';
import { createGetShowSaveConfirmation } from '../formPage/store/selectorFactory';

const entityName = 'gameBatchPush';

const params = {
  endpoint: 'meta/batch-push',
  create: {
    fields: [
      'app',
      'countryOfResidenceId',
      'deepLink',
      'fanOfTeamId',
      'icon',
      'image',
      'localeId',
      'message',
      'notGameId',
      'notQuizId',
      'pushIdentifier',
      'testMemberId',
      'title',
    ],
    buttonLabel: 'Push',
    title: 'start a new batch push',
  },
  api: {
    getDescriptionAPIEndpoint: (_, queryParams) => ({
      url: `meta/batch-push${getURLQuery({ ...queryParams, formDescription: 1 })}`,
      method: 'POST',
    }),
    getCreateAPIEndpoint: body => ({
      url: 'meta/batch-push?zidane=1',
      method: 'POST',
      body: { push: body },
    }),
  },
  reduxExtension: {
    saveCreation: (saveCreationNormal, dispatch, getState) => {
      const confirm = createGetShowSaveConfirmation(entityName)(getState());
      saveCreationNormal(true, true, confirm).then(res => {
        if (!confirm && res && res.message) {
          dispatch(createShowShowSaveConfirmation(entityName)());
          return dispatch(createSetConfirmText(entityName)(res.message));
        }
        dispatch(createHideShowSaveConfirmation(entityName)());
        return res && res.message && dispatch(createSetSaveSuccessText(entityName)(res.message));
      });
    },
  },
};

const GameBatchPush = createFormPage(entityName, params);

export default GameBatchPush;
