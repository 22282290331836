import React from 'react';
import { PropTypes } from 'prop-types';
import Toggle from 'material-ui/Toggle';
import NumberEdit from '../../../components/FormField/Number';

const FixturesPerWeekLimit = ({ editedValue, onValueChange }) => (
  <>
    <Toggle
      label="Limit fixtures per week"
      toggled={Boolean(editedValue)}
      onToggle={() => onValueChange(!editedValue ? 1 : 0)}
    />
    {editedValue !== 0 && editedValue !== null && (
      <NumberEdit name="Amount" value={editedValue} handleEditField={val => onValueChange(val)} />
    )}
  </>
);

FixturesPerWeekLimit.propTypes = {
  editedValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onValueChange: PropTypes.func.isRequired,
};
FixturesPerWeekLimit.defaultProps = {
  editedValue: null,
};

export default FixturesPerWeekLimit;
