import React from 'react';
import createFormPage from '../formPage';
import tabs from '../../../routes/tabs';
import AssetSection from './AssetSection/AssetSection';
import AutoInAndOutSelector from '../../../components/AutoInAndOutSelector';

const params = {
  endpoint: 'meta/asset-group',
  sections: {
    General: [
      'availableFromDateUtc',
      'availableToDateUtc',
      'campaignId',
      'eventId',
      'displayImage',
      'fixturesStartDate',
      'fixturesEndDate',
      'name',
      'type',
    ],
    Assets: [{ fieldPath: 'id', Component: AssetSection }],
    Countries: [
      {
        fieldPath: 'id',
        // eslint-disable-next-line react/prop-types
        Component: ({ editedValue }) => (
          <AutoInAndOutSelector
            requestBodyName="ids"
            activeItemsString="Active countries"
            availableItemsString="Add countries"
            endpoints={{
              fetchActive: `meta/asset-group/${editedValue}/countries?`,
              fetchAvailable: `meta/asset-group/${editedValue}/countries/available?`,
              add: `meta/asset-group/${editedValue}/countries/add`,
              remove: `meta/asset-group/${editedValue}/countries/remove`,
            }}
          />
        ),
      },
    ],
  },
  create: {
    fields: ['name', 'type', 'availableFromDateUtc'],
  },
  tabs: tabs.assetGroup,
};

const channelGroup = createFormPage('assetGroup', params);

export default channelGroup;
