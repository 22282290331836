/**            -                              ___
 *            |.|                          . /\_/\
 *          __]-[_________ /             .  (-<_>-)
 *         /        _____|<_          .   .  \/_\/
 *        / _   &  /               .   .
 *       / / \_ __|            .
 *     _/_/  / X   \
 *    <_/   /   ____\
 *         /___/|  /             zinedine zidane
 *         |  / ( <                --france--
 *         ( <   \ |
 *          \ |   >\
 *          _>|  (_/
 *         (__|
 *
 * MatchPint Ltd
 * @author Andreia Almeida
 * Date: 20/12/2018
 */

import gameShareCopy from './EntityList';

import createListPage from '../listPage';
import { NUMBER } from '../listPage/fieldTypes';

const createPage = ({ entity }) =>
  createListPage(entity, {
    id: {
      label: 'id',
      type: NUMBER,
    },
    gameId: {
      label: 'gameId',
      type: NUMBER,
    },
    type: {
      label: 'type',
      type: NUMBER,
    },
  });

export default gameShareCopy.map(createPage);
