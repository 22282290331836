/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 30/04/2018
 */
import React from 'react';
import LinearProgress from 'material-ui/LinearProgress';

import style from './index.scss';

const LoadingPage = () => (
  <div className="Loading_Page">
    <LinearProgress
      className="Loading_Page__icon"
      mode="indeterminate"
      style={{
        backgroundColor: style.loadingIconColor,
      }}
    />
  </div>
);

export default LoadingPage;
