import React from 'react';
import venueUsersList from './entityList';

import createListPage from '../listPage';
import tabs from '../../../routes/tabs';

import { STRING, NUMBER } from '../listPage/fieldTypes';

const venueUsers = createListPage(
  venueUsersList,
  {
    id: {
      label: 'ID',
      type: NUMBER,
    },
    venue: {
      label: 'Venue',
      type: STRING,
      renderValue: venue => <p>{venue.name}</p>,
    },
    member: {
      label: 'Member',
      type: STRING,
      renderValue: member => (
        <p>
          {member.firstName} {member.lastName}
        </p>
      ),
    },
    type: {
      label: 'Type',
      type: STRING,
    },
  },
  {
    navTabs: tabs.venueUsers,
  },
);

export default venueUsers;
