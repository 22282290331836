//      ,---.
//    ,/     \__.'~\
//    |        '  . `===---      -Ed touched this file-
//    `|   ,     ,--'
//     `. /___,-'
//       |    \
//       ,\_  ,`-=

import * as c from './constants';

export const initialState = {
  topFixtures: [],
  allFixtures: [],
  numberOfMatches: 0,
  fetchingFixtures: false,
  fetchingMoreFixtures: false,
  fetchingTopFixtures: false,
  error: null,
  updateTopFixtureError: null,
  addOrRemoveFixtureError: null,
  fetchingMPCountries: false,
  matchpintCountries: [],
  matchpintCountriesError: null,
  fixtureItemLoading: null,
};

const topFixturesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case c.FETCH_TOP_FIXTURES_SUCCESS:
      return {
        ...state,
        topFixtures: action.payload.data.result.sort((a, b) => (a.priority > b.priority ? 1 : -1)),
        fetchingTopFixtures: false,
        numberOfMatches: action.payload.data.matches,
        error: null,
      };

    case c.FETCH_TOP_FIXTURES_PENDING:
      return {
        ...state,
        fetchingTopFixtures: true,
      };
    case c.FETCH_TOP_FIXTURES_ERROR:
      return {
        ...state,
        fetchingTopFixtures: false,
        error: action.payload,
      };

    case c.ORDER_CHANGED:
      return {
        ...state,
        topFixtures: action.payload,
        error: null,
      };
    case c.ORDER_CHANGED_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case c.FETCH_MP_COUNTRIES_SUCCESS:
      return {
        ...state,
        fetchingMPCountries: false,
        matchpintCountries: action.payload,
      };
    case c.FETCH_MP_COUNTRIES_ERROR:
      return {
        ...state,
        fetchingMPCountries: false,
        matchpintCountriesError: action.payload,
      };
    case c.FETCH_MP_COUNTRIES_PENDING:
      return {
        ...state,
        fetchingMPCountries: true,
      };
    case c.FETCH_FIXTURES_FOR_SEARCH_PENDING:
      return {
        ...state,
        fetchingFixtures: true,
      };
    case c.FETCH_FIXTURES_FOR_SEARCH_ERROR:
      return {
        ...state,
        fetchingFixtures: false,
      };
    case c.FETCH_FIXTURES_FOR_SEARCH_SUCCESS:
      return {
        ...state,
        allFixtures: action.payload,
        fetchingFixtures: false,
      };
    case c.FETCH_MORE_FIXTURES_FOR_SEARCH_PENDING:
      return {
        ...state,
        fetchingMoreFixtures: true,
      };
    case c.FETCH_MORE_FIXTURES_FOR_SEARCH_ERROR:
      return {
        ...state,
        fetchingMoreFixtures: false,
      };
    case c.FETCH_MORE_FIXTURES_FOR_SEARCH_SUCCESS:
      return {
        ...state,
        allFixtures: [...state.allFixtures, ...action.payload],
        fetchingMoreFixtures: false,
        error: null,
      };
    case c.FIXTURE_ITEM_LOADING:
      return {
        ...state,
        fixtureItemLoading: action.payload,
      };
    case c.LOCALLY_REMOVE_TOP_FIXTURE: {
      return {
        ...state,
        fixtureItemLoading: null,
        topFixtures: state.topFixtures.filter(fixture => fixture.id !== action.payload.id),
        addOrRemoveFixtureError: null,
      };
    }
    case c.LOCALLY_ADD_TOP_FIXTURE: {
      const updatedState = [...state.topFixtures, action.payload];
      return {
        ...state,
        topFixtures: updatedState,
        fixtureItemLoading: null,
        addOrRemoveFixtureError: null,
      };
    }
    case c.ADD_OR_REMOVE_FIXTURE_ERROR: {
      return {
        ...state,
        fixtureItemLoading: null,
        addOrRemoveFixtureError: action.payload,
      };
    }
    case c.UPDATE_TOP_FIXTURE: {
      const topFixtures = [...state.topFixtures];
      topFixtures[topFixtures.findIndex(f => f.id === action.payload.id)] = action.payload;
      return {
        ...state,
        topFixtures,
        error: null,
      };
    }
    case c.UPDATE_TOP_FIXTURE_ERROR:
      return {
        ...state,
        updateTopFixtureError: action.payload,
      };
    case c.CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        updateTopFixtureError: null,
        addOrRemoveFixtureError: null,
        matchpintCountriesError: null,
      };
    default:
      return state;
  }
};

export default topFixturesReducer;
