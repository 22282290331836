/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 13/11/2018
 */
import PropTypes from 'prop-types';

import { PAGE_TYPE_CREATE, PAGE_TYPE_EDIT } from './constants';

export const filterTypes = ['boolean', 'enum', 'string', 'integer', 'date', 'auto-complete'];

/**
 * @description Shape of the allowed types of form page.
 */
export const pageType = PropTypes.oneOf([PAGE_TYPE_CREATE, PAGE_TYPE_EDIT]);

/**
 * @description Shape of the form page parameters object.
 */
export const parameters = PropTypes.shape(); // TODO

/**
 * @description Shape of a field value.
 */
export const sectionField = PropTypes.oneOfType([
  PropTypes.string, // Format: field1.field2.fields3...
  PropTypes.arrayOf(PropTypes.string), // Format: ['field1', 'field2', 'fields3']
  PropTypes.shape({
    // If you want to render a personalized component.
    Component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]), // Component to render.
    fieldPath: PropTypes.oneOfType([
      // Path to the object to inject in the Component.
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    id: PropTypes.string,
  }),
]);

/**
 * @description Shape of a form section value.
 */
export const section = PropTypes.arrayOf(sectionField);
