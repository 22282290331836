import { games, quizzes } from '../template/games';
import * as perm from './permissionsForRoutes';

const menuLink = [
  {
    name: 'Venues',
    links: [
      {
        link: '/venues',
        name: 'Venues',
        limitedAccess: perm.venues,
        nestedLink: [
          {
            link: '/venues/venue-group',
            name: 'Groups',
            limitedAccess: perm.venueGroup,
          },
          {
            link: '/venues/venue-users',
            name: 'Users',
            limitedAccess: perm.venueMember,
          },
          {
            link: '/venues/group-code',
            name: 'PI codes',
            limitedAccess: perm.groupCodes,
          },
          {
            link: '/venues/venue-completer',
            name: 'Venue Completer',
            limitedAccess: perm.venueCompleter,
            nestedLink: [
              {
                link: '/venues/venue-completer/venues',
                name: 'Venue Completer Venues',
                limitedAccess: perm.venueCompleter,
              },
            ],
          },
          {
            link: '/import-pubs',
            name: 'Import venues',
            limitedAccess: perm.importVenues,
            nestedLink: [
              {
                link: '/meta/import-status?importer=MatchPint%5CImporter%5CVenueImporter',
                name: 'Status',
                limitedAccess: perm.importVenues,
              },
            ],
          },
        ],
      },
      {
        link: '/offers',
        name: 'Offers',
        limitedAccess: perm.offer,
        nestedLink: [
          {
            link: '/offers/redemption-code',
            name: 'Redemption Codes',
            limitedAccess: perm.offer,
          },
        ],
      },

      {
        link: '/import-events',
        name: 'Import Events',
        limitedAccess: perm.importEvents,
        nestedLink: [
          {
            link: '/meta/import-status?importer=MatchPint%5CImporter%5CVenueEventsImporter',
            name: 'Status',
            limitedAccess: perm.importVenues,
          },
        ],
      },
    ],
  },
  {
    name: 'Brands',
    links: [
      {
        link: '/adverts',
        name: 'Adverts',
        limitedAccess: perm.adverts,
      },
      {
        link: '/campaigns',
        name: 'Campaigns',
        limitedAccess: perm.campaign,
      },
      {
        link: '/venues/data-scope',
        name: 'Venue Finder Data Scopes',
        limitedAccess: perm.venueFinderDataScopes,
      },
      {
        link: '/games/',
        name: 'Games',
        limitedAccess: perm.game,
        nestedLink: games.map(g => ({
          link: `/games/edit?id=${g.game}`,
          name: g.gameName,
          limitedAccess: perm.game,
          nestedLink: [
            {
              link: `/games/${g.game}/leagues`,
              name: 'Leagues',
              limitedAccess: perm.league,
            },
            {
              link: `/games/${g.game}/share-copy`,
              name: 'Share Copy',
              limitedAccess: perm.game,
            },
            {
              link: `/games/${g.game}/${g.leagueType}-league-prize`,
              name: `${g.leagueType} prize`,
              limitedAccess: perm.gameLeagueRoundPrize,
            },
            {
              link: `/games/${g.game}/fixtures`,
              name: 'Matches',
              limitedAccess: perm.fixture,
            },
            {
              link: `/games/${g.game}/prizes`,
              name: 'Prizes',
              limitedAccess: perm.gamePrize,
            },
            {
              link: `/games/${g.game}/prizes-members`,
              name: 'Prize Member',
              limitedAccess: perm.gamePrizeMember,
            },
            {
              link: `/sports/competitions/rounds?competitionId=${g.competitionId}`,
              name: 'Rounds',
              limitedAccess: perm.competitionRound,
            },
            {
              link: `/games/reactive-prize/${g.game}`,
              name: 'Reactive Prize',
              limitedAccess: perm.reactivePrize,
            },
            {
              link: `/meta/batch-push/create?gameId=${g.game}`,
              name: 'Batch Push',
              limitedAccess: perm.gameBatchPush,
            },
          ],
        })),
      },
      {
        link: '/games/quizzes/',
        name: 'Quizzes',
        limitedAccess: perm.game,
        nestedLink: quizzes.map(quiz => ({
          link: `/games/quizzes/edit?id=${quiz.id}`,
          name: quiz.name,
          limitedAccess: perm.quiz,
          nestedLink: [
            {
              link: `/games/quizzes/${quiz.id}/rounds`,
              name: `Rounds`,
              limitedAccess: perm.quizRound,
            },
            {
              link: `/games/quizzes/${quiz.id}/questions`,
              name: `Questions`,
              limitedAccess: perm.quizQuestion,
            },
            {
              link: `/games/quizzes/rounds/themes`,
              name: `Themes`,
              limitedAccess: perm.quizThemes,
            },
            {
              link: `/games/quizzes/${quiz.id}/answers`,
              name: 'Answers',
              limitedAccess: perm.quizAnswer,
            },
            {
              link: `/games/quizzes/${quiz.id}/members`,
              name: 'Members',
              limitedAccess: perm.quizMember,
            },
            {
              link: `/meta/batch-push/create?quizId=${quiz.id}`,
              name: 'Batch Push',
              limitedAccess: perm.quizBatchPush,
            },
          ],
        })),
      },
    ],
  },
  {
    name: 'Sports',
    links: [
      {
        link: `/sports`,
        name: 'Sport',
        limitedAccess: perm.sports,
      },
      {
        link: '/sport-priority',
        name: 'Sport priority',
        limitedAccess: perm.sportPriority,
      },
      {
        link: '/sports/competitions',
        name: 'Competitions',
        limitedAccess: perm.competitions,
      },
      {
        link: '/competition-priority',
        name: 'Competition priority',
        limitedAccess: perm.compeitionPriority,
      },
      {
        link: '/sports/teams',
        name: 'Teams',
        limitedAccess: perm.team,
      },
      {
        link: '/sports/fixtures',
        name: 'Events',
        limitedAccess: perm.events,
        nestedLink: [
          {
            link: '/sports/service-events/mismatch',
            name: 'Consumer Events',
            limitedAccess: perm.consumerEvents,
          },
        ],
      },
      {
        link: '/meta/calendar-content',
        name: 'Calendar Content',
        limitedAccess: perm.calendarContent,
      },
    ],
  },
  {
    name: 'App',
    links: [
      {
        link: '/homepage',
        name: 'App Homepage',
        limitedAccess: perm.homepage,
      },
      {
        link: '/meta/homepage/sections',
        name: 'App Homepage sections',
        limitedAccess: perm.homepage,
      },
      {
        link: '/meta/news',
        name: 'News',
        limitedAccess: perm.news,
      },
      {
        link: '/members',
        name: 'Members',
        limitedAccess: perm.members,
      },
      {
        link: '/meta/batch-push/create',
        name: 'Batch Push',
        limitedAccess: perm.batch,
      },
      {
        link: '/meta/csv-batch-push/create',
        name: 'CSV Batch Push',
        limitedAccess: perm.batch,
      },
      {
        link: '/meta/pills',
        name: 'Pills',
        limitedAccess: perm.pills,
      },
      {
        link: '/deeplinks',
        name: 'Deeplinks',
        limitedAccess: perm.deeplinks,
      },
      {
        link: '/contents/trivias',
        name: 'Beer mat trivia',
        limitedAccess: perm.trivias,
      },
    ],
  },
  {
    name: 'Web and App',
    links: [
      {
        link: '/big-fixtures',
        name: 'Big fixtures',
        limitedAccess: perm.bigFixtures,
      },
      {
        link: '/meta/channels',
        name: 'Channels',
        limitedAccess: perm.channels,
      },
    ],
  },
  {
    name: 'Portal',
    links: [
      {
        link: '/meta/country-channels',
        name: 'Channel Groups',
        limitedAccess: perm.countryChannel,
      },
      {
        link: '/contents/social-media-themes/competition-countries',
        name: 'Competitions by country',
        limitedAccess: perm.venueSocialMediaCompetitionCountry,
      },

      {
        link: '/meta/pub-portal/permissions',
        name: 'Permissions',
        limitedAccess: perm.permission,
      },
      {
        link: '/meta/pub-portal/tiers',
        name: 'Tiers',
        limitedAccess: perm.tier,
      },
      {
        link: '/venues/pub-portal-code',
        name: 'Promo Codes',
        limitedAccess: perm.pubPortalCode,
      },
    ],
  },
  {
    name: 'Misc',
    links: [
      {
        link: '/meta/asset-group',
        name: 'Asset Group',
        limitedAccess: perm.assetGroup,
      },
      {
        link: '/meta/reports',
        name: 'Reports',
        limitedAccess: perm.reports,
      },
      {
        link: '/URLShortener',
        name: 'URL Shortener',
        limitedAccess: perm.URLShortener,
      },
      {
        link: '/venues/mandates',
        name: 'Mandates',
        limitedAccess: perm.mandates,
      },
      {
        link: '/meta/parameters',
        name: 'Parameters',
        limitedAccess: perm.parameter,
      },
      {
        link: '/meta/cache/bust/create',
        name: 'Bust Cache',
        limitedAccess: perm.bustCache,
      },
    ],
  },
];

export default menuLink;
