/* eslint-disable react/jsx-props-no-spreading */
/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 25/06/2018
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';

/**
 * @description List which contains the different droppable elements.
 * @param {string} className
 * @param {string} droppableId Id of the list (used for DragAndDrop Context).
 * @param {node} header
 * @param {[node]} items All the items need an id prop to be processed.
 * @returns {node}
 */
const DnDList = ({ className, droppableId, header, items, renderItem, listStyleOverride }) => (
  <div className="DragAndDrop__List_container" style={listStyleOverride}>
    <Droppable droppableId={droppableId}>
      {(provided, snapshot) => (
        <div
          className={`${className} DragAndDrop__List ${snapshot.isDraggingOver ? ' active' : ''}`}
          ref={provided.innerRef}
        >
          {header !== null ? <div className="DragAndDrop__List_header">{header}</div> : null}
          <div className="DragAndDrop__List_content_container">
            <div className="DragAndDrop__List_content">
              {items
                ? items.map((item, index) => (
                    <Draggable key={`draggable-${item.id}`} draggableId={item.id} index={index}>
                      {providedDrag => (
                        <div
                          key={`draggable-container-${item.id}`}
                          ref={providedDrag.innerRef}
                          {...providedDrag.draggableProps}
                          {...providedDrag.dragHandleProps}
                          style={providedDrag.draggableProps.style}
                        >
                          {renderItem(item, index)}
                        </div>
                      )}
                    </Draggable>
                  ))
                : null}
            </div>
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </div>
);

DnDList.propTypes = {
  listStyleOverride: PropTypes.shape(),
  className: PropTypes.string,
  droppableId: PropTypes.string.isRequired,
  header: PropTypes.node,
  // Items to render
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  ),
  // Function allowing to render a Draggable item based on an element of this.props.items
  renderItem: PropTypes.func.isRequired,
};

DnDList.defaultProps = {
  listStyleOverride: null,
  className: '',
  header: null,
  items: [],
};

export default DnDList;
