import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Checkbox } from 'material-ui';
import Dialog from 'material-ui/Dialog';
import { Link } from 'react-router';
import Close from 'material-ui/svg-icons/navigation/close';
import { getBase64FromFile } from '../../utils/helpers';
import { getVenueCompleterData, updateVenueCompleterData } from '../../store/thunks/api-venues';
import LoadingWheel from '../../components/LoadingWheel';
import MpResponse from '../../utils/apiRequest/MpResponse';
import TextField from '../../components/FormField/Text';
import NumberField from '../../components/FormField/Number';
import OpeningHoursEditor from './OpeningHoursEditor';
import FileUpload from '../../components/FileUpload/FileUpload';
import { fileUploadAcceptTypes } from '../../utils/globalVariables';
import { completerURL } from '../../template/forms/venueCompleter/UploadBatch';
import FormAlert from '../../components/FormAlert';

const Button = ({ children, className, onClick, disabled, loading }) => (
  <button
    disabled={disabled}
    type="button"
    className={`rounded px-4 py-1 font-semibold ${className}`}
    onClick={onClick}
  >
    {!loading ? children : <LoadingWheel size="inline" color="black" />}
  </button>
);

const Review = ({ review, reviews, updateReviews }) => {
  const [showMore, setShowMore] = useState(false);
  const { author, rating, review: text } = review;
  const reviewOnProfile = reviews?.find(r => r.author === author);
  const textLength = 64;

  return (
    <div className="w-44 bg-veryLightGrey p-4">
      <div className="flex">
        <div className="flex-1">
          {[...Array(rating)].map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={`${author}${i}`}>&#9733;</span>
          ))}
        </div>
        {updateReviews && (
          <div>
            <Checkbox
              checked={reviewOnProfile}
              onClick={() => {
                if (reviewOnProfile) {
                  updateReviews(reviews.filter(r => r.author !== author));
                } else {
                  updateReviews([...reviews, review]);
                }
              }}
            />
          </div>
        )}
      </div>
      <p>{author}</p>
      <p>
        {text?.slice(0, !showMore ? textLength : undefined)}
        {!showMore ? '...' : ''}
      </p>
      {text?.length > textLength && (
        <Button
          className="flex w-full items-center justify-center pt-3 text-sm"
          onClick={() => setShowMore(!showMore)}
        >
          <p className="mr-2">read {showMore ? 'less' : 'more'} </p>
          <div className={showMore ? '-rotate-90' : 'rotate-90'}>▸</div>
        </Button>
      )}
    </div>
  );
};

const ImageModal = ({ close, open }) => (
  <Dialog
    onRequestClose={close}
    open={Boolean(open)}
    modal={false}
    bodyClassName="flex justify-center overflow-scroll"
  >
    <div className="flex justify-center">
      <div className="absolute right-8 top-8 rounded bg-white p-1 hover:cursor-pointer">
        <Close onClick={close} className="" />
      </div>
      <img alt="" src={open} />
    </div>
  </Dialog>
);

const Photo = ({ img, onClick, checked, onCheck, error }) => (
  <div
    style={{ backgroundImage: `url(${img})` }}
    className={`flex h-52 w-52 rounded-md bg-cover ${error ? 'border-2 border-primaryRed' : ''}`}
  >
    <Button className="flex-1" onClick={onClick} />
    {onCheck && (
      <div>
        <Checkbox
          checked={checked}
          onClick={onCheck}
          style={{ backgroundColor: 'white' }}
          iconStyle={{ marginRight: 0 }}
        />
      </div>
    )}
  </div>
);

const baseFields = [
  'screensNumber',
  'longDescription',
  'servesFood',
  'hasBigScreen',
  'hasGarden',
  'hasOutdoorScreen',
  'hasSound',
  'hasWifi',
  'allowsPet',
  'website',
  'phone',
];

const VenueCompleter = ({
  fetch,
  update,
  params: { id },
  location: {
    query: { batchId },
  },
}) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [newData, setNewData] = useState({});
  const [fieldChoice, setFieldChoice] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalImage, setModalImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isUpdatingImages, setIsUpdatingImages] = useState(false);
  const [selectedGoogleImages, setSelectedGoogleImages] = useState([]);
  const [errorImages, setErrorImages] = useState([]);
  const [forceSave, setForceSave] = useState(false);
  const [nextID, setNextID] = useState(null);

  const setFields = (fields, dataSet) =>
    fields
      .map(f => ({ key: f, value: dataSet?.venue?.[f] }))
      .reduce((obj, item) => ({ ...obj, [item.key]: item.value }), {});

  const setTemporaryErr = e => {
    setError(e);
    const errors = [];
    // eslint-disable-next-line array-callback-return
    e.data.error.fields.map(fe => {
      if (selectedGoogleImages.includes(fe.id)) {
        errors.push(fe.id);
      }
    });

    setErrorImages(errors);

    setTimeout(() => setError(null), 5000);
  };

  useEffect(() => {
    setLoading(true);
    setNextID(null);
    setFieldChoice({});
    fetch(id)
      .then(r => r.getResult())
      .then(res => {
        setData(res);
        setNewData({
          ...setFields(baseFields, res),
          status: 'done',
          venueReviews: res.venue.venueReviews || [],
        });
        setForceSave(true);
        setError(null);
      })
      .catch(setTemporaryErr)
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading) {
    return <LoadingWheel />;
  }

  const hasunSavedChanges = forceSave || baseFields.some(f => data.venue?.[f] !== newData[f]);

  const fields = ['website', 'phone'];

  const hasGoogleReviews = data?.google?.reviews?.length > 0;
  const hasVenueReviews = newData?.venueReviews?.length > 0;

  const uploadFile = (file, successCallback, errorCallback) =>
    getBase64FromFile(file)
      .then(base64 => {
        successCallback();
        return base64;
      })
      .catch(errorCallback);

  const updateData = () => fetch(id).then(res => setData(res.getResult()));

  const facilities = [
    { icon: '', text: 'Food', field: 'servesFood' },
    { icon: '', text: 'Big Screen', field: 'hasBigScreen' },
    { icon: '', text: 'Garden', field: 'hasGarden' },
    { icon: '', text: 'Outdoor Screen', field: 'hasOutdoorScreen' },
    { icon: '', text: 'Commentary', field: 'hasSound' },
    { icon: '', text: 'WIFI', field: 'hasWifi' },
    { icon: '', text: 'Pet friendly', field: 'allowsPet' },
  ];

  const save = () =>
    update(id, newData)
      .then(r => {
        if (r.status === 202) {
          throw r;
        }
        return r;
      })
      .then(r => {
        setForceSave(false);
        setNextID(r.getResult()?.next);
        setError(null);
      })
      .then(updateData)
      .catch(setTemporaryErr);

  const updateImages = payload => {
    setErrorImages([]);
    return update(id, payload)
      .then(r => {
        if (r.status === 202) {
          throw r;
        }
        return r;
      })
      .then(r => {
        setNextID(r.getResult()?.next);
        setError(null);
      })
      .then(updateData)
      .catch(setTemporaryErr);
  };

  const isProfilePicDefault =
    data?.venue?.profilePicture?.original ===
    'https://matchpint-cdn.matchpint.cloud/shared/img/pub/default/original.jpg';

  return (
    <>
      <div className="sticky top-0 z-10 flex justify-between bg-white px-2 py-4">
        {batchId && (
          <Link className="flex items-center" to={`${completerURL}/venues?batchId=${batchId}`}>
            <div className="mr-1 rotate-180 pb-1 text-xl">▸</div>Back to Batch
          </Link>
        )}
        <div>
          {/* TODO ADD A PREVIOUS BUTTON */}
          <Button
            disabled={!hasunSavedChanges}
            onClick={save}
            className={`${
              hasunSavedChanges ? 'bg-primaryYellow' : 'border border-darkGreen text-darkGreen'
            } mx-2`}
          >
            {hasunSavedChanges ? 'Save' : 'Saved'}
          </Button>
          {nextID && (
            <Link
              to={`${completerURL}/edit/${nextID}${batchId ? `?batchId=${batchId}` : ''}`}
              className={`${
                hasunSavedChanges ? 'bg-lightGrey' : 'bg-primaryYellow'
              } mr-2 rounded px-3 py-2`}
            >
              Next
            </Link>
          )}
        </div>
      </div>
      <div className="container mx-auto py-4">
        <FormAlert
          className="top-10"
          entityDescription={null}
          errorMessage={error ? MpResponse.getErrorMessage(error) : null}
          fieldsError={error?.data?.error?.fields}
        />
        <div className="items-center justify-between lg:flex">
          <p className="pb-4 pl-4 text-4xl font-semibold uppercase">{data?.venue?.name}</p>
          <div className="mr-2 text-sm">
            <p className="text-lightRed">Is there an error on this profile?</p>
            <label className="mr-2" htmlFor="dataMissing">
              Data is missing
            </label>
            <input
              className="mr-4"
              type="radio"
              onChange={() => {
                setForceSave(true);
                setNewData({ ...newData, status: 'dataMissing' });
              }}
              checked={newData.status === 'dataMissing'}
              id="dataMissing"
            />
            <label className="mr-2" htmlFor="dataWrong">
              Data is wrong
            </label>
            <input
              className="mr-4"
              type="radio"
              onChange={() => {
                setForceSave(true);
                setNewData({ ...newData, status: 'dataWrong' });
              }}
              checked={newData.status === 'dataWrong'}
              id="dataWrong"
            />
            <label className="mr-2" htmlFor="dataDone">
              Data is complete
            </label>
            <input
              type="radio"
              onChange={() => {
                setForceSave(true);
                setNewData({ ...newData, status: 'done' });
              }}
              checked={newData.status === 'done'}
              id="dataDone"
            />
          </div>
        </div>
        <p className="pl-4">{data?.venue?.address1}</p>
        <p className="pl-4">{data?.venue?.city}</p>
        <p className="pl-4">{data?.venue?.postCode}</p>

        <div className="border-b border-lightGrey lg:flex">
          <div className="flex-1 border-b border-lightGrey p-4 lg:border-b-0 lg:border-r">
            {fields.map(f => (
              <Field
                key={f}
                field={f}
                fieldChoice={fieldChoice}
                setFieldChoice={setFieldChoice}
                data={data}
                setNewData={setNewData}
                newData={newData}
              />
            ))}
            <NumberField
              handleEditField={v => {
                const screensNumber = parseInt(v, 10);
                if (screensNumber > 0) {
                  setNewData({ ...newData, screensNumber });
                }
              }}
              name="Number of Screens"
              value={newData?.screensNumber}
            />
            <p className="font-xl py-4 font-semibold">Facilities</p>
            <div className="flex flex-wrap gap-2">
              {facilities.map(f => (
                <button
                  key={`${f.field}`}
                  onClick={() => setNewData({ ...newData, [f.field]: !newData[f.field] })}
                  type="button"
                  className="flex items-center bg-veryLightGrey px-3 py-1"
                >
                  <p className="text-sm font-semibold">{f.text}</p>
                  <div>
                    <Checkbox checked={newData[f.field]} />
                  </div>
                </button>
              ))}
            </div>
          </div>
          <SelectOpeningHours
            data={data}
            currentSelection={fieldChoice?.openingHours}
            updateFieldChoice={openingHours => {
              setFieldChoice({ ...fieldChoice, openingHours });
              setForceSave(true);
            }}
            setNewData={setNewData}
            newData={newData}
          />
        </div>
        <TextField
          handleEditField={v => setNewData({ ...newData, longDescription: v })}
          name="Description"
          containerClassName="flex-1"
          text={newData.longDescription}
        />

        <div className="p-4">
          {hasVenueReviews && (
            <>
              <p className="font-xl pb-4 font-semibold">Profile Reviews</p>
              <div className="flex flex-wrap gap-2">
                {newData.venueReviews?.map(review => (
                  <Review key={`${review?.author}${review?.rating}-venue`} review={review} />
                ))}
              </div>
            </>
          )}
          {hasGoogleReviews && (
            <>
              <p className="font-xl pb-4 font-semibold">Google Reviews</p>
              <div className="flex flex-wrap gap-2">
                {data?.google?.reviews?.map(review => (
                  <Review
                    key={`${review?.author}${review?.rating}-google`}
                    review={{ ...review, source: 'google' }}
                    updateReviews={venueReviews => {
                      setForceSave(true);
                      setNewData({ ...newData, venueReviews });
                    }}
                    reviews={newData.venueReviews}
                  />
                ))}
              </div>
            </>
          )}
          <p className="font-xl py-4 font-semibold">Photos</p>
          <p className="font-semibold opacity-70">Current</p>
          <p className="py-2 text-sm opacity-70">Profile Pic</p>
          <Photo
            onClick={() => setModalImage(data?.venue?.profilePicture?.original)}
            img={data?.venue?.profilePicture?.original}
          />
          <p className="py-2 text-sm opacity-70">Other Photos</p>
          <div className="flex flex-wrap gap-2">
            {Object.values(data.venue.extraImages).map(i => (
              <Photo
                key={i.id}
                onClick={() => setModalImage(i.original)}
                img={i.original}
                checked={selectedImages.includes(i.id)}
                onCheck={() => {
                  if (selectedImages.includes(i.id)) {
                    setSelectedImages(selectedImages.filter(si => si !== i.id));
                  } else {
                    setSelectedImages([...selectedImages, i.id]);
                  }
                }}
              />
            ))}
          </div>
          {selectedImages.length > 0 && (
            <div className="flex pt-4">
              <Button
                loading={isUpdatingImages}
                disabled={isUpdatingImages}
                onClick={() => {
                  setIsUpdatingImages(true);
                  updateImages({ imagesDelete: selectedImages })
                    .then(() => setSelectedImages([]))
                    .finally(() => setIsUpdatingImages(false));
                }}
                className="bg-primaryRed text-white "
              >
                Delete
              </Button>
              {selectedImages.length === 1 && (
                <Button
                  loading={isUpdatingImages}
                  disabled={isUpdatingImages}
                  className="ml-3 bg-primaryYellow"
                  onClick={() => {
                    const profilePic = Object.values(data.venue.extraImages).find(
                      eI => eI.id === selectedImages[0],
                    );
                    setIsUpdatingImages(true);
                    updateImages({
                      venueImages: [{ ...profilePic, profilePicture: true, source: 'db' }],
                    })
                      .then(() => setSelectedImages([]))
                      .finally(() => setIsUpdatingImages(false));
                  }}
                >
                  Make Profile Pic
                </Button>
              )}
            </div>
          )}
          <p className="py-2 font-semibold opacity-70">From Google</p>
          <div className="flex flex-wrap gap-2">
            {data.google.photos.map(i => (
              <Photo
                key={i.id}
                onClick={() => setModalImage(i.imageUrl)}
                img={i.imageUrl}
                error={errorImages.includes(i.id)}
                checked={selectedGoogleImages.includes(i.id)}
                onCheck={() => {
                  if (selectedGoogleImages.includes(i.id)) {
                    setSelectedGoogleImages(selectedGoogleImages.filter(si => si !== i.id));
                  } else {
                    setSelectedGoogleImages([...selectedGoogleImages, i.id]);
                  }
                }}
              />
            ))}
          </div>
          {selectedGoogleImages.length > 0 && (
            <div className="flex pt-4">
              <Button
                loading={isUpdatingImages}
                disabled={isUpdatingImages}
                className="bg-primaryYellow"
                onClick={() => {
                  setIsUpdatingImages(true);
                  updateImages({
                    venueImages: data.google.photos
                      .filter(g => selectedGoogleImages.includes(g.id))
                      .map(g => ({
                        source: 'google',
                        profilePicture: false,
                        id: g.id,
                        image: g.imageUrl,
                      })),
                  })
                    .then(() => setSelectedGoogleImages([]))

                    .finally(() => setIsUpdatingImages(false));
                }}
              >
                Add
              </Button>
            </div>
          )}

          <p className="py-2 font-semibold opacity-70">Upload Photos</p>
          <FileUpload
            acceptType={fileUploadAcceptTypes.image}
            processFile={async (f, s, e) => {
              setIsUploading(true);
              const image = await uploadFile(f, s, e);
              updateImages({
                venueImages: [
                  {
                    source: 'upload',
                    image,
                    id: Date.now(),
                    // Make profile picture if there is a default one and there are no extraImages
                    profilePicture: isProfilePicDefault && data?.venue?.extraImages?.length === 0,
                  },
                ],
              }).finally(() => setIsUploading(false));
            }}
            overridePreviewComponent={isUploading ? <LoadingWheel /> : <div />}
          />
        </div>
        <ImageModal open={modalImage} close={() => setModalImage(null)} />
      </div>
    </>
  );
};

const SelectOpeningHours = ({ data, currentSelection, updateFieldChoice, newData, setNewData }) => {
  const [showCopyDayButton, setShowCopyDayButton] = useState(null);

  const modes = ['venue', 'google'];

  return (
    <div className="p-4">
      <p className="font-lg pb-4 font-semibold">Opening Hours</p>
      <div className="flex">
        {modes.map(m => {
          const isSelected = currentSelection === m;
          if (isSelected) {
            const onChange = oH => setNewData({ ...newData, openingHours: oH });
            const { openingHours } = newData;
            return (
              <div>
                {Object.keys(openingHours).map(day => (
                  <OpeningHoursEditor
                    key={`${day}`}
                    showCopyDayButton={showCopyDayButton === day}
                    copyDayButtonAction={() => {
                      const newDays = {};
                      Object.keys(openingHours).forEach(d => {
                        newDays[d] = {
                          opening: openingHours[day].opening,
                          closing: openingHours[day].closing,
                        };
                      });
                      onChange(newDays);
                      setShowCopyDayButton(null);
                    }}
                    text={day.slice(0, 3)}
                    valueFrom={openingHours[day].opening}
                    valueTo={openingHours[day].closing}
                    setDay={(from, to) => {
                      setShowCopyDayButton(day);
                      onChange({
                        ...openingHours,
                        [day]: {
                          opening: from,
                          closing: to,
                        },
                      });
                    }}
                  />
                ))}
              </div>
            );
          }
          return (
            <div
              key={m}
              className={`flex pr-4 ${
                currentSelection && !isSelected ? 'scale-75 opacity-60' : ''
              }`}
            >
              <div>
                <p className="pb-2 text-sm">On {m}</p>
                {Object.keys(data?.[m]?.openingHours).map(day => {
                  const { opening, closing } = data?.[m]?.openingHours?.[day] || {};
                  const midnight = '00:00';
                  const isClosed = opening === null && closing === null;
                  const isOpen24 = opening === midnight && closing === midnight;
                  const getValue = () => {
                    if (isClosed) {
                      return 'Closed';
                    }
                    if (isOpen24) {
                      return 'Open 24 hours';
                    }
                    return `${opening} - ${closing}`;
                  };
                  return (
                    <div key={`${day}-${m}`} className="flex">
                      <p className="w-10 text-sm font-semibold uppercase">{day?.slice(0, 3)}:</p>
                      <p className="ml-2 text-sm">{getValue()}</p>
                    </div>
                  );
                })}
              </div>
              <div>
                <Checkbox
                  checked={isSelected}
                  onClick={() => {
                    if (!isSelected) {
                      setNewData({ ...newData, openingHours: data?.[m]?.openingHours });
                      updateFieldChoice(m);
                    }
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const Field = ({ field, fieldChoice, setFieldChoice, newData, setNewData, data }) => {
  const modes = ['venue', 'google'];
  return (
    <>
      <p className="font-lg font-semibold capitalize">{field}</p>
      <div className="flex">
        {modes.map(m => {
          const isSelected = fieldChoice[field] === m;
          return (
            <div
              key={m}
              className={`flex ${
                fieldChoice[field] && !isSelected ? 'scale-90 opacity-60' : 'flex-1 '
              }`}
            >
              <TextField
                containerClassName="flex-1"
                handleEditField={v => setNewData({ ...newData, [field]: v })}
                placeholder={field}
                name={`On ${m}`}
                disabled={!isSelected}
                text={newData?.[field] || data?.[m]?.[field]}
              />

              <div>
                <Checkbox
                  checked={isSelected}
                  onClick={() => {
                    if (!isSelected) {
                      setNewData({ ...newData, [field]: data?.[m]?.[field] });
                      setFieldChoice({ ...fieldChoice, [field]: m });
                    }
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

VenueCompleter.propTypes = {
  fetch: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  params: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
};

Review.propTypes = {
  review: PropTypes.shape({
    author: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    review: PropTypes.string.isRequired,
  }).isRequired,
  reviews: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateReviews: PropTypes.func.isRequired,
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

Button.defaultProps = {
  disabled: false,
  loading: false,
  className: '',
  children: null,
};

SelectOpeningHours.propTypes = {
  data: PropTypes.shape().isRequired,
  currentSelection: PropTypes.string.isRequired,
  newData: PropTypes.shape().isRequired,
  updateFieldChoice: PropTypes.func.isRequired,
  setNewData: PropTypes.func.isRequired,
};

Photo.propTypes = {
  img: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  onCheck: PropTypes.func,
  error: PropTypes.bool,
};
Photo.defaultProps = {
  checked: null,
  onCheck: null,
  error: false,
};

ImageModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func.isRequired,
};
ImageModal.defaultProps = {
  open: false,
};

const mapDispatchToProps = {
  fetch: getVenueCompleterData,
  update: updateVenueCompleterData,
};

export default connect(null, mapDispatchToProps)(VenueCompleter);
