/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import ExpendableCard from '../../../components/ExpendableCard';
import FileUpload from '../../../components/FileUpload/FileUpload';
import { fileUploadAcceptTypes } from '../../../utils/globalVariables';
import { getBase64FromFile } from '../../../utils/helpers';
import './styles.scss';

const Advert = ({ ad, zone, editedValue, onValueChange, uploadFile }) => (
  <>
    <button
      onClick={() => {
        const safe = [...editedValue];
        const index = safe.findIndex(a => a.type.type === ad?.type?.type);
        safe.splice(index, 1);
        onValueChange(safe);
      }}
      type="button"
      className="adZone_deleteButton"
    >
      DELETE {ad?.type?.type} AD
    </button>
    {Object.keys(zone?.sizes).map(k => (
      <FileUpload
        key={k}
        label={k}
        help={zone?.sizes[k]}
        acceptType={fileUploadAcceptTypes?.image}
        filePath={ad?.imageUrls[k]}
        processFile={(f, success, error) => uploadFile(f, success, error, ad?.type?.type, k)}
        deleteAction={null}
      />
    ))}
  </>
);

const AddButton = ({ zone, onValueChange, editedValue }) => (
  <button
    className="adZone_button"
    onClick={() =>
      onValueChange([
        ...editedValue,
        {
          type: { type: zone.type },
          imageUrls: {
            '2xl': null,
            xl: null,
            lg: null,
            md: null,
            sm: null,
            smallest: null,
          },
        },
      ])
    }
    type="button"
  >
    Add {zone.title}
  </button>
);

const Zones = ({ editedValue, onValueChange }) => {
  const zoneTypes = {
    leaderboard: {
      type: 'leaderboard',
      title: 'Leaderboard',
      sizes: {
        '2xl': '2190 x 400',
        xl: '1533 x 400',
        lg: '1280 x 400',
        md: '1022 x 400',
        sm: '768 x 400',
        smallest: '640 x 400',
      },
    },
    fixtureGrid: {
      type: 'fixtureGrid',
      title: 'Fixture grid',
      sizes: {
        lg: '230 x 340',
        md: '165 x 235',
        sm: '290 x 210',
        smallest: '585 x 210',
      },
    },
    venueList: {
      type: 'venueList',
      title: 'Venue list',
      sizes: {
        xl: '700 x 144',
        lg: '620 x 148',
        md: '985 x 162',
        sm: '728 x 145',
        smallest: '600 x 205',
      },
    },
  };

  const updateImageValue = (type, image, value) => {
    const safe = [...editedValue];
    const index = safe.findIndex(a => a.type.type === type);
    safe[index].imageUrls[image] = value;
    onValueChange(safe);
  };

  const uploadFile = (file, successCallback, errorCallback, type, image) =>
    getBase64FromFile(file)
      .then(base64 => {
        updateImageValue(type, image, base64);
        successCallback();
      })
      .catch(errorCallback);

  return (
    <div className="adZone_buttonContainer">
      {Object.values(zoneTypes).map(zone => {
        const active = editedValue.find(ad => ad?.type?.type === zone.type);
        return (
          <React.Fragment key={zone.title}>
            {!active ? (
              <AddButton zone={zone} onValueChange={onValueChange} editedValue={editedValue} />
            ) : (
              <ExpendableCard
                className="adZone_expandable"
                expanded={Boolean(active)}
                key={zone.title}
                title={zone.title}
              >
                <Advert
                  ad={active}
                  zone={zone}
                  editedValue={editedValue}
                  onValueChange={onValueChange}
                  uploadFile={uploadFile}
                />
              </ExpendableCard>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

Zones.propTypes = {
  editedValue: PropTypes.arrayOf(PropTypes.shape()),
  onValueChange: PropTypes.func.isRequired,
};
Zones.defaultProps = {
  editedValue: [],
};

export default Zones;
