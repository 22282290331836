import EntityList from '../listPage/EntityList';

export default new EntityList(
  'reports',
  'meta/reports',
  {
    zidane: false,
  },
  undefined,
  undefined,
  'Reports',
);
