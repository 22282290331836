import competitionRoundList from './entityList';

import createListPage from '../listPage';

import { STRING, NUMBER } from '../listPage/fieldTypes';

const competitionRoundListPage = createListPage(competitionRoundList, {
  id: {
    label: 'ID',
    type: NUMBER,
  },
  name: {
    label: 'Round Name',
    type: STRING,
  },
  competitionId: {
    label: 'Competition',
    type: NUMBER,
  },
});

export default competitionRoundListPage;
