import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Card from 'material-ui/Card';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';

import { connect } from 'react-redux';
import FormAlert from '../../components/FormAlert';
import LoadingWheel from '../../components/LoadingWheel';
import MpLink from '../../components/MpLink/MpLink';

import './index.scss';
import theme from '../../theme/index.scss';

import { changePassword } from '../../store/changePassword/thunks/changePassword';
import {
  getError,
  getSuccessMessage,
  isLoading as getIsLoading,
} from '../../store/changePassword/selectors';

import publicPage from '../../hocs/PublicPage';

class ChangePasswordPage extends Component {
  constructor() {
    super();
    this.state = { email: '' };

    this.onChangeEmail = this.onChangeEmail.bind(this);
  }

  onChangeEmail(e) {
    this.setState({ email: e.target.value });
  }

  render() {
    const { error, isLoading, resetEmail, successMessage } = this.props;

    const { email } = this.state;

    return (
      <div className="ChangePasswordPage__main">
        {error ? (
          <div className="ChangePasswordPage_alert_wrapper">
            <FormAlert errorMessage={error} />
          </div>
        ) : null}
        <Card className="ChangePasswordPage_input_wrapper">
          <div className="ChangePasswordPage__form">
            {!successMessage ? (
              <div className="ChangePasswordPage__form_hint">
                Please enter your email address You will receive an email with a link to change your
                password.
              </div>
            ) : (
              <div className="ChangePasswordPage__form_success">{successMessage}</div>
            )}
            {!isLoading ? (
              <TextField
                fullWidth
                hintText="Your email address"
                onChange={this.onChangeEmail}
                required
              />
            ) : (
              <LoadingWheel size="small" />
            )}

            <div className="ChangePasswordPage__form_submit">
              <FlatButton
                backgroundColor={theme.primaryYellow}
                className="ChangePasswordPage__form_submit_button"
                color="primary"
                onClick={() => resetEmail(email)}
              >
                SEND
              </FlatButton>
            </div>
            <div className="ChangePasswordPage__form_login">
              Go back to the <MpLink to="/">login page</MpLink>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

ChangePasswordPage.propTypes = {
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  resetEmail: PropTypes.func.isRequired,
  successMessage: PropTypes.string,
};

ChangePasswordPage.defaultProps = {
  error: null,
  isLoading: false,
  successMessage: null,
};

const mapStateToProps = state => ({
  error: getError(state),
  isLoading: getIsLoading(state),
  successMessage: getSuccessMessage(state),
});

const mapDispatchToProps = {
  resetEmail: changePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(publicPage(ChangePasswordPage));
