/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 26/04/2018
 */
import React from 'react';
import PropTypes from 'prop-types';

import Paper from 'material-ui/Paper';
import Subheader from 'material-ui/Subheader';

import LoadingWheel from '../../../../../components/LoadingWheel';
import './index.scss';
import FilterField from '../../../../../components/FilterField';
import { filterTypes } from '../../../../forms/formPage/utils/propTypes';

/**
 * @description Section containing the buttons to select a filter.
 * @constructor
 */
const AvailableFilters = ({ addFilter, activeFilters, availableFilters, isLoading }) => (
  <Paper className="FilterSection__SelectFiltersSection">
    <Subheader className="FilterSection__SelectFiltersSection_title">Filters</Subheader>
    <div className="FilterSection__SelectFiltersSection_container">
      {Object.keys(availableFilters).map(id => (
        <FilterField
          clearSearchTextOnSelect
          addFilter={addFilter}
          filter={availableFilters[id]}
          id={id}
          key={id}
          value={activeFilters[id]}
        />
      ))}
    </div>
    {isLoading ? (
      <div className="FilterSection__SelectFiltersSection_loading_container">
        <LoadingWheel size="small" />
      </div>
    ) : null}
  </Paper>
);

AvailableFilters.propTypes = {
  activeFilters: PropTypes.shape(),
  addFilter: PropTypes.func.isRequired,
  availableFilters: PropTypes.objectOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      type: PropTypes.oneOf(filterTypes).isRequired,
    }),
  ).isRequired,
  isLoading: PropTypes.bool,
};

AvailableFilters.defaultProps = {
  activeFilters: {},
  isLoading: false,
};

export default AvailableFilters;
