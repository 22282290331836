import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DnDContext from '../../components/DragAndDrop/Context';
import DnDList from '../../components/DragAndDrop/List';
import FormAlert from '../../components/FormAlert';
import LoadingWheel from '../../components/LoadingWheel';
import MpSectionHeader from '../../components/MpSectionHeader';
import SaveBar from '../../components/SaveBar';
import SportSection from '../../components/SportSection';
import './index.scss';
import MatchPintCountrySelector from '../../components/MatchPintCountrySelector/MatchPintCountrySelector';
import {
  getFetchSportsError,
  getSaveOrderSportsError,
  getEditedSports,
  hasUnStagedChanges,
  isSportsOrderSaving,
  areSportsFetching,
} from '../../store/sportsOrder/selectors';
import {
  changeOrder,
  discardChanges,
  fetchCompetitions,
  saveCompetitionsOrder,
} from '../../store/sportsOrder/thunks';
import { fetchSports } from '../../store/thunks/api-sports';

const CompetitionPriority = ({
  getSports,
  discard,
  hasChanges,
  isLoading,
  saveOrder,
  comps,
  changeSportOrder,
  errorFetch,
  errorSave,
  getComps,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedSport, setSelectedSport] = useState(null);
  const [sports, setSports] = useState([]);

  useEffect(() => {
    if (selectedCountry && selectedSport) {
      getComps(selectedCountry.id, selectedSport);
    }
  }, [getComps, selectedCountry, selectedSport]);

  useEffect(() => {
    if (selectedCountry) {
      getSports(selectedCountry.id).then(r => setSports(r.getResult()));
    }
  }, [getSports, selectedCountry]);

  const onReorder = (listId, indexStart, indexEnd) => {
    const state = DnDContext.onReOrderDefault({ comps }, listId, indexStart, indexEnd);
    changeSportOrder(state.comps);
  };

  if (errorSave || errorFetch) {
    return (
      <FormAlert className="CompetitionPriority__error" errorMessage={errorSave || errorFetch} />
    );
  }

  return (
    <div className="CompetitionPriority">
      <MatchPintCountrySelector
        onSelect={country => setSelectedCountry(country)}
        selectedCountry={selectedCountry}
      />
      {isLoading && <LoadingWheel size="full-page" />}
      <div className="CompetitionPriority_sports">
        {sports.map(s => (
          <button
            onClick={() => setSelectedSport(s.id)}
            type="button"
            className={`CompetitionPriority_sports_sport${
              selectedSport === s.id ? '_selected' : ''
            }`}
          >
            {s.name}
          </button>
        ))}
      </div>
      {!selectedSport && <p>Please pick a sport above</p>}
      {selectedCountry && selectedSport && (
        <DnDContext className="CompetitionPriority__DnD_container" onReorder={onReorder}>
          <DnDList
            droppableId="comps"
            header={<MpSectionHeader title="Competition Priority" />}
            renderItem={(item, index) => (
              <SportSection
                name={item.name}
                roundLogo={item.competitionLogo}
                priority={index + 1}
              />
            )}
            items={comps}
          />
        </DnDContext>
      )}
      <SaveBar
        onDiscardAction={discard}
        onSaveAction={() => saveOrder(selectedCountry.id, selectedSport)}
        open={hasChanges}
      />
    </div>
  );
};

CompetitionPriority.propTypes = {
  changeSportOrder: PropTypes.func.isRequired,
  discard: PropTypes.func.isRequired,
  errorFetch: PropTypes.string,
  errorSave: PropTypes.string,
  getSports: PropTypes.func.isRequired,
  getComps: PropTypes.func.isRequired,
  hasChanges: PropTypes.bool,
  isLoading: PropTypes.bool,
  saveOrder: PropTypes.func.isRequired,
  comps: PropTypes.arrayOf(PropTypes.shape()),
};

CompetitionPriority.defaultProps = {
  errorFetch: null,
  errorSave: null,
  hasChanges: false,
  isLoading: false,
  comps: null,
};

const mapStateToProps = state => ({
  errorFetch: getFetchSportsError(state),
  errorSave: getSaveOrderSportsError(state),
  hasChanges: hasUnStagedChanges(state),
  isLoading: isSportsOrderSaving(state) || areSportsFetching(state),
  comps: getEditedSports(state),
});

const mapDispatchToProps = {
  changeSportOrder: changeOrder,
  discard: discardChanges,
  getSports: fetchSports,
  getComps: fetchCompetitions,
  saveOrder: saveCompetitionsOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompetitionPriority);
