import createFormPage from '../formPage';
import { games } from '../../games';

const params = gameId => ({
  endpoint: `games/${gameId}/share-copy`,
  sections: {
    general: ['title', 'message'],
  },
});

export default games.map(({ game }) => createFormPage('gameShareCopy', params(game)));
