import { request } from './requests';

export default (memberId, roundId) => dispatch =>
  dispatch(
    request('/games/quizzes/2/members/reset-answers', {
      method: 'POST',
      body: {
        memberId,
        roundId,
      },
    }),
  );
