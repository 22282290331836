import EntityList from '../listPage/EntityList';

const groupCodeList = new EntityList(
  'groupCodes',
  'venues/group-code',
  {},
  undefined,
  undefined,
  'PI Codes',
);

export default groupCodeList;
