import quizThemes from './entityList';

import createListPage from '../listPage';
import tabs from '../../../routes/tabs';

const quizTheme = createListPage(
  quizThemes,
  {
    name: {
      label: 'name',
    },
  },
  {
    navTabs: tabs.quizTheme,
  },
);

export default quizTheme;
