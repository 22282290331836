/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 18/09/2018
 */
import React from 'react';

import authenticated from '../../../hocs/authenticated';

import EntityList from './EntityList';
import ListPage from './components/ListPage';
import reformatFields from './fieldTypes';

import { createGetOnClickLink, getDefaultRenderItemSideButtons } from './utils';
import * as permissions from '../../../utils/permissionsForRoutes';

/**
 * @description Allow to create a List Page.
 * @param {EntityList} entity
 * @param {Object} fields
 * @param {Object} params Parameters to render the list page.
 * @param {null|function} getOnClickLink Function that given an item returns the Zidane link to go
 *  to when clicking on the item in the list.
 *  - If not specified, will return '/{entityName}/edit?id={item.id}'.
 *  - If set to null, no link will be implemented.
 * @param {function} renderItemButtons Function that given an item will return the buttons to render
 *  on the right hand side of a list item.
 */
export default function createListPage(
  entity,
  fields,
  { getOnClickLink, renderItemButtons, navTabs } = {},
) {
  if (!(entity instanceof EntityList)) {
    throw new TypeError(`The entity parameter should be an instance of ${EntityList.name}.`);
  }

  return {
    entityName: entity.entityName,
    reducer: entity.getReducer(),
    route: {
      path: entity.endpoint.replace(/\/+$/, ''),
      component: authenticated(
        () => (
          <ListPage
            entity={entity}
            fields={reformatFields(fields)}
            getOnClickLink={createGetOnClickLink(entity, { getOnClickLink })}
            renderItemButtons={
              renderItemButtons ||
              getDefaultRenderItemSideButtons(entity, {
                getOnClickLink: createGetOnClickLink(entity, { getOnClickLink }),
              })
            }
          />
        ),
        navTabs,
        permissions[entity.entityName],
      ),
    },
  };
}
