import createFormPage from '../formPage';

const params = {
  endpoint: 'sports/competitions/rounds',
  sections: {
    General: ['name', 'active', 'startTimeUtc', 'endTimeUtc'],
    'Hero Tile': ['heroTile.title', 'heroTile.description', 'heroTile.image'],
  },
};

const competitionRound = createFormPage('competitionRound', params);

export default competitionRound;
