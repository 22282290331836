import React from 'react';
import { PropTypes } from 'prop-types';
import './styles.scss';

const ConfigOk = ({ value }) =>
  Array.isArray(value) ? (
    <div>
      {value.map((item, i) => (
        <p className="ConfigOk_listItem" key={item}>
          {i + 1}. {item}
        </p>
      ))}
    </div>
  ) : (
    '✅'
  );

ConfigOk.propTypes = {
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.bool]).isRequired,
};

export default ConfigOk;
