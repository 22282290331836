import EntityList from '../listPage/EntityList';

export default new EntityList(
  'trivias',
  'contents/trivias',
  {
    zidane: true,
  },
  undefined,
  undefined,
  'Beer Mat Trivia',
);
