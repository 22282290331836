//              __
//             /(`o
//       ,-,  //  \\
//      (,,,) ||   V
//     (,,,,)\//
//     (,,,/w)-'
//     \,,/w)
//     `V/uu
//       / |
//       | |
//       o o
//       \ |
//  \,/  ,\|,.  \,/
import * as c from './constants';

export const fetchingActive = () => ({ type: c.FETCH_ACTIVE_PENDING });
export const fetchingAvailable = () => ({ type: c.FETCH_AVAILABLE_PENDING });
export const loadActive = (r, more) => ({
  type: more ? c.LOAD_MORE_ACTIVE : c.FETCH_ACTIVE_SUCCESS,
  payload: { activeItems: r.data.result, activeMatches: r.data.matches },
});
export const loadAvailable = (r, more) => ({
  type: more ? c.LOAD_MORE_AVAILABLE : c.FETCH_AVAILABLE_SUCCESS,
  payload: { availableItems: r.data.result, availableMatches: r.data.matches },
});
export const fetchingError = err => ({ type: c.FETCH_ERROR, payload: err.message });
export const addItem = item => ({ type: c.ADD, payload: { item } });
export const removeItem = item => ({ type: c.REMOVE, payload: { item } });
export const saveSuccess = () => ({ type: c.SAVE_CHANGES_SUCCESS });
export const saveError = errorMessage => ({ type: c.SAVE_CHANGES_ERROR, payload: errorMessage });
export const updateAvailableSearch = searchText => ({
  type: c.SET_AVAILABLE_SEARCH_TERM,
  payload: { searchText },
});
export const updateActiveSearch = searchText => ({
  type: c.SET_ACTIVE_SEARCH_TERM,
  payload: { searchText },
});
export const discardChanges = () => ({ type: c.DISCARD_CHANGES });
