/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */
import Cookies from 'js-cookie';

export const MpCookieGet = name => Cookies.get(name);

/**
 * Removes Cookie from the browser.
 * @param name of the attribute in Cookie.
 * @param attributes Optional attributes ex : {path: '/q', domain: 'qwew'}, see more here: https://github.com/js-cookie/js-cookie
 * @return {*}
 */
export const MpCookieRemove = (name, attributes = {}) => Cookies.remove(name, attributes);
