import EntityList from '../listPage/EntityList';

export default new EntityList(
  'team',
  'sports/teams',
  {
    zidane: true,
  },
  undefined,
  undefined,
  'Teams',
);
