import EntityList from '../listPage/EntityList';

export default new EntityList(
  'calendarContent',
  'meta/calendar-content',
  {
    zidane: false,
  },
  undefined,
  undefined,
  'Calendar Content',
);
