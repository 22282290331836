/* eslint-disable react/jsx-no-bind */
/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 25/06/2018
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { DragDropContext } from 'react-beautiful-dnd';
import List from './List';

import './index.scss';

/**
 * @description Context handling the Drag And Drop.
 *  To create a set of Drag And Drop Lists, you need to include all of them
 *  as children of a Context component.
 *  Once that done, you need to define the `onMove` and `onReOrder` functions
 *  (the 2 helpers functions `Context.onMoveDefault` and `Context.onReOrderDefault`
 *  can help).
 */
class Context extends Component {
  /**
   * @description Allows given a state object containing the lists and their items
   *  to return the state object after an element has been moved inside a list.
   * @param {{ listId: [Array] }} state State object containing for each listId the list
   *  of items rendered inside the list.
   * @param {string} listId Id of the list involved.
   * @param {number} indexStart Original index of the element.
   * @param {number} indexEnd Final index of the element.
   * @returns {Object}
   */
  static onReOrderDefault(state, listId, indexStart, indexEnd) {
    // Clone the original state.
    const result = { ...state };
    // Remove the element from the list...
    const [removed] = result[listId].splice(indexStart, 1);

    // ... and add it at the right index.
    result[listId].splice(indexEnd, 0, removed);

    return result;
  }

  static onMoveDefault(state, sourceId, destinationId, sourceIndex, destinationIndex) {
    // Clone the original state.
    const result = { ...state };

    const sourceClone = [...state[sourceId]];
    const destClone = [...state[destinationId]];

    // Remove the element from the source list.
    const [removed] = sourceClone.splice(sourceIndex, 1);

    // Add the element to the destination list.
    destClone.splice(destinationIndex, 0, removed);

    result[sourceId] = sourceClone;
    result[destinationId] = destClone;

    return result;
  }

  onDragEnd(result) {
    const { children } = this.props;

    if (React.Children.count(children) > 1) {
      return this.onDragEndMultiList(result);
    }

    return this.onDragEndSingleList(result);
  }

  /**
   * @description Function triggered after a Drag And Drop from the user.
   * @param {{
   *  source: {
   *    droppableId: {string},
   *    index: {number},
   *  },
   *  destination: {
   *    droppableId: {string},
   *    index: {number},
   *  }
   * }} result Object describing what has happened during the Drag And Drop.
   */
  onDragEndSingleList(result) {
    const { source, destination } = result;
    const { onReorder } = this.props;

    // Dropped outside a list.
    if (!destination) {
      return;
    }

    onReorder(source.droppableId, source.index, destination.index);
  }

  onDragEndMultiList(result) {
    const { source, destination } = result;
    const { onMove, onReorder } = this.props;

    // Dropped outside a list.
    if (!destination) {
      return;
    }

    // If the element place isn't changed.
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      onReorder(source.droppableId, source.index, destination.index);
    } else {
      onMove(source.droppableId, destination.droppableId, source.index, destination.index);
    }
  }

  render() {
    const { children, className } = this.props;

    React.Children.forEach(children, child => {
      if (child.type.name !== List.name) {
        throw new TypeError(
          `Unexpected children instance of ${child.type.name}. Only instances of ${List.name} are allowed as children.`,
        );
      }
    });

    return (
      <div className={`${className ? `${className} ` : ''} DragAndDrop__Context`}>
        <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>{children}</DragDropContext>
      </div>
    );
  }
}

Context.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onReorder: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
};

Context.defaultProps = {
  className: null,
};

export default Context;
