import EntityList from '../listPage/EntityList';

export default new EntityList(
  'quiz',
  'games/quizzes/',
  {
    zidane: true,
  },
  undefined,
  undefined,
  'Quizzes',
);
