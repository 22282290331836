/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 27/06/2018
 */

import { FETCH_SECTIONS_PENDING, SAVE_ORDER_PENDING } from './constants';

/**
 * @description Returns if the homepage sections are still fetching.
 * @param state
 * @returns {boolean}
 */
export function areSectionsFetching(state) {
  return state.homepage.fetch.status === FETCH_SECTIONS_PENDING;
}

/**
 * @description Returns the sections currently on the Homepage
 *  (which can have been modified by the user).
 * @param {Object} state
 * @returns {Object|null}
 */
export function getSelectedSections(state) {
  return state.homepage.fetch.sections.edited.selected;
}

/**
 * @description Get the error message received after a fetch homepage section request.
 * @param {Object} state
 * @returns {*}
 */
export function getFetchSectionsError(state) {
  return state.homepage.fetch.error;
}

/**
 * @description Get the error message received after a save section order request.
 * @param {Object} state
 * @returns {*}
 */
export function getSaveSectionsOrderError(state) {
  return state.homepage.save.error;
}

/**
 * @description Returns the sections currently not on the Homepage
 *  (which can have been modified by the user).
 * @param {Object} state
 * @returns {Object|null}
 */
export function getNotSelectedSections(state) {
  return state.homepage.fetch.sections.edited.notSelected;
}

/**
 * @description Returns if some modifications have been made without being changed.
 * @param {Object} state
 * @returns {boolean}
 */
export function hasUnStagedChanges(state) {
  const { current, edited } = state.homepage.fetch.sections;

  if (!current.selected || !edited.selected || !current.notSelected || !edited.notSelected) {
    return false;
  }

  if (edited.selected.length !== current.selected.length) {
    return true;
  }

  for (let i = 0; i < edited.selected.length; i += 1) {
    if (!current.selected[i] || current.selected[i].id !== edited.selected[i].id) {
      return true;
    }
  }
  return false;
}

/**
 * @description Returns if the last save request is still sending.
 * @param state
 * @returns {boolean}
 */
export function isSectionsOrderSaving(state) {
  return state.homepage.save.status === SAVE_ORDER_PENDING;
}
