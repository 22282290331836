/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 29/06/2018
 */

import { combineReducers } from 'redux';
import * as constants from './constants';

export const initialState = {
  fetch: {
    status: null,
    sports: {
      edited: null,
      current: null,
    },
    error: null,
  },
  save: {
    status: null,
    error: null,
  },
};

/**
 * @description Reducer responsible of the Sports store.
 * @param {Object} state
 * @param {Object} action
 * @returns {*}
 */
function fetch(state = initialState.fetch, action = {}) {
  switch (action.type) {
    case constants.FETCH_SPORTS_PENDING:
      return {
        ...state,
        status: constants.FETCH_SPORTS_PENDING,
      };
    case constants.FETCH_SPORTS_SUCCESS:
      return {
        ...state,
        status: constants.FETCH_SPORTS_SUCCESS,
        sports: {
          edited: [...action.sports],
          current: [...action.sports],
        },
      };
    case constants.SAVE_ORDER_SUCCESS:
      return {
        ...state,
        sports: {
          ...state.sports,
          current: [...state.sports.edited],
        },
      };
    case constants.CHANGE_ORDER:
      return {
        ...state,
        sports: {
          ...state.sports,
          edited: [...action.sports],
        },
      };
    case constants.DISCARD_CHANGES:
      return {
        ...state,
        sports: {
          ...state.sports,
          edited: [...state.sports.current],
        },
      };
    case constants.FETCH_SPORTS_ERROR:
      return {
        ...state,
        status: constants.FETCH_SPORTS_ERROR,
        error: action.error,
      };
    default:
      return state;
  }
}

/**
 * @description Reducer responsible of the Save store (status and error message).
 * @param state
 * @param action
 * @returns {*}
 */
function save(state = initialState.save, action = {}) {
  switch (action.type) {
    case constants.SAVE_ORDER_PENDING:
      return {
        ...state,
        status: constants.SAVE_ORDER_PENDING,
      };
    case constants.SAVE_ORDER_SUCCESS:
      return {
        ...state,
        status: constants.SAVE_ORDER_SUCCESS,
      };
    case constants.SAVE_ORDER_ERROR:
      return {
        ...state,
        status: constants.SAVE_ORDER_ERROR,
        error: action.error,
      };
    case constants.SUCCESS_DISMISS:
      return {
        ...state,
        status: null,
      };
    default:
      return state;
  }
}

/**
 * @description Reducer responsible of the Sports Order store (fetch and save).
 * @param {Object} state
 * @param {Object} action
 * @returns {*}
 */
export default combineReducers({
  fetch,
  save,
});
