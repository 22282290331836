import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PasswordReset from '../../../../components/PasswordReset/PasswordReset';
import { submitPassword } from '../../../../store/thunks/api-member';

const PasswordResetComp = ({ editedValue, submit }) => (
  <PasswordReset updatePassword={(pass, passConfirm) => submit(editedValue, pass, passConfirm)} />
);

const mapDispatch = dispatch => ({
  submit: (id, password, passwordConfirm) =>
    dispatch(submitPassword(id, password, passwordConfirm)),
});

PasswordResetComp.propTypes = {
  editedValue: PropTypes.number,
  submit: PropTypes.func.isRequired,
};

PasswordResetComp.defaultProps = {
  editedValue: null,
};

export default connect(null, mapDispatch)(PasswordResetComp);
