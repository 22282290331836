import * as consts from './consts';

export const getAnswersForQuesionSuccess = answers => ({
  type: consts.GET_ANSWERS_FOR_QUESTION_SUCCESS,
  payload: answers,
});

export const setAnswersForQuesionError = error => ({
  type: consts.SET_ANSWERS_FOR_QUESTION_ERROR,
  payload: error,
});

export const changeValueForAnswer = (value, field, answerId, index) => ({
  type: consts.CHANGE_VALUE_FOR_ANSWER,
  payload: { value, field, answerId, index },
});

export const undoChangesForAnswer = answerId => ({
  type: consts.UNDO_CHANGES_FOR_ANSWER,
  payload: { answerId },
});

export const undoAllChangesForAnswers = () => ({
  type: consts.UNDO_ALL_CHANGES_FOR_ANSWERS,
});

export const removeAnswer = answerId => ({
  type: consts.REMOVE_ANSWER,
  payload: answerId,
});

export const addAnotherAnswer = () => ({
  type: consts.ADD_ANOTHER_ANSWER,
});

export const answersCreatedSuccess = answers => ({
  payload: answers,
  type: consts.CREATE_ANSWERS_SUCCESS,
});

export const answersUpdatedSuccess = () => ({
  type: consts.UPDATE_ANSWERS_SUCCESS,
});

export const removeUnsavedAnswer = index => ({
  type: consts.REMOVE_UNSAVED_ANSWER,
  payload: index,
});
