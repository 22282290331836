//      ,---.
//    ,/     \__.'~\
//    |        '  . `===---      -Ed touched this file-
//    `|   ,     ,--'
//     `. /___,-'
//       |    \
//       ,\_  ,`-=

import React from 'react';
import { PropTypes } from 'prop-types';
import './styles.scss';
import EditIcon from 'material-ui/svg-icons/image/edit';
import Plus from 'material-ui/svg-icons/content/add-circle-outline';
import Minus from 'material-ui/svg-icons/content/remove-circle-outline';
import drag from './drag.png';
import LoadingWheel from '../../components/LoadingWheel';

const moment = require('moment');

const FixtureItem = ({
  onEdit,
  onAdd,
  onRemove,
  toBeRemoved,
  id,
  name,
  startTime,
  endTime,
  isLoading,
}) => (
  <div className="TopFixturesList_dnd_item_row">
    {onEdit && (
      <div className="TopFixturesList_dnd_icon_drag TopFixturesList_hideOnMobile">
        <img src={drag} alt="draggable" />
      </div>
    )}
    <p className="TopFixturesList_dnd_item TopFixturesList_hideOnMobile">{id}</p>
    <p className="TopFixturesList_dnd_item_longer">{name}</p>
    <p className="TopFixturesList_dnd_item_longer">
      {moment.utc(startTime).local().format('DD/MM/YYYY HH:mm')}
    </p>
    {endTime && (
      <p className="TopFixturesList_dnd_item_longer">
        {moment.utc(endTime).local().format('DD/MM/YYYY HH:mm')}
      </p>
    )}
    {isLoading && <LoadingWheel className="TopFixturesList_dnd_icon" />}
    {onAdd && !toBeRemoved && !isLoading && (
      <Plus className="TopFixturesList_dnd_icon" onClick={onAdd} />
    )}
    {onRemove && toBeRemoved && !isLoading && (
      <Minus className="TopFixturesList_dnd_icon" onClick={onRemove} />
    )}
    {onEdit && <EditIcon className="TopFixturesList_dnd_icon" onClick={onEdit} />}
  </div>
);

FixtureItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  toBeRemoved: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
};
FixtureItem.defaultProps = {
  onEdit: null,
  endTime: null,
  onAdd: null,
  onRemove: null,
  toBeRemoved: false,
};

export default FixtureItem;
