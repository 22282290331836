//              __
//             /(`o
//       ,-,   \\
//      (,,,) ||   V
//     (,,,,)\//
//     (,,,/w)-'
//     \,,/w)
//     `V/uu
//       / |
//       | |
//       o o
//       \ |
//  \,/  ,\|,.  \,/
//
//  Ed likes automated stuffs
//  2019/09/11 - does it really matter though?

import React, { useEffect, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { FlatButton } from 'material-ui';
import Dialog from 'material-ui/Dialog';
import FontIcon from 'material-ui/FontIcon';
import HelpIcon from 'material-ui/svg-icons/action/help';
import InAndOutSelector from '../InAndOutSelector';
import colors from '../../theme/index.scss';
import reducer, { initialState } from './InAndOutReducer';
import * as actions from './actions';
import { fetchItems, addItems, removeItems } from './requests';
import csvtemplate from './csvTemplate.png';

const limit = 10;

const AutoInAndOutSelector = ({
  endpoints,
  requestBodyName,
  formatName,
  activeItemsString,
  availableItemsString,
  disableSearch,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getActiveItems = useCallback(
    (offset = 0) => {
      dispatch(actions.fetchingActive());
      return fetchItems(
        `${endpoints.fetchActive}numberOfMatches=1&search=${state.activeSearchTerm}&offset=${offset}&limit=${limit}`,
      )
        .then(r => dispatch(actions.loadActive(r, offset !== 0)))
        .catch(err => dispatch(actions.fetchingError(err)));
    },
    [endpoints.fetchActive, state.activeSearchTerm],
  );

  const getAvailableItems = useCallback(
    (offset = 0) => {
      dispatch(actions.fetchingAvailable());
      return fetchItems(
        `${endpoints.fetchAvailable}numberOfMatches=1&search=${state.availableSearchTerm}&offset=${offset}&limit=${limit}`,
      )
        .then(r => dispatch(actions.loadAvailable(r, offset !== 0)))
        .catch(err => dispatch(actions.fetchingError(err)));
    },
    [endpoints.fetchAvailable, state.availableSearchTerm],
  );

  const saveItems = () =>
    Promise.all([
      state.changes.toAdd.length > 0 &&
        addItems(endpoints.add, state.changes.toAdd, requestBodyName),
      state.changes.toRemove.length > 0 &&
        removeItems(endpoints.remove, state.changes.toRemove, requestBodyName),
    ])
      .then(() => dispatch(actions.saveSuccess()))
      .catch(err => dispatch(actions.saveError(err.message)));

  useEffect(() => {
    getAvailableItems();
  }, [getAvailableItems, state.availableSearchTerm]);
  useEffect(() => {
    getActiveItems();
  }, [getActiveItems, state.activeSearchTerm, endpoints.fetchActive]);

  const uploader = (functio, endpoint, data) => {
    const reader = new FileReader();
    reader.onload = () =>
      functio(
        endpoint,
        reader.result.split(/\n/).map(e => e.split(',')[0]),
        requestBodyName,
      )
        .then(() => getAvailableItems())
        .then(() => getActiveItems());
    reader.readAsText(data);
  };

  return (
    <div className={`InAndOutSelector_outer${state.hasChanged ? '_highlight' : ''}`}>
      <p className="InAndOutSelector_warning">{state.hasChanged && 'You have unsaved changes'}</p>
      <div className="InAndOutSelector">
        <InAndOutSelector
          loading={state.availableIsFetching}
          mode="add"
          disableSearch={disableSearch}
          showLoadMore={state.edited.available.length < state.availableMatches}
          loadMore={() => getAvailableItems(state.offset.available)}
          items={formatName(state.edited.available)}
          onItemClick={(_key, item) => dispatch(actions.addItem(item))}
          onSearch={searchText => dispatch(actions.updateAvailableSearch(searchText))}
          title={`${availableItemsString} ${
            state.availableMatches ? `(${state.availableMatches})` : ''
          }`}
          searchText={state.availableSearchTerm}
        />

        <InAndOutSelector
          loading={state.activeIsFetching}
          mode="remove"
          disableSearch={disableSearch}
          showLoadMore={state.edited.active.length < state.activeMatches}
          loadMore={() => getActiveItems(state.offset.active)}
          items={formatName(state.edited.active)}
          onItemClick={(_key, item) => dispatch(actions.removeItem(item))}
          onSearch={searchText => dispatch(actions.updateActiveSearch(searchText))}
          title={`${activeItemsString} ${state.activeMatches ? `(${state.activeMatches})` : ''}`}
          searchText={state.activeSearchTerm}
        />
      </div>
      {state.error && <p>{state.error}</p>}
      <div className="InAndOutSelector_button_container">
        <CSVUpload
          removeUpload={e => uploader(removeItems, endpoints.remove, e.target.files[0])}
          addUpload={e => uploader(addItems, endpoints.add, e.target.files[0])}
        />
      </div>
      {state.hasChanged && (
        <div className="InAndOutSelector_button_container">
          <FlatButton
            className="InAndOutSelector_button"
            disabled={!state.hasChanged}
            backgroundColor={colors.primaryRed}
            onClick={() => dispatch(actions.discardChanges())}
          >
            Discard
          </FlatButton>
          <FlatButton
            className="InAndOutSelector_button"
            disabled={!state.hasChanged}
            backgroundColor={colors.primaryGreen}
            onClick={saveItems}
          >
            Save
          </FlatButton>
        </div>
      )}
    </div>
  );
};

const CSVUpload = ({ removeUpload, addUpload }) => {
  const [isHelpOpen, setHelpOpen] = React.useState(false);
  return (
    <>
      <div className="inOutCSVUpload">
        <div className="inOutCSVUpload_wrapper">
          <FlatButton
            className="inOutCSVUpload_csv"
            containerElement="label"
            label="Remove with CSV"
            labelPosition="before"
            // Upload Icon
            icon={<FontIcon className="material-icons">save_alt</FontIcon>}
          >
            <input
              accept="text/csv"
              className="inOutCSVUpload_csv_input"
              onChange={removeUpload}
              type="file"
              value=""
            />
          </FlatButton>
          <FlatButton
            className="inOutCSVUpload_help_csv"
            icon={<HelpIcon />}
            onClick={() => setHelpOpen(true)}
          />
        </div>
        <div className="inOutCSVUpload_wrapper">
          <FlatButton
            className="inOutCSVUpload_csv"
            containerElement="label"
            label="Add with CSV"
            labelPosition="before"
            // Upload Icon
            icon={<FontIcon className="material-icons">save_alt</FontIcon>}
          >
            <input
              accept="text/csv"
              className="inOutCSVUpload_csv_input"
              onChange={addUpload}
              type="file"
              value=""
            />
          </FlatButton>
          <FlatButton
            className="inOutCSVUpload_help_csv"
            icon={<HelpIcon />}
            onClick={() => setHelpOpen(true)}
          />
        </div>
      </div>
      <Dialog
        title="CSV - HELP"
        modal={false}
        open={isHelpOpen}
        onRequestClose={() => setHelpOpen(false)}
      >
        <div className="inOutCSVUpload_help_content_wrapper">
          <p>
            You can add or remove entire sets of data by uploading a csv. As you can see on the
            right picture, you have to add all the data in a single column.
          </p>
          <img
            className="inOutCSVUpload_help_content_img"
            src={csvtemplate}
            alt="template-visual"
          />
        </div>
      </Dialog>
    </>
  );
};

CSVUpload.propTypes = {
  removeUpload: PropTypes.func.isRequired,
  addUpload: PropTypes.func.isRequired,
};

AutoInAndOutSelector.propTypes = {
  requestBodyName: PropTypes.string,
  disableSearch: PropTypes.bool,
  formatName: PropTypes.func,
  activeItemsString: PropTypes.string,
  availableItemsString: PropTypes.string,
  endpoints: PropTypes.shape({
    add: PropTypes.string.isRequired,
    remove: PropTypes.string.isRequired,
    fetchActive: PropTypes.string.isRequired,
    fetchAvailable: PropTypes.string.isRequired,
  }).isRequired,
};

AutoInAndOutSelector.defaultProps = {
  requestBodyName: 'ids',
  disableSearch: false,
  formatName: data =>
    data.map(item => ({
      id: item.id,
      name: item.name,
    })),
  activeItemsString: 'Remove items',
  availableItemsString: 'Add items',
};

export default AutoInAndOutSelector;
