/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 26/06/2018
 */
import { combineReducers } from 'redux';
import * as constants from './constants';

export const initialState = {
  fetch: {
    status: null,
    sections: {
      edited: {
        selected: null,
        notSelected: null,
      },
      current: {
        selected: null,
        notSelected: null,
      },
    },
    error: null,
  },
  save: {
    error: null,
  },
};

/**
 * @description Reducer responsible of the Homepage Section store.
 * @param {Object} state
 * @param {Object} action
 * @returns {*}
 */
function fetch(state = initialState.fetch, action = {}) {
  switch (action.type) {
    case constants.FETCH_SECTIONS_PENDING:
      return {
        ...state,
        status: constants.FETCH_SECTIONS_PENDING,
      };
    case constants.FETCH_SECTIONS_SUCCESS:
      return {
        ...state,
        status: constants.FETCH_SECTIONS_SUCCESS,
        sections: {
          current: {
            selected: action.sections
              .filter(section => section.homepageOrder !== null)
              .sort((sectionA, sectionB) => sectionA.homepageOrder - sectionB.homepageOrder),
            notSelected: action.sections.filter(section => section.homepageOrder === null),
          },
          edited: {
            selected: action.sections
              .filter(section => section.homepageOrder !== null)
              .sort((sectionA, sectionB) => sectionA.homepageOrder - sectionB.homepageOrder),
            notSelected: action.sections.filter(section => section.homepageOrder === null),
          },
        },
      };
    case constants.SAVE_ORDER_SUCCESS:
      return {
        ...state,
        sections: {
          ...state.sections,
          current: {
            selected: [...state.sections.edited.selected],
            notSelected: [...state.sections.edited.notSelected],
          },
        },
      };
    case constants.CHANGE_HOMEPAGE:
      return {
        ...state,
        sections: {
          current: state.sections.current,
          edited: {
            selected: action.selected.sort(
              (sectionA, sectionB) => sectionA.homepageOrder - sectionB.homepageOrder,
            ),
            notSelected: action.notSelected,
          },
        },
      };
    case constants.DISCARD_CHANGES:
      return {
        ...state,
        sections: {
          ...state.sections,
          edited: { ...state.sections.current },
        },
      };
    case constants.FETCH_SECTIONS_ERROR:
      return {
        ...state,
        status: constants.FETCH_SECTIONS_ERROR,
        error: action.error,
      };
    default:
      return state;
  }
}

/**
 * @description Reducer responsible of the Save store (status and error message).
 * @param {Object} state
 * @param {Object} action
 * @returns {*}
 */
function save(state = initialState.save, action = {}) {
  switch (action.type) {
    case constants.SAVE_ORDER_PENDING:
      return {
        ...state,
        status: constants.SAVE_ORDER_PENDING,
      };
    case constants.SAVE_ORDER_SUCCESS:
      return {
        ...state,
        status: constants.SAVE_ORDER_SUCCESS,
      };
    case constants.SAVE_ORDER_ERROR:
      return {
        ...state,
        status: constants.SAVE_ORDER_ERROR,
        error: action.error,
      };
    default:
      return state;
  }
}

/**
 * @description Reducer responsible of the Homepage Order store (fetch and save).
 * @param {Object} state
 * @param {Object} action
 * @returns {*}
 */
export default combineReducers({
  fetch,
  save,
});
