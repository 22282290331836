/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 17/10/2018
 */
import React from 'react';
import PropTypes from 'prop-types';

import Avatar from 'material-ui/Avatar';
import Toggle from 'material-ui/Toggle';

import InfoIcon from '../../InfoIcon';

import colors from '../../../theme/index.scss';

import './index.scss';

const BooleanField = ({ data, handleEditField, help, icon, name }) => {
  const prefixes = [];

  if (icon) {
    prefixes.push(
      <Avatar
        backgroundColor={colors.veryLightGrey}
        className="BooleanField_header_avatar"
        color={colors.darkGrey}
        icon={icon}
        key="avatar"
        size={20}
      />,
    );
  }

  prefixes.push(<span key="label">{name}</span>);

  return (
    <div className="BooleanField">
      <div className="BooleanField_header">
        <Toggle
          label={prefixes}
          toggled={data}
          onToggle={(e, toggle) => handleEditField(toggle)}
          id={name}
        />
      </div>
      {help && (
        <InfoIcon className="BooleanField_info" tooltipPosition="bottom-left" tooltip={help} />
      )}
    </div>
  );
};

BooleanField.propTypes = {
  data: PropTypes.bool,
  handleEditField: PropTypes.func.isRequired,
  help: PropTypes.string,
  icon: PropTypes.node,
  name: PropTypes.string.isRequired,
};

BooleanField.defaultProps = {
  data: null,
  help: null,
  icon: null,
};

export default BooleanField;
