import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MpLink from '../MpLink/MpLink';
import MenuArrowButton from './MenuArrowButton';
import './index.scss';
import canUserAccessPage from '../../utils/permissions';

const SideBarItem = ({ linkItem, currentRoute, className, permissions }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const checkForCurrentPathInNestedLinks =
    linkItem.nestedLink &&
    linkItem.nestedLink.some(
      nested =>
        nested.link === currentRoute ||
        window.location.href.endsWith(nested.link) ||
        (nested.nestedLink &&
          nested.nestedLink.some(nestedNestedLink => nestedNestedLink.link === currentRoute)),
    );

  useEffect(() => {
    if (checkForCurrentPathInNestedLinks) {
      setMenuOpen(true);
    }
  }, [checkForCurrentPathInNestedLinks, currentRoute, linkItem]);

  return (
    <>
      <div
        className={
          // both window.location and linkItem methods are required, since not all links share the same srtructure
          linkItem.link === currentRoute || window.location.href.endsWith(linkItem.link)
            ? 'SideBarItem_link_active'
            : 'SideBarItem_link'
        }
      >
        <MpLink to={linkItem.link} className={`SideBarItem_link_${className}`}>
          <p className="SideBarItem_link_title">{linkItem.name}</p>
        </MpLink>
        {linkItem?.nestedLink?.some(nestedLink =>
          canUserAccessPage(permissions, nestedLink.limitedAccess),
        ) && <MenuArrowButton menuOpen={menuOpen} toggleMenu={() => setMenuOpen(!menuOpen)} />}
      </div>
      {linkItem.nestedLink &&
        menuOpen &&
        linkItem.nestedLink.map(
          nestedLink =>
            canUserAccessPage(permissions, nestedLink.limitedAccess) && (
              <SideBarItem
                key={nestedLink.name}
                className="nested"
                currentRoute={currentRoute}
                linkItem={nestedLink}
                permissions={permissions}
              />
            ),
        )}
    </>
  );
};

export default SideBarItem;

SideBarItem.propTypes = {
  currentRoute: PropTypes.string.isRequired,
  linkItem: PropTypes.shape({
    link: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    nestedLink: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
  className: PropTypes.string,
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    }),
  ),
};

SideBarItem.defaultProps = {
  className: 'first',
  permissions: [],
};
