import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ClearButton from '../../ClearButton';
import InfoIcon from '../../InfoIcon';

import './index.scss';

const DateTime = ({ dateTime, error, handleEditField, help, name }) => {
  const tempMoment = moment(dateTime);
  const mainDateTime = tempMoment.isValid() ? tempMoment.format('YYYY-MM-DDTHH:mm') : '';
  return (
    <div className="DateTime">
      <span className="DateTime__Title">{name}</span>
      <div className="DateTime__Field">
        <input
          value={mainDateTime}
          onChange={e => {
            const date = moment(e.target.value);
            handleEditField(date?.isValid() ? date.format('YYYY-MM-DD HH:mm:ss') : null);
          }}
          type="datetime-local"
        />
        {error && <div>{Array.isArray(error) ? error.join(' | ') : error}</div>}
        <ClearButton clearableValue={dateTime} handleCancel={() => handleEditField(null)} />
      </div>
      {help && <InfoIcon tooltipPosition="bottom-left" tooltip={help} />}
    </div>
  );
};

DateTime.propTypes = {
  dateTime: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  handleEditField: PropTypes.func.isRequired,
  help: PropTypes.string,
  name: PropTypes.string.isRequired,
};

DateTime.defaultProps = {
  dateTime: null,
  error: null,
  help: null,
};

export default DateTime;
