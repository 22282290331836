//              __
//             /(`o
//       ,-,  //  \\
//      (,,,) ||   V
//     (,,,,)\//
//     (,,,/w)-'
//     \,,/w)
//     `V/uu
//       / |
//       | |
//       o o
//       \ |
//  \,/  ,\|,.  \,/

import request from '../../utils/apiRequest/requests';

export const fetchItems = itemsURL => request(itemsURL);
export const addItems = (itemsURL, itemIDs, requestBodyName = 'ids') =>
  request(itemsURL, {
    body: {
      [requestBodyName]: itemIDs,
    },
    method: 'POST',
  });
export const removeItems = (itemsURL, itemIDs, requestBodyName = 'ids') =>
  request(itemsURL, {
    body: {
      [requestBodyName]: itemIDs,
    },
    method: 'DELETE',
  });
