/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FlatButton from 'material-ui/FlatButton';

import withMediaProps from '../../hocs/withMediaProps';

import './index.scss';

import colors from '../../theme/index.scss';
import { displaySticky } from '../../store/globalError/actions';

const SaveBar = withMediaProps(
  ({ isPhone, message, onSaveAction, switchToStickyDisplay, onDiscardAction, open }) => (
    <div>
      {open && (
        <div className="SaveBar">
          {!isPhone && <span className="SaveBar__message">{message}</span>}
          <div className="SaveBar__buttons">
            <FlatButton
              backgroundColor={colors.standardGrey}
              className="SaveBar__buttons_dismiss"
              hoverColor={colors.darkGrey}
              label="DISCARD"
              onClick={onDiscardAction}
            />
            <FlatButton
              backgroundColor={colors.primaryYellow}
              className="SaveBar__buttons_save"
              hoverColor={colors.darkYellow}
              label="SAVE"
              onClick={() => {
                onSaveAction();
                switchToStickyDisplay();
              }}
            />
          </div>
        </div>
      )}
    </div>
  ),
);

SaveBar.propTypes = {
  message: PropTypes.string,
  onSaveAction: PropTypes.func.isRequired,
  onDiscardAction: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

SaveBar.defaultProps = {
  message: 'Seems like you modified something. Do you want to save it?',
  open: true,
};

const mapDispatchToProps = dispatch => ({
  switchToStickyDisplay: () => dispatch(displaySticky()),
});

export default connect(null, mapDispatchToProps)(SaveBar);
