import sports from './entityList';

import createListPage from '../listPage';

import { STRING } from '../listPage/fieldTypes';
import tabs from '../../../routes/tabs';

const sportsList = createListPage(
  sports,
  {
    id: {
      label: 'Id',
      type: STRING,
    },
    name: {
      label: 'Name',
      type: STRING,
    },
  },
  {
    navTabs: tabs.sports,
  },
);

export default sportsList;
