/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 18/04/2018
 */

import * as constants from './constants';

export function loadAuthenticatedMember() {
  return {
    type: constants.LOAD_AUTHENTICATED_MEMBER,
  };
}

export function loadAuthenticatedMemberError({ error }) {
  return {
    type: constants.LOAD_AUTHENTICATED_MEMBER_ERROR,
    error,
  };
}

export function loadAuthenticatedMemberSuccess({ member }) {
  return {
    type: constants.LOAD_AUTHENTICATED_MEMBER_SUCCESS,
    member,
  };
}

export const loadMemberPermissionsPending = () => ({
  type: constants.LOAD_USER_PERMISSIONS_PENDING,
});
export const loadMemberPermissionsSuccess = permissions => ({
  type: constants.LOAD_USER_PERMISSIONS_SUCCESS,
  payload: permissions,
});

export const loadUserLocales = locales => ({
  type: constants.LOAD_USER_LOCALES,
  payload: locales,
});
