/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 26/10/2018
 */

// Form functionality.
export const DISCARD_CHANGES = entityName => `${entityName}/discard-changes`;
export const DISMISS_SUCCESS_MESSAGE = entityName => `${entityName}/dismiss-success-message`;

// Edit values.
export const EDIT_CURRENT = entityName => `${entityName}/edit/current`;
export const EDIT_NEW = entityName => `${entityName}/edit/new`;

// Fetch data.
export const FETCH_PENDING = entityName => `${entityName}/data/pending`;
export const FETCH_SUCCESS = entityName => `${entityName}/data/success`;
export const FETCH_ERROR = entityName => `${entityName}/data/error`;

// Fetch description.
export const FETCH_DESCRIPTION_PENDING = entityName => `${entityName}/description/pending`;
export const FETCH_DESCRIPTION_SUCCESS = entityName => `${entityName}/description/success`;
export const FETCH_DESCRIPTION_ERROR = entityName => `${entityName}/description/error`;

// Send directly data to the server without changing the form.
export const SEND_DIRECT_EDITION_PENDING = entityName => `${entityName}/send-data/pending`;
export const SEND_DIRECT_EDITION_SUCCESS = entityName => `${entityName}/send-data/success`;
export const SEND_DIRECT_EDITION_ERROR = entityName => `${entityName}/send-data/error`;

// Save edition.
export const SAVE_EDITION_PENDING = entityName => `${entityName}/save/pending`;
export const SAVE_EDITION_SUCCESS = entityName => `${entityName}/save/success`;
export const SAVE_EDITION_ERROR = entityName => `${entityName}/save/error`;

export const SET_DEFUALT_VALUES = entityName => `${entityName}/defaultValues/set`;

// Save creation.
export const SAVE_CREATION_PENDING = entityName => `${entityName}/create/pending`;
export const SAVE_CREATION_SUCCESS = entityName => `${entityName}/create/success`;
export const SAVE_CREATION_SUCCESS_PRECONFIRM = entityName =>
  `${entityName}/createPreConfirm/success`;
export const SAVE_CREATION_ERROR = entityName => `${entityName}/create/error`;

export const SHOW_SHOW_CONFIRM = entityName => `${entityName}/showConfirm/show`;
export const HIDE_SHOW_CONFIRM = entityName => `${entityName}/showConfirm/hide`;

export const SET_CONFIRM_TEXT = entityName => `${entityName}/confirmText/set`;

export const SET_SAVE_SUCCESS_TEXT = entityName => `${entityName}/saveSuccessText/set`;

export const CLEAR_VALUES = entityName => `${entityName}/clearValues/umnount`;
