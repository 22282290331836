//         .-.
//         ((`-) Q U O N K
//          \\
//           \\
//    .="""=._))
//   /  .,   .'
//  /__(,_.-'
// `    /|
//     /_|__
//       | `))
//       |
//      -"==

export const FETCH_PAYMENT_INFO_PENDING = 'FETCH_PAYMENT_INFO_PENDING';
export const FETCH_PAYMENT_INFO_SUCCESS = 'FETCH_PAYMENT_INFO_SUCCESS';
export const FETCH_PAYMENT_INFO_ERROR = 'FETCH_PAYMENT_INFO_ERROR';

export const FETCH_PAYMENT_INFO_SHAPE_PENDING = 'FETCH_PAYMENT_INFO_SHAPE_PENDING';
export const FETCH_PAYMENT_INFO_SHAPE_SUCCESS = 'FETCH_PAYMENT_INFO_SHAPE_SUCCESS';
export const FETCH_PAYMENT_INFO_SHAPE_ERROR = 'FETCH_PAYMENT_INFO_SHAPE_ERROR';

export const SAVE_PENDING = 'SAVE_PAYMENT_INFO_PENDING';
export const SAVE_SUCCESS = 'SAVE_PAYMENT_INFO_SUCCESS';
export const SAVE_ERROR = 'SAVE_PAYMENT_INFO_ERROR';

export const HAS_CHANGED = 'PAYMENT_INFO_HAS_CHANGED';
export const DISCARD_CHANGES = 'DISCARD_PAYMENT_INFO_CHANGES';
