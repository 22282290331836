/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 26/06/2018
 */

export const FETCH_SECTIONS_PENDING = 'homepage/fetch/pending';
export const FETCH_SECTIONS_SUCCESS = 'homepage/fetch/success';
export const FETCH_SECTIONS_ERROR = 'homepage/fetch/error';

export const CHANGE_HOMEPAGE = 'homepage/changeHomepage';

export const DISCARD_CHANGES = 'homepage/discardChanges';

export const SAVE_ORDER_PENDING = 'homepage/saveOrder/pending';
export const SAVE_ORDER_SUCCESS = 'homepage/saveOrder/success';
export const SAVE_ORDER_ERROR = 'homepage/saveOrder/error';
