/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 02/11/2018
 */
import { connect } from 'react-redux';

// Redux.
import * as select from '../store/selectorFactory';
import { createEditCurrentEntity, createEditNewEntity } from '../store/actionCreatorFactory';
import { createFetchData, createSendDirectEdition } from '../store/thunksFactory';

// Utils.
import { get } from '../../../../utils/object';
import { PAGE_TYPE_EDIT, PAGE_TYPE_CREATE } from './constants';
import FormError from './FormError';

/**
 * @description Allows to connect a specific Component to a form's Redux store.
 * @param Component
 */
export function connectWithFormStore(Component) {
  /**
   * @description Map a part of the state of form page's redux store to the component.
   * @param state State of the Redux store.
   * @param {string} entityName Entity name of the form page.
   * @param {Array<string>|string|null} fieldPath Path of the field to inject(if not set, all the
   *  state of the form page's store will be injected).
   * @param {Object} parameters Parameters of the form page.
   * @param {string} type Type of page.
   * @return {{description: *, editedValue: *, error: *, initialValue: *}}
   */
  const mapStateToProps = (state, { entityName, fieldPath, parameters, type }) => {
    const formDescription = select.createGetDescription(entityName, parameters)(state);

    let editedData;
    let fieldErrors;
    let initialData;

    switch (type) {
      case PAGE_TYPE_EDIT:
        editedData = select.createGetEditedData(entityName, parameters)(state);
        fieldErrors = select.createGetEditionFieldErrors(entityName, parameters)(state);
        initialData = select.createGetInitialData(entityName, parameters)(state);

        break;
      case PAGE_TYPE_CREATE:
        editedData = select.createGetDataToCreate(entityName, parameters)(state);
        fieldErrors = select.createGetCreationFieldErrors(entityName, parameters)(state);
        initialData = {};
        break;
      default:
        throw new FormError(
          `Unknown page type: ${type}. Must be one of [${PAGE_TYPE_EDIT}, ${PAGE_TYPE_CREATE}]`,
        );
    }

    return {
      description: get(fieldPath, formDescription),
      editedValue: get(fieldPath, editedData),
      fullResource: editedData,
      error: get(fieldPath, fieldErrors),
      initialValue: get(fieldPath, initialData),
      renderKey: select.createGetRenderKey(entityName, parameters)(state),
    };
  };

  /**
   * @description Inject the onValueChange that allows to edit a field of the form.
   * @param {function} dispatch Redux dispatch function.
   * @param {string} entityName Entity name of the form page.
   * @param {Array<string>|string|null} fieldPath Path of the field to inject(if not set, all the
   *  state of the form page's store will be injected).
   * @param {Object} parameters Parameters of the form page.
   * @param {string} type Type of page.
   * @return {{onValueChange: (function(*=): *)}}
   */
  const mapDispatchToProps = (dispatch, { entityName, fieldPath, parameters, type }) => {
    // Function to edit the form.
    let onValueChange;
    // Function to send directly data to the server.
    let sendData;
    const fetch = id => dispatch(createFetchData(entityName, parameters)(id));

    switch (type) {
      case PAGE_TYPE_EDIT:
        onValueChange = value =>
          dispatch(createEditCurrentEntity(entityName, parameters)(fieldPath, value));
        sendData = (value, pathList = fieldPath) =>
          dispatch(createSendDirectEdition(entityName, parameters)(pathList, value));
        break;
      case PAGE_TYPE_CREATE:
        onValueChange = value =>
          dispatch(createEditNewEntity(entityName, parameters)(fieldPath, value));
        sendData = null;
        break;
      default:
        throw new FormError(
          `Unknown page type: ${type}. Must be one of [${PAGE_TYPE_EDIT}, ${PAGE_TYPE_CREATE}]`,
        );
    }

    return {
      onValueChange,
      sendData,
      fetch,
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(Component);
}

export default { connectWithFormStore };
