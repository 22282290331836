import reportsList from './entityList';
import { STRING } from '../listPage/fieldTypes';

import createListPage from '../listPage';

const getReportLink = report =>
  report?.metabaseLink ? report?.metabaseLink : `/meta/reports/${report.name}`;

const reportsPage = createListPage(
  reportsList,
  {
    description: {
      label: 'description',
      type: STRING,
    },
  },
  { getOnClickLink: getReportLink, renderItemButtons: () => null },
);

export default reportsPage;
