/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 26/10/2018
 */

import EntityList from '../listPage/EntityList';

export default new EntityList(
  'members',
  'members/',
  {
    zidane: true,
  },
  undefined,
  undefined,
  'Members',
);
