import * as actions from './actions';
import { getFixture, postUpdateResult } from '../thunks/api-fixture';

export function fetchFixture(fixtureId) {
  return function _fetchFixture(dispatch) {
    dispatch(actions.fetchFixturePending());

    return dispatch(getFixture(fixtureId))
      .then(response => response.getResult())
      .then(fixture => {
        dispatch(actions.fetchFixtureSuccess(fixture));
        return fixture;
      })
      .catch(error => {
        dispatch(actions.fetchFixtureFail());
        return error.message;
      });
  };
}

export function sendFixtureResultUpdate(fixtureId, result) {
  return function _sendFixtureResultUpdate(dispatch) {
    dispatch(actions.setResultStart());
    return dispatch(postUpdateResult(fixtureId, result))
      .then(() => {
        dispatch(actions.setResultSuccess());
        return dispatch(fetchFixture(fixtureId));
      })
      .catch(error => {
        dispatch(actions.setResultFail());
        return error.message;
      });
  };
}
