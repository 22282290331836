import PropTypes from 'prop-types';
import React from 'react';

const OpeningHours = ({ editedValue }) => (
  <>
    <p>Please use pogba if you wish to update opening hours</p>
    {Object.keys(editedValue).map(k => (
      <p style={{ textTransform: 'capitalize' }} key={k}>
        {k} {editedValue[k].opening} - {editedValue[k].closing}
      </p>
    ))}
  </>
);

OpeningHours.propTypes = {
  editedValue: PropTypes.shape().isRequired,
};

export default OpeningHours;
