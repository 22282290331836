import { request } from './requests';

export const getAnswersForQuestion = (questionId, quizId) => dispatch =>
  dispatch(request(`/games/quizzes/${quizId}/answers?questionId=${questionId}&limit=100`));

export const updateQuizAnswer = (quizAnswer, quizId) => dispatch =>
  dispatch(
    request(`games/quizzes/${quizId}/answers/${quizAnswer.id}/update?zidane=1`, {
      method: 'PUT',
      body: {
        quizAnswer,
      },
    }),
  );

export const createQuizAnswer = (quizAnswer, quizId) => dispatch =>
  dispatch(
    request(`/games/quizzes/${quizId}/answers/add?zidane=1`, {
      method: 'POST',
      body: {
        quizAnswer,
      },
    }),
  );
