import React from 'react';
import PropTypes from 'prop-types';

const Images = ({ fullResource: { extraImages, profilePicture } }) => {
  const photos = Object.keys(extraImages).map(i => extraImages[i]['1024_450']);

  const imageStyles = { maxWidth: '350px', width: '100%', margin: '0 8px' };

  return (
    <>
      <p>Please use Pogba if you wish to update images</p>
      <img style={imageStyles} alt="" src={profilePicture['1024_450']} />
      {photos.map(e => (
        <img key={e} style={imageStyles} alt="" src={e} />
      ))}
    </>
  );
};

Images.propTypes = {
  fullResource: PropTypes.shape().isRequired,
};

export default Images;
