/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 18/04/2018
 */

import * as constants from './constants';

export function loginFailure(error) {
  return {
    type: constants.LOGIN_FAILURE,
    error,
  };
}

export function loginSuccess(member) {
  return {
    type: constants.LOGIN_SUCCESS,
    member,
  };
}

export function loginPending() {
  return { type: constants.LOGIN_PENDING };
}

export function logoutAction() {
  return { type: constants.LOGOUT };
}
