// single place to change game data stuff
export const games = [
  {
    game: 44,
    entityName: '1',
    competitionId: 386,
    gameName: 'CCIC - NI',
    leagueType: 'round',
  },
  {
    game: 43,
    entityName: '2',
    competitionId: 386,
    gameName: 'CCIC - ROI',
    leagueType: 'round',
  },
  {
    game: 42,
    entityName: '3',
    competitionId: 386,
    gameName: 'CCIC - 0.0',
    leagueType: 'round',
  },
  {
    game: 41,
    entityName: '4',
    competitionId: 386,
    gameName: 'CCIC - LFC',
    leagueType: 'round',
  },
  {
    game: 45,
    entityName: '5',
    competitionId: 386,
    gameName: 'Guinness 0.0 CIC - EPL',
    leagueType: 'round',
  },
  {
    game: 46,
    entityName: '6',
    competitionId: 386,
    gameName: 'Guinness CIC - AUS',
    leagueType: 'round',
  },
  {
    game: 47,
    entityName: '7',
    competitionId: 386,
    gameName: 'Guinness Autumns CIC',
    leagueType: 'round',
  },
];

export const quizzes = [
  {
    id: 2,
    entityName: 'quiz',
    name: 'FANZO Quiz',
  },
  {
    id: 3,
    entityName: 'frenchQuiz',
    name: 'FANZO French Quiz',
  },
  {
    id: 6,
    entityName: 'aussieQuiz',
    name: 'FANZO Australia Quiz',
  },
  {
    id: 4,
    entityName: 'NRLQuiz',
    name: 'NRL Quiz',
  },
  {
    id: 5,
    entityName: 'aflQuiz',
    name: 'AFL Quiz',
  },
  {
    id: 7,
    entityName: 'ieQuiz',
    name: 'IE Quiz',
  },
];
