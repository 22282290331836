//      ,---.
//    ,/     \__.'~\
//    |        '  . `===---      -Ed touched this file-
//    `|   ,     ,--'
//     `. /___,-'
//       |    \
//       ,\_  ,`-=

import moment from 'moment';
import * as a from './actions';
import * as f from '../thunks/api-topFixtures';

const defaultError = 'An error occured, please refresh the page and try again.';

export const fetchMatchpintCountries = () => dispatch => {
  dispatch(a.fetchMatchPintCountriesPending());
  return dispatch(f.getMatchPintCountries())
    .then(r => {
      if (r.status === 202) {
        return dispatch(
          a.fetchMatchPintCountriesError((r.error && r.error.message) || defaultError),
        );
      }
      return dispatch(a.fetchMatchPintCountriesSuccess(r.getResult()));
    })
    .catch(err =>
      dispatch(a.fetchMatchPintCountriesError((err.error && err.error.message) || defaultError)),
    );
};

export const fetchTopFixtures = countryId => dispatch => {
  dispatch(a.fetchTopFixturesPending());
  return dispatch(f.fetchTopFixtures(countryId))
    .then(r => {
      if (r.status === 202) {
        return dispatch(a.fetchTopFixturesError((r.error && r.error.message) || defaultError));
      }
      return dispatch(a.fetchTopFixturesSuccess(r));
    })
    .catch(err =>
      dispatch(a.fetchTopFixturesError((err.error && err.error.message) || defaultError)),
    );
};

export const saveTopFixturesOrder = (countryId, newOrder) => dispatch => {
  dispatch(a.changeOrder(newOrder));
  return dispatch(
    f.saveTopFixtures(
      newOrder.map(fix => fix.id),
      countryId,
    ),
  )
    .then(r => r.status === 202 && dispatch(a.changeOrderError(r.error.message || defaultError)))
    .catch(err => a.changeOrderError(err.error.message || defaultError));
};

export const updateFixture = fixture => dispatch =>
  dispatch(f.updateTopFixture(fixture))
    .then(r => {
      if (r.status === 202) {
        return dispatch(a.updateTopFixtureError(r.error.message || defaultError));
      }
      return dispatch(a.updateTopFixture(fixture));
    })
    .catch(r => dispatch(a.updateTopFixtureError(r.error.message || defaultError)));

export const addFixture = (fix, countryId) => dispatch => {
  dispatch(a.setFixtureItemLoading(fix.id));
  return dispatch(
    f.addFixtureToTops({
      fixtureId: fix.id,
      countryId,
      startTimeUtc: moment().format(),
      endTimeUtc: moment(fix.startTimeUtc).add(1, 'hour').format(),
    }),
  )
    .then(r => {
      if (r.status === 202) {
        return dispatch(a.addOrRemoveFixtureError(r.error.message || defaultError));
      }
      return dispatch(a.locallyAddTopFixture(r.getResult()));
    })
    .catch(r => dispatch(a.addOrRemoveFixtureError(r.error.message || defaultError)));
};

export const removeFixture = fix => dispatch => {
  dispatch(a.setFixtureItemLoading(fix.fixture.id));
  return dispatch(f.removeFixtureFromTops(fix.id))
    .then(r => {
      if (r.status === 202) {
        return dispatch(a.addOrRemoveFixtureError(r.error.message || defaultError));
      }
      return dispatch(a.locallyRemoveTopFixture(fix));
    })
    .catch(r =>
      dispatch(a.addOrRemoveFixtureError((r && r.error && r.error.message) || defaultError)),
    );
};

export const onSearchFixtures =
  (searchTerm, countryId, offset = 0, limit = undefined, includePast = '0') =>
  dispatch => {
    if (offset === 0) {
      dispatch(a.fetchFixturesForSearchPending());
    } else {
      dispatch(a.fetchMoreFixturesForSearchPending());
    }

    return dispatch(f.getFixtures(searchTerm, countryId, limit, offset, includePast))
      .then(r => {
        if (r.status !== 202) {
          return offset === 0
            ? dispatch(a.fetchFixturesForSearchSuccess(r.getResult()))
            : dispatch(a.fetchMoreFixturesForSearchSuccess(r.getResult()));
        }
        return offset === 0
          ? a.fetchFixturesForSearchError(r.error && r.error.message)
          : a.fetchMoreFixturesForSearchError(r.error && r.error.message);
      })
      .catch(r =>
        offset === 0
          ? a.fetchFixturesForSearchError(r.error && r.error.message)
          : a.fetchMoreFixturesForSearchError(r.error && r.error.message),
      );
  };
