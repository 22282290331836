/**            -                              ___
 *            |.|                          . /\_/\
 *          __]-[_________ /             .  (-<_>-)
 *         /        _____|<_          .   .  \/_\/
 *        / _   &  /               .   .
 *       / / \_ __|            .
 *     _/_/  / X   \
 *    <_/   /   ____\
 *         /___/|  /             zinedine zidane
 *         |  / ( <                --france--
 *         ( <   \ |
 *          \ |   >\
 *          _>|  (_/
 *         (__|
 *
 * MatchPint Ltd
 * @author Andreia Almeida
 * Date: 20/12/2018
 */

import gamePrizeMembersList from './entityList';

import createListPage from '../listPage';
import { NUMBER, STRING } from '../listPage/fieldTypes';
import tabs from '../../../routes/tabs';

const createPage = ({ entity, game }) =>
  createListPage(
    entity,
    {
      id: {
        label: 'id',
        type: NUMBER,
      },
      memberId: {
        label: 'Member',
        renderValue: (value, values) =>
          values?.member?.firstName && values?.member?.lastName
            ? `${values.member.firstName} ${values.member.lastName} (${value})`
            : value,
        type: STRING,
      },
      gamePrizeId: {
        label: 'Prize',
        renderValue: (value, values) => values?.gamePrize?.name || value,
        type: STRING,
      },
      active: {
        label: 'League',
        renderValue: (_, values) =>
          values?.league?.name && values?.league?.id
            ? `${values.league.name} (${values.league.id})`
            : null,
        type: STRING,
      },
    },
    {
      navTabs: tabs[`${game.entityName}PrizesMembers`],
    },
  );

export default gamePrizeMembersList.map(createPage);
