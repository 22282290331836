/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 17/10/2018
 */

import React from 'react';
import PropTypes from 'prop-types';

import * as moment from 'moment';

import TimePicker from 'material-ui/TimePicker';

import ClearButton from '../../ClearButton';

import './style.scss';

// TODO: null for time fields ?
// TODO: error management ?

const TimeField = ({ onValueChange, value, name }) => {
  const momentValue = moment(value, 'HH:mm:ss');
  const timeValue = value && momentValue.isValid() ? momentValue.toDate() : null;

  return (
    <div className="TimeField_wrapper">
      <p>{name}</p>
      <div className="TimeField_timeContainer">
        <TimePicker
          autoOk
          className="TimeField__time"
          format="24hr"
          onChange={(e, date) => onValueChange(moment(date).format('HH:mm:ss'))}
          value={timeValue}
        />
        <ClearButton
          key="clear-button"
          className="TimeField__cancel"
          clearableValue={value !== '00:00:00' && value && momentValue.isValid()}
          handleCancel={() => onValueChange('00:00:00')}
        />
      </div>
    </div>
  );
};

TimeField.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
};

TimeField.defaultProps = {
  value: null,
};

export default TimeField;
