import calendarContentList from './entityList';
import createListPage from '../listPage';
import tabs from '../../../routes/tabs';

const calendarContent = createListPage(
  calendarContentList,
  {
    id: { label: 'id' },
    relatedType: { label: 'Content for' },
    relatedId: { label: 'Related id' },
    title: { label: 'Title' },
    emoji: { label: 'Emoji' },
    content: { label: 'Content' },
    createdAt: { label: 'createdAt' },
  },
  {
    navTabs: tabs.calendarContent,
  },
);

export default calendarContent;
