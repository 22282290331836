/* eslint-disable react/destructuring-assignment */

import * as constants from './constants';

export const initialState = {
  changePassword: {
    status: null,
    email: null,
    message: null,
    error: null,
  },
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.CHANGE_PASSWORD_PENDING:
      return {
        ...state,
        status: constants.CHANGE_PASSWORD_PENDING,
        email: action.email,
      };
    case constants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        status: constants.CHANGE_PASSWORD_SUCCESS,
        message: action.message,
        error: null,
      };
    case constants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        status: constants.CHANGE_PASSWORD_FAILURE,
        message: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
