/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 02/05/2018
 */

import offersList from './entityList';

import tabs from '../../../routes/tabs';

import createListPage from '../listPage';

import * as fieldTypes from '../listPage/fieldTypes';

const offerListPage = createListPage(
  offersList,
  {
    id: {
      label: 'ID',
      type: fieldTypes.NUMBER,
      style: {
        width: '7%',
      },
    },
    title: {
      label: 'Title',
      type: fieldTypes.STRING,
      style: {
        width: '20%',
      },
    },
    fromDateUtc: {
      label: 'Start date',
      onPhone: false,
      type: fieldTypes.DATE,
      style: {
        width: '10%',
      },
    },
    toDateUtc: {
      label: 'End date',
      onPhone: false,
      type: fieldTypes.DATE,
      style: {
        width: '10%',
      },
    },
    venuesNumber: {
      label: 'Venues',
      type: fieldTypes.NUMBER,
      onPhone: false,
      style: {
        width: '5%',
        textAlign: 'center',
      },
    },
  },
  {
    navTabs: tabs.offer,
  },
);

export default offerListPage;
