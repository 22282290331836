//         .-.
//         ((`-) Q U O N K
//          \\
//           \\
//    .="""=._))
//   /  .,   .'
//  /__(,_.-'
// `    /|
//     /_|__
//       | `))
//       |
//      -"==

import * as constants from './constants';

export function fetchPending() {
  return {
    type: constants.FETCH_PAYMENT_INFO_PENDING,
  };
}

export function fetchSuccess(paymentInfo) {
  return {
    type: constants.FETCH_PAYMENT_INFO_SUCCESS,
    payload: paymentInfo,
  };
}

export function fetchError(error) {
  return {
    type: constants.FETCH_PAYMENT_INFO_ERROR,
    payload: { error },
  };
}

export function fetchShapePending() {
  return {
    type: constants.FETCH_PAYMENT_INFO_SHAPE_PENDING,
  };
}

export function fetchShapeSuccess(shape) {
  return {
    type: constants.FETCH_PAYMENT_INFO_SHAPE_SUCCESS,
    payload: shape,
  };
}

export function fetchShapeError(error) {
  return {
    type: constants.FETCH_PAYMENT_INFO_SHAPE_ERROR,
    payload: { error },
  };
}

export function hasChanged(field, value) {
  return {
    type: constants.HAS_CHANGED,
    payload: {
      field,
      value,
    },
  };
}

export function discardChanges() {
  return {
    type: constants.DISCARD_CHANGES,
  };
}

export function savePending() {
  return {
    type: constants.SAVE_PENDING,
  };
}

export function saveSuccess() {
  return {
    type: constants.SAVE_SUCCESS,
  };
}

export function saveError(error) {
  return {
    type: constants.SAVE_ERROR,
    payload: { error },
  };
}
