/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 18/04/2018
 */

/**
 * @description Returns if the application is loading the authentication info about the user.
 * @param state
 * @returns {boolean}
 */
export function isLoadingAuthentication(state) {
  return state.member.status === 'pending';
}

/**
 * @description Returns if the current user is authenticated.
 * @param state
 * @returns {boolean|number}
 */
export function isAuthenticated(state) {
  return state.member.authenticatedMember !== null && state.member.authenticatedMember.id !== null;
}

/**
 * The currently logged in member
 *
 * @param state
 * @returns {{
 *   firstName: string,
 *   lastName: string,
 *   id: number
 * }}
 */
export function getAuthenticatedMember(state) {
  return state.member.authenticatedMember;
}

export const getPermissions = state => state.member.permissions;
export const getPermissionsPending = state => state.member.permissionsPending;
