import { quizzes } from '../../games';
import EntityList from '../listPage/EntityList';

export default quizzes.map(
  ({ id, entityName }) =>
    new EntityList(
      `${entityName}Questions`,
      `games/quizzes/${id}/questions`,
      {
        zidane: true,
      },
      undefined,
      undefined,
      'Quiz Questions',
    ),
);
