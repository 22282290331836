/**            -                              ___
 *            |.|                          . /\_/\
 *          __]-[_________ /             .  (-<_>-)
 *         /        _____|<_          .   .  \/_\/
 *        / _   &  /               .   .
 *       / / \_ __|            .
 *     _/_/  / X   \
 *    <_/   /   ____\
 *         /___/|  /             zinedine zidane
 *         |  / ( <                --france--
 *         ( <   \ |
 *          \ |   >\
 *          _>|  (_/
 *         (__|
 *
 * MatchPint Ltd
 * @author Andreia Almeida
 * Date: 06/12/2018
 */

import React from 'react';
import ScoreIcon from 'material-ui/svg-icons/action/assignment-turned-in';
import ListIcon from 'material-ui/svg-icons/action/assignment';
import matchesList from './entityList';

import createListPage from '../listPage';

import { STRING, DATE } from '../listPage/fieldTypes';
import ListPageIconButton from '../listPage/components/IconButton';
import { getDefaultRenderItemSideButtons } from '../listPage/utils';

const getScoreLink = fixture => `/sports/fixtures/v2/${fixture.id}/result`;

const getEditLink = fixture => `/sports/fixtures/v2/edit?id=${fixture.id}`;

const renderMatchesSideButton = (fixture, comp) => {
  const defaultButtons = getDefaultRenderItemSideButtons(comp, {
    getOnClickLink: getEditLink,
  })(fixture);

  defaultButtons.push(
    <ListPageIconButton key="stats" icon={<ScoreIcon />} link={getScoreLink(fixture)} />,
  );

  if (fixture?.minuteEvents && fixture?.homeTeam?.id && fixture?.awayTeam?.id) {
    defaultButtons.push(
      <ListPageIconButton
        key="minute-events"
        icon={<ListIcon />}
        link={`/sports/fixtures/v2/${fixture.id}/minute-events`}
      />,
    );
  }

  return defaultButtons;
};

const renderScore = values => {
  // Make sure we display the score of home team first
  if (
    values.results &&
    values.results[0] &&
    values.results[1] &&
    values.homeTeam &&
    values.homeTeam.id
  ) {
    if (values.results[0].teamId === values.homeTeam.id) {
      return `${values.results[0].score} - ${values.results[1].score}`;
    }
    return `${values.results[1].score} - ${values.results[0].score}`;
  }
  return '-';
};

const createPage = component =>
  createListPage(
    component,
    {
      name: {
        label: 'Match',
        type: STRING,
      },
      round: {
        label: 'Round',
        type: STRING,
        renderValue: value => value && value.name,
      },
      startTimeUtc: {
        label: 'Date',
        type: DATE,
      },
      results: {
        label: 'Score',
        type: STRING,
        renderValue: (_, values) => renderScore(values),
      },
    },
    {
      renderItemButtons: fix => renderMatchesSideButton(fix, component),
      getOnClickLink: getEditLink,
    },
  );

export default matchesList.map(createPage);
