/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 08/06/2018
 */

import * as constants from './constants';

export function changePasswordFailure(error) {
  return {
    type: constants.CHANGE_PASSWORD_FAILURE,
    error,
  };
}

export function changePasswordSuccess(message) {
  return {
    type: constants.CHANGE_PASSWORD_SUCCESS,
    message,
  };
}

export function changePasswordPending(email) {
  return {
    type: constants.CHANGE_PASSWORD_PENDING,
    email,
  };
}
