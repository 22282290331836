import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import request from '../../../../utils/apiRequest/requests';
import FormField from '../../../../components/FormField';
import ExpendableCard from '../../../../components/ExpendableCard';
import MpResponse from '../../../../utils/apiRequest/MpResponse';
import { deepEqual } from '../../../../utils/object';
import ReadOnly from '../../../../components/FormField/ReadOnly';
import NumberEdit from '../../../../components/FormField/Number';
import './AssetSection.scss';
import MpModal from '../../../../components/MpModal/mpModal';

const hiddenFields = [
  'id',
  'assetGroupId',
  'createdAt',
  'updatedAt',
  'logoCoordinatesX1',
  'logoCoordinatesX2',
  'logoCoordinatesY1',
  'logoCoordinatesY2',
];

const Button = ({ onClick, className, children }) => (
  <button
    className={`rounded-lg px-4 py-1 font-semibold ${className}`}
    type="button"
    onClick={onClick}
  >
    {children}
  </button>
);

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Button.defaultProps = { className: 'bg-primaryYellow' };

const AssetSection = ({ fullResource }) => {
  const { id: groupId } = fullResource;

  const [fieldDesc, setFieldDesc] = useState({});
  const [initialImages, setInitialImages] = useState([]);
  const [editedImages, setEditedImages] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});
  const [dragStartX, setDragStartX] = useState(0);
  const [dragStartY, setDragStartY] = useState(0);
  const [deleteId, setDeleteId] = useState(null);
  const imgRef = useRef();

  const resetFieldErrorsForImage = imgId => {
    const tempFieldErrors = { ...fieldErrors };
    delete tempFieldErrors[imgId];
    setFieldErrors(tempFieldErrors);
  };

  const displayErrors = id =>
    fieldErrors?.[id] && (
      <p className="my-2 bg-lightRed py-2 pl-3 text-lg font-semibold">
        {MpResponse.getErrorMessage(fieldErrors[id])}
      </p>
    );

  const loadAssets = useCallback(
    () =>
      // load assets in a group
      request(`meta/asset-image?assetGroupId=${groupId}`).then(r => {
        const res = r.getResult();
        setEditedImages(res);
        // make sure initial isn't referenced by edited
        setInitialImages(JSON.parse(JSON.stringify(res)));
      }),
    [groupId],
  );

  const save = (data, isExisting) => {
    const endpoint = isExisting ? `/${data.id}/update` : '/add';
    const method = isExisting ? 'PUT' : 'POST';

    resetFieldErrorsForImage(data.id);

    const saveData = { ...data };

    if (!isExisting) {
      delete saveData.id;
    }

    request(`meta/asset-image${endpoint}`, { method, body: { assetImage: saveData } })
      .then(r => {
        if (r.isFormError()) {
          throw r;
        }
        const res = r.getResult();

        const initialIndex = editedImages.findIndex(ii => ii.id === data.id);
        const editedIndex = editedImages.findIndex(ii => ii.id === data.id);

        const copyInitial = [...initialImages];
        const copyEdited = [...editedImages];
        copyInitial[initialIndex] = res;
        copyEdited[editedIndex] = JSON.parse(JSON.stringify(res));
        setInitialImages(copyInitial);
        setEditedImages(copyEdited);
      })
      .catch(e => setFieldErrors({ ...fieldErrors, [data.id]: e }));
  };

  const deleteAsset = () => {
    request(`meta/asset-image/${deleteId}/delete`, { method: 'DELETE' })
      .then(loadAssets)
      .finally(() => setDeleteId(null));
  };

  useEffect(() => {
    loadAssets();
  }, [loadAssets]);

  useEffect(() => {
    request('meta/asset-image/0/update?formDescription=1', { method: 'PUT' }).then(r => {
      const res = r.getResult();
      setFieldDesc(
        Object.keys(res)
          .filter(key => !hiddenFields.includes(key))
          .reduce((obj, key) => {
            // eslint-disable-next-line no-param-reassign
            obj[key] = res[key];
            return obj;
          }, {}),
      );
    });
  }, []);

  const selectingfn = ei => {
    if (!ei.logoCoordinatesX1 && !ei.logoCoordinatesY1) {
      return {
        text: 'selecting top left point',
        value: ['logoCoordinatesX1', 'logoCoordinatesY1'],
      };
    }

    if (!ei.logoCoordinatesX2 && !ei.logoCoordinatesY2) {
      return {
        text: 'selecting bottom right point',
        value: ['logoCoordinatesX2', 'logoCoordinatesY2'],
      };
    }

    return {};
  };

  return (
    <>
      {editedImages.map((ei, index) => {
        const isExistingImage = typeof initialImages.find(iI => iI.id === ei.id)?.id !== 'string';

        const canSave = !deepEqual(editedImages[index], initialImages[index]);

        const { text, value } = selectingfn(ei);

        const handleEditField = (val, field) => {
          const arrayCopy = [...editedImages];
          const tempCopy = arrayCopy[index];
          tempCopy[field] = val;
          arrayCopy[index] = tempCopy;
          setEditedImages(arrayCopy);
        };

        const height = ei.logoCoordinatesY2 - ei.logoCoordinatesY1;
        const width = ei.logoCoordinatesX2 - ei.logoCoordinatesX1;

        return (
          <ExpendableCard key={ei.id} title={ei.name}>
            {displayErrors(ei.id)}
            {Object.values(fieldDesc)?.map(f => (
              <FormField
                key={f.name}
                fullResource={{ id: ei.id }}
                error={fieldErrors?.[ei.id]?.data?.error?.fields?.[f.name]}
                description={f}
                editedValue={ei[f.name]}
                onValueChange={val => handleEditField(val, f.name)}
              />
            ))}
            {ei.assetImage && (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <div
                type="button"
                onClick={e => {
                  // if they're not already set then set top left and bottom right
                  if (value) {
                    const rect = e.target.getBoundingClientRect();
                    handleEditField(Math.round(e.clientX - rect.left), value[0]);
                    handleEditField(Math.round(e.clientY - rect.top), value[1]);
                  }
                }}
                className={`relative inline border ${value ? 'hover:cursor-pointer' : ''}`}
              >
                <img ref={imgRef} className="AssetSection_image" alt="" src={ei.assetImage} />
                <div
                  style={{
                    top: ei.logoCoordinatesY1,
                    left: ei.logoCoordinatesX1,
                    height,
                    width,
                  }}
                  draggable
                  onDragStart={e => {
                    // on start drag store the position of the box
                    const box = e.currentTarget.getBoundingClientRect();
                    setDragStartX(e.screenX - box.left);
                    setDragStartY(e.screenY - box.top);
                  }}
                  onDragEnd={e => {
                    // on end compare that start point to the endpoint to get the diff and add that to the
                    // relevant corordinates, check that they're inside the image and save them
                    const box = e.currentTarget.getBoundingClientRect();
                    const newDiffX = e.screenX - box.left - dragStartX;
                    const newDiffY = e.screenY - box.top - dragStartY;

                    const imgBox = imgRef.current.getBoundingClientRect();

                    const arrayCopy = [...editedImages];
                    const tempCopy = arrayCopy[index];
                    tempCopy.logoCoordinatesX1 += newDiffX;
                    tempCopy.logoCoordinatesX2 += newDiffX;
                    tempCopy.logoCoordinatesY1 += newDiffY;
                    tempCopy.logoCoordinatesY2 += newDiffY;
                    arrayCopy[index] = tempCopy;

                    if (
                      tempCopy.logoCoordinatesX1 >= 0 &&
                      tempCopy.logoCoordinatesY1 >= 0 &&
                      tempCopy.logoCoordinatesX2 <= imgBox.width &&
                      tempCopy.logoCoordinatesY2 <= imgBox.height
                    ) {
                      setEditedImages(arrayCopy);
                    }
                  }}
                  className="AssetSection_logo"
                />
              </div>
            )}
            <p className="text-lg text-lightRed">{text}</p>
            <div className="flex items-center">
              <ReadOnly name="Top Left" text={`${ei.logoCoordinatesX1}, ${ei.logoCoordinatesY1}`} />
              <ReadOnly
                name="Bottom Right"
                text={`${ei.logoCoordinatesX2}, ${ei.logoCoordinatesY2}`}
              />
              <NumberEdit
                name="Height"
                min={1}
                handleEditField={val =>
                  handleEditField(parseInt(val, 10) + ei.logoCoordinatesY1, 'logoCoordinatesY2')
                }
                value={height}
              />
              <NumberEdit
                name="Width"
                min={1}
                value={width}
                handleEditField={val =>
                  handleEditField(parseInt(val, 10) + ei.logoCoordinatesX1, 'logoCoordinatesX2')
                }
              />
              {ei.logoCoordinatesX1 && ei.logoCoordinatesY1 && (
                <Button
                  className="h-10 bg-black text-white"
                  onClick={() => {
                    handleEditField(null, 'logoCoordinatesY1');
                    handleEditField(null, 'logoCoordinatesY2');
                    handleEditField(null, 'logoCoordinatesX2');
                    handleEditField(null, 'logoCoordinatesX1');
                  }}
                >
                  RESET
                </Button>
              )}
            </div>

            {displayErrors(ei.id)}
            <Button className="mr-4 bg-darkRed" onClick={() => setDeleteId(ei.id)}>
              Delete
            </Button>
            {canSave && <Button onClick={() => save(ei, isExistingImage)}>Save</Button>}
          </ExpendableCard>
        );
      })}

      <Button
        onClick={() => {
          const newID = `NEW${editedImages.length + 1}`;
          setInitialImages([...initialImages, { id: newID }]);
          setEditedImages([...editedImages, { assetGroupId: groupId, id: newID }]);
        }}
      >
        Create New
      </Button>

      <MpModal
        visible={deleteId}
        mainText="YOU ARE ABOUT TO DELETE AN ASSET. ARE YOU SURE?"
        subText="ALL UNSAVED CHANGES WILL BE LOST"
        warningText="THIS ACTION CANNOT BE UNDONE"
        onConfirm={deleteAsset}
        onCancel={() => setDeleteId(null)}
      />
    </>
  );
};

AssetSection.propTypes = {
  fullResource: PropTypes.shape().isRequired,
};

export default AssetSection;
