/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 27/07/2018
 */

import createListPage from '../listPage';
import * as fieldTypes from '../listPage/fieldTypes';

import venuesList from './entityList';

const venuesListPage = createListPage(venuesList, {
  id: {
    label: 'ID',
    type: fieldTypes.NUMBER,
    style: {
      width: '10%',
    },
  },
  name: {
    label: 'Name',
    style: {
      width: '20%',
    },
  },
  postCode: {
    label: 'Postcode',
    style: {
      width: '10%',
    },
  },
  venueGroupOwner: {
    label: 'Owner',
    style: {
      width: '20%',
    },
    renderValue: value => value?.name || '',
  },
  venueGroupOperator: {
    label: 'Operator',
    style: {
      width: '20%',
    },
    renderValue: value => value?.name || '',
  },
  venueGroupPaid: {
    label: 'Paid by',
    style: {
      width: '20%',
    },
    renderValue: value => value?.name || '',
  },
});

export default venuesListPage;
