/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */
import React from 'react';
import PropTypes from 'prop-types';

import TextField from 'material-ui/TextField';

import './index.scss';
import InfoIcon from '../InfoIcon';

const verifyTextLength = (text, maxTextLength, handleEditField) => {
  if (text.length <= maxTextLength) {
    handleEditField(text);
  }
};

const MultilineTextFieldEdit = ({
  error,
  handleEditField,
  help,
  name,
  maxTextLength,
  previous,
  previousText,
  text,
}) => (
  <div className="MultilineTextFieldEdit">
    <div className="MultilineTextFieldEdit_left">
      <div className="MultilineTextFieldEdit_left_new">
        <TextField
          className="MultilineTextFieldEdit_left_new_text"
          errorText={error}
          floatingLabelText={name}
          multiLine
          onChange={(e, newText) => verifyTextLength(newText, maxTextLength, handleEditField)}
          rows={2}
          value={text}
        />
        {text ? (
          <div className="MultilineTextFieldEdit_left_new_chars">
            {`Number of characters: ${text.length} `} {maxTextLength ? `/ ${maxTextLength}` : ''}{' '}
            chars
          </div>
        ) : null}
      </div>
      <div className="MultilineTextFieldEdit_left_info_wrapper">
        <InfoIcon tooltip={help} tooltipPosition="bottom-left" />
      </div>
    </div>
    {previous && (
      <div className="MultilineTextFieldEdit_previous">
        <h5>Previous :</h5>
        <span className="MultilineTextFieldEdit_previous_text">{previousText}</span>
      </div>
    )}
  </div>
);

MultilineTextFieldEdit.propTypes = {
  error: PropTypes.string,
  handleEditField: PropTypes.func,
  help: PropTypes.string,
  name: PropTypes.string,
  maxTextLength: PropTypes.number,
  previous: PropTypes.bool,
  previousText: PropTypes.string,
  text: PropTypes.string,
};

MultilineTextFieldEdit.defaultProps = {
  error: null,
  handleEditField: () => {},
  help: 'Not available.',
  name: 'Enter new:',
  maxTextLength: 5000,
  previous: false,
  previousText: null,
  text: '',
};

export default MultilineTextFieldEdit;
