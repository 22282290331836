/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 24/05/2018
 */
import * as constants from './constants';

/**
 * @description Action describing when the user receives a global error.
 * @param {string} errorType
 * @returns {{type: string, errorType: string}}
 */
export function receiveErrorAction(errorType) {
  return {
    type: constants.RECEIVE_GLOBAL_ERROR,
    errorType,
  };
}

export function dismissErrorAction() {
  return {
    type: constants.DISMISS_GLOBAL_ERROR,
  };
}

export function displaySticky() {
  return {
    type: constants.DISPLAY_STICKY_GLOBAL_ERROR,
  };
}

export function displayInline() {
  return {
    type: constants.DISPLAY_INLINE_GLOBAL_ERROR,
  };
}
