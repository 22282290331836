import React from 'react';
import channelGroupList from './entityList';

import createListPage from '../listPage';
import tabs from '../../../routes/tabs';

const channelGroupPage = createListPage(
  channelGroupList,
  {
    channelId: {
      label: 'Channel ID',
    },
    channel: {
      label: 'Channel',
      renderValue: channel => <p>{channel.name}</p>,
    },
    country: {
      label: 'Country',
      renderValue: country => <p>{country.name}</p>,
    },
  },
  {
    navTabs: tabs.channelGroups,
  },
);

export default channelGroupPage;
