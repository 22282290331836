/* eslint-disable react/prop-types */
import createFormPage from '../formPage';

const params = {
  endpoint: 'contents/social-media-themes/competition-countries',
  create: {
    fields: [
      'countryId',
      'competitionId',
      'logo',
      'backgroundLandscape',
      'backgroundInstagram',
      'showAsLogo',
    ],
  },
};

export default createFormPage('venueSocialMediaCompetitionCountry', params);
