import createFormPage from '../formPage';
import PermissionsSelector from './PermissionsSelector';
import FixturesPerWeekLimit from './FixturesPerWeekLimit';

export default createFormPage('tier', {
  endpoint: 'meta/pub-portal/tiers',
  sections: {
    General: [
      { fieldPath: 'name' },
      { fieldPath: 'countryId' },
      { fieldPath: 'referenceTierId' },
      { fieldPath: 'description' },
      { fieldPath: 'details' },
      { fieldPath: 'groupCode' },
      { fieldPath: 'fixtureWeekLimit', Component: FixturesPerWeekLimit },
    ],
    Pricing: [
      { fieldPath: 'monthlyPrice' },
      { fieldPath: 'monthlyXeroCode' },
      { fieldPath: 'quarterlyPrice' },
      { fieldPath: 'quarterlyXeroCode' },
      { fieldPath: 'yearlyPrice' },
      { fieldPath: 'yearlyXeroCode' },
    ],
    Permissions: [{ fieldPath: 'id', Component: PermissionsSelector }],
  },
  create: {
    fields: ['name', 'countryId', 'referenceTierId', 'groupCode', 'monthlyPrice', 'yearlyPrice'],
  },
});
