import EntityList from '../listPage/EntityList';

export default new EntityList(
  'countryChannel',
  'meta/country-channels',
  {
    zidane: true,
  },
  undefined,
  undefined,
  'Channel Groups',
);
