/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 26/10/2018
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

// Redux.
import { getQueryId } from '../../../../store/navigation/selectors';
import { createFetchData } from '../store/thunksFactory';
import { createClearValues } from '../store/actionCreatorFactory';
// Ui Component.
import Form from './Form';

// Utils.
import { PAGE_TYPE_EDIT } from '../utils/constants';
import * as customPropTypes from '../utils/propTypes';
import LoadingWheel from '../../../../components/LoadingWheel';

/**
 * @description Component rendering a Form page. This component will mainly fetch the data used
 *  the form.
 */
const EditPage = ({ id, entityName, parameters, fetchAPIData, clearValues }) => {
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    fetchAPIData(id).then(() => setLoading(false));
    return () => clearValues();
    // to prevent double firing fetch after successToast is dismissed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <LoadingWheel size="inline" />;
  }

  return <Form entityName={entityName} parameters={parameters} type={PAGE_TYPE_EDIT} />;
};

const mapStateToProps = state => ({
  id: getQueryId(state),
});

const mapDispatchToProps = (dispatch, { entityName, parameters }) => ({
  fetchAPIData: id => dispatch(createFetchData(entityName, parameters)(id)),
  clearValues: () => dispatch(createClearValues(entityName)()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPage);

EditPage.propTypes = {
  entityName: PropTypes.string.isRequired,
  parameters: customPropTypes.parameters.isRequired,
  fetchAPIData: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  clearValues: PropTypes.func.isRequired,
};
