/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 18/10/2018
 */
/**
 * @description Allows to access to a deep field inside an object.
 *  If at anytime the path doesn't exist anymore, it will return undefined.
 *
 *  This function is basically here to avoid the classic nightmare:
 *  obj && obj.param1 && obj.params1.param2 ...
 *
 *  Instead, you can type get(['param1', 'param2'], obj).
 *
 * @param {Array<string>} pathList List of field names to get access to the value.
 * @param {Object} object Object storing the data.
 * @return {*}
 */
export function get(pathList = [], object = {}) {
  const reducer = (currentObject, field) => {
    if (currentObject && field) {
      return currentObject[field];
    }
    return undefined;
  };
  return pathList.reduce(reducer, object);
}

/**
 * @description Allows to deep edit a field of an object given a path list.
 *  For example setting the value "huehue" in the object {} with the path list ['a', 'b', 'c']
 *  will return the following object: {a: {b: {c: 'huehue'}}}.
 *
 *  This function is pure.
 * @param {Array<string>} pathList Path list to access the field to edit.
 * @param {*} value Value to set.
 * @param {Object} object Object to edit
 */
export function set(pathList, value, object) {
  if (pathList && pathList.length === 0) {
    return object;
  }

  if (pathList.length === 1) {
    return {
      ...object,
      [pathList[0]]: value,
    };
  }

  if (!object) {
    return {
      [pathList[0]]: set(pathList.slice(1), value, {}),
    };
  }

  return {
    ...object,
    [pathList[0]]: set(pathList.slice(1), value, object[pathList[0]]),
  };
}

export function deepEqual(x, y) {
  const ok = Object.keys;
  const tx = typeof x;
  const ty = typeof y;
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length && ok(x).every(key => deepEqual(x[key], y[key]))
    : x === y;
}
