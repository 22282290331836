import EntityList from '../listPage/EntityList';

export default new EntityList(
  'channels',
  'meta/channels',
  {
    zidane: true,
  },
  undefined,
  undefined,
  'channels',
);
