import * as actions from './actions';
import { getReport } from '../thunks/api-reports';

// eslint-disable-next-line import/prefer-default-export
export function fetchReport(id, query) {
  return function _fetchReport(dispatch) {
    dispatch(actions.fetchReportPending());

    return dispatch(getReport(id, query))
      .then(response => response.getResult())
      .then(report => {
        dispatch(actions.fetchReportSuccess(report));
        dispatch(actions.setAvailableFilters(report.filters));
        dispatch(actions.setAppliedFilters(report.filters));
        return report;
      })
      .catch(error => {
        dispatch(actions.fetchReportFail());
        return error.message;
      });
  };
}
