/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 13/09/2018
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fieldsPropTypes } from '../../../../utils/customPropTypes';
import EntityList from '../EntityList';
import FilterSection from './FilterSection';
import List from './List';
import SearchBar from './SearchBar';

import FormAlert from '../../../../components/FormAlert';

import LoadMoreButton from '../../../../components/LoadMoreButton';

import './index.scss';

const ListPage = ({
  fetchList,
  fields,
  entity,
  errorMessage,
  getOnClickLink,
  loadMore,
  renderItemButtons,
  showLoadMore,
}) => {
  useEffect(() => {
    fetchList();
  }, [fetchList]);
  return (
    <div className="ListPage__main">
      <h1 className="ListPage__title">{entity.formattedName}</h1>
      <div className="ListPage__search">
        <SearchBar entity={entity} />
      </div>
      <div className="ListPage__filters">
        <FilterSection entity={entity} />
      </div>
      {errorMessage && <FormAlert className="ListPage__error" errorMessage={errorMessage} />}
      <List
        entity={entity}
        fields={fields}
        getOnClickLink={getOnClickLink}
        renderItemButtons={renderItemButtons}
      />

      {showLoadMore ? (
        <div className="ListPage__load_more">
          <LoadMoreButton onClick={loadMore} />
        </div>
      ) : null}
    </div>
  );
};

ListPage.propTypes = {
  entity: PropTypes.instanceOf(EntityList),
  errorMessage: PropTypes.string,
  fetchList: PropTypes.func.isRequired,
  fields: fieldsPropTypes.isRequired,
  getOnClickLink: PropTypes.func,
  loadMore: PropTypes.func.isRequired,
  showLoadMore: PropTypes.bool,
  renderItemButtons: PropTypes.func,
};

ListPage.defaultProps = {
  errorMessage: null,
  getOnClickLink: null,
  renderItemButtons: null,
  showLoadMore: false,
  entity: null,
};

const mapStateToProps = (state, { entity }) => ({
  errorMessage: entity.getErrorMessage(state),
  showLoadMore: entity.canLoadMore(state),
});

const mapDispatchToProps = (dispatch, { entity }) => ({
  fetchList: () => dispatch(entity.fetchList()),
  loadMore: () => dispatch(entity.loadMore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListPage);
