import React from 'react';
import ImportItems from '../../components/ImportItems/ImportItems';

const ImportVenueEventPage = () => (
  <ImportItems
    endpoint="/meta/import?type=venueEvents"
    templateEndpoint="/meta/import/template?type=venueEvents"
    description="Upload CSV file here to add fixtures to pubs"
  />
);

export default ImportVenueEventPage;
