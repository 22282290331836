/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */
import React from 'react';
import PropTypes from 'prop-types';

import InfoIcon from '../../InfoIcon';
import MpLink from '../../MpLink/MpLink';

import './index.scss';

const ReadOnly = ({ help, isLink, name, text }) => {
  const textToRender = text ? text.toString() : '-';

  return (
    <div className="TextField">
      <div className="TextField_content">
        <div className="TextField_content_name">{name}</div>
        <div className="TextField_content_text">
          {isLink ? (
            <MpLink to={text ? text.toString() : null} target="_blank">
              {textToRender}
            </MpLink>
          ) : (
            textToRender
          )}
        </div>
      </div>
      {help ? <InfoIcon tooltipPosition="bottom-left" tooltip={help} /> : null}
    </div>
  );
};

ReadOnly.propTypes = {
  help: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLink: PropTypes.bool,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

ReadOnly.defaultProps = {
  help: null,
  isLink: false,
  name: null,
  text: null,
};

export default ReadOnly;
