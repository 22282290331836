import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const TOTAL_TRANSITION_TIME = 1100;

/**
 * @params onAnimationEnd {function} Callback to call when the animation has finished.
 */

const AnimatedCheckbox = ({ onAnimationEnd }) => {
  useEffect(() => {
    if (onAnimationEnd) {
      // Dirty but wasn't able to wait for the end of the CSS3 Animation to trigger onAnimationEnd.
      setTimeout(onAnimationEnd, TOTAL_TRANSITION_TIME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <svg className="AnimatedCheckbox" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <g>
        <circle className="AnimatedCheckbox__circle" cx="26" cy="26" r="25" fill="none" />
        <path className="AnimatedCheckbox__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </g>
    </svg>
  );
};

AnimatedCheckbox.propTypes = {
  onAnimationEnd: PropTypes.func,
};

AnimatedCheckbox.defaultProps = {
  onAnimationEnd: null,
};

export default AnimatedCheckbox;
