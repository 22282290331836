import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import DownArrow from './down-arrow';

const MenuArrowButton = ({ toggleMenu, menuOpen, fill }) => (
  // keyboard_listener and Static HTML elements require a role.
  <button className="menu_button" type="button" onClick={() => toggleMenu()}>
    <div className={menuOpen ? 'menu_button_arrow_open' : 'menu_button_arrow'}>
      <DownArrow alt="open" fill={fill} />
    </div>
  </button>
);

export default MenuArrowButton;

MenuArrowButton.propTypes = {
  fill: PropTypes.string,
  menuOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

MenuArrowButton.defaultProps = {
  fill: undefined,
};
