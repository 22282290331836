import createListPage from '../listPage';
import eventsList from './entityList';

import tabs from '../../../routes/tabs';

const moment = require('moment');

const eventsListPage = createListPage(
  eventsList,
  {
    eventName: {
      label: 'Name',
    },
    foundEventId: {
      label: 'Found event id',
    },
    startTimeUtc: {
      label: 'Start time',
      renderValue: value => moment.utc(value).local().format('DD MMM YYYY - HH:mm'),
    },
  },
  {
    navTabs: tabs.eventsList,
  },
);

export default eventsListPage;
