/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 26/10/2018
 */

import memberList from './entityList';

import createListPage from '../listPage';

import { STRING, NUMBER } from '../listPage/fieldTypes';

import tabs from '../../../routes/tabs';

const memberListPage = createListPage(
  memberList,
  {
    id: {
      label: 'ID',
      type: NUMBER,
    },
    firstName: {
      label: 'First Name',
      type: STRING,
    },
    lastName: {
      label: 'Last Name',
      type: STRING,
    },
  },
  {
    navTabs: tabs.member,
  },
);

export default memberListPage;
