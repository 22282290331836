import { quizzes } from '../../games';
import createFormPage from '../formPage';

const getParams = id => ({
  endpoint: `games/quizzes/${id}/answers`,
  create: {
    fields: ['id', 'questionId', 'text', 'correct', 'active'],
  },
});
export default quizzes.map(q => createFormPage('quizAnswer', getParams(q.id)));
