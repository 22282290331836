import { quizzes } from '../../games';
import EntityList from '../listPage/EntityList';

export default quizzes.map(
  ({ id, entityName }) =>
    new EntityList(
      `${entityName}Rounds`,
      `games/quizzes/${id}/rounds`,
      {
        zidane: true,
      },
      undefined,
      undefined,
      'Quiz Rounds',
    ),
);
