/**            -                              ___
 *            |.|                          . /\_/\
 *          __]-[_________ /             .  (-<_>-)
 *         /        _____|<_          .   .  \/_\/
 *        / _   &  /               .   .
 *       / / \_ __|            .
 *     _/_/  / X   \
 *    <_/   /   ____\
 *         /___/|  /             zinedine zidane
 *         |  / ( <                --france--
 *         ( <   \ |
 *          \ |   >\
 *          _>|  (_/
 *         (__|
 *
 * MatchPint Ltd
 * @author Andreia Almeida
 * Date: 19/11/2018
 */

import leagueList from './entityList';

import createListPage from '../listPage';
import { NUMBER, STRING } from '../listPage/fieldTypes';

import tabs from '../../../routes/tabs';

const createPage = ({ entity, game }) =>
  createListPage(
    entity,
    {
      id: {
        label: 'Id',
        type: NUMBER,
      },
      name: {
        label: 'Name',
        type: STRING,
      },
      code: {
        label: 'Code',
        onPhone: false,
        type: STRING,
      },
      type: {
        label: 'Type',
        onPhone: false,
        type: STRING,
        renderValue: value => (value === 'private' ? 'Private' : 'System'),
      },
      ownerId: {
        label: 'Owner',
        onPhone: false,
        type: STRING,
      },
    },
    {
      navTabs: tabs[`${game.entityName}Leagues`],
    },
  );

export default leagueList.map(createPage);
