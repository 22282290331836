import React from 'react';

import PropTypes from 'prop-types';
import OnBoardingTile from '../../../../components/OnBoardingTile/OnBoardingTile';

const QuizOnboardingTile = ({ editedValue, onValueChange, fullResource }) => (
  <OnBoardingTile
    entity="quiz"
    entityId={fullResource.id}
    tiles={editedValue}
    onValueChange={onValueChange}
  />
);

QuizOnboardingTile.propTypes = {
  editedValue: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
    }),
  ),
  onValueChange: PropTypes.func.isRequired,
  fullResource: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

QuizOnboardingTile.defaultProps = {
  editedValue: [],
};
export default QuizOnboardingTile;
