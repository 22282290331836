/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import createFormPage from '../formPage';

const ImportedData = ({ importedData }) => (
  <>
    <b>Data imported:</b>
    <div className="Data">
      {Object.keys(importedData).map(k => (
        <div className="Data_row" key={k}>
          <div className="Data_cell">{k}</div>
          <div className="Data_cell">{importedData[k]}</div>
        </div>
      ))}
    </div>
  </>
);

ImportedData.propTypes = {
  importedData: PropTypes.string.isRequired,
};

const params = {
  endpoint: 'meta/import-status',
  sections: {
    General: [
      'jobId',
      'status',
      'attempts',
      {
        fieldPath: 'data',
        Component: ({ initialValue }) => <ImportedData importedData={initialValue} />,
      },
      {
        fieldPath: 'response',
        Component: ({ initialValue }) => (
          <div>
            <b>Response: </b>
            <div dangerouslySetInnerHTML={{ __html: initialValue }} />
          </div>
        ),
      },
    ],
  },
};
const importStatus = createFormPage('importStatus', params);

export default importStatus;
