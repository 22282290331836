import { getURLQuery } from '@matchpint/react-common';
import { push } from 'react-router-redux';
import createFormPage from '../formPage';
import { createSetSaveSuccessText } from '../formPage/store/actionCreatorFactory';

const entityName = 'offerCodeBulk';

const params = {
  endpoint: 'offers/redemption-code/bulk',
  create: {
    allFields: true,
  },
  api: {
    getDescriptionAPIEndpoint: (_, queryParams) => ({
      url: `offers/redemption-code/bulk/add${getURLQuery({
        ...queryParams,
        formDescription: 1,
      })}`,
      method: 'POST',
    }),
  },
  reduxExtension: {
    saveCreation: (saveCreationNormal, dispatch) =>
      saveCreationNormal(true, true).then(
        res =>
          res?.message &&
          dispatch(createSetSaveSuccessText(entityName)(res.message)) &&
          dispatch(push(`/offers/redemption-code?offerId=${res.offerId}`)),
      ),
  },
};

export default createFormPage(entityName, params);
