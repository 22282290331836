/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 08/06/2018
 */

import * as constants from './constants';

export function isLoading(state) {
  return state.changePassword.status === constants.CHANGE_PASSWORD_PENDING;
}

export function getError(state) {
  return state.changePassword.error;
}

export function getSuccessMessage(state) {
  return state.changePassword.message;
}
