import createFormPage from '../formPage';
import CountriesSelector from './component/countriesSelector';
import QuizOnboardingTile from './component/QuizOnboardingTile';

const params = {
  endpoint: 'games/quizzes',
  sections: {
    GENERAL: ['active', 'id', 'name', 'offerId', 'questionsPerRound', 'timePerQuestion'],
    'Terms and Conditions': [
      'termsConditionsTitle',
      'termsConditions',
      'termsConditionsDisclaimer',
    ],
    'Onboarding Tiles': [{ fieldPath: 'onBoardingTiles', Component: QuizOnboardingTile }],
    countries: [{ fieldPath: 'id', Component: CountriesSelector }],
    'Homepage Tile': ['style.homepageBackgroundImage'],
  },
};
const quiz = createFormPage('quiz', params);

export default quiz;
