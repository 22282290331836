/**            -                              ___
 *            |.|                          . /\_/\
 *          __]-[_________ /             .  (-<_>-)
 *         /        _____|<_          .   .  \/_\/
 *        / _   &  /               .   .
 *       / / \_ __|            .
 *     _/_/  / X   \
 *    <_/   /   ____\
 *         /___/|  /             zinedine zidane
 *         |  / ( <                --france--
 *         ( <   \ |
 *          \ |   >\
 *          _>|  (_/
 *         (__|
 *
 * MatchPint Ltd
 * @author Andreia Almeida
 * Date: 18/12/2018
 */

import EntityList from '../listPage/EntityList';
import { games } from '../../games';

export default games.map(game => ({
  entity: new EntityList(
    'gamePrize',
    `games/${game.game}/prizes`,
    {
      zidane: true,
    },
    undefined,
    undefined,
    'Game Prizes',
  ),
  game,
}));
