import EntityList from '../listPage/EntityList';

export default new EntityList(
  'assetGroup',
  'meta/asset-group',
  undefined,
  undefined,
  undefined,
  'Asset Group',
);
