/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */
import React from 'react';
import PropTypes from 'prop-types';

import * as moment from 'moment';

import DatePicker from 'material-ui/DatePicker';

import ClearButton from '../ClearButton';

import InfoIcon from '../InfoIcon';

import './index.scss';

const DatePickerEdit = ({ date, error, handleEditField, help, isPhone, name }) => {
  const momentDate = moment(date);

  const value = momentDate.isValid() ? momentDate.toDate() : null;

  return (
    <div className="DatePickerEdit__item">
      <div className="DatePickerEdit__item_content">
        <span>{name}</span>
        <div className="DatePickerEdit__item_content_picker">
          <DatePicker
            className="DatePickerEdit__item_content_picker_date"
            mode={isPhone ? null : 'landscape'}
            value={value}
            onChange={(event, newDate) => handleEditField(moment(newDate).format('YYYY-MM-DD'))}
            id={name}
          />
          {error && (
            <div className="DatePickerEdit__item_content_picker_error">
              {Array.isArray(error) ? error.join(' | ') : error}
            </div>
          )}
          <ClearButton
            className="DatePickerEdit__item_content_picker_cancel"
            clearableValue={!!value}
            handleCancel={() => handleEditField(null)}
          />
        </div>
      </div>
      {help && <InfoIcon tooltipPosition="bottom-left" tooltip={help} />}
    </div>
  );
};

DatePickerEdit.propTypes = {
  date: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  handleEditField: PropTypes.func.isRequired,
  help: PropTypes.string,
  isPhone: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

DatePickerEdit.defaultProps = {
  date: null,
  error: null,
  help: null,
  isPhone: true,
};

export default DatePickerEdit;
