import createFormPage from '../formPage';

export default createFormPage('permission', {
  endpoint: 'meta/pub-portal/permissions',
  sections: {
    General: [{ fieldPath: 'name' }, { fieldPath: 'code' }, { fieldPath: 'description' }],
  },
  create: {
    fields: ['name', 'code', 'description'],
  },
});
