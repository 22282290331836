import React from 'react';
import { PropTypes } from 'prop-types';
import { TextField, Checkbox } from 'material-ui';
import './styles.scss';
import moment from 'moment';

const MandateDetails = ({ fullResource }) => {
  const { active, goCardlessManadateCreationDate } = fullResource;

  const textFields = [
    'id',
    'venueId',
    'accountNumberEnding',
    'bankName',
    'goCardlessMandateId',
    'goCardlessBankAccountId',
  ];
  return (
    <>
      <p className="MandatePage_warning">All fields are read only</p>
      <Checkbox checked={active} label="Active" />

      <div className="MandatePage">
        {textFields.map(field => (
          <TextField
            className="MandatePage_field"
            value={fullResource[field]}
            placeholder={field}
            floatingLabelText={field}
          />
        ))}

        <TextField
          value={moment(goCardlessManadateCreationDate).format('DD/MM/YYYY HH:mm')}
          floatingLabelText="GC Mandate creation date"
        />
      </div>
    </>
  );
};

MandateDetails.propTypes = {
  fullResource: PropTypes.shape().isRequired,
};

export default MandateDetails;
