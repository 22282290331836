export function getReport(state) {
  return state.reports.report;
}

export function reportListIsLoading(state) {
  return state.reports.loading;
}

export function getAvailableFilters(state) {
  return state.reports.available;
}

export function getAppliedFilters(state) {
  return state.reports.applied;
}
