/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */

import { loginPending, loginSuccess, loginFailure } from '../actions';
import * as apiAuth from '../../thunks/api-auth';
import { loadAuthenticatedMemberSuccess } from '../../member/actions';

export function postLogin(member) {
  return function _postLogin(dispatch) {
    dispatch(loadAuthenticatedMemberSuccess(member));
  };
}

export function login(email, password) {
  return async function _login(dispatch) {
    dispatch(loginPending());
    return dispatch(apiAuth.login(email, password))
      .then(response => response.getResult())
      .then(result => {
        dispatch(loginSuccess(result));
        return dispatch(postLogin(result));
      })
      .catch(error => {
        dispatch(loginFailure(error.message));
      });
  };
}
