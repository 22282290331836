/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 18/04/2018
 */

/**
 * Return 'error', 'pending' or 'success', or `null` if login process hasn't been started.
 * @param state
 */
export function loginStatus(state) {
  return state.auth.login.status;
}

/**
 * Return a map from field name to the error string associated with that field name.
 * @param state
 * @returns {{
 *  fieldName: Object
 * }}
 */
export function loginFieldErrors(state) {
  return state.auth.login.fieldErrors;
}

/**
 * Any generic error associated with the login which isn't associated with a field.
 * @param state
 * @returns {string}
 */
export function loginErrorMessage(state) {
  return state.auth.login.errorMessage;
}
