/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 30/04/2018
 */

import { request } from './requests';

export function getMember(memberId) {
  return async function _getMember(dispatch) {
    return dispatch(request(`members/${memberId}`));
  };
}

export function clearDevicesForMember(memberId) {
  return async function _clearDevicesForMember(dispatch) {
    return dispatch(request(`members/${memberId}/remove-devices`, { method: 'DELETE' }));
  };
}

export const anonymiseMember = memberId => dispatch =>
  dispatch(request(`members/${memberId}/anonymise`, { method: 'PUT' }));

export const fetchUserPermissions = memberId => dispatch =>
  dispatch(request(`members/roles?limit=100&forMember=${memberId}`));

export const submitPassword = (memberId, password, passwordConfirm) => dispatch =>
  dispatch(
    request(`members/${memberId}/reset-password `, {
      method: 'PUT',
      body: {
        password,
        passwordConfirm,
      },
    }),
  );

export const clearCheckinPlay = (memberId, gameID, deleteCheckin) => dispatch =>
  dispatch(
    request(
      `games/${gameID}/clear-checkin-play?memberId=${memberId}&deleteCheckin=${Boolean(
        deleteCheckin,
      )}`,
    ),
  );

export const fetchLocales = memberId => dispatch =>
  dispatch(request(`/members/${memberId}/locales`));
