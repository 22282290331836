/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Avatar from 'material-ui/Avatar';
import List from 'material-ui/List';
import FlatButton from 'material-ui/FlatButton';
import Popover from 'material-ui/Popover/Popover';
import { Menu, MenuItem } from 'material-ui/Menu';
import ArrowDown from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import ArrowUp from 'material-ui/svg-icons/hardware/keyboard-arrow-up';
import MpLink from '../MpLink/MpLink';
import sideBarLinks from '../../utils/menuLinks';
import sizes from './index.scss';
import theme from '../../theme/index.scss';
import { getCurrentLocation, getNavbarOffset } from '../../store/navigation/selectors';
import SideBarItem from '../SideBarItem';
import { getPermissions } from '../../store/member/selectors';
import canUserAccessPage from '../../utils/permissions';
import { updateNavigationBarScroll } from '../../store/navigation/actions';

class SideBarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorOrigin: {
        horizontal: 'left',
        vertical: 'top',
      },
      targetOrigin: {
        horizontal: 'left',
        vertical: 'bottom',
      },
      anchorEl: null,
    };

    this.ref = createRef();
    this.handleClick = this.handleClick.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
  }

  componentDidMount() {
    const { offsetTop } = this.props;
    this.ref.current.scrollTop = offsetTop;
    this.ref.current.addEventListener('scroll', this);
  }

  componentWillUnmount() {
    this.ref.current.removeEventListener('scroll', this);
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleEvent() {
    const { updateSidebarScroll } = this.props;
    updateSidebarScroll(this.ref.current.scrollTop);
  }

  handleClick(event) {
    event.preventDefault();
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  }

  handleRequestClose() {
    this.setState({
      open: false,
    });
  }

  render() {
    const { member, signOut, currentRoute, userPermissions } = this.props;
    const { anchorEl, anchorOrigin, open, targetOrigin } = this.state;
    return (
      <div
        ref={this.ref}
        className={`NavSideBar__side_bar ${
          process.env.REACT_APP_DEV === 'true' ? 'development' : ''
        } ${process.env.REACT_APP_STAGING === 'true' ? 'staging' : ''}`}
      >
        <div className="NavSideBar__side_bar_top">
          <div className="NavSideBar__side_bar_top_logo">
            <MpLink to="/">
              <img
                src="/assets/yellow_transparent_logo.jpg"
                alt="logo"
                className="NavSideBar__side_bar_top_logo_self"
              />
            </MpLink>
          </div>
          <SideBarItem
            currentRoute={currentRoute}
            linkItem={{ link: process.env.REACT_APP_STAPI_URL, name: 'STRAPI' }}
          />

          <List className="NavSideBar__side_bar_top_links_container">
            {sideBarLinks
              .filter(elm =>
                elm.links.some(link => canUserAccessPage(userPermissions, link.limitedAccess)),
              )
              .map(linkItem => (
                <React.Fragment key={linkItem.name}>
                  <div className="NavSideBar__side_bar_separator">{linkItem.name}</div>
                  {linkItem.links.map(
                    link =>
                      canUserAccessPage(userPermissions, link.limitedAccess) && (
                        <SideBarItem
                          key={link.name}
                          currentRoute={currentRoute}
                          permissions={userPermissions}
                          linkItem={link}
                        />
                      ),
                  )}
                </React.Fragment>
              ))}
          </List>
        </div>
        <div className="NavSideBar__side_bar_options">
          <FlatButton
            className="NavSideBar__side_bar_options_flat_button"
            onClick={this.handleClick}
          >
            {member && (
              <Avatar
                className="NavSideBar__side_bar_options_avatar"
                size={parseInt(sizes.avatarRadius, 10)}
                src={member.profilePicture}
              />
            )}
            <div className="NavSideBar__side_bar_options_names">
              <span className="NavSideBar__side_bar_options_lastname">
                {member && member.lastName && member.lastName.toUpperCase()}
              </span>
              <span className="NavSideBar__side_bar_options_firstname">
                {member && member.firstName}
              </span>
            </div>
            {open ? (
              <ArrowUp className="NavSideBar__side_bar_options_icon" color={theme.white} />
            ) : (
              <ArrowDown className="NavSideBar__side_bar_options_icon" color={theme.white} />
            )}
          </FlatButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={anchorOrigin}
            targetOrigin={targetOrigin}
            onRequestClose={this.handleRequestClose}
          >
            <Menu>
              <MenuItem primaryText="Refresh" onClick={() => window.location.reload()} />
              <MenuItem primaryText="Sign out" onClick={() => signOut()} />
            </Menu>
          </Popover>
        </div>
      </div>
    );
  }
}

SideBarContent.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    profilePicture: PropTypes.string,
  }).isRequired,
  signOut: PropTypes.func.isRequired,
  currentRoute: PropTypes.string.isRequired,
  updateSidebarScroll: PropTypes.func.isRequired,
  offsetTop: PropTypes.number.isRequired,
  userPermissions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    }),
  ),
};

SideBarContent.defaultProps = {
  userPermissions: null,
};

const mapStateToProps = state => ({
  userPermissions: getPermissions(state),
  currentRoute: getCurrentLocation(state),
  offsetTop: getNavbarOffset(state),
});

const mapDispatchToProps = dispatch => ({
  updateSidebarScroll: val => dispatch(updateNavigationBarScroll(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBarContent);
