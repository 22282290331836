/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 13/11/2018
 */
import { get } from '../../../../../utils/object';
import FormError from '../FormError';

export function isCreatePageAvailable(entityName, parameters) {
  return !!get(['create'], parameters);
}

export function getButtonLabel(entityName, parameters) {
  return get(['create', 'buttonLabel'], parameters) || 'SAVE';
}

export function getSectionTitle(entityName, parameters) {
  return get(['create', 'title'], parameters) || `NEW ${entityName.toUpperCase()}`;
}

export function getFieldsToDisplay(entityName, parameters) {
  const fields = get(['create', 'fields'], parameters);

  if (!fields) {
    throw new FormError(
      `You need to provide some fields (or add property "allFields: true" inside 'create' ) to render a valid "Create" page for the form "${entityName}"`,
    );
  }

  return fields;
}

export function getDefaultFields(entityName, parameters) {
  return get(['create', 'defaultFields'], parameters);
}
