/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 21/05/2018
 */
import { getURLQuery } from '@matchpint/react-common';
import listReducer from './reducers';
import * as actions from './actions';
import * as constants from './constants';
import * as apiCall from './apiCall';
import MpResponse from '../../../../utils/apiRequest/MpResponse';
import { getServicesEndpoint } from '../../../../utils/environment';

// TODO: inject directly the selectors / actions / thunks inside the ListPage when needed.

/**
 * @description This class provides the tools necessary to declare in Redux and
 * to use the usual functions needed to implement a list.
 * It implements:
 *  - a Redux state structure;
 *  - the Micro-service calls to get a list and its total size;
 *  - a filter implementation.
 */

const SEARCH_FILTER_NAME = 'search';
class EntityList {
  /**
   * @description Constructor
   * @param {string} name Name of the list declared in `src/store/List.js`
   * @param {string} endpoint Micro-service to call for this route.
   * @param {Object} defaultQueryParameters Default parameters to apply to the endpoint
   *  (useful for eager loading).
   * @param {function} baseSelector allow to provide a base selector
   * different that the base of the state tree.
   * @param {Object} defaultFilterQueryParameters Default parameters to apply when fetching filters.
   */
  constructor(
    name,
    endpoint,
    defaultQueryParameters = {},
    baseSelector = null,
    defaultFilterQueryParameters = {},
    formattedName = name,
  ) {
    if (endpoint.charAt(0) === '/') {
      throw new Error(
        `Entity endpoints should not start with /, try search your project for ${endpoint}`,
      );
    }
    this.entityName = name;
    this.endpoint = endpoint;
    this.defaultQueryParameters = defaultQueryParameters;
    this.getListFromApi = apiCall.getListFromAPI(this.endpoint, defaultQueryParameters);
    this.getFiltersFromApi = apiCall.getFiltersFromAPI(this.endpoint, defaultFilterQueryParameters);
    this.formattedName = formattedName;

    if (baseSelector === null) {
      this.baseSelector = state => state.list[this.entityName];
    } else {
      this.baseSelector = state => baseSelector(state)[this.entityName];
    }
  }

  /**
   * @description Returns the reducer associated to the list for Redux's reducer declaration.
   */
  getReducer() {
    return listReducer(this.entityName);
  }

  /**
   * @description Returns the thunk responsible for loading the filters of the list.
   */
  fetchFiltersAndSortBy() {
    return async function _fetchFilters(dispatch) {
      dispatch(actions.fetchFiltersPending(this.entityName)());
      return dispatch(this.getFiltersFromApi())
        .then(response => {
          if (!response.isOK()) {
            return Promise.reject(response);
          }
          return { filters: response.getFilters(), sortColumns: response.getSortOptions() };
        })
        .then(({ filters, sortColumns }) => {
          dispatch(actions.fetchFiltersSuccess(this.entityName)({ filters, sortColumns }));
          return { filters, sortColumns };
        })
        .catch(error => {
          const errorMessage = error instanceof MpResponse ? error.data.error : error.message;
          dispatch(actions.fetchFiltersFailure(this.entityName)(errorMessage));
        });
    }.bind(this);
  }

  exportItems(quantity) {
    return async function _exportItems(dispatch, getState) {
      if (Object.keys(this.getAvailableFilters(getState(), true)).length === 0) {
        await this.fetchFiltersAndSortBy()(dispatch);
      }

      const activeFilters = this.getActiveFilters(getState());
      const limit = this.getListPageSize(getState());
      const offset = this.getListPageOffset(getState());

      window.open(
        `${getServicesEndpoint()}${this.endpoint}${getURLQuery({
          ...this.defaultQueryParameters,
          ...activeFilters,
          limit,
          offset,
          numberOfMatches: true,
          export: quantity,
        })}`,
      );
    }.bind(this);
  }

  /**
   * @description Returns the thunk responsible for loading the element of the list.
   */
  fetchList() {
    return async function _fetchList(dispatch, getState) {
      if (Object.keys(this.getAvailableFilters(getState(), true)).length === 0) {
        await this.fetchFiltersAndSortBy()(dispatch);
      }

      // Initial load of the list.
      const activeFilters = this.getActiveFilters(getState());
      const activeSortBy = this.getActiveSortBy(getState());
      const limit = this.getListPageSize(getState());
      const offset = 0;

      const querySortBy = {};
      // Only one sort by at the same time.
      // Might be null though.
      const sortColumn = Object.keys(activeSortBy)[0];
      if (activeSortBy[sortColumn]) {
        querySortBy.sortColumn = sortColumn;
        querySortBy.sortOrder = activeSortBy[sortColumn];
      }

      dispatch(actions.fetchListPending(this.entityName)());
      return dispatch(
        this.getListFromApi({
          ...activeFilters,
          ...querySortBy,
          limit,
          offset,
          numberOfMatches: true,
        }),
      )
        .then(response => {
          const result = Array.isArray(response.getResult())
            ? response.getResult()
            : Object.values(response.getResult());
          const count = response.getListCount() || result.length;
          return {
            result,
            count,
          };
        })
        .then(({ result, count }) => {
          dispatch(actions.fetchListSuccess(this.entityName)({ result, count }));
          return { result, count };
        })
        .catch(error => {
          const errorMessage = error instanceof MpResponse ? error.data.error : error.message;
          dispatch(actions.fetchListFailure(this.entityName)(errorMessage));
        });
    }.bind(this);
  }

  /**
   * @description Returns the thunk responsible for loading more elements of the list.
   */
  loadMore() {
    return async function _loadMore(dispatch, getState) {
      const activeFilters = this.getActiveFilters(getState());
      const activeSortBy = this.getActiveSortBy(getState());
      const limit = this.getListPageSize(getState());
      const offset = this.getListPageOffset(getState()) + limit;

      const querySortBy = {};
      // Only one sort by at the same time.
      // Might be null though.
      const sortColumn = Object.keys(activeSortBy)[0];
      if (activeSortBy[sortColumn]) {
        querySortBy.sortColumn = sortColumn;
        querySortBy.sortOrder = activeSortBy[sortColumn];
      }

      dispatch(actions.fetchListPending(this.entityName)());
      return dispatch(
        this.getListFromApi({
          ...activeFilters,
          ...querySortBy,
          limit,
          offset,
        }),
      )
        .then(response => {
          if (!response.isOK()) {
            return Promise.reject(response);
          }
          return response.getResult();
        })
        .then(result => {
          dispatch(actions.fetchLoadMore(this.entityName)(result));
          return result;
        })
        .catch(error => {
          const errorMessage = error instanceof MpResponse ? error.data.error : error.message;
          dispatch(actions.fetchListFailure(this.entityName)(errorMessage));
        });
    }.bind(this);
  }

  /**
   * @description Returns the thunk responsible for adding / modifying a filter to the list.
   * @param {string} id Id of the filter to add / change.
   * @param {any} value Value of the filter.
   */
  filterBy(id, value, isMulti) {
    return function _filterBy(dispatch, getState) {
      const actionFunction = isMulti ? actions.addMultiFilterAction : actions.addFilterAction;
      dispatch(actionFunction(this.entityName)(id, value));
      return this.fetchList()(dispatch, getState);
    }.bind(this);
  }

  /**
   * @description Returns the thunk responsible for removing a filter previously applied to
   *  the list.
   * @param {string} filterId Id of the filter to remove.
   */
  removeFilter(filterId) {
    return function _removeFilter(dispatch, getState) {
      dispatch(actions.removeFilterAction(this.entityName)(filterId));
      return this.fetchList()(dispatch, getState);
    }.bind(this);
  }

  /**
   * @description Returns the thunk to add a sortBy option. If the sort order is null,
   * it will not be taken into account.
   * @param sortColumn
   * @param sortOrder
   * @return {any}
   */
  sortBy(sortColumn, sortOrder) {
    return function _sortBy(dispatch, getState) {
      // If the sort by is already present, we nullify it.
      // It corresponds to the user clicking twice on the sort,
      // cancelling it.
      let filteredSortOrder = sortOrder;
      const activeSortBy = this.getActiveSortBy(getState());
      if (activeSortBy[sortColumn] && activeSortBy[sortColumn] === sortOrder) {
        filteredSortOrder = null;
      }
      dispatch(actions.addSortByAction(this.entityName)(sortColumn, filteredSortOrder));
      return this.fetchList()(dispatch, getState);
    }.bind(this);
  }

  /**
   * @description Returns the list of elements.
   * @param {Object} state Redux global state.
   * @return {Array|null}
   */
  getList(state) {
    return this.baseSelector(state).fetch.result;
  }

  /**
   * @description Returns if the list is currently fetching some elements.
   * @param {Object} state Redux global state.
   * @returns {boolean}
   */
  isListFetching(state) {
    return this.baseSelector(state).fetch.status === constants.FETCH_LIST_PENDING;
  }

  /**
   * @description Returns the potential error message received by the Micro-service.
   * @param {Object} state Redux global state.
   * @returns {string|null}
   */
  getErrorMessage(state) {
    return this.baseSelector(state).fetch.error
      ? this.baseSelector(state).fetch.error.message
      : null;
  }

  /**
   * @description Returns the total number of elements loadable in the list.
   * @param {Object} state Redux global state.
   * @returns {number|null}
   */
  getListCount(state) {
    return this.baseSelector(state).fetch.count;
  }

  /**
   * @description Returns the filters currently applied to the list.
   * @param {Object} state Redux global state.
   * @returns {Object}
   */
  getActiveFilters(state) {
    return this.baseSelector(state).filters.active;
  }

  /**
   * @description Returns the available filters to the list (except the search).
   * @param {Object} state Redux global state.
   * @param includeSearch
   * @returns {Object}
   */
  getAvailableFilters(state, includeSearch = false) {
    // Remove the search from the available filters.
    const { baseSelector } = this;
    const filtersWithoutSearch = { ...baseSelector(state).filters.available };

    // If there is a "search" filter available, don't display it in the normal filters
    // (display it as a search bar)
    if (
      Object.hasOwnProperty.call(filtersWithoutSearch, SEARCH_FILTER_NAME) &&
      includeSearch === false
    ) {
      delete filtersWithoutSearch[SEARCH_FILTER_NAME];
    }

    return filtersWithoutSearch;
  }

  /**
   * @description Returns if the filters are currently fetching.
   * @param {Object} state Redux global state.
   * @returns {Object}
   */
  areFiltersLoading(state) {
    return this.baseSelector(state).filters.status === constants.FETCH_FILTERS_PENDING;
  }

  /**
   * @description Returns all active sort by.
   * @param state
   */
  getActiveSortBy(state) {
    return this.baseSelector(state).sortBy.active;
  }

  /**
   * @description Returns all available sort by.
   * @param state
   */
  getAllAvailableSortBy(state) {
    return this.baseSelector(state).sortBy.available;
  }

  /**
   * @description Returns if there are still some elements that can be fetched
   *  from the API.
   * @param {Object} state Redux global state.
   * @returns {boolean}
   */
  canLoadMore(state) {
    if (this.baseSelector(state).fetch.result === null) {
      return false;
    }

    if (this.baseSelector(state).fetch.count === null) {
      return true;
    }

    return this.baseSelector(state).fetch.result.length < this.baseSelector(state).fetch.count;
  }

  /**
   * @description Returns the information about a possible search that can be applied to the list.
   * @param {Object} state Redux global state.
   * @returns {Object|null}
   */
  getSearchInfo(state) {
    if (
      Object.hasOwnProperty.call(this.baseSelector(state).filters.available, SEARCH_FILTER_NAME)
    ) {
      const searchValue = Object.hasOwnProperty.call(
        this.baseSelector(state).filters.active,
        SEARCH_FILTER_NAME,
      )
        ? this.baseSelector(state).filters.active[SEARCH_FILTER_NAME]
        : null;
      return {
        ...this.baseSelector(state).filters.available[SEARCH_FILTER_NAME],
        value: searchValue,
      };
    }
    return null;
  }

  /**
   * Get the current search value, if there is one and search is available & active.
   * @param state
   * @return {null|String}
   */
  getSearchValue(state) {
    if (
      this.baseSelector(state).filters.available &&
      this.baseSelector(state).filters.available[SEARCH_FILTER_NAME]
    ) {
      if (this.baseSelector(state).filters.active) {
        return this.baseSelector(state).filters.active[SEARCH_FILTER_NAME];
      }
      return null;
    }
    return null;
  }

  /**
   * @description Return the size of each page that is loaded in the list.
   * @param {Object} state Redux global state.
   * @returns {number}
   */
  getListPageSize(state) {
    return this.baseSelector(state).fetch.pageSize;
  }

  /**
   * @description Return the last offset that has been loaded for the list.
   * @param {Object} state Redux global state.
   * @returns {number}
   */
  getListPageOffset(state) {
    return this.baseSelector(state).fetch.offset;
  }

  /**
   * @description Determines if the search bar can be shown.
   * @param state
   * @return {Object|boolean}
   */
  isSearchAvailable(state) {
    return this.areFiltersLoading(state) || this.getSearchInfo(state) !== null;
  }

  /**
   * @description Returns the current search that is active.
   * @param state
   * @return {*|string}
   */
  getSearchQuery(state) {
    return this.getActiveFilters(state)[SEARCH_FILTER_NAME] || '';
  }

  /**
   * @description Cancels the current search.
   * @return {function}
   */
  cancelSearch() {
    return function _cancelSearch(dispatch) {
      return dispatch(this.removeFilter(SEARCH_FILTER_NAME));
    }.bind(this);
  }

  /**
   * @description Applies a search query to the list.
   * @param query
   * @return {function(*): *}
   */
  search(query) {
    return function _search(dispatch) {
      return dispatch(this.filterBy(SEARCH_FILTER_NAME, query));
    }.bind(this);
  }
}

export default EntityList;
