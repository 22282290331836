/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 30/07/2018
 */
import React from 'react';
import PropTypes from 'prop-types';

import { TableHeaderColumn } from 'material-ui/Table';

import SortAscIcon from 'material-ui/svg-icons/navigation/arrow-drop-up';
import SortDescIcon from 'material-ui/svg-icons/navigation/arrow-drop-down';

import colors from '../../theme/index.scss';
import './index.scss';

export const ASC = 'asc';
export const DESC = 'desc';

const TableHeaderColumnWithSort = ({
  availableSortBy,
  activeSortBy,
  className,
  columnName,
  fieldName,
  handleSort,
  style,
}) => {
  const selected = activeSortBy[fieldName];
  const withSort = fieldName in availableSortBy;
  return (
    <TableHeaderColumn className={className} style={style}>
      <div className="TableHeaderColumnWithSort__name">{columnName}</div>
      {columnName && withSort ? (
        <div className="TableHeaderColumnWithSort__sort_wrapper">
          <SortAscIcon
            className="TableHeaderColumnWithSort__sort_asc"
            color={selected === ASC ? colors.lightGrey : colors.black}
            onClick={() => handleSort(fieldName, ASC)}
          />
          <SortDescIcon
            className="TableHeaderColumnWithSort__sort_desc"
            onClick={() => handleSort(fieldName, DESC)}
            color={selected === DESC ? colors.lightGrey : colors.black}
          />
        </div>
      ) : null}
    </TableHeaderColumn>
  );
};

TableHeaderColumnWithSort.propTypes = {
  activeSortBy: PropTypes.shape(),
  availableSortBy: PropTypes.shape(),
  className: PropTypes.string,
  columnName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  fieldName: PropTypes.string,
  handleSort: PropTypes.func,
  style: PropTypes.shape(),
};

TableHeaderColumnWithSort.defaultProps = {
  activeSortBy: {},
  availableSortBy: {},
  className: null,
  columnName: null,
  fieldName: null,
  handleSort: () => {},
  style: {},
};

export default TableHeaderColumnWithSort;
