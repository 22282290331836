/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */

import campaignsList from './entityList';

import createListPage from '../listPage';

import { NUMBER } from '../listPage/fieldTypes';
import tabs from '../../../routes/tabs';

const campaignListPage = createListPage(
  campaignsList,
  {
    id: {
      label: 'ID',
      type: NUMBER,
      style: {
        width: '5%',
      },
    },
    name: {
      label: 'Name',
    },
  },
  {
    getViewLink: null,
    navTabs: tabs.campaign,
  },
);

export default campaignListPage;
