import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'material-ui/Avatar';
import Divider from 'material-ui/Divider';
import Alert from 'material-ui/svg-icons/alert/warning';
import { FlatButton } from 'material-ui';
import colors from '../../theme/index.scss';

import './index.scss';

const MpModal = ({ visible, mainText, subText, warningText, onConfirm, onCancel, children }) =>
  visible ? (
    <div className="modal">
      <div className="content">
        <div className="content_header">
          <Avatar className="content_header_avatar" icon={<Alert />} />
        </div>
        {mainText && <p className="pt-6 text-sm text-standardGrey">{mainText}</p>}
        {subText && <p className="py-2 text-center">{subText}</p>}
        {warningText && (
          <div className="content_errorText">
            <Divider className="content_errorText_divider" />
            <p className="content_errorText_message">{warningText}</p>
          </div>
        )}
        {children}
        <div className="content_buttons">
          <FlatButton
            backgroundColor={colors.darkRed}
            className="content_buttons_submit"
            hoverColor={colors.primaryRed}
            style={{ color: colors.black }}
            label="SUBMIT"
            onClick={() => onConfirm()}
          />
          <FlatButton
            className="content_buttons_cancel"
            secondary
            label="CANCEL"
            onClick={() => onCancel()}
          />
        </div>
      </div>
    </div>
  ) : null;

MpModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  mainText: PropTypes.string,
  subText: PropTypes.string,
  warningText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.node,
};

MpModal.defaultProps = {
  mainText: null,
  subText: null,
  warningText: null,
  children: null,
};

export default MpModal;
