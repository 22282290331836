/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 24/05/2018
 */
import React from 'react';
import PropTypes from 'prop-types';

import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import Close from 'material-ui/svg-icons/navigation/close';
import { connect } from 'react-redux';

import { getGlobalErrorType, isGlobalErrorDisplayed } from '../../store/globalError/selectors';
import {
  dismissGlobalError,
  goToHomepage as goToHomepageThunk,
} from '../../store/globalError/thunks/globalError';

import './index.scss';

export const TYPE_CRASH = 'globalError/crash';
export const TYPE_UNAUTHORIZED = 'globalError/unauthorized';
export const TYPE_SERVER_ERROR = 'globalError/server_error';

class ErrorModal extends React.Component {
  getDescription() {
    const { type } = this.props;

    switch (type) {
      case TYPE_CRASH:
        return 'Sorry but it seems like Zidane crashed… You will be sent back to HomePage.';
      case TYPE_UNAUTHORIZED:
        return 'Sorry but it seems like an error happened, we will need to log you out.';
      case TYPE_SERVER_ERROR:
        return 'Sorry but a server error happened. Please click below to return to the homepage or try again.';
      default:
        return 'An error occurred…';
    }
  }

  renderActionButtons() {
    const { type, goToHomepage } = this.props;

    let firstButtonLabel;
    let secondButtonLabel;

    switch (type) {
      case TYPE_CRASH:
        firstButtonLabel = 'Go back to the Homepage';
        break;
      case TYPE_UNAUTHORIZED:
        firstButtonLabel = 'Go back to the Login Page';
        break;
      case TYPE_SERVER_ERROR:
        firstButtonLabel = 'Go back to the Homepage';
        secondButtonLabel = 'Reload';
        break;
      default:
        firstButtonLabel = 'Close';
    }

    return (
      <>
        <FlatButton label={firstButtonLabel} onClick={() => goToHomepage()} secondary />
        {secondButtonLabel && (
          <FlatButton
            label={secondButtonLabel}
            secondary
            onClick={() => window.location.reload()}
          />
        )}
      </>
    );
  }

  render() {
    const { close, open, type } = this.props;

    if (!type) {
      return <div />;
    }

    return (
      <Dialog
        actions={this.renderActionButtons()}
        className="ErrorModal"
        onRequestClose={close}
        open={open}
        modal
        title="An Error happened"
      >
        <Close onClick={close} className="ErrorModal__close" />
        <div>
          {this.getDescription()}
          <br />
          {'If this error persists, please contact the Tech Team on this '}
          <a className="ErrorModal__link" href="https://trello.com/b/q2fubwFz/tech-support">
            Trello board
          </a>
          {' or by '}
          <a
            className="ErrorModal__link"
            href="mailto:dev@matchpint.co.uk?subject='Crash on Zidane'"
          >
            email
          </a>
          .
          <div
            className="ErrorModal__image"
            style={{
              backgroundImage: 'url(https://www.matchpint.co.uk/img/assets/zidane-error-crash.jpg)',
            }}
          />
        </div>
      </Dialog>
    );
  }
}

ErrorModal.propTypes = {
  goToHomepage: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  open: PropTypes.bool,
  type: PropTypes.oneOf([TYPE_UNAUTHORIZED, TYPE_CRASH, TYPE_SERVER_ERROR]),
};

ErrorModal.defaultProps = {
  open: false,
  type: null,
};

const mapStateToProps = state => ({
  type: getGlobalErrorType(state),
  open: isGlobalErrorDisplayed(state),
});

const mapDispatchToProps = {
  close: dismissGlobalError,
  goToHomepage: goToHomepageThunk,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
