import React from 'react';
import PropTypes from 'prop-types';
import AutoInAndOutSelector from '../../../../components/AutoInAndOutSelector';

const CompetitionsSelector = ({ editedValue }) => (
  <AutoInAndOutSelector
    activeItemsString="Active competitions"
    availableItemsString="Add competitions"
    formatName={data => data.map(item => ({ id: item.id, name: item.name }))}
    endpoints={{
      fetchActive: `/sports/competitions?inTeamId=${editedValue}&`,
      fetchAvailable: `/sports/competitions?outTeamId=${editedValue}&`,
      add: `sports/teams/${editedValue}/competitions/add`,
      remove: `sports/teams/${editedValue}/competitions/remove`,
    }}
  />
);

CompetitionsSelector.propTypes = {
  editedValue: PropTypes.number.isRequired,
};

export default CompetitionsSelector;
