/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 22/05/2018
 */
import EntityList from '../listPage/EntityList';

/**
 * @description Campaigns list Entity.
 * @type {EntityList}
 */
const campaignsList = new EntityList(
  'campaign',
  'campaigns/',
  {
    loadBrandDetails: true,
    withOptins: true,
  },
  undefined,
  undefined,
  'Campaigns',
);

export default campaignsList;
