/* eslint-disable react/destructuring-assignment */
/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /                  ..
 *           \          /                  ( '`<
 *            )       /`                    )(
 *           /      /`               ( ----'  '.
 * Author: Marwan                    (         ;
 * Date: 04/10/2018                   (_______,'
 *                           ~^~^~^~^~Ed made it worse^~^~^~^~^~^~
 */

import React from 'react';

import { debounce } from 'lodash';
import autobind from 'react-autobind';
import './index.scss';

import PropTypes from 'prop-types';
import AutoComplete from 'material-ui/AutoComplete';
import EditIcon from 'material-ui/svg-icons/image/edit';
import Plus from 'material-ui/svg-icons/content/add-circle';

import { FlatButton } from 'material-ui';
import LoadingWheel from '../LoadingWheel';
import { routes as formRoutes } from '../../template/forms';
import { routes as listRoutes } from '../../template/lists';

import ClearButton from '../ClearButton';

import { dataSourcePropTypes, formatDataSourcePropTypes } from './propTypes';

import calculateDataSource from './calculateDataSource';

/**
 * @param dataSource {function|Array|Object} Source of data that will be displayed in
 *  the AutoComplete.
 *  If dataSource is an Array/Object, the data will be displayed directly using formatDataSource.
 *  If dataSource is a function, it will take one string parameter "searchText" and should return
 *  a micro-service endpoint to call (string).
 * @param errorMessage {string} Error message to display by default.
 * @param formatDataSource {function} Function that based on a dataSource (or the result of a
 *  micro-service call) will return a list of Object { text: string, value: string|number }.
 * @param fullWidth {boolean} if true, the AutoComplete text field will have a 100% style width.
 * @param label {string} Name of the field (to be displayed).
 * @param onValueChange {function} Function to call when the user selects a proposed value.
 * @param searchText {string|number} Initial text to be displayed. If dataSource is a
 *  function, put the original value of the field and the Autocomplete will replace
 *  it with the associated text if possible.
 * @param editEntityRoute {string} If this route exists in formRoutes or listRoutes, a button that
 *  navigates to the entity's edit page will be displayed next to the Autocomplete field. If the
 *  route exists but entity ID is invalid, offer a create button instead.
 * @param debounceTime {number} amount of miliseconds to debounce the API calls after typing
 */

class AutoCompleteTextField extends React.Component {
  constructor(props) {
    super(props);
    const { errorMessage, searchText } = props;
    this.state = {
      error: errorMessage,
      searchText,
      fetchingData: false,
      dataSource: [],
    };

    autobind(this);
    this.delayedHandleChange = debounce(this.onFetchFromDataSource, props.debounceTime);
  }

  async componentDidMount() {
    const newState = await this.getSearchAndDataSourceFromProps();
    this.setState(newState);
  }

  shouldComponentUpdate(nextProps, nextState) {
    // only re-render component if state or selected Value changes - this prevents unnecessary
    // API calls when using a component other than the Autocomplete
    return nextState !== this.state || nextProps.searchText !== this.props.searchText;
  }

  async componentDidUpdate(prevProps) {
    const { dataSource, errorMessage, searchText } = this.props;

    const newState = {};

    // If some changes are made to the searchText or dataSource, we update the state's.
    if (prevProps.searchText !== searchText || prevProps.dataSource !== dataSource) {
      const { searchText: newSearchText, dataSource: newDataSource } =
        await this.getSearchAndDataSourceFromProps();

      newState.searchText = newSearchText;
      newState.dataSource = newDataSource;
      newState.error = null;
    }

    if (prevProps.errorMessage !== errorMessage) {
      newState.error = errorMessage;
    }

    if (Object.keys(newState).length > 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(newState);
    }
  }

  handleChangeWithDebounce(event) {
    event.persist(); // persists event for debounce to work correctly
    this.setState({ fetchingData: true });
    this.delayedHandleChange();
  }

  /**
   * @description Callback triggered when the user changes the text of the Field.
   */
  async onFetchFromDataSource() {
    const { searchText } = this.state;
    const { dataSource, formatDataSource } = this.props;

    // Update the state's dataSource based on the new searchText.
    try {
      const newDataSource = await calculateDataSource(searchText, {
        dataSource,
        formatDataSource,
      });
      return this.setState({ fetchingData: false, dataSource: newDataSource });
    } catch (error) {
      return this.setState({ error: error.message, fetchingData: false, dataSource: [] });
    }
  }

  onNewRequest(_, index) {
    // Callback triggered when the user clicks on one of the proposed values.
    const { dataSource } = this.state;

    if (index !== -1) {
      this.setState(
        { error: null, searchText: this.props.clearOnSubmit ? null : dataSource[index].text },
        () => {
          this.props.onValueChange(dataSource[index].value);
        },
      );
    }
  }

  onClickOutside() {
    const { dataSource, searchText } = this.state;

    if (this.props.searchText) {
      // if a value is selected and the input focused - do nothing when the user clicks away
      return;
    }

    if (dataSource.length >= 1 && !this.props.searchText) {
      // if stuff was found but nothing selected
      if (searchText !== dataSource[0].text) {
        this.setState({
          error: 'Please select one of the proposed values.',
        });
        return;
      }
    }

    if (dataSource.length === 0 && !this.props.searchText && this.state.searchText) {
      // if nothing was found and field unselected
      this.setState({ error: 'Nothing was found, please try again.' });
      return;
    }

    this.setState({ error: null });
  }

  /**
   * @description Calculates the new searchText and new dataSource to store in the local State.
   * @return {Promise<{ searchText: string, dataSource: Array}>}
   */
  async getSearchAndDataSourceFromProps() {
    const { dataSource, formatDataSource, searchText } = this.props;

    // We calculate the new data source based on the searchText.
    let newDataSource = [];
    try {
      newDataSource = await calculateDataSource(searchText, {
        dataSource,
        formatDataSource,
      });
    } catch (error) {
      this.setState({ error: error.message });
    }

    const newSearchText =
      newDataSource.length > 0 && searchText === newDataSource[0].value
        ? newDataSource[0].text
        : searchText;

    return {
      searchText: newSearchText,
      dataSource: newDataSource,
    };
  }

  render() {
    const { fullWidth, label, disabled, onValueChange, editEntityRoute } = this.props;
    const { dataSource, searchText, error, fetchingData } = this.state;

    const getEditOrCreateButton = () => {
      const mode = this.props.searchText ? 'edit' : 'create';

      const getRoute = () => {
        //  `/flamingos/` => `flamingos/edit` or `flamingos/create`
        const route =
          editEntityRoute.substr(-1) !== '/'
            ? `${editEntityRoute}/${mode}`
            : `${editEntityRoute}${mode}`;
        return route.charAt(0) === '/' ? route.slice(1, route.length) : route;
      };

      // if route exists - render edit/create button
      if ([...formRoutes, ...listRoutes].find(obj => obj.path === getRoute())) {
        const getEntityName = () => {
          if (this.props.label) {
            return this.props.label.includes('id')
              ? this.props.label.split('id')[0]
              : this.props.label;
          }
          return 'entity ';
        };

        const buttonConfig =
          mode === 'edit'
            ? {
                icon: <EditIcon className="AutoComplete_editButton_icon" />,
                text: `Edit ${getEntityName()}`,
                route: `/${getRoute()}?id=${this.props.searchText}`,
              }
            : {
                route: `/${getRoute()}`,
                text: `Create ${getEntityName()}`,
                icon: <Plus className="AutoComplete_editButton_icon" />,
              };

        return (
          <FlatButton
            className="AutoComplete_editButton"
            icon={buttonConfig.icon}
            href={buttonConfig.route}
            target="_blank"
          >
            {buttonConfig.text}
          </FlatButton>
        );
      }
      return null;
    };

    return (
      <div className="AutoComplete">
        <div className="AutoComplete_textarea">
          <AutoComplete
            dataSource={dataSource}
            errorText={error}
            disabled={disabled}
            floatingLabelText={label}
            filter={AutoComplete.noFilter}
            fullWidth={fullWidth}
            menuProps={{
              maxHeight: 300,
            }}
            onClose={this.onClickOutside}
            onNewRequest={this.onNewRequest}
            openOnFocus
            onKeyUp={this.handleChangeWithDebounce}
            // store searchText for UI purposes +  to be accessed in delayedHandleChange
            onUpdateInput={text => this.setState({ searchText: text })}
            searchText={searchText ? searchText.toString() : ''}
          />
          <div className="AutoComplete_loadingContainer">
            {fetchingData && <LoadingWheel size="inline" />}
          </div>
        </div>
        <div className="AutoComplete_clearbutton">
          <ClearButton
            clearableValue={Boolean(searchText)}
            handleCancel={() => this.setState({ searchText: null }, () => onValueChange(null))}
          />
        </div>
        {editEntityRoute && getEditOrCreateButton()}
      </div>
    );
  }
}

AutoCompleteTextField.propTypes = {
  disabled: PropTypes.bool,
  dataSource: dataSourcePropTypes,
  errorMessage: PropTypes.string,
  formatDataSource: formatDataSourcePropTypes,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
  searchText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editEntityRoute: PropTypes.string,
  debounceTime: PropTypes.number,
  clearOnSubmit: PropTypes.bool,
};

AutoCompleteTextField.defaultProps = {
  disabled: false,
  dataSource: [],
  debounceTime: 400,
  errorMessage: null,
  formatDataSource: entities =>
    entities.map(entity => ({
      text: `${entity.name} (${entity.id})`,
      value: entity.id,
    })),
  fullWidth: null,
  label: '',
  searchText: null,
  editEntityRoute: null,
  clearOnSubmit: false,
};

export default AutoCompleteTextField;
