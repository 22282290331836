/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 01/11/2018
 */
import React from 'react';
import PropTypes from 'prop-types';

import { Avatar } from 'material-ui';
import { List, ListItem } from 'material-ui/List';

import './style.scss';
import Plus from 'material-ui/svg-icons/content/add-circle-outline';
import Minus from 'material-ui/svg-icons/content/remove-circle-outline';
import LoadMoreButton from '../LoadMoreButton';
import MpSearchBar from '../MpSearchBar';
import LoadingWheel from '../LoadingWheel';

const InAndOutSelector = ({
  mode,
  items,
  onSearch,
  onItemClick,
  onItemGroupClick,
  searchText,
  title,
  showLoadMore,
  loadMore,
  disableSearch,
  loading,
}) => {
  const groupIcon = mode && mode === 'add' ? <Plus /> : <Minus />;
  const listArray = (item, key, icon) => (
    <ListItem
      key={`${item.id}`}
      className="InAndOut__nestedList_add"
      leftAvatar={<Avatar size={30}>{item.id}</Avatar>}
      onClick={() => onItemClick(key, item)}
      primaryText={
        <span className="InAndOut__nestedList_add_text">
          {item.name}
          {item.image}
        </span>
      }
      rightIcon={icon}
    />
  );

  return (
    <div className="InAndOut">
      <div className="InAndOut_header">
        <div className="InAndOut_header_title">
          {groupIcon}
          <span>{title}</span>
        </div>
      </div>
      <List className="InAndOut_list">
        {!Array.isArray(items)
          ? Object.keys(items).map(key => (
              <ListItem
                key={key}
                className="InAndOut_list_item"
                leftIcon={groupIcon}
                onClick={() => {
                  if (onItemGroupClick) {
                    onItemGroupClick(key);
                  }
                }}
                primaryText={key}
                primaryTogglesNestedList={onItemGroupClick === null}
                nestedItems={items[key].map(item => listArray(item, key, groupIcon))}
              />
            ))
          : items.map(item => listArray(item, item.id, groupIcon))}

        <div className="InAndOut_loadMore">
          {loading && <LoadingWheel size="small" />}
          {showLoadMore && loadMore && !loading && <LoadMoreButton onClick={loadMore} />}
        </div>
      </List>
      {!disableSearch && (
        <div className="InAndOut_footer">
          <MpSearchBar
            className="InAndOut_footer_search"
            cancelSearch={() => onSearch('')}
            search={onSearch}
            searchInfo={{ value: searchText }}
          />
        </div>
      )}
    </div>
  );
};

const selectorPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    leftAvatar: PropTypes.node,
    leftIcon: PropTypes.node,
    name: PropTypes.string.isRequired,
    image: PropTypes.node,
    rightIcon: PropTypes.node,
  }),
);

InAndOutSelector.propTypes = {
  loading: PropTypes.bool,
  disableSearch: PropTypes.bool,
  mode: PropTypes.oneOf(['add', 'remove']),
  showLoadMore: PropTypes.bool,
  items: PropTypes.oneOfType([PropTypes.objectOf(selectorPropTypes), selectorPropTypes]),
  onSearch: PropTypes.func,
  onItemClick: PropTypes.func.isRequired,
  onItemGroupClick: PropTypes.func,
  searchText: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  loadMore: PropTypes.func,
};

InAndOutSelector.defaultProps = {
  loading: false,
  mode: null,
  disableSearch: false,
  showLoadMore: false,
  items: {},
  onItemGroupClick: null,
  onSearch: null,
  searchText: '',
  loadMore: null,
};

export default InAndOutSelector;
