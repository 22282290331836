/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 08/06/2018
 */

import * as apiAuth from '../../thunks/api-auth';
import { changePasswordFailure, changePasswordPending, changePasswordSuccess } from '../actions';

export function changePassword(email) {
  return async function _changePassword(dispatch) {
    dispatch(changePasswordPending(email));
    return dispatch(apiAuth.resetPassword(email))
      .then(response => response.getResult().message)
      .then(message => {
        dispatch(changePasswordSuccess(message));
        return message;
      })
      .catch(error => {
        dispatch(changePasswordFailure(error.message));
      });
  };
}

export default { changePassword };
