import EntityList from '../listPage/EntityList';

const socialMediaCompetitionCountriesList = new EntityList(
  'venueSocialMediaCompetitionCountry',
  'contents/social-media-themes/competition-countries',
  {},
  undefined,
  undefined,
  'Competition by country for Social Media Posts',
);

export default socialMediaCompetitionCountriesList;
