/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 19/09/2018
 */
import { connect } from 'react-redux';

import MpSearchBar from '../../../../components/MpSearchBar';

const mapStateToProps = (state, { entity }) => ({
  query: entity.getSearchQuery(state),
  show: entity.isSearchAvailable(state),
  searchInfo: entity.getSearchInfo(state),
});

const mapDispatchToProps = (dispatch, { entity }) => ({
  cancelSearch: () => dispatch(entity.cancelSearch()),
  search: query => dispatch(entity.search(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MpSearchBar);
