/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 09/11/2018
 */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Redux store.
import { createDiscardChanges, createSaveEdition } from '../store/thunksFactory';
import * as select from '../store/selectorFactory';

// UI Component.
import SaveBar from '../../../../components/SaveBar';
import * as customPropTypes from '../utils/propTypes';

const mapStateToProps = (state, { entityName, parameters }) => ({
  open: select.createHasUnStagedChanges(entityName, parameters)(state),
});

const mapDispatchToProps = (dispatch, { entityName, parameters }) => ({
  onDiscardAction: () => dispatch(createDiscardChanges(entityName, parameters)()),
  onSaveAction: () => dispatch(createSaveEdition(entityName, parameters)()),
});

const ConnectedSaveBar = connect(mapStateToProps, mapDispatchToProps)(SaveBar);

ConnectedSaveBar.propTypes = {
  entityName: PropTypes.string.isRequired,
  parameters: customPropTypes.parameters.isRequired,
};

export default ConnectedSaveBar;
