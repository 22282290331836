import pillsList from './entityList';

import createListPage from '../listPage';
import tabs from '../../../routes/tabs';

const pillsPage = createListPage(
  pillsList,
  {
    name: {
      label: 'name',
    },
  },
  {
    navTabs: tabs.pills,
  },
);

export default pillsPage;
