import EntityList from '../listPage/EntityList';

export default new EntityList(
  'venueGroup',
  'venues/venue-group',
  {
    zidane: true,
  },
  undefined,
  undefined,
  'Venue Groups',
);
