//      ,---.
//    ,/     \__.'~\
//    |        '  . `===---      -Ed touched this file-
//    `|   ,     ,--'
//     `. /___,-'
//       |    \
//       ,\_  ,`-=

export const CLEAR_TRANSLATIONS = 'CLEAR_TRANSLATIONS';
export const FETCH_TRANSLATIONS_PENDING = 'FETCH_TRANSLATIONS_PENDING';
export const FETCH_TRANSLATIONS_SUCCESS = 'FETCH_TRANSLATIONS_SUCCESS';
export const FETCH_TRANSLATIONS_ERROR = 'FETCH_TRANSLATIONS_ERROR';

export const UPDATE_TRANSLATIONS_PENDING = 'UPDATE_TRANSLATIONS_PENDING';
export const UPDATE_TRANSLATIONS_SUCCESS = 'UPDATE_TRANSLATIONS_SUCCESS';
export const UPDATE_TRANSLATIONS_ERROR = 'UPDATE_TRANSLATIONS_ERROR';
