import * as actions from './actions';
import {
  fetchCompetitionsList,
  fetchSports as fetchSportsList,
  saveCompetitionsPriority,
  saveSportsPriority,
} from '../thunks/api-sports';

import { getEditedSports } from './selectors';

export const changeOrder = sports => dispatch => dispatch(actions.changeSportsOrder(sports));

export const discardChanges = () => dispatch => dispatch(actions.discardChanges());

export const fetchSports = countryId => dispatch => {
  dispatch(actions.loadSports());

  return dispatch(fetchSportsList(countryId))
    .then(response => response.getResult())
    .then(sports => {
      dispatch(actions.loadSportsSuccess(sports));
      return sports;
    })
    .catch(error => {
      dispatch(actions.loadSportsError(error.message));
      return error.message;
    });
};

export const fetchCompetitions = (countryId, sportId) => dispatch => {
  dispatch(actions.loadSports());

  return dispatch(fetchCompetitionsList(countryId, sportId))
    .then(response => response.getResult())
    .then(sports => {
      dispatch(actions.loadSportsSuccess(sports));
      return sports;
    })
    .catch(error => {
      dispatch(actions.loadSportsError(error.message));
      return error.message;
    });
};

export const saveSportsOrder = countryId => (dispatch, getState) => {
  const sports = getEditedSports(getState());

  dispatch(actions.saveSportsOrder());

  const sportIds = sports.map(sport => sport.id);

  return dispatch(saveSportsPriority(sportIds, countryId))
    .then(response => response.getResult())
    .then(result => {
      dispatch(actions.saveSportsOrderSuccess());
      return result;
    })
    .catch(error => {
      dispatch(actions.saveSportsOrderError(error.message));
      return error.message;
    });
};

export const saveCompetitionsOrder = (countryId, sportId) => (dispatch, getState) => {
  const comps = getEditedSports(getState());

  dispatch(actions.saveSportsOrder());

  const compIds = comps.map(sport => sport.id);

  return dispatch(saveCompetitionsPriority(compIds, countryId, sportId))
    .then(response => response.getResult())
    .then(result => {
      dispatch(actions.saveSportsOrderSuccess());
      return result;
    })
    .catch(error => {
      dispatch(actions.saveSportsOrderError(error.message));
      return error.message;
    });
};
