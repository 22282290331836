import createFormPage from '../formPage';

const params = {
  endpoint: 'meta/channels',
  sections: {
    General: ['id', 'name', 'activo', 'cnumber', 'logo'],
  },
};
const channels = createFormPage('channels', params);

export default channels;
