/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 18/04/2018
 */

import * as constants from './constants';

export function changeLocationAction(payload) {
  return {
    type: constants.LOCATION_CHANGE,
    payload: {
      action: payload.action ? payload.action : 'POP',
      hash: payload.hash ? payload.hash : '',
      key: payload.key ? payload.key : null,
      pathname: payload.pathname ? payload.pathname : '/',
      query: payload.query ? payload.query : {},
      search: payload.search ? payload.search : '',
      state: payload.state ? payload.state : undefined,
    },
  };
}

export const updateNavigationBarScroll = val => ({
  type: constants.UPDATE_NAV_SCROLL,
  payload: val,
});

export default { changeLocationAction };
