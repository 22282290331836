import { request } from './requests';

export function getFixture(fixtureId) {
  return async function _getFixture(dispatch) {
    return dispatch(request(`sports/fixtures/v2/${fixtureId}`, { method: 'GET' }));
  };
}

export function postUpdateResult(fixtureId, results) {
  return async function _postUpdateResult(dispatch) {
    return dispatch(
      request(`sports/fixtures/v2/${fixtureId}/results`, { method: 'POST', body: { results } }),
    );
  };
}

export const fetchFixtureMinuteEvents = fixtureId => async dispatch =>
  dispatch(request(`sports/fixtures/v2/${fixtureId}/get-minute-events`));

export const saveFixtureMinuteEvents = (fixtureId, event) => async dispatch =>
  dispatch(
    request(`sports/fixtures/v2/${fixtureId}/save-minute-event`, {
      method: 'POST',
      body: { event },
    }),
  );

export const fetchMinuteEventsTypes = gameId => async dispatch =>
  dispatch(request(`games/${gameId}/minute-events`));
