import EntityList from '../listPage/EntityList';

const eventsList = new EntityList(
  'events',
  'sports/fixtures',
  {
    zidane: true,
  },
  undefined,
  undefined,
  'Events',
);

export default eventsList;
