//     __
// ___( o)>
// \ <_. )
//  `---'
//
import { getURLQuery } from '@matchpint/react-common';
import { request } from './requests';

export const getCRMNotes = (venueId, search, sort) => async dispatch =>
  dispatch(
    request(
      `venues/${venueId}/notes${getURLQuery({
        sortColumn: sort.type,
        sortOrder: sort.direction,
        search,
        limit: 1000,
      })}`,
    ),
  );

export const createNewCRMNote = (message, authorId, venueId) => async dispatch =>
  dispatch(
    request(`venues/${venueId}/notes/add`, {
      method: 'POST',
      body: { notes: { message, authorId, venueId } },
    }),
  );
