import EntityList from '../listPage/EntityList';
import { games } from '../../games';

export default games.map(game => ({
  entity: new EntityList(
    'gamePrizeMember',
    `games/${game.game}/prizes-members`,
    {
      zidane: true,
    },
    undefined,
    undefined,
    'Game Prize Members',
  ),
  game,
}));
