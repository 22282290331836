import { games } from '../../games';

import createFormPage from '../formPage';

const params = gameId => ({
  endpoint: `games/${gameId}/prizes-members`,
  create: {
    fields: ['memberId', 'gamePrizeId', 'expiryDateUtc'],
  },
});

export default games.map(({ game }) => createFormPage('gamePrizeMember', params(game)));
