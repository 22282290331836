import * as consts from './consts';
import { deepCopyOfObject } from '../../utils';

export const initialState = {
  initialAnswers: [],
  updatedAnswers: [],
  error: null,
  answersToAdd: [],
};

const quizAnswersForQuestionReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case consts.GET_ANSWERS_FOR_QUESTION_SUCCESS:
      return {
        ...state,
        initialAnswers: deepCopyOfObject(action.payload),
        updatedAnswers: deepCopyOfObject(action.payload),
        error: null,
      };
    case consts.SET_ANSWERS_FOR_QUESTION_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case consts.CHANGE_VALUE_FOR_ANSWER: {
      const { value, field, answerId, index } = action.payload;
      const updatedAnswers = [...state.updatedAnswers];
      const answersToAdd = [...state.answersToAdd];
      const ind = updatedAnswers.findIndex(item => item.id === answerId);
      // if answer already exists - find by ID and update
      if (answerId && ind !== -1) {
        updatedAnswers[ind][field] = value;
      }
      // if it's yet to be created - find and update by index
      if (answersToAdd[index]) {
        answersToAdd[index][field] = value;
      }

      return {
        ...state,
        updatedAnswers,
        answersToAdd,
      };
    }

    case consts.ADD_ANOTHER_ANSWER:
      return {
        ...state,
        answersToAdd: [
          ...state.answersToAdd,
          {
            correct: false,
            text: '',
            active: true,
          },
        ],
      };
    case consts.UNDO_CHANGES_FOR_ANSWER: {
      const updatedAnswers = [...state.updatedAnswers];
      const ind = updatedAnswers.findIndex(item => item.id === action.payload.answerId);
      updatedAnswers[ind] = { ...state.initialAnswers[ind] };
      return {
        ...state,
        updatedAnswers,
      };
    }
    case consts.REMOVE_ANSWER:
      return {
        ...state,
        initialAnswers: state.initialAnswers.filter(ans => ans.id !== action.payload),
        updatedAnswers: state.updatedAnswers.filter(ans => ans.id !== action.payload),
      };
    case consts.REMOVE_UNSAVED_ANSWER: {
      const answersToAdd = [...state.answersToAdd];
      answersToAdd.splice(action.payload, 1);
      return {
        ...state,
        answersToAdd,
      };
    }
    case consts.UNDO_ALL_CHANGES_FOR_ANSWERS:
      return {
        ...state,
        updatedAnswers: deepCopyOfObject(state.initialAnswers),
        answersToAdd: [],
      };

    case consts.UPDATE_ANSWERS_SUCCESS:
      return {
        ...state,
        initialAnswers: deepCopyOfObject(state.updatedAnswers),
      };
    case consts.CREATE_ANSWERS_SUCCESS:
      return {
        ...state,
        updatedAnswers: deepCopyOfObject([...state.updatedAnswers, ...action.payload]),
        initialAnswers: deepCopyOfObject([...state.updatedAnswers, ...action.payload]),
        answersToAdd: [],
      };
    default:
      return state;
  }
};

export default quizAnswersForQuestionReducer;
