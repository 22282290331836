/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 02/05/2018
 */
import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from 'material-ui/CircularProgress';

import './index.scss';

const getWheelRadius = size => {
  switch (size) {
    case 'full-page':
      return 100;
    case 'medium':
      return 80;
    case 'small':
      return 40;
    case 'inline':
      return 24;
    default:
      return 60;
  }
};

const LoadingWheel = ({ show, size, className, color }) => {
  const radius = getWheelRadius(size);

  if (!show) {
    return null;
  }

  if (size === 'full-page') {
    return (
      <div className="LoadingWheel__fullWidth">
        <CircularProgress size={radius} thickness={7} className={className} color={color} />
      </div>
    );
  }

  return <CircularProgress size={radius} thickness={7} className={className} color={color} />;
};

LoadingWheel.propTypes = {
  show: PropTypes.bool,
  size: PropTypes.oneOf(['inline', 'small', 'medium', 'full-page']),
  className: PropTypes.string,
  color: PropTypes.string,
};

LoadingWheel.defaultProps = {
  show: true,
  size: 'small',
  className: '',
  color: null,
};

export default LoadingWheel;
