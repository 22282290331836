/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 18/09/2018
 */

import React from 'react';
import FontIcon from 'material-ui/FontIcon';
import moment from 'moment';

import './components/index.scss';

/*
 * Available types.
 */
export const DATE = 'date';
export const STRING = 'string';
export const NUMBER = 'number';
export const BOOLEAN = 'boolean';

const defaultFieldConfig = {
  renderLabel: label => label,
  renderValue: value => value,
  style: {},
  onDesktop: true,
  onPhone: true,
  onTablet: true,
};

function getDefaultFieldConfig(fieldType) {
  switch (fieldType) {
    case DATE:
      return {
        ...defaultFieldConfig,
        renderLabel: label => (
          <span>
            <FontIcon className="material-icons ListPage__list_header_date_icon">event</FontIcon>
            {label}
          </span>
        ),
        renderValue: value => (value ? moment(value).format('YYYY-MM-DD') : '-'),
      };
    case BOOLEAN:
      return {
        ...defaultFieldConfig,
        renderValue: value => (value ? 'Yes' : 'No'),
      };
    case NUMBER:
    case STRING:
      return defaultFieldConfig;
    default:
      throw new TypeError(`Unknown type ${fieldType}`);
  }
}

function reformatFields(fields) {
  const reformattedFields = {};

  const keys = Object.keys(fields);
  let field;

  for (let i = 0; i < keys.length; i += 1) {
    field = fields[keys[i]];

    // Default "STRING" type if nothing is given.
    if (!field.type) {
      field.type = STRING;
    }

    reformattedFields[keys[i]] = {
      ...getDefaultFieldConfig(field.type),
      ...field,
    };
  }

  return reformattedFields;
}

export default reformatFields;
