//                                   ___
//                                ,-""   `.
//                              ,'  _   e )`-._
//                             /  ,' `-._<.===-'  H E N L O
//                            /  /
//                           /  ;
//               _.--.__    /   ;
//  (`._    _.-""       "--'    |
//  <_  `-""                     \
//   <`-                          :
//    (__   <__.                  ;
//      `-.   '-.__.      _.'    /
//         \      `-.__,-'    _,'
//          `._    ,    /__,-'
//             ""._\__,'< <____
//                  | |  `----.`.
//                  | |        \ `.
//                  ; |___      \-``
//                  \   --<
//                   `.`.<
//
import EntityList from '../listPage/EntityList';

export default new EntityList(
  'pubPortalCode',
  'venues/pub-portal-code',
  {
    zidane: true,
  },
  undefined,
  undefined,
  'Promo Codes',
);
