import React, { useState } from 'react';
import './styles.scss';
import FlatButton from 'material-ui/FlatButton/FlatButton';
import PropTypes from 'prop-types';
import { Toggle } from 'material-ui';
import FileUpload from '../FileUpload/FileUpload';
import ExpendableCard from '../ExpendableCard';
import LoadingWheel from '../LoadingWheel';
import colors from '../../theme/index.scss';
import request from '../../utils/apiRequest/requests';
import { fileUploadAcceptTypes } from '../../utils/globalVariables';
import { getServicesEndpoint } from '../../utils/environment';

const ImportItems = ({
  description,
  endpoint,
  templateEndpoint,
  tooltip,
  deleteEmptyCellsToggle,
  additionalPostData,
  onSuccess,
}) => {
  const [file, setFile] = useState(null);
  const [result, setResult] = useState(null);
  const [link, setLink] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [deleteEmtpyCells, setDeleteEmtpyCells] = useState(false);
  const submit = async () => {
    setError(null);
    setResult(null);
    setUploading(true);
    setLink(null);
    request(`${endpoint}${deleteEmtpyCells ? '&deleteEmptyCells=1' : ''}`, {
      body: { file, ...additionalPostData },
      method: 'POST',
    })
      .then(r => {
        if (r?.data?.error) {
          return setError(r?.data?.error?.message || 'Error uploading file');
        }
        const response = r.getResult();
        if (!response) {
          setError('Error uploading file');
        }
        if (response?.message) {
          setResult(response?.message);
        }
        if (onSuccess) {
          onSuccess();
        }
        return setLink(response?.link);
      })
      .catch(err => {
        setError(err?.data?.error?.message);
      })
      .finally(() => setUploading(false));
  };

  const uploadFile = async f => {
    if (f) {
      const reader = new FileReader();
      reader.readAsDataURL(f);
      setFileName(f.name);
      reader.onload = () => setFile(reader.result);
    } else {
      setError('Error processing file. Please try a different file');
    }
  };

  return (
    <div className="ImportItems">
      <ExpendableCard expanded expandable={false} title="Import">
        <p className="ImportItems_description">{description}</p>
        <div className="ImportItems_uploader_container">
          <div className="ImportItems_uploader">
            <FileUpload
              acceptType={fileUploadAcceptTypes.csv}
              processFile={(proccesFile, successCallback, errorCallback) =>
                uploadFile(proccesFile).then(successCallback).catch(errorCallback)
              }
            />
          </div>

          {file && (
            <div className="ImportItems_uploader">
              <p>{fileName}</p>
              {!uploading ? (
                <FlatButton
                  className="ImportItems_uploader_button"
                  backgroundColor={colors.primaryYellow}
                  onClick={() => submit()}
                  label="Import"
                />
              ) : (
                <LoadingWheel />
              )}
            </div>
          )}
        </div>
        <div className="ImportItems_uploader_buttons">
          <div className="ImportItems_uploader_buttons_innerContainer">
            <FlatButton
              className="ImportItems_uploader_buttons_button"
              backgroundColor={colors.primaryYellow}
              href={`${getServicesEndpoint()}${templateEndpoint}`}
              label="Download Template"
            />
            {tooltip && <p className="ImportItems_uploader_buttons_button_help">{tooltip}</p>}
          </div>

          {deleteEmptyCellsToggle && (
            <div className="ImportItems_uploader_clearEmpty">
              <div className="ImportItems_uploader_clearEmpty_toggle">
                <p className="ImportItems_uploader_clearEmpty_label" htmlFor="#toggle">
                  Ignore empty
                </p>
                <Toggle
                  id="toggle"
                  labelPosition="right"
                  label="Delete Empty"
                  onToggle={() => setDeleteEmtpyCells(!deleteEmtpyCells)}
                />
              </div>
              <p className="ImportItems_uploader_buttons_button_help">
                {deleteEmtpyCells
                  ? 'Blank cells will replace current data in those fields. Use this if you want to delete data from the system! Leave a field blank to delete that data'
                  : 'Blank cells in the spreadsheet you upload will be ignored. Use this if you want to change data for only the fields you have populated. The rest of the data will remain the same.'}
              </p>
            </div>
          )}
        </div>
        <div className={`ImportItems_section${result ? '_errors' : ''}`}>
          {result && (
            <>
              <h4>Result:</h4>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: result }} />
            </>
          )}
          {error && <p className="ImportItems_textError">{error}</p>}
          {link && (
            <a href={link} rel="noopener noreferrer" target="_blank">
              Download errors
            </a>
          )}
        </div>
      </ExpendableCard>
    </div>
  );
};

export default ImportItems;

ImportItems.propTypes = {
  description: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  templateEndpoint: PropTypes.string.isRequired,
  deleteEmptyCellsToggle: PropTypes.bool,
  additionalPostData: PropTypes.shape(),
  onSuccess: PropTypes.func,
};

ImportItems.defaultProps = {
  tooltip: null,
  deleteEmptyCellsToggle: false,
  additionalPostData: {},
  onSuccess: null,
};
