//      ,---.
//    ,/     \__.'~\
//    |        '  . `===---      -Ed touched this file-
//    `|   ,     ,--'
//     `. /___,-'
//       |    \
//       ,\_  ,`-=
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './styles.scss';
import { FlatButton } from 'material-ui';
import moment from 'moment';
import Plus from 'material-ui/svg-icons/content/add-circle';
import DnDContext from '../../components/DragAndDrop/Context';
import DnDList from '../../components/DragAndDrop/List';

import * as t from '../../store/topFixtures/thunks';
import * as s from '../../store/topFixtures/selectors';
import FixtureItem from './FixtureItem';
import FixtureSearchList from './FixtureSearchList';
import EditingDialog from './EditingDialog';
import LoadingWheel from '../../components/LoadingWheel';
import MpModal from '../../components/MpModal/mpModal';
import MatchPintCountrySelector from '../../components/MatchPintCountrySelector/MatchPintCountrySelector';

const TopFixturesList = ({
  topFixtures,
  fetchingTopFixtures,
  error,
  updateTopFixtureError,
  isFixtureItemLoading,
  changeOrder,
  addFixture,
  removeFixture,
  fetchTopFixtures,
  updateFixture,
}) => {
  const [adding, setAdding] = useState(null);
  const [editing, setEditing] = useState(null);
  const [initialFixture, setInitialFixture] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [fixtureToDelete, setFixtureToDelete] = useState(null);

  const onReorder = (listId, indexStart, indexEnd) => {
    const newOrder = DnDContext.onReOrderDefault(
      {
        topFixtures: [...topFixtures],
      },
      listId,
      indexStart,
      indexEnd,
    );
    changeOrder(selectedCountry.id, newOrder.topFixtures);
  };

  const onCloseDialog = () => {
    fetchTopFixtures(selectedCountry.id);
    setEditing(null);
    setInitialFixture(null);
  };

  const enableSave = () => {
    if (initialFixture && editing && moment(editing.startTimeUtc) < moment(editing.endTimeUtc)) {
      const initialKeys = Object.keys(initialFixture);
      return initialKeys.find(key => editing[key] !== initialFixture[key]);
    }
    return false;
  };

  return (
    <div className={`TopFixturesList${error ? '_error' : ''}`}>
      {error && <p className="TopFixturesList_error_message">{error}</p>}
      <h1>Big fixtures</h1>
      <MatchPintCountrySelector
        onSelect={c => {
          setSelectedCountry(c);
          fetchTopFixtures(c.id);
        }}
        selectedCountry={selectedCountry}
      />
      {selectedCountry ? (
        <>
          <div className="TopFixturesList_header">
            <p className="TopFixturesList_dnd_item TopFixturesList_hideOnMobile">ID</p>
            <p className="TopFixturesList_dnd_item_longer">Fixture name</p>
            <p className="TopFixturesList_dnd_item_longer">Display start time</p>
            <p className="TopFixturesList_dnd_item_longer">Display end time</p>
            <div className="TopFixturesList_dnd_icon" />
          </div>

          {fetchingTopFixtures && <LoadingWheel />}
          {topFixtures.length === 0 && !fetchingTopFixtures && (
            <h3>No top fixtures for {selectedCountry.name} yet. Click Add to fix that</h3>
          )}
          {topFixtures.length > 0 && !fetchingTopFixtures && (
            <DnDContext onReorder={onReorder}>
              <DnDList
                listStyleOverride={{ width: '100%' }}
                className="TopFixturesList_dnd"
                items={topFixtures}
                renderItem={item => (
                  <FixtureItem
                    name={item.fixture && item.fixture.name}
                    toBeRemoved
                    isLoading={isFixtureItemLoading === item.fixture && item.fixture.id}
                    onRemove={() => setFixtureToDelete(item)}
                    id={item.fixture && item.fixture.id}
                    startTime={item.startTimeUtc}
                    endTime={item.endTimeUtc}
                    onEdit={() => {
                      setEditing(item);
                      setInitialFixture(item);
                    }}
                  />
                )}
                droppableId="topFixtures"
              />
            </DnDContext>
          )}

          {editing && (
            <EditingDialog
              onDelete={() => setFixtureToDelete(editing)}
              error={updateTopFixtureError}
              selectedCountry={selectedCountry}
              editingFixture={editing}
              isRemovingFixture={isFixtureItemLoading === editing.fixture.id}
              enableSave={enableSave()}
              onClose={() => onCloseDialog()}
              updateFixture={() => updateFixture(editing).then(() => onCloseDialog())}
              setEditing={setEditing}
            />
          )}
          <MpModal
            mainText={`Are you sure you want to remove ${
              fixtureToDelete && fixtureToDelete.fixture && fixtureToDelete.fixture.name
            } ? `}
            visible={Boolean(fixtureToDelete)}
            onConfirm={() => {
              removeFixture(fixtureToDelete).then(() => {
                setFixtureToDelete(null);
                onCloseDialog();
              });
            }}
            onCancel={() => setFixtureToDelete(null)}
          />
          <FlatButton className="TopFixturesList_addButton" onClick={() => setAdding(true)}>
            Add fixtures <Plus />
          </FlatButton>
          {adding && (
            <FixtureSearchList
              onAdd={fix => addFixture(fix, selectedCountry.id)}
              onRemove={fix =>
                removeFixture(topFixtures.find(topFixture => fix.id === topFixture.fixtureId))
              }
              country={selectedCountry}
              fixtures={topFixtures}
              open={adding}
              onClose={() => setAdding(false)}
            />
          )}
        </>
      ) : (
        <h3>To see more options, select a country</h3>
      )}
    </div>
  );
};

TopFixturesList.propTypes = {
  topFixtures: PropTypes.PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fetchingTopFixtures: PropTypes.bool.isRequired,
  error: PropTypes.string,
  updateTopFixtureError: PropTypes.string,
  changeOrder: PropTypes.func.isRequired,
  addFixture: PropTypes.func.isRequired,
  removeFixture: PropTypes.func.isRequired,
  fetchTopFixtures: PropTypes.func.isRequired,
  updateFixture: PropTypes.func.isRequired,
  isFixtureItemLoading: PropTypes.number,
};

TopFixturesList.defaultProps = {
  error: null,
  isFixtureItemLoading: null,
  updateTopFixtureError: null,
};

const mapStateToProps = state => ({
  topFixtures: s.getTopFixtures(state),
  fetchingTopFixtures: s.getFetchingTopFixtures(state),
  error: s.getError(state),
  updateTopFixtureError: s.getUpdateTopFixtureError(state),
  isFixtureItemLoading: s.getIsFixtureItemLoading(state),
});

const mapDispatchToProps = dispatch => ({
  fetchTopFixtures: countryId => dispatch(t.fetchTopFixtures(countryId)),
  changeOrder: (countryId, newOrder) => dispatch(t.saveTopFixturesOrder(countryId, newOrder)),
  removeFixture: fix => dispatch(t.removeFixture(fix)),
  addFixture: (fix, countryId) => dispatch(t.addFixture(fix, countryId)),
  updateFixture: fixture => dispatch(t.updateFixture(fixture)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TopFixturesList);
