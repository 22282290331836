import React from 'react';
import importStatusList from './entityList';
import colors from '../../../theme/index.scss';

import createListPage from '../listPage';

const getStatusColour = status => {
  switch (status) {
    case 'pending':
      return colors.primaryYellow;
    case 'success':
      return colors.primaryGreen;
    case 'error':
      return colors.primaryRed;
    default:
      return colors.primaryOrange;
  }
};

const importStatus = createListPage(importStatusList, {
  jobId: {
    label: 'jobId',
  },
  importer: {
    label: 'importer',
  },
  status: {
    label: 'status',
    renderValue: status => <p style={{ backgroundColor: getStatusColour(status) }}>{status}</p>,
  },
  createdByMember: {
    label: 'Created By',
    renderValue: createdByMember => (
      <p>
        {createdByMember.firstName} {createdByMember.lastName}
      </p>
    ),
  },
  attempts: {
    label: 'attempts',
  },
  createdAt: {
    label: 'createdAt',
  },
});

export default importStatus;
