import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as mediaReducer } from 'redux-mediaquery';
import { browserHistory } from 'react-router';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import thunkMiddleware from 'redux-thunk';
import changePasswordReducer from './changePassword/reducer';
import globalErrorReducer from './globalError/reducer';
import homepageReducer from './homepage/reducer';
import loginReducer from './login/reducer';
import memberReducer from './member/reducer';
import { reducer as formReducer } from '../template/forms';
import { reducer as listReducer } from '../template/lists';
import sportOrder from './sportsOrder/reducer';
import FixtureResultReducer from './FixtureResults/reducer';
import ReportsListReducer from './ReportsReducer/reducer';
import topFixturesReducer from './topFixtures/reducer';
import translationsReducer from './translations/reducer';
import paymentInfo from './paymentInfo/reducer';
import quizAnswersForQuestionReducer from './quizAnswersForQuestion/reducer';
import sidebarReducer from './navigation/reducer';
import toastReducer from './toast/reducer';
/* eslint-disable import/no-extraneous-dependencies */

function configureStore(initialState) {
  const reducers = combineReducers({
    auth: loginReducer,
    changePassword: changePasswordReducer,
    form: formReducer,
    globalError: globalErrorReducer,
    homepage: homepageReducer,
    list: listReducer,
    member: memberReducer,
    media: mediaReducer,
    routing: routerReducer,
    sportOrder,
    paymentInfo,
    result: FixtureResultReducer,
    reports: ReportsListReducer,
    topFixtures: topFixturesReducer,
    translations: translationsReducer,
    quizAnswersForQuestion: quizAnswersForQuestionReducer,
    sidebar: sidebarReducer,
    toast: toastReducer,
  });

  const middleware = [thunkMiddleware, routerMiddleware(browserHistory)];

  return createStore(reducers, initialState, composeWithDevTools(applyMiddleware(...middleware)));
}

export default configureStore;
