/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 07/11/2018
 */

export const isDevEnvironment = () => process.env.NODE_ENV === 'development';

const host = window?.location?.hostname || '';

const allDomains = ['daznbarfinder.de', 'ontapsports.live', 'fanzo.com'];

export const findDomainExt = () => allDomains.find(item => host.includes(item)) || '';

const replaceDomain = tobeReplaced => tobeReplaced.replace('{{domain}}', findDomainExt());

export const getServicesEndpoint = () => replaceDomain(process.env.REACT_APP_ROOT_URL);
export const getAdvertServiceEndpoint = () =>
  replaceDomain(process.env.REACT_APP_ADVERT_SERVICE_URL);
