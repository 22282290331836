/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 16/10/2018
 */

import React from 'react';
import { getURLQuery } from '@matchpint/react-common';
import EditIcon from 'material-ui/svg-icons/image/edit';
import ListPageIconButton from './components/IconButton';

/**
 * @description Returns a relative cleaned path from an endpoint.
 * @param {string} endpoint
 * @return {string}
 */
function getPathFromEntity(endpoint) {
  let path = endpoint;

  // Make sure that we have one and only one / at the beginning.
  path = path.replace(/^\/*/, '/');

  // Remove useless / at the end of the endpoint.
  path = path.replace(/\/+$/, '');

  return path;
}

/**
 * @description Generates the default getEditLink function that will be used in the lists.
 * @param {EntityList} entity
 * @return {function(*): string}
 */
export function getEditLinkFromEntity(entity) {
  return item => `${getPathFromEntity(entity.endpoint)}/edit${getURLQuery({ id: item.id })}`;
}

export function getDefaultEditButton(link) {
  return <ListPageIconButton key="edit-button" link={link} icon={<EditIcon />} />;
}

/**
 * @description Generates the default renderItemSideButtons.
 * @param {EntityList} entity
 * @return {function}
 */
export function getDefaultRenderItemSideButtons(entity, options = {}) {
  return (item, filters) => {
    const { getOnClickLink } = options;
    const getLinkMethod = getOnClickLink || getEditLinkFromEntity(entity);
    const editLink = getLinkMethod(item, filters);

    return [getDefaultEditButton(editLink)];
  };
}

export function createGetOnClickLink(entity, { getOnClickLink }) {
  if (getOnClickLink === undefined) {
    return getEditLinkFromEntity(entity);
  }

  if (getOnClickLink === null) {
    return null;
  }

  return getOnClickLink;
}
