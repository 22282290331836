//              __
//             /(`o
//       ,-,  //  \\
//      (,,,) ||   V
//     (,,,,)\//
//     (,,,/w)-'
//     \,,/w)
//     `V/uu
//       / |
//       | |
//       o o
//       \ |
//  \,/  ,\|,.  \,/
import * as c from './constants';

export const initialState = {
  current: {
    available: [],
    active: [],
  },
  changes: {
    toAdd: [],
    toRemove: [],
  },
  edited: {
    available: [],
    active: [],
  },
  offset: {
    available: 0,
    active: 0,
  },
  availableMatches: null,
  activeMatches: null,
  error: null,
  hasChanged: false,
  activeIsFetching: false,
  availableIsFetching: false,
  isSaving: false,
  availableSearchTerm: '',
  activeSearchTerm: '',
};

const inAndOutSelectionReducer = (state, action) => {
  switch (action.type) {
    case c.FETCH_ACTIVE_PENDING:
      return {
        ...state,
        activeIsFetching: true,
      };
    case c.FETCH_AVAILABLE_PENDING:
      return {
        ...state,
        availableIsFetching: true,
      };
    case c.FETCH_ERROR:
      return {
        ...state,
        availableIsFetching: false,
        activeIsFetching: false,
      };
    case c.FETCH_AVAILABLE_SUCCESS: {
      const { availableItems, availableMatches } = action.payload;
      const selectedItems = {
        ...state.edited,
        available: availableItems,
      };
      return {
        ...state,
        availableMatches,
        current: selectedItems,
        edited: selectedItems,
        offset: {
          ...state.offset,
          available: state.offset.available + availableItems.length,
        },
        hasChanged: false,
        availableIsFetching: false,
        selectedItemsIsFetching: false,
      };
    }
    case c.FETCH_ACTIVE_SUCCESS: {
      const { activeItems, activeMatches } = action.payload;
      const selectedItems = {
        ...state.edited,
        active: activeItems,
      };
      return {
        ...state,
        activeMatches,
        current: selectedItems,
        edited: selectedItems,
        hasChanged: false,
        activeIsFetching: false,
        offset: {
          ...state.offset,
          active: state.offset.active + activeItems.length,
        },
      };
    }
    case c.ADD: {
      const newAvailable = [...state.edited.available];
      const item = newAvailable.splice(
        state.edited.available.findIndex(i => i.id === action.payload.item.id),
        1,
      );
      if (item.length === 0) {
        return { ...state };
      }
      const newEdited = {
        active: [...state.edited.active, item[0]],
        available: newAvailable,
      };
      const newChanges = {
        toAdd: [...state.changes.toAdd],
        toRemove: [...state.changes.toRemove],
      };
      if (
        state.changes.toAdd.indexOf(item[0].id) === -1 &&
        state.current.active.indexOf(item[0]) === -1
      ) {
        newChanges.toAdd.push(item[0].id);
      }
      if (state.changes.toRemove.indexOf(item[0].id) !== -1) {
        newChanges.toRemove.splice(state.changes.toRemove.indexOf(item[0].id), 1);
      }
      return {
        ...state,
        availableMatches: state.availableMatches - 1 || state.current.availableMatches - 1,
        activeMatches: state.activeMatches + 1 || state.current.availableMatches - 1,
        hasChanged: newChanges.toAdd.length > 0 || newChanges.toRemove.length > 0,
        edited: { ...newEdited },
        changes: { ...newChanges },
      };
    }
    case c.REMOVE: {
      const newActive = [...state.edited.active];
      const item = newActive.splice(
        state.edited.active.findIndex(i => i.id === action.payload.item.id),
        1,
      );
      if (item.length === 0) {
        return { ...state };
      }
      const newEdited = {
        available: [...state.edited.available, item[0]],
        active: newActive,
      };
      const newChanges = {
        toAdd: [...state.changes.toAdd],
        toRemove: [...state.changes.toRemove],
      };
      if (
        state.changes.toRemove.indexOf(item[0].id) === -1 &&
        state.current.available.indexOf(item[0]) === -1
      ) {
        newChanges.toRemove.push(item[0].id);
      }
      if (state.changes.toAdd.indexOf(item[0].id) !== -1) {
        newChanges.toAdd.splice(state.changes.toAdd.indexOf(item[0].id), 1);
      }
      return {
        ...state,
        activeMatches: state.activeMatches - 1,
        availableMatches: state.availableMatches + 1,
        hasChanged: newChanges.toAdd.length > 0 || newChanges.toRemove.length > 0,
        edited: { ...newEdited },
        changes: { ...newChanges },
      };
    }
    case c.DISCARD_CHANGES: {
      return {
        ...state,
        changes: initialState.changes,
        edited: state.current,
        hasChanged: false,
      };
    }
    case c.SAVE_CHANGES_PENDING: {
      return {
        ...state,
        isSaving: true,
      };
    }
    case c.SAVE_CHANGES_SUCCESS: {
      return {
        ...state,
        isSaving: false,
        changes: initialState.changes,
        current: state.edited,
        hasChanged: false,
      };
    }
    case c.SAVE_CHANGES_ERROR: {
      return {
        ...state,
        isSaving: false,
      };
    }
    case c.SET_ACTIVE_SEARCH_TERM: {
      const { searchText } = action.payload;
      return {
        ...state,
        activeSearchTerm: searchText,
      };
    }
    case c.SET_AVAILABLE_SEARCH_TERM: {
      const { searchText } = action.payload;
      return {
        ...state,
        availableSearchTerm: searchText,
      };
    }
    case c.LOAD_MORE_AVAILABLE: {
      const { availableItems } = action.payload;
      const previousavailableItems = [...state.edited.available];
      const newList = previousavailableItems.concat(availableItems);
      return {
        ...state,
        current: {
          ...state.current,
          available: newList,
          active: state.current.active,
        },
        edited: {
          ...state.edited,
          available: newList,
          active: state.edited.active,
        },
        offset: {
          ...state.offset,
          available: state.offset.available + availableItems.length,
        },
        availableIsFetching: false,
      };
    }
    case c.LOAD_MORE_ACTIVE: {
      const { activeItems } = action.payload;
      const previousactiveItems = [...state.edited.active];
      const newList = previousactiveItems.concat(activeItems);
      return {
        ...state,
        current: {
          ...state.current,
          available: state.current.available,
          active: newList,
        },
        edited: {
          ...state.edited,
          available: state.edited.available,
          active: newList,
        },
        offset: {
          ...state.offset,
          active: state.offset.active + activeItems.length,
        },
        activeIsFetching: false,
      };
    }
    default:
      return state;
  }
};

export default inAndOutSelectionReducer;
