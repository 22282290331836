import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.scss';
import { createGetSaveSuccessText } from '../../store/selectorFactory';
import { createSetSaveSuccessText } from '../../store/actionCreatorFactory';
import ClearButton from '../../../../../components/ClearButton';

// when the message changes to something not null, start a count down timer
// if mouse enters the pop up stop the timer
// if mouse leaves resume the timer
// when the timer ends empty the message
// add a close button to force empty the message
// support the message being html

const SaveSuccessPopUp = ({ successMessage, clearText }) =>
  successMessage ? (
    <div className="SaveSuccessPopUp">
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: successMessage }}
      />
      <ClearButton
        className="SaveSuccessPopUp_clearButton"
        handleCancel={() => clearText()}
        clearableValue={successMessage}
      />
    </div>
  ) : null;

const mapStateToProps = (state, { entityName }) => ({
  successMessage: createGetSaveSuccessText(entityName)(state),
});

const mapDispatchToProps = (dispatch, { entityName }) => ({
  clearText: () => dispatch(createSetSaveSuccessText(entityName)('')),
});

SaveSuccessPopUp.propTypes = {
  successMessage: PropTypes.string,
  clearText: PropTypes.func.isRequired,
};

SaveSuccessPopUp.defaultProps = {
  successMessage: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveSuccessPopUp);
