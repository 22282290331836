/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */
import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';

const FormPasswordInput = ({ id, onChange, onKeyPress, required, error, className, label }) => {
  if (Object.keys(error).length > 0) {
    return (
      <TextField
        fullWidth
        id={id}
        required={required}
        label={label}
        placeholder={label}
        type="password"
        className={`${className} FormPasswordInput_form_mail_input_text`}
        margin="normal"
        autoComplete="current-password"
        errorText={error.message}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
    );
  }
  return (
    <TextField
      fullWidth
      id={id}
      required={required}
      label={label}
      placeholder={label}
      type="password"
      autoComplete="current-password"
      className={`${className} FormPasswordInput_form_mail_input_text`}
      margin="normal"
      onChange={onChange}
      onKeyPress={onKeyPress}
    />
  );
};

FormPasswordInput.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.shape(),
  className: PropTypes.string,
  label: PropTypes.string,
};

FormPasswordInput.defaultProps = {
  id: '',
  required: false,
  error: {},
  className: 'default',
  label: 'Password',
  onKeyPress: null,
};

export default FormPasswordInput;
