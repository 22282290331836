import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import Subheader from 'material-ui/Subheader';
import { getURLQuery } from '@matchpint/react-common';
import colors from '../../theme/index.scss';
import './style.scss';
import { fetchReport } from '../../store/ReportsReducer/thunks';
import {
  getReport,
  reportListIsLoading,
  getAppliedFilters,
  getAvailableFilters,
} from '../../store/ReportsReducer/selectors';
import AvailableFilters from '../../template/lists/listPage/components/FilterSection/AvailableFilters';
import ActiveFilters from '../../template/lists/listPage/components/FilterSection/ActiveFiltersSection';
import {
  addFilterAction,
  removeFilterAction,
  clearReport,
} from '../../store/ReportsReducer/actions';
import LoadingWheel from '../../components/LoadingWheel';
import { filterTypes } from '../../template/forms/formPage/utils/propTypes';
import { getServicesEndpoint } from '../../utils/environment';

const ReportPage = ({
  addFilter,
  appliedFilters,
  availableFilters,
  isLoading,
  isPhone,
  report,
  removeFilter,
  retrieveReport,
  params,
  location: { query },
  clearReportData,
}) => {
  useEffect(() => {
    if (params.name && query) {
      retrieveReport(params.name, query);
    }
    return () => clearReportData();
  }, [clearReportData, params.name, query, retrieveReport]);

  if (isLoading) {
    return <LoadingWheel size="small" />;
  }

  if (!report) {
    return null;
  }

  return (
    <div className="ReportPage__main">
      <Subheader>
        <h1 className="ReportPage__title">{report?.name}</h1>
        <p>{report?.description}</p>
      </Subheader>
      <div className="ReportPage__filters">
        <AvailableFilters
          addFilter={addFilter}
          availableFilters={availableFilters}
          activeFilters={appliedFilters}
          isLoading={isLoading}
        />
        <ActiveFilters
          availableFilters={availableFilters}
          activeFilters={appliedFilters}
          isPhone={isPhone}
          removeFilter={removeFilter}
        />
      </div>
      <FlatButton
        backgroundColor={colors.standardGrey}
        disabled={
          !Object.values(availableFilters)
            .filter(elm => elm?.required)
            .every(
              filtered =>
                Object.keys(appliedFilters).includes(filtered?.name) &&
                appliedFilters[filtered?.name] !== null,
            )
        }
        className="ReportPage__mainPage_buttons_submit"
        label="Submit"
        href={`${getServicesEndpoint()}meta/reports/get/${report?.name}${getURLQuery({
          ...appliedFilters,
          ...query,
        })}`}
      />
    </div>
  );
};

ReportPage.propTypes = {
  appliedFilters: PropTypes.objectOf(PropTypes.shape()),
  availableFilters: PropTypes.objectOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      type: PropTypes.oneOf(filterTypes).isRequired,
    }),
  ).isRequired,
  addFilter: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isPhone: PropTypes.bool.isRequired,
  removeFilter: PropTypes.func.isRequired,
  retrieveReport: PropTypes.func.isRequired,
  report: PropTypes.shape(),
  params: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape.isRequired,
  }).isRequired,
  clearReportData: PropTypes.func.isRequired,
};

ReportPage.defaultProps = {
  report: null,
  appliedFilters: {},
  isLoading: false,
};

const mapDispatchToProps = dispatch => ({
  addFilter: (name, value, isMulti) => dispatch(addFilterAction(name, value, isMulti)),
  removeFilter: name => dispatch(removeFilterAction(name)),
  retrieveReport: (id, query) => dispatch(fetchReport(id, query)),
  clearReportData: () => dispatch(clearReport()),
});

const mapStateToProps = state => ({
  appliedFilters: getAppliedFilters(state),
  availableFilters: getAvailableFilters(state),
  isLoading: reportListIsLoading(state),
  report: getReport(state),
  isPhone: state.media.isPhone,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);
