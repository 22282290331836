import permissionsList from './entityList';

import createListPage from '../listPage';
import { STRING } from '../listPage/fieldTypes';

import tabs from '../../../routes/tabs';

const permissionsListPage = createListPage(
  permissionsList,
  {
    name: {
      label: 'Name',
      type: STRING,
      style: {
        width: '40%',
      },
    },
  },
  {
    navTabs: tabs.permissions,
  },
);

export default permissionsListPage;
