/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 18/04/2018
 */

import { combineReducers } from 'redux';
import * as constants from './constants';

export const initialState = {
  login: {
    status: null,
  },
};

function loginReducer(state = initialState.login, action = {}) {
  switch (action.type) {
    case constants.LOGIN_SUCCESS:
      return {
        ...state,
        status: 'success',
      };
    case constants.LOGIN_PENDING:
      return { ...state, status: 'pending' };
    case constants.LOGIN_FAILURE:
      return {
        ...state,
        status: 'error',
        errorMessage: action.error.message ? action.error.message : action.error,
        fieldErrors: action.error.fields ? action.error.fields : {},
      };
    case constants.LOGOUT:
      return { ...state, status: null };
    default:
      return state;
  }
}

export default combineReducers({
  login: loginReducer,
});
