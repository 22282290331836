import React from 'react';
import createFormPage from '../formPage';
import Zones from './Zones';

const params = {
  endpoint: 'adverts',
  create: {
    fields: [
      'name',
      'openInNewTab',
      'active',
      'startTimeUtc',
      'endTimeUtc',
      'targetURL',
      'targetTerritory',
      'deliveryPercent',
      'keywords',
      'alt',
    ],
  },
  sections: {
    General: [
      'id',
      'openInNewTab',
      'name',
      'active',
      'startTimeUtc',
      'endTimeUtc',
      'targetURL',
      'targetTerritory',
      'deliveryPercent',
      'keywords',
      'alt',
    ],
    // eslint-disable-next-line react/prop-types, react/jsx-props-no-spreading
    Zones: [{ fieldPath: 'zones', Component: props => <Zones {...props} /> }],
  },
  otherSection: false,
};
const adverts = createFormPage('adverts', params);

export default adverts;
