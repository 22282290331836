import React from 'react';
import newsList from './entityList';

import createListPage from '../listPage';
import { STRING, BOOLEAN } from '../listPage/fieldTypes';

import tabs from '../../../routes/tabs';

const newsListPage = createListPage(
  newsList,
  {
    id: {
      label: 'Id',
      type: STRING,
    },
    image: {
      label: 'Image',
      renderValue: value => (
        <img style={{ maxHeight: 80, padding: '10px 0' }} src={value} alt="Preview" />
      ),
    },
    name: {
      label: 'Name',
      type: STRING,
    },
    locationBased: {
      label: 'Location Based',
      type: BOOLEAN,
    },
    createdAt: {
      label: 'Created',
      type: STRING,
    },
  },
  {
    navTabs: tabs.news,
  },
);

export default newsListPage;
