/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */
import React from 'react';
import PropTypes from 'prop-types';

import IconButton from 'material-ui/IconButton';

import ClearIcon from 'material-ui/svg-icons/content/clear';

import './index.scss';

const ClearButton = ({ className, handleCancel, clearableValue }) => {
  if (clearableValue) {
    return (
      <IconButton className={`${className} ClearButton`} onClick={handleCancel}>
        <ClearIcon />
      </IconButton>
    );
  }
  return null;
};

ClearButton.propTypes = {
  className: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  clearableValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

ClearButton.defaultProps = {
  className: null,
  clearableValue: null,
};

export default ClearButton;
