/* eslint-disable react/jsx-props-no-spreading */
/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 26/10/2018
 */
import React from 'react';

import authenticated from '../../../hocs/authenticated';

import createReducer from './store/reducerFactory';

import CreatePage from './components/CreatePage';
import EditPage from './components/EditPage';

import { getRouterCreatePath, getRouterEditPath } from './utils/paramParsers/router';
import { isCreatePageAvailable } from './utils/paramParsers/create';
import * as permissions from '../../../utils/permissionsForRoutes';

/**
 * @description Function that will given a unique entityName and parameters will generate
 *  a personalized form page.
 * @param {string} entityName Name of the entity in the back-end (ex: member, venue, offer…)
 * @param {Object} parameters Parameters that allows to personalize the form (define sections,
 *  include personalized components…)
 * @return {{entityName: *, routes: [{path: string, component: *}], reducer}}
 */
function createForm(entityName, parameters) {
  const routes = [];
  if (parameters.endpoint.charAt(0) === '/') {
    throw new Error(
      `Entity endpoints should not start with /, try search your project for ${parameters.endpoint}`,
    );
  }

  const FormEditPage = authenticated(
    props => <EditPage {...props} entityName={entityName} parameters={parameters} />,
    parameters.tabs,
    permissions[entityName],
  );

  routes.push({
    path: getRouterEditPath(entityName, parameters),
    component: FormEditPage,
  });

  if (isCreatePageAvailable(entityName, parameters)) {
    const FormCreatePage = authenticated(
      props => <CreatePage {...props} entityName={entityName} parameters={parameters} />,
      parameters.tabs,
      permissions[entityName],
    );

    routes.push({
      path: getRouterCreatePath(entityName, parameters),
      component: FormCreatePage,
    });
  }

  return {
    entityName,
    reducer: createReducer(entityName),
    routes,
  };
}

export default createForm;
