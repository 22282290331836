/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 18/04/2018
 */
import * as constants from './constants';
import * as loginConstants from '../login/constants';

export const initialState = {
  status: null,
  authenticatedMember: null,
  permissionsPending: false,
  permissions: null,
};

function memberReducer(state = initialState, action = {}) {
  switch (action.type) {
    case constants.LOAD_AUTHENTICATED_MEMBER:
      return {
        ...state,
        status: 'pending',
      };
    case loginConstants.LOGIN_SUCCESS:
      return {
        ...state,
        status: 'success',
        authenticatedMember: {
          id: action.member.id,
          profilePicture: action.member.profilePicture,
          firstName: action.member.firstName,
          lastName: action.member.lastName,
          role: action.member.role ? action.member.role : 'BOGOSS',
        },
      };
    case constants.LOAD_AUTHENTICATED_MEMBER_SUCCESS:
      return {
        ...state,
        status: 'success',
        authenticatedMember: {
          ...state.authenticatedMember,
          ...action.member,
        },
      };
    case constants.LOAD_AUTHENTICATED_MEMBER_ERROR:
    case loginConstants.LOGOUT:
    case loginConstants.LOGIN_FAILURE:
      return {
        ...state,
        status: 'error',
        authenticatedMember: null,
      };
    case constants.LOAD_USER_PERMISSIONS_PENDING:
      return {
        ...state,
        permissionsPending: true,
      };
    case constants.LOAD_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissionsPending: false,
        permissions: action.payload,
      };
    case constants.LOAD_USER_LOCALES:
      return {
        ...state,
        authenticatedMember: {
          ...state.authenticatedMember,
          locales: action.payload,
        },
      };

    default:
      return state;
  }
}

export default memberReducer;
