import createFormPage from '../formPage';

const params = {
  endpoint: 'venues/venue-users',
  General: ['name'],
  create: {
    allFields: true,
  },
};

export default createFormPage('venueMember', params);
