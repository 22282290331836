/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 21/05/2018
 */

/**
 * @description Constants used in Redux by the EntityList.
 */

export const FETCH_LIST_PENDING = 'fetchList/pending';
export const FETCH_LIST_SUCCESS = 'fetchList/success';
export const FETCH_LIST_FAILURE = 'fetchList/failure';

export const LOAD_MORE = 'fetch/load_more';

export const FETCH_FILTERS_PENDING = 'fetchFilters/pending';
export const FETCH_FILTERS_SUCCESS = 'fetchFilters/success';
export const FETCH_FILTERS_ERROR = 'fetchFilters/error';

export const ADD_FILTER = 'filters/add';
export const ADD_MULTI_FILTER = 'filters/multi/add';
export const REMOVE_FILTER = 'filters/remove';

export const ADD_SORT_BY = 'sortBy/add';
