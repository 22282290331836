/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 26/10/2018
 */

import { combineReducers } from 'redux';

import * as constants from './constantFactory';

import { get, set } from '../../../../utils/object';

/**
 * @description Base initial state of any form page's Redux store.
 * @type {Object}
 */
const initialState = {
  data: {
    changes: {}, // Data that will be sent in the save function.
    description: {}, // Form description.
    editedData: {}, // Edited ata displayed in the form.
    hasUnStagedChanges: false, // Tells if the data has been modified by the user.
    initialData: {}, // Initial data retrieved from the server.
    createData: {}, // Data to be sent to create a new entity.
    createSuccessText: null,
    renderKey: 'initial',
  },
  errors: {
    create: null, // Error message received when creating a new entity.
    createFieldErrors: null, // Fields errors received when creating a new entity.
    fetchData: null, // Error received when fetching the form description.
    fetchDescription: null, // Error received when fetching the form description.
    isCreationSuccessful: false, // Returns if the form's creation has been successful.
    isEditionSuccessful: false, // Returns if the form's edition has been successful.
    save: null, // Error message received when saving changes of a current entity.
    saveFieldErrors: null, // Fields errors received when saving the form data.
  },
  isLoading: {
    create: false, // Returns if the form is sending a new entity to the server.
    fetchData: false, // Returns if the form is fetching description from the server.
    fetchDescription: false, // Returns if the form is fetching description from the server.
    save: false, // Returns if the form is sending modification to the server.
  },
  showSuccessMessage: false, // Returns if the save success message should be displayed.
  showSaveConfirmation: false,
  saveConfirmationText: 'Are you sure?',
};

function createDataReducer(entityName) {
  return function dataReducer(state = initialState.data, action = {}) {
    switch (action.type) {
      case constants.SAVE_CREATION_SUCCESS(entityName):
        return {
          ...state,
          createSuccessText: action.payload.result.message,
          createData: {},
        };
      case constants.CLEAR_VALUES(entityName):
        return initialState.data;
      case constants.DISCARD_CHANGES(entityName):
        return {
          ...state,
          changes: {},
          editedData: state.initialData,
          hasUnStagedChanges: false,
          renderKey: `discarded${new Date().getTime()}`,
        };
      case constants.EDIT_CURRENT(entityName): {
        const { pathList, value } = action.payload;
        return {
          ...state,
          changes: set(pathList, value, state.changes),
          editedData: set(pathList, value, state.editedData),
          hasUnStagedChanges: true,
        };
      }
      case constants.EDIT_NEW(entityName): {
        const { pathList, value } = action.payload;
        return {
          ...state,
          createData: set(pathList, value, state.createData),
        };
      }
      case constants.FETCH_DESCRIPTION_SUCCESS(entityName): {
        const { payload } = action;
        const { data } = payload;
        return {
          ...state,
          description: data,
        };
      }
      case constants.FETCH_SUCCESS(entityName): {
        const { payload } = action;
        const { data } = payload;
        return {
          ...state,
          editedData: data,
          initialData: data,
          hasUnStagedChanges: false,
        };
      }
      case constants.SAVE_EDITION_SUCCESS(entityName): {
        const { payload } = action;
        const { data } = payload;
        return {
          ...state,
          changes: {},
          editedData: data,
          initialData: data,
          hasUnStagedChanges: false,
        };
      }
      case constants.SEND_DIRECT_EDITION_SUCCESS(entityName): {
        const { pathList, data } = action.payload;
        // New value for field's path.
        const newValue = get(pathList, data);
        return {
          ...state,
          editedData: set(pathList, newValue, state.editedData),
          currentData: set(pathList, newValue, state.currentData),
        };
      }
      case constants.SET_DEFUALT_VALUES(entityName): {
        const newCreatedData = Object.keys(state.description).map(elm => ({
          [elm]:
            state.description[elm].options && state.description[elm].options.defaultValue
              ? state.description[elm].options.defaultValue
              : undefined,
        }));

        return {
          ...state,
          createData: Object.assign({}, ...newCreatedData),
        };
      }
      case constants.SET_SAVE_SUCCESS_TEXT(entityName):
        return {
          ...state,
          createSuccessText: action.payload,
        };
      default:
        return state;
    }
  };
}

function createErrorsReducer(entityName) {
  return function errorsReducer(state = initialState.errors, action = {}) {
    switch (action.type) {
      case constants.FETCH_DESCRIPTION_ERROR(entityName): {
        const { payload } = action;
        const { error } = payload;
        return {
          ...state,
          description: error,
        };
      }
      case constants.FETCH_DESCRIPTION_SUCCESS(entityName):
        return {
          ...state,
          description: null,
        };
      case constants.SEND_DIRECT_EDITION_ERROR(entityName):
      case constants.SAVE_EDITION_ERROR(entityName): {
        const { error, fieldErrors } = action.payload;
        return {
          ...state,
          isEditionSuccessful: false,
          save: error,
          saveFieldErrors: fieldErrors,
        };
      }
      case constants.SAVE_EDITION_SUCCESS(entityName):
        return {
          ...state,
          isEditionSuccessful: true,
          save: null,
          saveFieldErrors: null,
        };
      case constants.SAVE_CREATION_ERROR(entityName): {
        const { error, fieldErrors } = action.payload;
        return {
          ...state,
          create: error,
          createFieldErrors: fieldErrors,
          isCreationSuccessful: false,
        };
      }
      case constants.SAVE_CREATION_SUCCESS(entityName):
      case constants.SAVE_CREATION_SUCCESS_PRECONFIRM(entityName):
        return {
          ...state,
          create: null,
          createFieldErrors: null,
          isCreationSuccessful: true,
        };
      case constants.FETCH_ERROR(entityName): {
        const { payload } = action;
        const { error } = payload;
        return {
          ...state,
          fetchData: error,
        };
      }
      case constants.FETCH_SUCCESS(entityName):
        return {
          ...state,
          fetchData: null,
        };
      default:
        return state;
    }
  };
}

function createIsLoadingReducer(entityName) {
  return function isLoadingReducer(state = initialState.isLoading, action = {}) {
    switch (action.type) {
      case constants.FETCH_DESCRIPTION_PENDING(entityName):
        return {
          ...state,
          fetchDescription: true,
        };
      case constants.FETCH_DESCRIPTION_ERROR(entityName):
      case constants.FETCH_DESCRIPTION_SUCCESS(entityName):
        return {
          ...state,
          fetchDescription: false,
        };
      case constants.FETCH_PENDING(entityName):
        return {
          ...state,
          fetchData: true,
        };
      case constants.FETCH_ERROR(entityName):
      case constants.FETCH_SUCCESS(entityName):
        return {
          ...state,
          fetchData: false,
        };
      case constants.SEND_DIRECT_EDITION_PENDING(entityName):
      case constants.SAVE_CREATION_PENDING(entityName):
        return {
          ...state,
          create: true,
        };
      case constants.SEND_DIRECT_EDITION_ERROR(entityName):
      case constants.SEND_DIRECT_EDITION_SUCCESS(entityName):
      case constants.SAVE_CREATION_ERROR(entityName):
      case constants.SAVE_CREATION_SUCCESS(entityName):
      case constants.SAVE_CREATION_SUCCESS_PRECONFIRM(entityName):
        return {
          ...state,
          create: false,
        };
      case constants.SAVE_EDITION_PENDING(entityName):
        return {
          ...state,
          save: true,
        };
      case constants.SAVE_EDITION_ERROR(entityName):
      case constants.SAVE_EDITION_SUCCESS(entityName):
        return {
          ...state,
          save: false,
        };
      default:
        return state;
    }
  };
}

function createShowSuccessMessageReducer(entityName) {
  return function showSuccessMessageReducer(state = initialState.showSuccessMessage, action = {}) {
    switch (action.type) {
      case constants.SEND_DIRECT_EDITION_SUCCESS(entityName):
      case constants.SAVE_EDITION_SUCCESS(entityName):
        return true;
      case constants.DISMISS_SUCCESS_MESSAGE(entityName):
        return false;
      default:
        return state;
    }
  };
}

function toggleShowConfirmationReducer(entityName) {
  return function showSuccessMessageReducer(
    state = initialState.showSaveConfirmation,
    action = {},
  ) {
    switch (action.type) {
      case constants.SHOW_SHOW_CONFIRM(entityName):
        return true;
      case constants.HIDE_SHOW_CONFIRM(entityName):
      case constants.SAVE_CREATION_SUCCESS(entityName):
      case constants.SAVE_CREATION_ERROR(entityName):
        return false;
      default:
        return state;
    }
  };
}

function confirmationText(entityName) {
  return function showSuccessMessageReducer(
    state = initialState.saveConfirmationText,
    action = {},
  ) {
    switch (action.type) {
      case constants.SET_CONFIRM_TEXT(entityName):
        return action.payload;
      case constants.SAVE_CREATION_SUCCESS(entityName):
        return initialState.saveConfirmationText;
      default:
        return state;
    }
  };
}

export default function createReducer(entityName) {
  return combineReducers({
    data: createDataReducer(entityName),
    errors: createErrorsReducer(entityName),
    isLoading: createIsLoadingReducer(entityName),
    showSuccessMessage: createShowSuccessMessageReducer(entityName),
    showSaveConfirmation: toggleShowConfirmationReducer(entityName),
    saveConfirmationText: confirmationText(entityName),
  });
}
