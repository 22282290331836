/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 24/05/2018
 */
import { TYPE_UNAUTHORIZED } from '../constants';
import * as actions from '../actions';
import { getGlobalErrorType } from '../selectors';
import logout from '../../login/thunks/logout';
import { navigate } from '../../navigation/thunks';

export function displayGlobalError(errorType) {
  return function _displayGlobalError(dispatch) {
    dispatch(actions.receiveErrorAction(errorType));
  };
}

export function goToHomepage() {
  return function _dismissGlobalError(dispatch) {
    dispatch(actions.dismissErrorAction());
    navigate('/')(dispatch);
  };
}

export function dismissGlobalError() {
  return function _dismissGlobalError(dispatch, getState) {
    dispatch(actions.dismissErrorAction());
    if (getGlobalErrorType(getState()) === TYPE_UNAUTHORIZED) {
      logout()(dispatch);
    }
  };
}
