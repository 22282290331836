import createListPage from '../listPage';
import assetGroup from './entityList';
import tabs from '../../../routes/tabs';

export default createListPage(
  assetGroup,
  {
    name: {
      label: 'Name',
    },
  },
  {
    navTabs: tabs.assetGroup,
  },
);
