//      ,---.
//    ,/     \__.'~\
//    |        '  . `===---      -Ed touched this file-
//    `|   ,     ,--'
//     `. /___,-'
//       |    \
//       ,\_  ,`-=

import * as c from './constants';

export const fetchTopFixturesSuccess = result => ({
  type: c.FETCH_TOP_FIXTURES_SUCCESS,
  payload: result,
});
export const fetchTopFixturesError = err => ({
  type: c.FETCH_TOP_FIXTURES_ERROR,
  payload: err,
});
export const fetchTopFixturesPending = () => ({
  type: c.FETCH_TOP_FIXTURES_PENDING,
});

export const changeOrder = fixtures => ({
  type: c.ORDER_CHANGED,
  payload: fixtures,
});
export const changeOrderError = error => ({
  type: c.ORDER_CHANGED_ERROR,
  payload: error,
});

export const fetchMatchPintCountriesSuccess = countries => ({
  type: c.FETCH_MP_COUNTRIES_SUCCESS,
  payload: countries,
});
export const fetchMatchPintCountriesError = error => ({
  type: c.FETCH_MP_COUNTRIES_ERROR,
  payload: error,
});
export const fetchMatchPintCountriesPending = () => ({
  type: c.FETCH_MP_COUNTRIES_PENDING,
});

export const fetchFixturesForSearchSuccess = fixtures => ({
  type: c.FETCH_FIXTURES_FOR_SEARCH_SUCCESS,
  payload: fixtures,
});
export const fetchFixturesForSearchError = error => ({
  type: c.FETCH_FIXTURES_FOR_SEARCH_ERROR,
  payload: error,
});
export const fetchFixturesForSearchPending = () => ({
  type: c.FETCH_FIXTURES_FOR_SEARCH_PENDING,
});

export const fetchMoreFixturesForSearchSuccess = fixtures => ({
  type: c.FETCH_MORE_FIXTURES_FOR_SEARCH_SUCCESS,
  payload: fixtures,
});
export const fetchMoreFixturesForSearchError = error => ({
  type: c.FETCH_MORE_FIXTURES_FOR_SEARCH_ERROR,
  payload: error,
});

export const setFixtureItemLoading = fixtureID => ({
  type: c.FIXTURE_ITEM_LOADING,
  payload: fixtureID,
});

export const fetchMoreFixturesForSearchPending = () => ({
  type: c.FETCH_MORE_FIXTURES_FOR_SEARCH_PENDING,
});

export const addOrRemoveFixtureError = message => ({
  type: c.ADD_OR_REMOVE_FIXTURE_ERROR,
  payload: message,
});

export const locallyAddTopFixture = fixture => ({
  type: c.LOCALLY_ADD_TOP_FIXTURE,
  payload: fixture,
});

export const locallyRemoveTopFixture = fixture => ({
  type: c.LOCALLY_REMOVE_TOP_FIXTURE,
  payload: fixture,
});

export const updateTopFixture = fixture => ({
  type: c.UPDATE_TOP_FIXTURE,
  payload: fixture,
});
export const updateTopFixtureError = error => ({
  type: c.UPDATE_TOP_FIXTURE_ERROR,
  payload: error,
});
export const clearErrors = () => ({
  type: c.CLEAR_ERRORS,
});
