/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 27/09/2018
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { isDevEnvironment } from '../utils/environment';
import ErrorModal, { TYPE_CRASH } from '../components/ErrorModal';

function errorHandler(ReactComponent) {
  if (isDevEnvironment()) {
    return ReactComponent;
  }

  class ReactComponentErrorHandled extends React.Component {
    constructor() {
      super();
      this.state = { hasError: false };
      this.onModalDismiss = this.onModalDismiss.bind(this);
    }

    componentDidCatch(error) {
      // eslint-disable-next-line no-console
      console.warn(error);
      this.setState({
        hasError: true,
      });
    }

    onModalDismiss() {
      const { goToHomepage } = this.props;
      goToHomepage();
      this.setState({ hasError: false });
    }

    render() {
      const { hasError } = this.state;
      if (hasError) {
        return <ErrorModal close={this.onModalDismiss} open type={TYPE_CRASH} />;
      }

      // eslint-disable-next-line react/jsx-props-no-spreading
      return <ReactComponent {...this.props} />;
    }
  }

  ReactComponentErrorHandled.propTypes = {
    goToHomepage: PropTypes.func.isRequired,
  };

  const mapDispatchToProps = dispatch => ({
    goToHomepage: () => dispatch(push('/')),
  });

  return connect(null, mapDispatchToProps)(ReactComponentErrorHandled);
}

export default errorHandler;
