import PropTypes from 'prop-types';

export const quizAnswerProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  correct: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
});

export const filterOptions = PropTypes.oneOfType([
  PropTypes.shape({
    serviceUrl: PropTypes.string,
    possibleValues: PropTypes.shape(),
  }),
  PropTypes.arrayOf(PropTypes.shape()),
]);

/**
 * Shape of the fields parameter.
 */
export const fieldsPropTypes = PropTypes.objectOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    onDesktop: PropTypes.bool.isRequired,
    onPhone: PropTypes.bool.isRequired,
    onTablet: PropTypes.bool.isRequired,
    renderValue: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    renderLabel: PropTypes.func.isRequired,
  }),
);

export const venueProps = PropTypes.shape({
  id: PropTypes.number,
  menu: PropTypes.string,
  allowsPet: PropTypes.bool,
  hasBigScreen: PropTypes.bool,
  hasGarden: PropTypes.bool,
  hasHDScreen: PropTypes.bool,
  hasOutdoorScreen: PropTypes.bool,
  hasSound: PropTypes.bool,
  hasWifi: PropTypes.bool,
  has3DScreen: PropTypes.bool,
  isFamilyFriendly: PropTypes.bool,
  servesFood: PropTypes.bool,
  screensNumber: PropTypes.number,
  openingHours: PropTypes.shape({
    monday: PropTypes.shape({
      opening: PropTypes.string,
      closing: PropTypes.string,
    }),
    tuesday: PropTypes.shape({
      opening: PropTypes.string,
      closing: PropTypes.string,
    }),
    wednesday: PropTypes.shape({
      opening: PropTypes.string,
      closing: PropTypes.string,
    }),
    thursday: PropTypes.shape({
      opening: PropTypes.string,
      closing: PropTypes.string,
    }),
    friday: PropTypes.shape({
      opening: PropTypes.string,
      closing: PropTypes.string,
    }),
    saturday: PropTypes.shape({
      opening: PropTypes.string,
      closing: PropTypes.string,
    }),
    sunday: PropTypes.shape({
      opening: PropTypes.string,
      closing: PropTypes.string,
    }),
  }),
  address1: PropTypes.string,
  address2: PropTypes.string,
  stateId: PropTypes.number,
  googlePlaceId: PropTypes.string,
  countryId: PropTypes.number,
  postCode: PropTypes.string,
  city: PropTypes.string,

  venue: PropTypes.shape(),
  venueDescription: PropTypes.shape(),
  ownershipType: PropTypes.string,
  completionStatus: PropTypes.shape(),
  chainId: PropTypes.number,
  tierId: PropTypes.number,
  signupDate: PropTypes.string,
  paymentStatus: PropTypes.string,
  memberSince: PropTypes.string,
  performances: PropTypes.shape(),
  ownerId: PropTypes.number,
  operatorId: PropTypes.number,
  paidById: PropTypes.number,
  name: PropTypes.string,
  chainDetails: PropTypes.shape({
    id: PropTypes.number,
  }),
  houseCode: PropTypes.string,
  location: PropTypes.shape(),
  booking: PropTypes.shape(),
  profilePicture: PropTypes.shape({
    original: PropTypes.string,
  }),
  extraImages: PropTypes.shape(),
  matchPintOwner: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  mail: PropTypes.string,
  phone: PropTypes.string,
  website: PropTypes.string,
  masterPub: PropTypes.number,

  findUs: PropTypes.string,
  findUsTxt: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  managerFirstName: PropTypes.string,
  managerLastName: PropTypes.string,
  managerEmail: PropTypes.string,
  managerPhone: PropTypes.string,
  areaManager: PropTypes.string,

  salesPerson: PropTypes.number,

  twitterHandle: PropTypes.string,
  facebookURL: PropTypes.string,
  accessToSocialMediaTool: PropTypes.bool,
});
