/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 08/06/2018
 */

export const CHANGE_PASSWORD_FAILURE = 'changePassword/failure';
export const CHANGE_PASSWORD_SUCCESS = 'changePassword/success';
export const CHANGE_PASSWORD_PENDING = 'changePassword/pending';
