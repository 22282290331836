import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '../InfoIcon';
import './style.scss';
import request from '../../utils/apiRequest/requests';
import { getBase64FromFile } from '../../utils/helpers';
import MpResponse from '../../utils/apiRequest/MpResponse';
import FileUpload from '../FileUpload/FileUpload';
import { fileUploadAcceptTypes } from '../../utils/globalVariables';

const OnBoardingTileImage = ({ help, entity, entityId, handleEditField, imageLink, index }) => {
  const [errorMessage, setErrorMessage] = React.useState(false);

  const getPreviewComponent = () =>
    imageLink && (
      <div>
        <a href={imageLink} rel="noopener noreferrer" target="_blank">
          DOWNLOAD
        </a>
        <button onClick={() => handleEditField(null)} type="submit">
          DELETE
        </button>
      </div>
    );

  const uploadFile = (file, load, errorCallback) => {
    getBase64FromFile(file)
      .then(fileContent =>
        request('meta/image/upload', {
          method: 'POST',
          body: {
            uploadPath: `${entity}/${entityId}/onboarding-${index}`,
            fileContent,
          },
        }).then(res => {
          if (res.isOK()) {
            handleEditField(res.getResult());
            load();
          } else {
            setErrorMessage(MpResponse.getErrorMessage(res));
          }
        }),
      )
      .catch(err => {
        errorCallback();
        setErrorMessage(err && err.message ? err.message : 'Whoops Something went wrong');
      });
  };

  return (
    <>
      {errorMessage && <p>{errorMessage}</p>}
      <FileUpload
        acceptType={fileUploadAcceptTypes.image}
        processFile={uploadFile}
        deleteFile={() => handleEditField(null)}
        overridePreviewComponent={getPreviewComponent()}
      />
      <InfoIcon className="OfferUpload_info" tooltip={help} />
    </>
  );
};

OnBoardingTileImage.propTypes = {
  help: PropTypes.string.isRequired,
  entity: PropTypes.string.isRequired,
  entityId: PropTypes.number.isRequired,
  handleEditField: PropTypes.func.isRequired,
  imageLink: PropTypes.string,
  index: PropTypes.number.isRequired,
};

OnBoardingTileImage.defaultProps = {
  imageLink: null,
};

export default OnBoardingTileImage;
