/**            -                              ___
 *            |.|                          . /\_/\
 *          __]-[_________ /             .  (-<_>-)
 *         /        _____|<_          .   .  \/_\/
 *        / _   &  /               .   .
 *       / / \_ __|            .
 *     _/_/  / X   \
 *    <_/   /   ____\
 *         /___/|  /             zinedine zidane
 *         |  / ( <                --france--
 *         ( <   \ |
 *          \ |   >\
 *          _>|  (_/
 *         (__|
 *
 * MatchPint Ltd
 * @author Andreia Almeida
 * Date: 06/12/2018
 */

import createFormPage from '../formPage';
import TeamAutoComplete from './components/TeamAutoComplete';

const params = {
  endpoint: 'sports/fixtures/v2',
  sections: {
    General: ['id', 'name', 'startTimeUtc', 'endTimeUtc'],
    'Home Team': [
      {
        fieldPath: 'homeTeam',
        Component: TeamAutoComplete,
      },
    ],
    'Away Team': [
      {
        fieldPath: 'awayTeam',
        Component: TeamAutoComplete,
      },
    ],
    Config: ['active', 'usedInGame', 'allowMargin', 'sportId', 'competitionId'],
  },
  otherSection: true,
};

const FixturePage = createFormPage('fixture', params);

export default FixturePage;
