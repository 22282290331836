import createListPage from '../listPage';
import { NUMBER } from '../listPage/fieldTypes';
import tabs from '../../../routes/tabs';
import gameMonthPrize from './entityList';

export default gameMonthPrize.map(({ entity, game }) =>
  createListPage(
    entity,
    {
      id: {
        label: 'id',
        type: NUMBER,
      },
      gameId: {
        label: 'Game',
        type: NUMBER,
      },
      league: {
        label: 'League',
        renderValue: value => value.name,
      },
      prize: {
        label: 'Prize',
        renderValue: value => value.name,
      },
      month: {
        label: 'Month',
        renderValue: value => value && value.substr(0, 7),
      },
      roundId: {
        label: 'roundId',
        type: NUMBER,
      },
    },
    {
      navTabs: tabs[`${game.entityName}MonthPrize`],
    },
  ),
);
