/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 12/11/2018
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

// Redux.
import { createFetchDescription } from '../store/thunksFactory';

// UI Component.
import Form from './Form';

// Utils.
import { PAGE_TYPE_CREATE } from '../utils/constants';
import * as customPropTypes from '../utils/propTypes';
import SaveConfirmDialog from './SaveConfirmDialog';
import { createSetInitialDefaultValues } from '../store/actionCreatorFactory';
import { createGetDescription } from '../store/selectorFactory';
import SaveSuccessPopUp from './SaveSuccessPopUp/SaveSuccessPopUp';

const CreatePage = ({
  entityName,
  parameters,
  fetchAPIDescription,
  location,
  setInitialValues,
  description,
}) => {
  useEffect(() => {
    fetchAPIDescription(location.query);
  }, [fetchAPIDescription, location]);

  useEffect(() => {
    // if there is a form description then loop through and set all the default values
    if (Object.keys(description).length > 0) {
      setInitialValues();
    }
  }, [description, setInitialValues]);

  return (
    <>
      <SaveConfirmDialog entityName={entityName} parameters={parameters} />
      <SaveSuccessPopUp entityName={entityName} parameters={parameters} />
      <Form entityName={entityName} parameters={parameters} type={PAGE_TYPE_CREATE} />
    </>
  );
};

const mapStateToProps = (state, { entityName }) => ({
  description: createGetDescription(entityName)(state),
});

const mapDispatchToProps = (dispatch, { entityName, parameters }) => ({
  fetchAPIDescription: queryParams =>
    dispatch(createFetchDescription(entityName, parameters)(0, queryParams)),
  setInitialValues: () => dispatch(createSetInitialDefaultValues(entityName)()),
});

CreatePage.propTypes = {
  location: PropTypes.shape().isRequired,
  entityName: PropTypes.string.isRequired,
  fetchAPIDescription: PropTypes.func.isRequired,
  parameters: customPropTypes.parameters.isRequired,
  setInitialValues: PropTypes.func.isRequired,
  description: PropTypes.shape().isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePage);
