import React, { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { FlatButton } from 'material-ui';
import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton';
import TextField from 'material-ui/TextField';
import CopyIcon from 'material-ui/svg-icons/content/content-copy';
import CheckIcon from 'material-ui/svg-icons/action/check-circle';
import colors from '../../theme/index.scss';
import request, { handleRequest } from '../../utils/apiRequest/requests';
import MpModal from '../../components/MpModal/mpModal';
import LoadingWheel from '../../components/LoadingWheel';
import './style.scss';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: 'Bearer 7euwrt3726hjkfhd9be7ea397fb322558cde27c719a3894e703215e076c0bd1',
};

const URLShortenerField = ({ text, id, value, urlStart, placeholder, onChange, fullWidth }) => (
  <>
    <p className="URLShortener_container_info">{text}</p>
    <div className="URLShortener_container_inputBox_customizeBox">
      {urlStart && <p className="URLShortener_container_inputBox_customizeBox_text">{urlStart}</p>}
      <TextField
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        fullWidth={fullWidth}
      />
    </div>
  </>
);

const URLShortener = () => {
  const [urlList, setUrlList] = useState([]);
  const [longUrl, setLongUrl] = useState('');
  const [iOSUrl, setiOSUrl] = useState('');
  const [androidUrl, setAndroidUrl] = useState('');
  const [customCode, setCustomCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [codeToDelete, setCodeToDelete] = useState(null);

  const customRequest = (path, options) =>
    request(path, { headers, ...options }, 'https://fzo.to', 'omit').then(handleRequest);

  const refreshList = (pageTemp = 1, searchTextTemp = '') => {
    setIsLoading(true);
    return customRequest(`/list?page=${pageTemp}&search=${searchTextTemp}`)
      .then(response => {
        const data = response?.data;
        setIsLoading(false);
        setPage(pageTemp + 1);
        if (pageTemp === 1) {
          setUrlList(data.data);
        } else {
          setUrlList([...urlList, ...data.data]);
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    refreshList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shortenNewUrl = () => {
    const body = {
      url: longUrl,
      code: customCode.length ? customCode : null,
      ios_url: iOSUrl.length ? iOSUrl : null,
      android_url: androidUrl.length ? androidUrl : null,
      type: 'custom',
    };
    customRequest('/create', { method: 'POST', body }).then(() => {
      refreshList();
      setLongUrl('');
      setCustomCode('');
      setiOSUrl('');
      setAndroidUrl('');
    });
  };

  const deleteUrl = () => {
    customRequest(`/delete/${codeToDelete}`, { method: 'DELETE' })
      .then(() => refreshList())
      .finally(() => setCodeToDelete(null));
  };

  const debouncedSearch = debounce(val => refreshList(1, val), 1000);

  return (
    <div className="URLShortener">
      <MpModal
        mainText="Are you sure you want delete?"
        visible={Boolean(codeToDelete)}
        onConfirm={() => deleteUrl(codeToDelete)}
        onCancel={() => setCodeToDelete(null)}
      />
      <div className="URLShortener_container">
        <p className="URLShortener_container_info">1. Paste the long URL below</p>
        <div className="URLShortener_container_inputBox">
          <TextField
            required
            id="longUrl"
            fullWidth
            placeholder="https://www.fanzo.com/place-the-url-you-want-to-shorten"
            value={longUrl}
            onChange={e => setLongUrl(e.target.value)}
          />
          <URLShortenerField
            text="2. iOS URL"
            id="codeiOS"
            value={iOSUrl}
            fullWidth
            placeholder="Leave blank to use the main URL above"
            onChange={e => setiOSUrl(e.target.value)}
          />
          <URLShortenerField
            text="3. Android URL"
            id="codeAndroid"
            value={androidUrl}
            fullWidth
            placeholder="Leave blank to use the main URL above"
            onChange={e => setAndroidUrl(e.target.value)}
          />
          <URLShortenerField
            text="4. Add custom link"
            id="code"
            value={customCode}
            urlStart="fzo.to/r/"
            placeholder="Leave blank for random output"
            onChange={e => setCustomCode(e.target.value)}
          />
          <FlatButton
            backgroundColor={colors.primaryYellow}
            disabled={!longUrl}
            onClick={() => longUrl && shortenNewUrl()}
          >
            SHORTEN
          </FlatButton>
        </div>
      </div>
      <div className="URLShortener_urlWrapper">
        <input
          className="rounded-lg border px-2 py-1"
          onChange={e => debouncedSearch(e.target.value)}
          placeholder="Search"
        />
        {isLoading ? (
          <div>
            <LoadingWheel size="medium" />
          </div>
        ) : (
          urlList.map(url => (
            <UrlTab
              data={url}
              key={url.id}
              onDelete={code => setCodeToDelete(code)}
              isUpdating={Boolean(codeToDelete)}
            />
          ))
        )}
      </div>
      <button
        type="button"
        className="my-4 rounded-lg bg-primaryYellow px-6 py-2"
        onClick={() => refreshList(page)}
      >
        Load More
      </button>
    </div>
  );
};
const UrlTab = ({ data, onDelete, isUpdating }) => {
  const [isCopied, setIsCopied] = React.useState(false);

  const { code, ios_url: iosUrl, android_url: androidUrl, url } = data;

  const textAreaRef = useRef(null);

  const copyToClipboard = e => {
    textAreaRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 500);
  };

  return (
    <div className="UrlTab">
      <div className="UrlTab_linksBox">
        <div className="UrlTab_linksBox_short">
          <textarea
            ref={textAreaRef}
            className="UrlTab_linksBox_short_link"
            rows={1}
            readOnly
            value={`fzo.to/r/${code}`}
          />
          <IconButton style={{ alignSelf: 'center' }} onClick={copyToClipboard}>
            {!isCopied ? <CopyIcon /> : <CheckIcon />}
          </IconButton>
        </div>
        <p className="UrlTab_linksBox_orginal">{url}</p>
        {iosUrl && <p className="UrlTab_linksBox_orginal">iOS: {iosUrl}</p>}
        {androidUrl && <p className="UrlTab_linksBox_orginal">Android: {androidUrl}</p>}
      </div>

      <FlatButton disabled={isUpdating} onClick={() => onDelete(code)}>
        <span role="img" aria-label="Delete">
          🗑️
        </span>
      </FlatButton>
    </div>
  );
};

UrlTab.propTypes = {
  data: PropTypes.shape({
    code: PropTypes.string,
    url: PropTypes.string,
    ios_url: PropTypes.string,
    android_url: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
};

URLShortenerField.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  urlStart: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
};

URLShortenerField.defaultProps = {
  urlStart: undefined,
  fullWidth: false,
};

export default URLShortener;
