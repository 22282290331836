/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 17/10/2018
 */
import React from 'react';
import PropTypes from 'prop-types';

import TextField from 'material-ui/TextField';
import InfoIcon from '../../InfoIcon';

import './index.scss';

const NumberEdit = ({ error, handleEditField, help, helpPosition, name, value, min }) => (
  <div className="NumberEdit__item">
    <div className="NumberEdit__item_content">
      <span>{name}</span>
      <TextField
        className="NumberEdit__item_content_value"
        errorText={error}
        id={name}
        onChange={(e, newValue) => handleEditField(newValue)}
        type="number"
        min={min}
        value={value !== null ? value.toString() : ''}
      />
    </div>
    {help && <InfoIcon tooltipPosition={helpPosition} tooltip={help} />}
  </div>
);

NumberEdit.propTypes = {
  error: PropTypes.string,
  handleEditField: PropTypes.func.isRequired,
  help: PropTypes.string,
  helpPosition: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.number,
};

NumberEdit.defaultProps = {
  error: null,
  help: null,
  helpPosition: 'bottom-left',
  value: null,
  min: null,
};

export default NumberEdit;
