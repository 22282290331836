/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 08/06/2018
 */
import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const PublicPageWrapper = ({ children }) => (
  <div className="PublicPage_full">
    <div className="PublicPage__background">
      <div className="PublicPage__background_left_banner">
        <img
          src="/assets/yellow_transparent_logo.jpg"
          alt="logo"
          className="PublicPage__background_mp_logo"
        />
      </div>
      <div className="PublicPage__background_main" />
    </div>
    <div className="PublicPage__main">{children}</div>
  </div>
);

PublicPageWrapper.propTypes = {
  children: PropTypes.node,
};

PublicPageWrapper.defaultProps = {
  children: null,
};

export default PublicPageWrapper;
