/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 26/07/2018
 */

import React from 'react';
import PropTypes from 'prop-types';

import { TwitterPicker } from 'react-color';

import InfoIcon from '../InfoIcon';

import './index.scss';
import colors from '../../theme/index.scss';

const ColorPickerWithPreview = ({
  classname,
  currentColor,
  defaultColors,
  handleChangeColor,
  help,
  isPhone,
  title,
}) => (
  <div className={`${classname} ColorPickerWithPreview__wrapper`}>
    <div className="ColorPickerWithPreview__title">
      <div className="ColorPickerWithPreview__title_text">{title}</div>
      {help ? <InfoIcon tooltip={help} tooltipPosition="bottom-right" /> : null}
    </div>
    <div className="ColorPickerWithPreview__picker">
      <TwitterPicker
        color={currentColor || {}}
        colors={defaultColors}
        onChangeComplete={color => handleChangeColor(color.hex)}
        triangle="hide"
        width={isPhone ? 180 : 400}
      />
    </div>
    <div className="ColorPickerWithPreview__preview_wrapper">
      <div
        className="ColorPickerWithPreview__preview"
        style={{
          backgroundColor: currentColor,
        }}
      />
    </div>
  </div>
);

ColorPickerWithPreview.propTypes = {
  classname: PropTypes.string,
  currentColor: PropTypes.string,
  defaultColors: PropTypes.arrayOf(PropTypes.string),
  handleChangeColor: PropTypes.func,
  help: PropTypes.string,
  isPhone: PropTypes.bool,
  title: PropTypes.string,
};

ColorPickerWithPreview.defaultProps = {
  classname: null,
  currentColor: '',
  defaultColors: [
    colors.primaryYellow,
    colors.primaryRed,
    colors.primaryGreen,
    colors.primaryOrange,
    colors.primaryPurple,
    colors.primaryLightBlue,
    colors.veryLightGrey,
    colors.standardGrey,
    colors.darkGrey,
    colors.black,
  ],
  handleChangeColor: () => {},
  help: null,
  isPhone: false,
  title: null,
};

export default ColorPickerWithPreview;
