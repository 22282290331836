import createFormPage from '../formPage';
import tabs from '../../../routes/tabs';

const params = {
  endpoint: 'contents/trivias',
  create: { allFields: true },
  tabs: tabs.trivia,
};

export default createFormPage('trivias', params);
