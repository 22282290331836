/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 24/09/2018
 */

class ServerError extends Error {
  constructor(message, code) {
    super();
    this.isServerError = true;
    // Built using the link below
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#Custom_Error_Types.
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ServerError);
    }

    this.message = message;
    this.code = code;
  }
}

export default ServerError;
