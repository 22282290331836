import { request } from './requests';

export const fetchSports = countryId => dispatch =>
  dispatch(
    request(`sports/?limit=-1&active=1&countryId=${countryId}`, {
      method: 'GET',
    }),
  );

export const fetchCompetitionsList = (countryId, sportId) => dispatch =>
  dispatch(
    request(`sports/competitions?sportId=${sportId}&countryId=${countryId}&limit=999`, {
      method: 'GET',
    }),
  );

export const saveSportsPriority = (sportIds, countryId) => dispatch =>
  dispatch(
    request('sports/priority/save', {
      method: 'PUT',
      body: {
        countryId,
        ids: sportIds,
      },
    }),
  );

export const saveCompetitionsPriority = (competitionIds, countryId, sportId) => dispatch =>
  dispatch(
    request('/sports/competitions/priority/save', {
      method: 'PUT',
      body: {
        countryId,
        ids: competitionIds,
        sportId,
      },
    }),
  );
