import createListPage from '../listPage';
import eventsList from './entityList';

import tabs from '../../../routes/tabs';

const groupCodeListPage = createListPage(
  eventsList,
  {
    name: {
      label: 'Name',
    },
    groupCode: {
      label: 'Group code',
    },
    nbActivePubs: {
      label: 'Active pubs',
    },
  },
  {
    navTabs: tabs.groupCode,
  },
);

export default groupCodeListPage;
