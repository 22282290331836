import tabs from '../../../routes/tabs';
import createFormPage from '../formPage';
import UploadBatch from './UploadBatch';

const formData = {
  endpoint: `venues/venue-completer`,
  sections: {
    GENERAL: [
      { fieldPath: 'id' },
      { fieldPath: 'assignedToMemberId' },
      { fieldPath: 'id', Component: UploadBatch },
    ],
  },
  otherSection: false,
  tabs: tabs.venueCompleter,
  create: {
    fields: ['name', 'assignedToMemberId', 'client'],
  },
};

export default createFormPage('venueCompleterBatch', formData);
