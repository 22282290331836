import React from 'react';
import quizRound from './entityList';
import { STRING, NUMBER, BOOLEAN } from '../listPage/fieldTypes';

import createListPage from '../listPage';
import tabs from '../../../routes/tabs';
import ConfigOk from './Components/ConfigOk';

const columns = {
  id: {
    label: 'Id',
    type: STRING,
  },
  name: {
    label: 'Name',
    type: STRING,
  },
  quizId: {
    label: 'Quiz ID',
    type: NUMBER,
  },
  configOk: {
    label: 'Config OK',
    type: BOOLEAN,
    renderValue: value => <ConfigOk value={value} />,
    style: {
      width: '40%',
    },
  },
};

export default quizRound.map(entity =>
  createListPage(entity, columns, {
    navTabs: tabs[entity.entityName],
  }),
);
