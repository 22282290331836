import {
  fetchTranslationsSuccess,
  fetchTranslationsError,
  updateTranslationsPending,
  updateTranslationsSuccess,
  updateTranslationsError,
  fetchTranslationsPending,
} from './actions';
import { fetchTranslations, updateTranslations } from '../thunks/api-translations';

export const fetchTranslationsForEntity = (endpoint, id) => dispatch => {
  dispatch(fetchTranslationsPending());
  return dispatch(fetchTranslations(endpoint, id))
    .then(r => dispatch(fetchTranslationsSuccess(r.getResult())))
    .catch(err => dispatch(fetchTranslationsError(err && err.message)));
};

export const updateTranslationsForEntity = (endpoint, id, translations) => dispatch => {
  dispatch(updateTranslationsPending());
  return dispatch(updateTranslations(endpoint, id, translations))
    .then(() => dispatch(updateTranslationsSuccess()))
    .catch(err => {
      dispatch(updateTranslationsError(err.message));
      return false;
    });
};
