export const FETCH_REPORT_PENDING = 'report/fetch/pending';
export const FETCH_REPORT_SUCCESS = 'report/fetch/success';
export const FETCH_REPORT_FAIL = 'report/fetch/fail';

export const SET_AVAILABLE_FILTERS = 'reports/set/availableFilters';
export const SET_APPLIED_FILTERS = 'reports/set/appliedFilters';
export const REPORT_ADD_FILTER = 'reports/add/filter';
export const REPORT_REMOVE_FILTER = 'reports/remove/filter';

export const CLEAR_REPORT = 'report/clear';
