import { FlatButton } from 'material-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ModeViewPortal from 'material-ui/svg-icons/action/launch';
import colors from '../../../theme/index.scss';

const ActionButtons = ({ fullResource: { matchpintUrl, pubPortalWebsite }, isDesktop }) => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <FlatButton
      backgroundColor={colors.standardGrey}
      icon={<ModeViewPortal color={colors.white} />}
      label={isDesktop ? 'View On Portal' : ''}
      onClick={() => window.open(pubPortalWebsite)}
      labelStyle={{ color: colors.white }}
    />
    <FlatButton
      backgroundColor={colors.standardGrey}
      icon={<ModeViewPortal color={colors.white} />}
      label={isDesktop ? 'View On Website' : null}
      onClick={() => window.open(matchpintUrl)}
      labelStyle={{ color: colors.white }}
    />
  </div>
);

ActionButtons.propTypes = {
  fullResource: PropTypes.shape().isRequired,
  isDesktop: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isDesktop: state.media.isDesktop,
});

export default connect(mapStateToProps)(ActionButtons);
