import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'material-ui';
import AutoInAndOutSelector from '../../../../components/AutoInAndOutSelector';

const CountriesSelector = ({ editedValue }) => (
  <AutoInAndOutSelector
    requestBodyName="ids"
    activeItemsString="Active countries"
    availableItemsString="Add countries"
    formatName={data =>
      data.map(item => ({
        id: item.id,
        name: item.name,
        leftAvatar: <Avatar size={30}>{item.id}</Avatar>,
      }))
    }
    endpoints={{
      fetchActive: `/games/${editedValue}/countries?`,
      fetchAvailable: `/games/${editedValue}/countries/available?`,
      add: `games/${editedValue}/countries/add-country`,
      remove: `games/${editedValue}/countries/remove-country`,
    }}
  />
);

CountriesSelector.propTypes = {
  editedValue: PropTypes.number.isRequired,
};

export default CountriesSelector;
