import { request } from './requests';

export const fetchTranslations = (endpoint, id) => dispatch =>
  dispatch(request(`${endpoint}/${id}/translations-list`));

export const updateTranslations = (endpoint, id, translations) => dispatch =>
  dispatch(
    request(`${endpoint}/${id}/translations-save`, {
      method: 'POST',
      body: {
        translations,
      },
    }),
  );
