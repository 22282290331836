/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 24/09/2018
 */

import ServerError from './ServerError';

/**
 * @description Error thrown when the server returns a Form error.
 */
class FormError extends ServerError {
  /**
   * @param {Object} fieldsError
   * @param {string} message
   * @param {number} code
   */
  constructor(fieldsError, message, code) {
    super(message, code);
    this.isFormError = true;
    // Built using the link below
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#Custom_Error_Types.
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FormError);
    }

    this.fieldsError = fieldsError;
  }
}

export default FormError;
