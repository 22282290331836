import venueGroupList from './entityList';

import createListPage from '../listPage';
import tabs from '../../../routes/tabs';

import { STRING, NUMBER } from '../listPage/fieldTypes';

const venueGroupsPage = createListPage(
  venueGroupList,
  {
    id: {
      label: 'ID',
      type: NUMBER,
    },
    name: {
      label: 'Name',
      type: STRING,
    },
  },
  {
    navTabs: tabs.venueGroups,
  },
);

export default venueGroupsPage;
