//              __
//             /(`o
//       ,-,  //  \\
//      (,,,) ||   V
//     (,,,,)\//
//     (,,,/w)-'
//     \,,/w)
//     `V/uu
//       / |
//       | |
//       o o
//       \ |
//  \,/  ,\|,.  \,/
//
import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'material-ui';
import AutoInAndOutSelctor from '../../../components/AutoInAndOutSelector';

const PermissionsSelector = ({ editedValue }) => (
  <AutoInAndOutSelctor
    requestBodyName="permissionIds"
    activeItemsString="Active Permissions"
    availableItemsString="Add permissions"
    formatName={data =>
      data.map(item => ({
        id: item.id,
        name: item.name,
        leftAvatar: <Avatar size={30}>{item.id}</Avatar>,
      }))
    }
    endpoints={{
      fetchActive: `meta/pub-portal/tiers/${editedValue}/permissions?`,
      fetchAvailable: `meta/pub-portal/tiers/${editedValue}/permissions?inTier=0&`,
      add: `meta/pub-portal/tiers/${editedValue}/permissions/add`,
      remove: `meta/pub-portal/tiers/${editedValue}/permissions/remove`,
    }}
  />
);

PermissionsSelector.propTypes = {
  editedValue: PropTypes.number.isRequired,
};

export default PermissionsSelector;
