import createFormPage from '../formPage';
import Channels from './Channels';
import UpdateSearch from './UpdateSearch';

const params = {
  endpoint: 'sports/fixtures',
  sections: {
    General: [
      'id',
      'abelsonUID',
      'name',
      'sportId',
      'endTimeUtc',
      'startTimeUtc',
      'image',
      'bigOpportunity',
      'team1',
      'team2',
      'competitionOverride',
      'strapiCopyId',
      { fieldPath: 'id', Component: UpdateSearch },
    ],
    Channels: [
      {
        fieldPath: 'channelByCountry',
        Component: Channels,
      },
    ],
  },
};

const EventPage = createFormPage('events', params);

export default EventPage;
