/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 30/04/2018
 */
import { isAuthenticated, getAuthenticatedMember } from '../selectors';
import { getMember } from '../../thunks/api-member';
import {
  loadAuthenticatedMember,
  loadAuthenticatedMemberSuccess,
  loadAuthenticatedMemberError,
} from '../actions';
import { MpCookieGet } from '../../../utils/MpCookie';

const checkAuthentication = () => (dispatch, getState) => {
  dispatch(loadAuthenticatedMember());

  const state = getState();

  if (isAuthenticated(state)) {
    dispatch(loadAuthenticatedMemberSuccess({ member: getAuthenticatedMember(state) }));
    return Promise.resolve();
  }

  if (MpCookieGet('id') && MpCookieGet('token')) {
    return dispatch(getMember(MpCookieGet('id')))
      .then(response => response.getResult())
      .then(result => {
        dispatch(loadAuthenticatedMemberSuccess({ member: result }));
        return Promise.resolve();
      })
      .catch(error => {
        dispatch(loadAuthenticatedMemberError({ error: { message: error.message } }));
        return Promise.reject();
      });
  }

  dispatch(loadAuthenticatedMemberError({ error: { message: 'No Cookie found for this user.' } }));
  return Promise.reject();
};

export default checkAuthentication;
