import React from 'react';
import PropTypes from 'prop-types';
import './users.scss';

const Users = ({ editedValue }) => (
  <>
    <p>Please use Pogba if you wish to update contacts</p>
    <div className="table">
      <p className="cell tenth">id</p>
      <p className="cell half">email</p>
      <p className="cell fifth">name</p>
      <p className="celltenth tenth">phoneNumber</p>
      <p className="cell tenth">role</p>
      {editedValue &&
        editedValue?.map(({ email, name, id, role, phoneNumber }) => (
          <React.Fragment key={id}>
            <p className="cell tenth">{id}</p>
            <p className="cell half">{email}</p>
            <p className="cell fifth">{name}</p>
            <p className="celltenth tenth">{phoneNumber}</p>
            <p className="cell tenth">{role}</p>
          </React.Fragment>
        ))}
    </div>
  </>
);

Users.propTypes = {
  editedValue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Users;
