/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */
/**
 * Object representing a response from Matchpint API servers.
 */
class MpResponse {
  constructor(status, data) {
    this.status = status;
    this.data = data;
  }

  /**
   * @description Returns if the response doesn't describe any error.
   * @returns {boolean}
   */
  isOK() {
    return this.status >= 200 && this.status < 300;
  }

  /**
   * @description When updating an entity/feature, if some fields in the form are wrong,
   * The answer will still be 2XX but will have an error field.
   * @returns {boolean}
   */
  isFormError() {
    return Object.hasOwnProperty.call(this.data, 'error');
  }

  /**
   * @description Gets the error message from an error object.
   * @param error
   */
  static getErrorMessage(error) {
    if (error instanceof MpResponse) {
      if (typeof error.data.error === 'string') {
        return error.data.error;
      }
      return error.data.error.message;
    }
    if (error.response instanceof MpResponse) {
      if (typeof error.response.data.error === 'string') {
        return error.response.data.error;
      }
      return error.response.data.error.message;
    }
    if (typeof error.message === 'string') {
      return error.message;
    }
    if (typeof error.response === 'string') {
      return error.response;
    }
    return error.response.message;
  }

  /**
   * @description Returns the result of the response.
   * @returns {Array|Object}
   */
  getResult() {
    return Object.hasOwnProperty.call(this.data, 'result') ? this.data.result : this.data;
  }

  /**
   * @description Returns the filters described in the response
   *  (to use with the query parameter { filtersDescription: true }).
   * @returns {Object}
   */
  getFilters() {
    // Condition for services that don't have the "filters" implemented yet.
    if (!Object.hasOwnProperty.call(this.data, 'filters')) {
      return {};
    }
    return this.data.filters;
  }

  /**
   * @description Returns the available sorting options described in the response
   *  (to use with the query parameter { filtersDescription: true }).
   * @returns {Object}
   */
  getSortOptions() {
    if (!Object.hasOwnProperty.call(this.data, 'sortColumns')) {
      return {};
    }
    return this.data.sortColumns;
  }

  getListCount() {
    if (this.data.matches === undefined) {
      // eslint-disable-next-line no-console
      console.warn(
        '"matches" field no existing for a Response. Make sure you have given the query parameter "numberOfMatches" in your request',
      );
    }
    return this.data.matches;
  }
}

export default MpResponse;
