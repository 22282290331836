import React from 'react';
import createListPage from '../listPage';
import socialMediaCompetitionCountriesList from './entityList';
import tabs from '../../../routes/tabs';

const socialMediaCompetitionCountriesListPage = createListPage(
  socialMediaCompetitionCountriesList,
  {
    competitionId: {
      label: 'Competition',
      renderValue: (value, values) =>
        values.competition && values.competition.name ? values.competition.name : value,
      style: {
        width: '10%',
      },
    },
    countryId: {
      label: 'Country',
      renderValue: (value, values) =>
        values.country && values.country.name ? values.country.name : value,
      style: {
        width: '10%',
      },
    },
    logo: {
      label: 'Logo',
      renderValue: value => (
        <img style={{ maxWidth: 100, maxHeight: 100 }} src={value} alt="Logo" />
      ),
      style: {
        width: '60%',
      },
    },
  },
  {
    navTabs: tabs.socialMediaCompetitionCountries,
  },
);

export default socialMediaCompetitionCountriesListPage;
