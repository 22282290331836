/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 26/04/2018
 */
import React from 'react';
import PropTypes from 'prop-types';

// UI imports
import Chip from 'material-ui/Chip';
import Paper from 'material-ui/Paper';
import Subheader from 'material-ui/Subheader';
import { filterTypes } from '../../../../forms/formPage/utils/propTypes';
import { filterOptions } from '../../../../../utils/customPropTypes';

/**
 * @description Section containing the active Filters.
 * @returns {node}
 * @constructor
 */
const ActiveFiltersSection = ({ availableFilters, activeFilters, isPhone, removeFilter }) =>
  Object.keys(activeFilters).length > 0 || !isPhone ? (
    <Paper className="FilterSection__ActiveFiltersSection">
      <Subheader className="FilterSection__ActiveFiltersSection_title">Applied filters</Subheader>
      <div className="FilterSection__ActiveFiltersSection_container">
        {Object.keys(activeFilters).map(id => {
          if (activeFilters[id] === null) {
            return null;
          }
          if (Object.keys(availableFilters).includes(id)) {
            const filterDescription = availableFilters[id];
            const filterValue = activeFilters[id];
            let filterLabel;
            switch (filterDescription.type) {
              case 'boolean': {
                filterLabel = `${filterDescription.title}: ${filterValue ? 'YES' : 'NO'}`;
                break;
              }
              case 'enum': {
                filterLabel = `${
                  filterDescription.title
                }: ${filterDescription.options.possibleValues
                  .filter(
                    elm =>
                      elm.key === filterValue ||
                      (Array.isArray(filterValue) && filterValue.includes(elm.key)),
                  )
                  .map(elm => elm.value)}`;
                break;
              }
              default: {
                filterLabel = `${filterDescription.title}: ${filterValue}`;
                break;
              }
            }
            return (
              <Chip
                className="FilterSection__ActiveFiltersSection_filter"
                key={id}
                onRequestDelete={() => removeFilter(id)}
              >
                {filterLabel}
              </Chip>
            );
          }
          // eslint-disable-next-line no-console
          console.warn(`Wrong filter for the key ${id}.`);
          return <div key={id} />;
        })}
      </div>
    </Paper>
  ) : (
    <div />
  );

ActiveFiltersSection.propTypes = {
  activeFilters: PropTypes.shape(),
  availableFilters: PropTypes.objectOf(
    PropTypes.shape({
      options: filterOptions,
      title: PropTypes.string.isRequired,
      type: PropTypes.oneOf(filterTypes).isRequired,
    }),
  ),
  isPhone: PropTypes.bool.isRequired,
  removeFilter: PropTypes.func.isRequired,
};

ActiveFiltersSection.defaultProps = {
  availableFilters: {},
  activeFilters: {},
};

export default ActiveFiltersSection;
