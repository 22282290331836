import React from 'react';
import createFormPage from '../formPage';

const params = {
  endpoint: 'offers/redemption-code',
  create: {
    allFields: true,
  },
  information: (
    <p>
      Setting or updating member id will not give the member an offer. You will also need to assign
      the offer to the member
    </p>
  ),
};

export default createFormPage('offerCodes', params);
