/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 09/11/2018
 */
import { connect } from 'react-redux';

// Store functions.
import * as select from '../store/selectorFactory';

// UI Component.
import FormAlert from '../../../../components/FormAlert';

// Utils.
import { PAGE_TYPE_EDIT, PAGE_TYPE_CREATE } from '../utils/constants';

/**
 * @description Returns the "Fetch error" selector base on the page type.
 * @param {string} entityName
 * @param {Object} parameters
 * @param {string} type
 * @return {function}
 */
function getFetchErrorSelector(entityName, parameters, type) {
  switch (type) {
    case PAGE_TYPE_EDIT:
      return select.createGetFetchDataError(entityName, parameters);
    case PAGE_TYPE_CREATE:
    default:
      return () => null;
  }
}

/**
 * @description Returns the "Save error" selector base on the page type.
 * @param {string} entityName
 * @param {Object} parameters
 * @param {string} type
 * @return {function}
 */
function getSaveErrorSelector(entityName, parameters, type) {
  switch (type) {
    case PAGE_TYPE_EDIT:
      return select.createGetEditionError(entityName, parameters);
    case PAGE_TYPE_CREATE:
      return select.createGetCreationError(entityName, parameters);
    default:
      return () => null;
  }
}

/**
 * @description Returns the "Field errors" selector base on the page type.
 * @param {string} entityName
 * @param {Object} parameters
 * @param {string} type
 * @return {function}
 */
function getFieldErrorsSelector(entityName, parameters, type) {
  switch (type) {
    case PAGE_TYPE_EDIT:
      return select.createGetEditionFieldErrors(entityName, parameters);
    case PAGE_TYPE_CREATE:
      return select.createGetCreationFieldErrors(entityName, parameters);
    default:
      return () => null;
  }
}

const mapStateToProps = (state, { entityName, parameters, type }) => {
  const formDescription = select.createGetDescription(entityName, parameters)(state);
  const fetchDescriptionError = select.createGetDescriptionFetchError(
    entityName,
    parameters,
  )(state);

  const fetchError = getFetchErrorSelector(entityName, parameters, type)(state);
  const fieldErrors = getFieldErrorsSelector(entityName, parameters, type)(state);
  const saveError = getSaveErrorSelector(entityName, parameters, type)(state);

  let errorMessageToDisplay = null;
  let fieldsErrorsToDisplay;

  if (fieldErrors) {
    fieldsErrorsToDisplay = fieldErrors;
  }

  if (saveError) {
    errorMessageToDisplay = saveError;
  }

  if (fetchError) {
    errorMessageToDisplay = fetchError;
  }

  if (fetchDescriptionError) {
    errorMessageToDisplay = fetchDescriptionError;
  }

  return {
    errorMessage: errorMessageToDisplay,
    fieldsError: fieldsErrorsToDisplay,
    description: formDescription,
  };
};

export default connect(mapStateToProps)(FormAlert);
