import trivia from './entityList';
import { STRING } from '../listPage/fieldTypes';

import createListPage from '../listPage';
import tabs from '../../../routes/tabs';

const triviaList = createListPage(
  trivia,
  {
    id: {
      label: 'Id',
      type: STRING,
      style: {
        width: '30px',
      },
    },
    question: {
      label: 'Question',
      type: STRING,
      renderValue: val => val && val.text,
    },
    answer: {
      label: 'Answer',
      type: STRING,
      renderValue: val => val && val.text,
    },
  },
  {
    navTabs: tabs.trivia,
  },
);

export default triviaList;
