import React, { useEffect, useState } from 'react';
import './styles.scss';
import { connect } from 'react-redux';
import FlatButton from 'material-ui/FlatButton/FlatButton';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import * as types from '../FormField/types';
import { updateTranslationsForEntity } from '../../store/translations/thunks';
import {
  getTranslationsError,
  getTranslationsUpdating,
  getInitialTranslations,
} from '../../store/translations/selectors';
import { deepCopyOfObject } from '../../utils';

import LoadingWheel from '../LoadingWheel';
import TextField from '../FormField/Text';
import ReadOnly from '../FormField/ReadOnly';
import DateTime from '../FormField/DateTime/dateTime';
import FileUpload from '../FileUpload/FileUpload';
import { getBase64FromFile } from '../../utils/helpers';
import ColorPickerWithPreview from '../ColorPickerWithPreview';
import { getAuthenticatedMember } from '../../store/member/selectors';

const UKLocaleID = 1;

const TranslationField = ({
  endpoint,
  initialTranslations,
  fieldName,
  label,
  updateTranslation,
  error,
  pending,
  id,
  defualtField,
  type,
  member,
  defaultValue,
}) => {
  const [localInitialTranslations, setLocalInitialTranslations] = useState(initialTranslations);
  const [selectedLocale, setSelectedLocale] = useState(member?.locales[0]);
  const [saveSuccess, setSaveSuccess] = useState('');
  const [value, setValue] = useState([]);
  const hasChanges = JSON.stringify(value) !== JSON.stringify(localInitialTranslations);

  useEffect(() => {
    if (initialTranslations) {
      setValue(initialTranslations);
      setLocalInitialTranslations(initialTranslations);
    }
  }, [initialTranslations]);

  // if the entitiy doesnt have an id then just return the deault - bit of a catch for create entities
  if (!id) {
    return defualtField;
  }

  const findValue = localeId => {
    const index = value.findIndex(t => parseInt(t.localeId, 10) === localeId);
    return index > -1 ? value[index][fieldName] : '';
  };

  const changeValue = (val, localeId) => {
    const newVal = deepCopyOfObject(value);
    const index = value.findIndex(t => parseInt(t.localeId, 10) === localeId);
    if (index > -1) {
      newVal[index][fieldName] = val;
    } else {
      newVal.push({ localeId, [fieldName]: val });
    }
    setValue(newVal);
  };

  const getUKTranslationField = () => {
    const ukLocale = member.locales.find(v => v.localeId === UKLocaleID);
    if (!ukLocale?.editable) {
      return <ReadOnly name={label} text={defaultValue} />;
    }
    return defualtField;
  };

  return (
    <div className={`TranslationFields${hasChanges ? '_unsaved' : ''}`}>
      <div className="TranslationFields_countrySelector">
        {member?.locales?.map(locale => (
          <FlatButton
            key={locale.localeId}
            onClick={() => setSelectedLocale(locale)}
            className={`TranslationFields_countrySelector_item${
              locale.localeId === selectedLocale?.localeId ? '_selected' : ''
            }`}
          >
            <img src={locale.flag} alt="" />
            <p>{locale.localeName}</p>
            {locale.localeId !== UKLocaleID && <p>{findValue(locale.localeId) ? ' ✅' : ' ❌'}</p>}
          </FlatButton>
        ))}
      </div>
      {selectedLocale.localeId !== UKLocaleID ? (
        <LocaleField
          disabled={!selectedLocale.editable}
          type={type}
          onChange={val => {
            changeValue(val, selectedLocale.localeId);
            setSaveSuccess('');
          }}
          value={findValue(selectedLocale.localeId)}
          label={label}
        />
      ) : (
        getUKTranslationField()
      )}
      {pending && <LoadingWheel />}
      {!pending && hasChanges && (
        <FlatButton
          className="TranslationFields_saveButton"
          onClick={() =>
            updateTranslation(endpoint, id, value).then(r => {
              if (r) {
                setLocalInitialTranslations(value);
                setSaveSuccess('Successfully saved');
              }
            })
          }
        >
          Save translations
        </FlatButton>
      )}
      {saveSuccess && <p className="TranslationFields_success">{saveSuccess}</p>}
      {error && <p className="TranslationFields_error">{error}</p>}
      {hasChanges && (
        <p className="TranslationFields_error">You have unsaved translation changes</p>
      )}
    </div>
  );
};

TranslationField.propTypes = {
  endpoint: PropTypes.string.isRequired,
  initialTranslations: PropTypes.arrayOf(PropTypes.shape()),
  fieldName: PropTypes.string.isRequired,
  updateTranslation: PropTypes.func.isRequired,
  error: PropTypes.string,
  pending: PropTypes.bool,
  member: PropTypes.shape(),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  defualtField: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(types)).isRequired,
  defaultValue: PropTypes.string,
};
TranslationField.defaultProps = {
  initialTranslations: null,
  pending: false,
  error: null,
  member: null,
  defaultValue: null,
};

const mapStateToProps = state => ({
  initialTranslations: getInitialTranslations(state),
  error: getTranslationsError(state),
  pending: getTranslationsUpdating(state),
  member: getAuthenticatedMember(state),
});

const mapDispatchToProps = dispatch => ({
  updateTranslation: (endpoint, id, translations) =>
    dispatch(updateTranslationsForEntity(endpoint, id, translations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TranslationField);

const LocaleField = ({ onChange, value, label, type, disabled, help }) => {
  switch (type) {
    case types.STRING:
      return (
        <TextField
          disabled={disabled}
          name={label}
          fullWidth
          handleEditField={val => onChange(val)}
          placeholder="No translation set"
          text={value}
          containerClassName="TranslationFields_input"
        />
      );
    case types.IMAGE:
      return (
        <FileUpload
          label={label}
          filePath={value}
          processFile={(file, load, errorCallback) => {
            getBase64FromFile(file)
              .then(base64 => {
                onChange(base64);
                load();
              })
              .catch(errorCallback);
          }}
          deleteAction={() => onChange()}
        />
      );
    case types.UTCDATETIME:
      return (
        <DateTime
          dateTime={value}
          handleEditField={val => onChange(moment.unix(moment(val).valueOf() / 1000).format())}
          help={help}
          name={label}
        />
      );
    case types.HEXCOLOR:
      return (
        <ColorPickerWithPreview
          title={label}
          help={help}
          handleChangeColor={onChange}
          currentColor={value}
        />
      );
    default:
      return (
        <TextField
          disabled={disabled}
          name={label}
          fullWidth
          handleEditField={val => onChange(val)}
          placeholder="No translation set"
          text={value}
          containerClassName="TranslationFields_input"
        />
      );
  }
};

LocaleField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  help: PropTypes.string,
  type: PropTypes.oneOf(Object.values(types)).isRequired,
  disabled: PropTypes.bool.isRequired,
};

LocaleField.defaultProps = {
  value: '',
  help: null,
};
