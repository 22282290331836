/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 17/10/2018
 */

/**
 * @description Based on a formDescription and a formFieldsError objects, returns an array of
 *  formatted error to display.
 * @param {Object} fieldsError
 * @param {Object} formDescription
 * @return {[{
 *   label: string,
 *   errorMessage: string
 * }]}
 */
export function getFormattedFieldsErrors(fieldsError, formDescription) {
  let formattedError = [];

  if (!fieldsError) {
    return [];
  }

  Object.keys(fieldsError).forEach(field => {
    // We can have some deep trees for entity's fields:
    // we need therefore to recursively get the associated errors.

    if (typeof fieldsError[field] === 'string' || Array.isArray(fieldsError[field])) {
      // First case: we are at the end of a branch.
      // fieldsError[field] contains the error/list of errors
      const label = formDescription?.[field]?.label || field;

      const errorMessage =
        typeof fieldsError[field] === 'string' ? fieldsError[field] : fieldsError[field].join(', ');

      formattedError.push({
        label,
        errorMessage,
      });
    } else {
      // Second case: we need to call getFormattedFieldsErrors recursively on each leaf.
      // fieldsError[field] is itself an object of formErrors.
      const recursiveErrors = getFormattedFieldsErrors(
        fieldsError[field],
        formDescription?.[field],
      );

      formattedError = formattedError.concat(recursiveErrors);
    }
  });

  return formattedError;
}

/**
 * @description Returns from an object description the list of pathList to access to access to the
 *  fields description.
 * @param {Object} formDescription
 * @param {Array<Array<string>>} baseList Base list to add the pathLists to
 *  (field used for recursion).
 * @return {
 *   Array<Array<string>>
 * }
 */
export function getDescriptionFieldPathLists(formDescription, baseList = []) {
  if (!formDescription || typeof formDescription !== 'object') {
    return [];
  }

  // We rely on the "type" field to know when to stop: an object is a field description if and only
  // if it contains a field "type" which value is a non-empty string.
  if (formDescription.type && typeof formDescription.type === 'string') {
    return [baseList];
  }

  let newPathList = [];

  Object.keys(formDescription).forEach(field => {
    newPathList = [
      ...newPathList,
      ...getDescriptionFieldPathLists(formDescription?.[field], [...baseList, field]),
    ];
  });

  return newPathList;
}
