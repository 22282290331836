/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 17/10/2018
 */

export const BOOLEAN = 'boolean';
export const DATE = 'date';
export const UTCDATETIME = 'UTCDateTime';
export const DATETIME = 'dateTime';
export const FLOAT = 'float';
export const INTEGER = 'integer';
export const LONG_TEXT = 'longText';
export const STRING = 'string';
export const TIME = 'time';
export const IMAGE = 'image';
export const INOUTSELECTOR = 'inOutSelector';
export const HEXCOLOR = 'HexColour';
export const VIDEO = 'video';
export const CSV = 'csv';
