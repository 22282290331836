/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */

/**
 * Returns the correct help message for the tooltip,
 * Regarding if it is not loaded yet, present, or absent.
 * @param {Object} entityDescription
 * @param {string} field
 * @return {string}
 */
export const getHelpTip = (entityDescription, field) => {
  if (Object.keys(entityDescription).length === 0) {
    return 'Wait..';
  }
  if (entityDescription[field]) {
    return entityDescription[field].label;
  }
  return 'Not Available Yet';
};

/**
 * Returns the possible max length of a field based on the description of an entity.
 * @param {Object} entityDescription
 * @param {string} field
 * @returns {number|null}
 */
export const getMaxLengthFromDescription = (entityDescription, field) => {
  if (Object.keys(entityDescription).length === 0) {
    return null;
  }

  if (!entityDescription[field]) {
    return null;
  }

  return entityDescription[field].options.maxLength;
};

export const getKeyByValue = (object, value) =>
  Object.keys(object).find(key => object[key] === value);

export const deepCopyOfObject = object => JSON.parse(JSON.stringify(object));
