//              __
//             /(`o
//       ,-,  //  \\
//      (,,,) ||   V
//     (,,,,)\//
//     (,,,/w)-'
//     \,,/w)
//     `V/uu
//       / |
//       | |
//       o o
//       \ |
//  \,/  ,\|,.  \,/
//
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AutoInAndOutSelctor from '../../../../components/AutoInAndOutSelector';
import { getQueryId } from '../../../../store/navigation/selectors';

const RolesForMembers = ({ memberID }) => (
  <AutoInAndOutSelctor
    disableSearch
    activeItemsString="Active Roles"
    availableItemsString="Add Roles"
    endpoints={{
      fetchActive: `members/roles?forMember=${memberID}&`,
      fetchAvailable: `members/roles?notForMember=${memberID}&`,
      add: `members/${memberID}/roles/add?zidane=1`,
      remove: `members/${memberID}/roles/remove?zidane=1`,
    }}
  />
);

RolesForMembers.propTypes = {
  memberID: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  memberID: getQueryId(state),
});

export default connect(mapStateToProps)(RolesForMembers);
