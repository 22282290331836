/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */
import { replace } from 'react-router-redux';
import { changeLocationAction } from '../actions';
import { DEFAULT_NAVIGATION_KEY } from '../constants';

/**
 * @param location Location of the feature that the user want to consult, ex: /offers
 * @param functionalityName Name of the functionality the user want to access, ex: view
 * @param id
 * @param data Saved in the store to enbale data transmission through the store.
 * @param navigationKey Key used to reference the navigation, ex: offer
 * @return {function}
 */
export function changeLocationWithId(location, functionalityName, id, data, navigationKey) {
  return async function _changeLocationWithId(dispatch) {
    const viewLocation = {
      pathname: `/${location}/${functionalityName}`,
      query: {
        id,
      },
      search: `?id=${id}`,
      key: `${navigationKey}/${DEFAULT_NAVIGATION_KEY}`,
      state: data,
    };
    dispatch(changeLocationAction(viewLocation));
  };
}

export function changeLocation(location) {
  return async function _changeLocation(dispatch) {
    const viewLocation = {
      pathname: location,
    };
    dispatch(changeLocationAction(viewLocation));
  };
}

/**
 * A Really simple navigation method to aid moving away from old routing packages
 * @param location String location of where to navigate to
 */
export function navigate(location) {
  return function _navigate(dispatch) {
    dispatch(replace(location));
  };
}
