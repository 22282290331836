/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 18/04/2018
 */

export const LOAD_AUTHENTICATED_MEMBER = 'load_authenticated_member/pending';
export const LOAD_AUTHENTICATED_MEMBER_ERROR = 'load_authenticated_member/error';
export const LOAD_AUTHENTICATED_MEMBER_SUCCESS = 'load_authenticated_member/success';

export const LOAD_USER_PERMISSIONS_SUCCESS = 'LOAD_USER_PERMISSIONS_SUCCESS';
export const LOAD_USER_PERMISSIONS_PENDING = 'LOAD_USER_PERMISSIONS_PENDING';

export const LOAD_USER_LOCALES = 'LOAD_USER_LOCALES';
