import React from 'react';
import createFormPage from '../formPage';
import {
  getHasQuizAnswerChanges,
  getHasAnswersToCreate,
} from '../../../store/quizAnswersForQuestion/selectors';
import {
  saveAnswersForQuestion,
  updateAnswersForQuestion,
} from '../../../store/quizAnswersForQuestion/thunks';
import QuizAnswerForQuestion from '../../../components/QuizAnswerForQuestion/QuizAnswerForQuestion';
import { undoAllChangesForAnswers } from '../../../store/quizAnswersForQuestion/actions';
import { getQueryId } from '../../../store/navigation/selectors';
import { quizzes } from '../../games';

const getParams = id => ({
  endpoint: `games/quizzes/${id}/questions`,
  sections: {
    General: [
      'roundId',
      {
        fieldPath: 'id',
        // eslint-disable-next-line react/jsx-props-no-spreading
        Component: props => <QuizAnswerForQuestion quizId={id} {...props} />,
      },
      'image',
      'number',
      'text',
      'active',
    ],
  },
  create: {
    fields: ['roundId', 'image', 'number', 'text', 'active'],
  },
  reduxExtension: {
    hasUnStagedChanges: (formValue, state) => getHasQuizAnswerChanges(state) || formValue,
    discardChanges: (discardFormChanges, dispatch) => {
      discardFormChanges();
      dispatch(undoAllChangesForAnswers());
    },
    saveForm: (saveQuizQuestion, dispatch, getState) => {
      saveQuizQuestion();
      const state = getState();
      if (getHasQuizAnswerChanges(state)) {
        dispatch(updateAnswersForQuestion(id));
        if (getHasAnswersToCreate(state)) dispatch(saveAnswersForQuestion(id, getQueryId(state)));
      }
    },
  },
});

export default quizzes.map(q => createFormPage('quizQuestion', getParams(q.id)));
