//      ,---.
//    ,/     \__.'~\
//    |        '  . `===---      -Ed touched this file-
//    `|   ,     ,--'
//     `. /___,-'
//       |    \
//       ,\_  ,`-=
//
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import './styles.scss';
import { FlatButton, Dialog, TextField } from 'material-ui';
import { connect } from 'react-redux';
import Close from 'material-ui/svg-icons/navigation/close';
import * as t from '../../store/topFixtures/thunks';
import * as s from '../../store/topFixtures/selectors';
import FixtureItem from './FixtureItem';
import LoadingWheel from '../../components/LoadingWheel';

const limit = 10;

const FixtureList = ({
  onClose,
  open,
  country,
  onAdd,
  onRemove,
  onSearchFixtures,
  allFixtures,
  fetchingFixtures,
  fetchingMoreFixtures,
  addOrRemoveFixtureError,
  topFixtures,
  isFixtureItemLoading,
}) => {
  const [offset, setOffset] = useState(0);
  const [searchTerm, setSearchTerm] = useState(null);

  useEffect(() => {
    onSearchFixtures(null, country.id);
  }, [country.id, onSearchFixtures]);

  const onSearchMoreFixtures = () => {
    onSearchFixtures(searchTerm, country.id, offset + limit);
    setOffset(offset + limit);
  };

  return (
    <Dialog open={country && open} onRequestClose={onClose} className="FixtureList_dialog">
      <div className="FixtureList_close">
        <Close onClick={onClose} />
      </div>
      <div className="FixtureList_container">
        <h3>Add Big Fixtures to display in {country.name}</h3>

        <div className="FixtureList_itemRow">
          <TextField
            floatingLabelText="Search"
            className="FixtureList_searchBar"
            onChange={e => setSearchTerm(e.target.value)}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                onSearchFixtures(searchTerm, country.id, 0);
              }
            }}
          />
          <FlatButton
            className="FixtureList_button"
            onClick={() => onSearchFixtures(searchTerm, country.id, 0)}
          >
            Search
          </FlatButton>
        </div>
        {addOrRemoveFixtureError && (
          <p className="TopFixturesList_error_message">{addOrRemoveFixtureError}</p>
        )}
        {fetchingFixtures ? (
          <LoadingWheel />
        ) : (
          <>
            <div className="TopFixturesList_dnd_item_row">
              <p className="TopFixturesList_dnd_item">ID</p>
              <p className="TopFixturesList_dnd_item_longer">Fixture</p>
              <p className="TopFixturesList_dnd_item_longer">Start Time</p>
              <p className="TopFixturesList_dnd_icon" />
            </div>
            <div className="FixtureList">
              {allFixtures.length > 0 ? (
                allFixtures.map(f => (
                  <FixtureItem
                    isLoading={isFixtureItemLoading === f.id}
                    key={f.id}
                    name={f.name}
                    id={f.id}
                    startTime={f.startTimeUtc}
                    onAdd={() => onAdd(f)}
                    onRemove={() => onRemove(f)}
                    toBeRemoved={Boolean(
                      topFixtures && topFixtures.find(topFixture => f.id === topFixture.fixtureId),
                    )}
                  />
                ))
              ) : (
                <h3>No fixtures found</h3>
              )}
            </div>
            <div className="FixtureList_itemRow">
              {fetchingMoreFixtures ? (
                <LoadingWheel />
              ) : (
                <FlatButton className="FixtureList_button" onClick={() => onSearchMoreFixtures()}>
                  Load more
                </FlatButton>
              )}
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};

FixtureList.propTypes = {
  onRemove: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  country: PropTypes.shape().isRequired,
  onSearchFixtures: PropTypes.func.isRequired,
  allFixtures: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchingFixtures: PropTypes.bool.isRequired,
  fetchingMoreFixtures: PropTypes.bool.isRequired,
  addOrRemoveFixtureError: PropTypes.string,
  topFixtures: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFixtureItemLoading: PropTypes.number,
};

FixtureList.defaultProps = {
  addOrRemoveFixtureError: null,
  isFixtureItemLoading: null,
};
const mapDispatchToProps = dispatch => ({
  onSearchFixtures: (term, countryId, offset) =>
    dispatch(t.onSearchFixtures(term, countryId, offset, limit)),
});

const mapStateToProps = state => ({
  allFixtures: s.getAllFixtures(state),
  fetchingFixtures: s.getFetchingFixtures(state),
  fetchingMoreFixtures: s.getFetchingMoreFixtures(state),
  addOrRemoveFixtureError: s.getAddOrRemoveFixtureError(state),
  topFixtures: s.getTopFixtures(state),
  isFixtureItemLoading: s.getIsFixtureItemLoading(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(FixtureList);
