//      ,---.
//    ,/     \__.'~\
//    |        '  . `===---      -Ed touched this file-
//    `|   ,     ,--'
//     `. /___,-'
//       |    \
//       ,\_  ,`-=

export const FETCH_TOP_FIXTURES_SUCCESS = 'FETCH_TOP_FIXTURES_SUCCESS';
export const FETCH_TOP_FIXTURES_PENDING = 'FETCH_TOP_FIXTURES_PENDING';
export const FETCH_TOP_FIXTURES_ERROR = 'FETCH_TOP_FIXTURES_ERROR';

export const ORDER_CHANGED = 'ORDER_CHANGED';
export const ORDER_CHANGED_ERROR = 'ORDER_CHANGED_ERROR';

export const FETCH_MP_COUNTRIES_SUCCESS = 'FETCH_MP_COUNTRIES_SUCCESS';
export const FETCH_MP_COUNTRIES_ERROR = 'FETCH_MP_COUNTRIES_ERROR';
export const FETCH_MP_COUNTRIES_PENDING = 'FETCH_MP_COUNTRIES_PENDING';

export const FETCH_FIXTURES_FOR_SEARCH_SUCCESS = 'FETCH_FIXTURES_FOR_SEARCH_SUCCESS';
export const FETCH_FIXTURES_FOR_SEARCH_ERROR = 'FETCH_FIXTURES_FOR_SEARCH_ERROR';
export const FETCH_FIXTURES_FOR_SEARCH_PENDING = 'FETCH_FIXTURES_FOR_SEARCH_PENDING';

export const FETCH_MORE_FIXTURES_FOR_SEARCH_SUCCESS = 'FETCH_MORE_FIXTURES_FOR_SEARCH_SUCCESS';
export const FETCH_MORE_FIXTURES_FOR_SEARCH_ERROR = 'FETCH_MORE_FIXTURES_FOR_SEARCH_ERROR';
export const FETCH_MORE_FIXTURES_FOR_SEARCH_PENDING = 'FETCH_MORE_FIXTURES_FOR_SEARCH_PENDING';

export const LOCALLY_ADD_TOP_FIXTURE = 'LOCALLY_ADD_TOP_FIXTURE';
export const LOCALLY_REMOVE_TOP_FIXTURE = 'LOCALLY_REMOVE_TOP_FIXTURE';
export const ADD_OR_REMOVE_FIXTURE_ERROR = 'ADD_OR_REMOVE_FIXTURE_ERROR';

export const UPDATE_TOP_FIXTURE = 'UPDATE_TOP_FIXTURE';
export const UPDATE_TOP_FIXTURE_ERROR = 'UPDATE_TOP_FIXTURE_ERROR';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const REMOVE_FIXTURE_PENDING = 'REMOVE_FIXTURE_PENDING';
export const FIXTURE_ITEM_LOADING = 'FIXTURE_ITEM_LOADING';
