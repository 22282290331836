import React from 'react';
import PropTypes from 'prop-types';

const Location = ({ editedValue }) =>
  editedValue && (
    <iframe
      title="venue location map"
      className="h-[60vh] w-full"
      loading="lazy"
      src={`https://www.google.com/maps/embed/v1/place?key=${
        process.env.REACT_APP_GOOGLE_MAPS_API_KEY
      }&q=${parseFloat(editedValue?.latitude)},${parseFloat(editedValue?.longitude)}`}
    />
  );

Location.propTypes = {
  editedValue: PropTypes.shape().isRequired,
};

export default Location;
