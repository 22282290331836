/* eslint-disable react/prop-types */
import React from 'react';
import createFormPage from '../formPage';
import ActionButtons from './ActionButtons';
import PaymentInfo from './PaymentInfo';
import OpeningHours from './OpeningHours';
import ReadOnly from '../../../components/FormField/ReadOnly';
import Images from './Images';
import AutoInAndOutSelector from '../../../components/AutoInAndOutSelector';
import Location from './Location';
import Users from './Users';
import { getHasChanged } from '../../../store/paymentInfo/selectors';
import { savePaymentInfo } from '../../../store/paymentInfo/thunks';
import { discardChanges } from '../../../store/paymentInfo/actionCreator';
import CRMNotesSection from './CRMNotesSection/CRMNotesSection';

const dataScopeURL = 'venues/data-scope/venue/';

const params = {
  endpoint: 'venues',
  sections: {
    General: [
      {
        fieldPath: 'id',
        Component: ActionButtons,
      },
      'status',
      'id',
      'name',
      'mail',
      'phone',
      {
        fieldPath: 'users',
        Component: ({ editedValue }) => (
          <ReadOnly name="Phone - Manager" text={editedValue && editedValue[0]?.phoneNumber} />
        ),
      },
      'website',
      'longDescription',
      'ownershipType',
      'tierId',
      'performances.current.lastLogin',
      'performances.current.lastActivity',
      'visible',
    ],
    'Sales Information': ['salesPerson', 'matchPintOwner.id'],
    Address: ['address1', 'address2', 'postCode', 'city', 'countryId', 'stateId', 'googlePlaceId'],
    'Payment Info': [
      'memberSince',
      'signupDate',
      'paymentStatus',
      {
        fieldPath: 'id',
        Component: PaymentInfo,
      },
    ],
    Xero: [
      'businessName',
      'businessAddress1',
      'businessAddress2',
      'businessAddressCity',
      'businessAddressPostCode',
      'businessContact',
    ],
    'Master Pub': ['masterPub'],
    Contacts: [{ fieldPath: 'users', Component: Users }],
    Chain: ['houseCode', 'ownerId', 'operatorId', 'paidById'],
    'Opening Hours': [{ fieldPath: 'openingHours', Component: OpeningHours }],
    'Booking Method': [
      ['booking', 'method'],
      ['booking', 'phone'],
      ['booking', 'url'],
    ],
    Images: [{ fieldPath: 'openingHours', Component: Images }],
    'Venue Finder Scopes': [
      {
        fieldPath: 'id',
        Component: ({ editedValue }) => (
          <AutoInAndOutSelector
            enableCSVUpload
            activeItemsString="In Scopes"
            availableItemsString="Scopes available"
            formatName={data =>
              data.map(item => ({
                id: item.id,
                name: item.dataScopeName,
              }))
            }
            endpoints={{
              fetchActive: `${dataScopeURL}${editedValue}?venueScope=in&`,
              fetchAvailable: `${dataScopeURL}${editedValue}?venueScope=out&`,
              add: `${dataScopeURL}${editedValue}/add`,
              remove: `${dataScopeURL}${editedValue}/remove`,
            }}
          />
        ),
      },
    ],
    'Venue Finder Scopes - Featured': [
      {
        fieldPath: 'id',
        Component: ({ editedValue }) => (
          <AutoInAndOutSelector
            enableCSVUpload
            activeItemsString="In Scopes"
            availableItemsString="Scopes available"
            formatName={data =>
              data.map(item => ({
                id: item.id,
                name: item.dataScopeName,
              }))
            }
            endpoints={{
              fetchActive: `${dataScopeURL}${editedValue}?venueScope=inFeatured&`,
              fetchAvailable: `${dataScopeURL}${editedValue}?venueScope=outFeatured&`,
              add: `${dataScopeURL}${editedValue}/add-featured`,
              remove: `${dataScopeURL}${editedValue}/remove-featured`,
            }}
          />
        ),
      },
    ],
    Offers: [
      {
        fieldPath: 'id',
        Component: ({ editedValue }) => (
          <AutoInAndOutSelector
            enableCSVUpload
            activeItemsString="Offers active"
            availableItemsString="Offers available"
            formatName={data =>
              data.map(item => ({
                id: item.id,
                name: item.title,
              }))
            }
            endpoints={{
              fetchActive: `offers/?notInPrizePool=1&inVenueId=${editedValue}&active=1&ongoing=1&`,
              fetchAvailable: `offers/?notInPrizePool=1&notInVenueId=${editedValue}&active=1&ongoing=1&`,
              add: `venues/${editedValue}/offers/add`,
              remove: `venues/${editedValue}/offers/remove`,
            }}
          />
        ),
      },
    ],
    'Venue Notes': [
      {
        fieldPath: 'id',
        Component: ({ fullResource }) => <CRMNotesSection venue={fullResource} />,
      },
    ],
    Location: [
      'location.latitude',
      'location.longitude',
      { fieldPath: 'location', Component: Location },
    ],
  },
  otherSection: false,
  reduxExtension: {
    hasUnStagedChanges: (formValue, state) => getHasChanged(state) || formValue,
    discardChanges: (discardFormChanges, dispatch) => {
      discardFormChanges();
      dispatch(discardChanges());
    },
    saveForm: (saveVenue, dispatch) => {
      saveVenue();
      dispatch(savePaymentInfo());
    },
  },
  api: {
    getDescriptionAPIEndpoint: item => ({
      url: `venues/update/${item}?formDescription=1&zidane=1&active&withOffersNumber=1`,
      method: 'PUT',
    }),
    getEditAPIEndpoint: (id, body) => ({
      url: `venues/update/${id}?zidane=1`,
      method: 'PUT',
      body: {
        venue: {
          id,
          ...body,
        },
      },
    }),
  },
};

export default createFormPage('venues', params);
