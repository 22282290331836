import homepageSections from './entityList';

import createListPage from '../listPage';

import { STRING } from '../listPage/fieldTypes';

const homepageSectionsList = createListPage(homepageSections, {
  id: {
    label: 'Id',
    type: STRING,
  },
  name: {
    label: 'Name',
    type: STRING,
  },
  type: {
    label: 'Type',
    type: STRING,
  },
});

export default homepageSectionsList;
