import createFormPage from '../formPage';
import TeamsSelector from './components/TeamsSelector';

const params = {
  endpoint: 'sports/competitions',
  sections: {
    General: [
      { fieldPath: 'name' },
      { fieldPath: 'active' },
      { fieldPath: 'id' },
      { fieldPath: 'sportId' },
      { fieldPath: 'competitionLogo' },
      { fieldPath: 'backgroundPortrait' },
      { fieldPath: 'image' },
      { fieldPath: 'nickname1' },
      { fieldPath: 'nickname2' },
      { fieldPath: 'seoPageUri' },
      { fieldPath: 'tvGuideUri' },
      { fieldPath: 'usableForPubOffer' },
    ],
    Teams: [
      {
        fieldPath: 'id',
        Component: TeamsSelector,
      },
    ],
  },
};

export default createFormPage('competitions', params);
