/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: M̶a̶r̶w̶a̶n̶ Tom
 * Date: 2̶7̶/̶0̶9̶/̶2̶0̶1̶8̶ 30/7/2020
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card } from 'material-ui/Card';
import Paper from 'material-ui/Paper';
import List from 'material-ui/List';
import Avatar from 'material-ui/Avatar';
import { FlatButton } from 'material-ui';
import SideBarItem from '../../components/SideBarItem';
import sizes from './style.scss';
import menuLinks from '../../utils/menuLinks';
import canUserAccessPage from '../../utils/permissions';
import { getCurrentLocation } from '../../store/navigation/selectors';
import { getAuthenticatedMember, getPermissions } from '../../store/member/selectors';
import colors from '../../theme/index.scss';
import request from '../../utils/apiRequest/requests';

const HomePage = ({ user, currentRoute, member, userPermissions }) => (
  <div className="Homepage">
    <div className="flex flex-1 items-center justify-between">
      <a className="Homepage__strapi rounded-lg" href={process.env.REACT_APP_STAPI_URL}>
        STRAPI
      </a>
      {(process.env.REACT_APP_DEV === 'true' || process.env.REACT_APP_STAGING === 'true') && (
        <button
          type="button"
          onClick={() => request('games/leagues/recalculate', { method: 'POST' })}
          className="rounded-lg bg-primaryPurple px-4 py-2 text-white"
        >
          Recalculate leagues
        </button>
      )}

      <Paper className="Homepage__paper" zDepth={3}>
        <div className="Homepage__paper__avatarContainer">
          {member && <Avatar size={parseInt(sizes.avatarRadius, 10)} src={member.profilePicture} />}
          <p>{`Welcome ${user.firstName}!`} </p>
          <FlatButton
            className="Homepage__avatarContainer__info__button"
            backgroundColor={colors.primaryGreen}
          >
            Signout
          </FlatButton>
        </div>
      </Paper>
    </div>
    <div className="Homepage__menu" id="Menu">
      {menuLinks
        .filter(elm =>
          elm.links.some(link => canUserAccessPage(userPermissions, link.limitedAccess)),
        )
        .map(linkItem => (
          <Card key={linkItem.name} className="Homepage__menu__menuTile">
            <div className="Homepage__menu__menuTile__menuTitle">{linkItem.name}</div>
            <List className="Homepage__menu__menuTile__listContainer">
              {linkItem.links.map(
                link =>
                  canUserAccessPage(userPermissions, link.limitedAccess) && (
                    <SideBarItem
                      key={link.name}
                      currentRoute={currentRoute}
                      linkItem={link}
                      permissions={userPermissions}
                    />
                  ),
              )}
            </List>
          </Card>
        ))}
    </div>
  </div>
);

HomePage.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    profilePicture: PropTypes.string,
  }).isRequired,
  currentRoute: PropTypes.string.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
  }).isRequired,
  userPermissions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    }),
  ),
};

HomePage.defaultProps = {
  userPermissions: null,
};

const mapStateToProps = state => ({
  currentRoute: getCurrentLocation(state),
  user: getAuthenticatedMember(state),
  userPermissions: getPermissions(state),
});

export default connect(mapStateToProps)(HomePage);
