/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Ross Johnson
 * Date: 12/12/2018
 */
import * as consts from './constants';

export const initialState = {
  fixture: null,
  loading: null,
  submitEnabled: false,
  readOnly: true,
  results: [],
};

function resultReducer(state = initialState, action = {}) {
  switch (action.type) {
    case consts.FETCH_FIXTURE_PENDING:
      return {
        ...state,
        loading: true,
        results: [],
      };
    case consts.FETCH_FIXTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        fixture: action.payload,
        results: action.payload.results,
        readOnly: action.payload.results ? action.payload.results.length === 2 : false,
      };
    case consts.FETCH_FIXTURE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case consts.UPDATE_SCORE_PENDING:
      return {
        ...state,
        loading: true,
      };
    case consts.UPDATE_SCORE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case consts.UPDATE_SCORE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case consts.UPDATE_RESULT:
      return {
        ...state,
        results: action.payload,
        submitEnabled: action.payload.length === 2,
      };
    default:
      return state;
  }
}

export default resultReducer;
