/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 18/10/2018
 */

import React from 'react';
import PropTypes from 'prop-types';
import SelectField from 'material-ui/SelectField';
import './index.scss';
import { MenuItem } from 'material-ui';
import ClearButton from '../ClearButton';

const EnumField = ({
  className,
  onChange,
  error,
  label,
  value,
  nullable,
  autoWidth,
  availableValues,
}) => (
  <div className="Enum">
    <div className="Enum_textarea">
      <SelectField
        className={className}
        errorText={error}
        autoWidth={autoWidth}
        floatingLabelText={label}
        onChange={(e, i, selected) => onChange(selected)}
        value={value}
      >
        {availableValues.map(val => (
          <MenuItem key={val.key} value={val.key} primaryText={val.value} />
        ))}
      </SelectField>
    </div>
    <div className="Enum_clearbutton">
      <ClearButton clearableValue={nullable} handleCancel={() => onChange(null)} />
    </div>
  </div>
);

EnumField.propTypes = {
  availableValues: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  value: PropTypes.string,
  nullable: PropTypes.bool,
  className: PropTypes.string,
  autoWidth: PropTypes.bool,
};

EnumField.defaultProps = {
  autoWidth: false,
  error: null,
  value: null,
  nullable: false,
  className: null,
};

export default EnumField;
