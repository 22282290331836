import React, { useState, useEffect, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import CRMNoteField from './CRMNoteField/CRMNoteField';
import CRMNotes from './CRMNotes/CRMNotes';
import { getAuthenticatedMember } from '../../../../store/member/selectors';
import { createNewCRMNote, getCRMNotes } from '../../../../store/thunks/api-crm';

const CRMNotesSection = ({ venue, currentUser, createNewNote, getNotesForVenue }) => {
  const [search, setSearch] = useState('');
  // for visual purposes only:
  const [sortingBy, setSortingBy] = useState({});
  const [notes, setNotes] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const fetchAllNotes = useCallback(() => {
    setIsFetching(true);
    getNotesForVenue(venue.id, search, sortingBy)
      .then(r => setNotes(r.getResult()))
      .finally(() => setIsFetching(false));
  }, [getNotesForVenue, search, sortingBy, venue.id]);

  useEffect(() => fetchAllNotes(), [fetchAllNotes]);

  return (
    <>
      <CRMNotes
        search={search}
        setSearch={setSearch}
        sortingBy={sortingBy}
        setSortingBy={setSortingBy}
        loading={isFetching}
        notes={notes}
      />
      <CRMNoteField
        onSaveNote={text =>
          createNewNote(text, currentUser.id, venue.id)
            .then(r => r.getResult())
            .then(fetchAllNotes)
        }
        saving={false}
      />
    </>
  );
};

CRMNotesSection.propTypes = {
  venue: PropTypes.shape().isRequired,
  createNewNote: PropTypes.func.isRequired,
  currentUser: PropTypes.shape().isRequired,
  getNotesForVenue: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentUser: getAuthenticatedMember(state),
});

const mapDispatchToProps = {
  getNotesForVenue: getCRMNotes,
  createNewNote: createNewCRMNote,
};

export default connect(mapStateToProps, mapDispatchToProps)(CRMNotesSection);
