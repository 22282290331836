//         .-.
//         ((`-) Q U O N K
//          \\
//           \\
//    .="""=._))
//   /  .,   .'
//  /__(,_.-'
// `    /|
//     /_|__
//       | `))
//       |
//      -"==
import * as constants from './constants';

const initialState = {
  paymentInfo: {
    edited: {},
    current: {},
  },
  error: null,
  hasChanged: false,
  isFetching: false,
  isFetchingShape: false,
  shape: {},
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case constants.FETCH_PAYMENT_INFO_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case constants.FETCH_PAYMENT_INFO_SUCCESS: {
      return {
        ...state,
        paymentInfo: {
          current: action.payload,
          edited: action.payload,
        },
        hasChanged: false,
        isFetching: false,
      };
    }
    case constants.FETCH_PAYMENT_INFO_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isFetching: false,
      };
    }

    case constants.FETCH_PAYMENT_INFO_SHAPE_PENDING:
      return {
        ...state,
        isFetchingShape: true,
      };
    case constants.FETCH_PAYMENT_INFO_SHAPE_SUCCESS: {
      return {
        ...state,
        shape: action.payload,
        isFetchingShape: false,
      };
    }
    case constants.FETCH_PAYMENT_INFO_SHAPE_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isFetchingShape: false,
      };
    }

    case constants.HAS_CHANGED: {
      const { value, field } = action.payload;
      return {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          edited: {
            ...state.paymentInfo.edited,
            [field]: value,
          },
        },
        hasChanged: true,
      };
    }
    case constants.DISCARD_CHANGES:
      return {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          edited: state.paymentInfo.current,
        },
        hasChanged: false,
      };
    case constants.SAVE_SUCCESS:
      return {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          current: state.paymentInfo.edited,
        },
        changes: initialState.changes,
        hasChanged: false,
      };
    case constants.SAVE_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
      };
    }
    default:
      return state;
  }
}

export default reducer;
