import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MenuItem, SelectField } from 'material-ui';
import Subheader from 'material-ui/Subheader';
import { Table, TableBody, TableHeader, TableRow, TableRowColumn } from 'material-ui/Table';
import Card from 'material-ui/Card';

import { fieldsPropTypes } from '../../../../utils/customPropTypes';

// Redux.
import EntityList from '../EntityList';

// UI Components.
import LoadingWheel from '../../../../components/LoadingWheel';
import TableHeaderColumnWithSort from '../../../../components/TableHeaderColumnWithSort';

import colors from '../../../../theme/index.scss';
import './index.scss';
import MpLink from '../../../../components/MpLink/MpLink';

const List = ({
  activeSortBy,
  addSortBy,
  availableSortBy,
  fields,
  getOnClickLink,
  isDesktop,
  isPhone,
  isTablet,
  isLoading,
  items,
  renderItemButtons,
  totalCount,
  exportEntity,
  currentCount,
  activeFilters,
}) => {
  const showColumn = field => {
    const { onDesktop, onPhone, onTablet } = fields[field];

    return (onDesktop && isDesktop) || (onPhone && isPhone) || (onTablet && isTablet);
  };

  const renderCardHeader = () => (
    <Subheader className="ListPage__list_header">
      {totalCount === null && isLoading ? (
        <span>Loading ... </span>
      ) : (
        `Total: ${totalCount === null ? '-' : totalCount}`
      )}
      <div className="ListPage__list_header_export_container">
        <SelectField value={null} floatingLabelText="Export" autoWidth>
          <MenuItem
            className="ListPage__list_header_export_button"
            onClick={() => exportEntity('all')}
          >
            All {totalCount} items
          </MenuItem>
          <MenuItem
            className="ListPage__list_header_export_button"
            onClick={() => exportEntity(currentCount)}
          >
            Only {currentCount} visible results
          </MenuItem>
        </SelectField>
      </div>
    </Subheader>
  );

  const renderTableHeader = () => (
    <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
      <TableRow>
        {Object.keys(fields).map(key => {
          const { label, renderLabel, style } = fields[key];

          return showColumn(key) ? (
            <TableHeaderColumnWithSort
              key={key}
              activeSortBy={activeSortBy}
              availableSortBy={availableSortBy}
              columnName={renderLabel(label)}
              fieldName={key}
              handleSort={addSortBy}
              style={style}
            />
          ) : null;
        })}
        <TableHeaderColumnWithSort className="ListPage__list_row_buttons_cell" />
      </TableRow>
    </TableHeader>
  );

  const renderTableRow = item => {
    const sideButtons = renderItemButtons(item, activeFilters);

    return (
      <TableRow
        key={item?.id}
        className="ListPage__list_row"
        hoverable={!!getOnClickLink}
        selectable={false}
        style={{
          pointer: getOnClickLink ? 'cursor' : 'default',
        }}
      >
        {Object.keys(fields).map(key => {
          if (!showColumn(key)) {
            return null;
          }

          if (item[key] === undefined) {
            // eslint-disable-next-line no-console
            console.error(`The field ${key} is not defined in the list's items.`);
          }

          const cellContent = fields[key].renderValue(item[key], item);

          // Enable the links to fit the size of the cells.
          const cellStyle = fields[key].style;

          // If a default link exists, creates a link so that clicking on a row acts like a link.
          return (
            <TableRowColumn style={cellStyle} key={key}>
              {getOnClickLink ? (
                <MpLink
                  className="ListPage__list_row_cell_link"
                  to={getOnClickLink(item, activeFilters)}
                >
                  {cellContent}
                </MpLink>
              ) : (
                cellContent
              )}
            </TableRowColumn>
          );
        })}
        <TableRowColumn className="ListPage__list_row_buttons_cell">
          <div className="ListPage__list_row_buttons_container">{sideButtons}</div>
        </TableRowColumn>
      </TableRow>
    );
  };
  return (
    <Card className="ListPage__list">
      {renderCardHeader()}

      <Table>
        {renderTableHeader()}

        <TableBody
          displayRowCheckbox={false}
          showRowHover={!!getOnClickLink}
          style={isLoading ? { backgroundColor: colors.veryLightGrey } : null}
        >
          {items && items.map(item => renderTableRow(item))}
        </TableBody>
      </Table>

      {isLoading ? (
        <div className="ListPage__list_footer">
          <LoadingWheel className="ListPage__list_footer_loader" size="medium" />
        </div>
      ) : null}
    </Card>
  );
};

List.propTypes = {
  activeSortBy: PropTypes.objectOf(
    PropTypes.oneOf([EntityList.SORT_ORDER_ASC, EntityList.SORT_ORDER_DESC]),
  ),
  addSortBy: PropTypes.func,
  availableSortBy: PropTypes.objectOf(PropTypes.string),
  fields: fieldsPropTypes.isRequired,
  getOnClickLink: PropTypes.func,
  isDesktop: PropTypes.bool.isRequired,
  isPhone: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape()),
  renderItemButtons: PropTypes.func,
  totalCount: PropTypes.number,
  exportEntity: PropTypes.func.isRequired,
  currentCount: PropTypes.number.isRequired,
  activeFilters: PropTypes.shape(),
};

List.defaultProps = {
  activeSortBy: {},
  availableSortBy: {},
  getOnClickLink: null,
  items: null,
  renderItemButtons: null,
  totalCount: null,
  addSortBy: () => {},
  activeFilters: {},
};

const mapStateToProps = (state, { entity }) => ({
  activeSortBy: entity.getActiveSortBy(state),
  activeFilters: entity.getActiveFilters(state),
  availableSortBy: entity.getAllAvailableSortBy(state),
  items: entity.getList(state),
  isDesktop: state.media.isDesktop,
  isPhone: state.media.isPhone,
  isTablet: state.media.isTablet,
  isLoading: entity.isListFetching(state),
  totalCount: entity.getListCount(state),
  currentCount: entity.getListPageSize(state) + entity.getListPageOffset(state),
});

const mapDispatchToProps = (dispatch, { entity }) => ({
  addSortBy: (field, order) => dispatch(entity.sortBy(field, order)),
  loadMore: () => dispatch(entity.loadMore()),
  exportEntity: amount => dispatch(entity.exportItems(amount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
