/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */
import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';

const FormEmailInput = ({ id, onChange, required, error, className }) => {
  if (Object.keys(error).length > 0) {
    return (
      <TextField
        id={id}
        required={required}
        label="Required"
        placeholder="Email"
        className={`${className} FormEmailInput_form_password_input_text`}
        margin="normal"
        errorText={error.message}
        onChange={event => onChange(event)}
      />
    );
  }
  return (
    <TextField
      id={id}
      required={required}
      label="Required"
      placeholder="Email"
      className={`${className} FormEmailInput_form_password_input_text`}
      margin="normal"
      onChange={event => onChange(event)}
    />
  );
};

FormEmailInput.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  error: PropTypes.shape(),
  className: PropTypes.string,
};

FormEmailInput.defaultProps = {
  id: '',
  required: false,
  error: {},
  className: 'default',
};

export default FormEmailInput;
