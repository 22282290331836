/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 01/11/2018
 */

import { get } from '../../../../../utils/object';

/**
 * @description Allows to extend API endpoint creators.
 * @param extensionName
 * @param defaultFunction
 * @return {function(*, *=): Function}
 */
function allowExtension(extensionName, defaultFunction) {
  return (entityName, parameters) => {
    const extension = get(['api', extensionName], parameters);

    return (...params) => {
      if (extension) {
        return extension(...params);
      }

      return defaultFunction(entityName, parameters)(...params);
    };
  };
}

/**
 * @description Returns the function to get the parameters of the API "fetch data" endpoint.
 * @param {string} entityName
 * @param {string} endpoint
 * @param {function} getDescriptionAPIEndpoint
 * @return {function}
 */
export const createDataAPIEndpoint = allowExtension(
  'getDataAPIEndpoint',
  (entityName, parameters) => {
    const { endpoint } = parameters;
    return id => ({
      url: `${endpoint}/${id}?zidane=1`,
      method: 'GET',
    });
  },
);

/**
 * @description Returns the function to get the parameters of the API "fetch description" endpoint.
 * @param {string} entityName
 * @param {string} endpoint
 * @param {function} getDescriptionAPIEndpoint
 * @return {function}
 */
export const createDescriptionAPIEndpoint = allowExtension(
  'getDescriptionAPIEndpoint',
  (entityName, parameters) => {
    const { endpoint } = parameters;
    return (id = 0) => ({
      url: `${endpoint}/${id}/update?formDescription=1&zidane=1`,
      method: 'PUT',
    });
  },
);

/**
 * @description Returns the function to get the parameters of the API "save" endpoint.
 * @param {string} entityName
 * @param {string} endpoint
 * @param {function} getEditAPIEndpoint
 * @return {function}
 */
export const createGetEditAPIEndpoint = allowExtension(
  'getEditAPIEndpoint',
  (entityName, parameters) => {
    const { endpoint } = parameters;
    return (id, body) => ({
      url: `${endpoint}/${id}/update?zidane=1`,
      method: 'PUT',
      body: {
        [entityName]: {
          id,
          ...body,
        },
      },
    });
  },
);

/**
 * @description Returns the function to get the parameters of the API "create new" endpoint.
 * @type {function(*, *=): Function}
 */
export const createGetCreateAPIEndpoint = allowExtension(
  'getCreateAPIEndpoint',
  (entityName, parameters) => {
    const { endpoint } = parameters;
    return fields => ({
      url: `${endpoint}/add?zidane=1`,
      method: 'POST',
      body: {
        [entityName]: fields,
      },
    });
  },
);
