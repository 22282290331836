import createFormPage from '../formPage';
import SportAutoComplete from './components/SportAutoComplete';
import CompetitionsSelector from './components/CompetitionsSelector';

const params = {
  endpoint: 'sports/teams',
  sections: {
    General: [
      { fieldPath: 'name' },
      { fieldPath: 'active' },
      {
        fieldPath: 'sport',
        Component: SportAutoComplete,
      },
      { fieldPath: 'countryId' },
      { fieldPath: 'priority' },
      { fieldPath: 'shortName' },
      { fieldPath: 'alias1' },
      { fieldPath: 'alias2' },
      { fieldPath: 'alias3' },
      { fieldPath: 'tvGuideUri' },
      { fieldPath: 'seoPageUri' },
      { fieldPath: 'stadium' },
    ],
    Imagary: ['logo', 'secondaryLogo', 'image'],
    Competitions: [
      {
        fieldPath: 'id',
        Component: CompetitionsSelector,
      },
    ],
  },
};

export default createFormPage('team', params);
