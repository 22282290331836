/**            -                              ___
 *            |.|                          . /\_/\
 *          __]-[_________ /             .  (-<_>-)
 *         /        _____|<_          .   .  \/_\/
 *        / _   &  /               .   .
 *       / / \_ __|            .
 *     _/_/  / X   \
 *    <_/   /   ____\
 *         /___/|  /             zinedine zidane
 *         |  / ( <                --france--
 *         ( <   \ |
 *          \ |   >\
 *          _>|  (_/
 *         (__|
 *
 * MatchPint Ltd
 * @author Andreia Almeida
 * Date: 22/11/2018
 */

import gamesList from './entityList';

import createListPage from '../listPage';

import { NUMBER, STRING } from '../listPage/fieldTypes';

import tabs from '../../../routes/tabs';

const gamesListPage = createListPage(
  gamesList,
  {
    id: {
      label: 'ID',
      type: NUMBER,
      style: {
        width: '10%',
      },
    },
    name: {
      label: 'Name',
      type: STRING,
    },
  },
  {
    navTabs: tabs.game,
  },
);

export default gamesListPage;
