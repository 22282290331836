/**            -                              ___
 *            |.|                          . /\_/\
 *          __]-[_________ /             .  (-<_>-)
 *         /        _____|<_          .   .  \/_\/
 *        / _   &  /               .   .
 *       / / \_ __|            .
 *     _/_/  / X   \
 *    <_/   /   ____\
 *         /___/|  /             zinedine zidane
 *         |  / ( <                --france--
 *         ( <   \ |
 *          \ |   >\
 *          _>|  (_/
 *         (__|
 *
 * MatchPint Ltd
 * @author Andreia Almeida
 * Date: 16/11/2018
 */

import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import Avatar from 'material-ui/Avatar';
import Arrow from 'material-ui/svg-icons/navigation/arrow-forward';

const OnBoardingTilePreview = ({ descriptionContent, imageLink, titleContent }) => (
  <>
    <p className="OnboardingTile__wrap_preview_title">Preview:</p>
    <div className="OnboardingTile__wrap_preview_content">
      {imageLink && (
        <img
          alt="onboarding tile"
          className="OnboardingTile__wrap_preview_content_pic"
          src={imageLink}
        />
      )}
      <div className="OnboardingTile__wrap_preview_content_text">
        <div className="OnboardingTile__wrap_preview_content_text_title">{titleContent}</div>
        <div className="OnboardingTile__wrap_preview_content_text_description">
          {descriptionContent}
        </div>
      </div>
      <div className="OnboardingTile__wrap_preview_content_arrow">
        <Avatar icon={<Arrow />} />
      </div>
    </div>
  </>
);

OnBoardingTilePreview.propTypes = {
  descriptionContent: PropTypes.string,
  imageLink: PropTypes.string,
  titleContent: PropTypes.string,
};

OnBoardingTilePreview.defaultProps = {
  descriptionContent: '',
  imageLink: '',
  titleContent: '',
};

export default OnBoardingTilePreview;
