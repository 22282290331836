//      ,---.
//    ,/     \__.'~\
//    |        '  . `===---      -Ed touched this file-
//    `|   ,     ,--'
//     `. /___,-'
//       |    \
//       ,\_  ,`-=

import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import './styles.scss';
import { FlatButton } from 'material-ui';
import { connect } from 'react-redux';
import { NorthernIreland, UKcountryID } from '../../utils/globalVariables';
import {
  getMatchpintCountries,
  getFetchingMPCountries,
  getMatchpintCountriesError,
} from '../../store/topFixtures/selectors';
import { fetchMatchpintCountries } from '../../store/topFixtures/thunks';
import LoadingWheel from '../LoadingWheel';

const MatchPintCountrySelector = ({
  countries,
  onSelect,
  selectedCountry,
  displayNI,
  fetchCountries,
  fetching,
  error,
}) => {
  useEffect(() => {
    if (!countries.length) fetchCountries();
  }, [countries.length, fetchCountries]);

  useEffect(() => {
    const UK = countries.find(ct => ct.id === UKcountryID);
    if (UK) onSelect(UK);
    // onSelect dep gets re-defined => rerenders above component => infinite fetch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  const countryList = [...countries];

  if (displayNI && !countryList.find(c => c.id === NorthernIreland.id)) {
    // forces Northern Ireland to sit next to England
    countryList.splice(countryList.findIndex(c => c.id === UKcountryID) + 1, 0, NorthernIreland);
  }

  return fetching ? (
    <LoadingWheel />
  ) : (
    <>
      {error && <p className="MatchPintCountrySelector_error">{error}</p>}
      <div className="MatchPintCountrySelector">
        {countryList.map(country => (
          <FlatButton
            key={country.id}
            onClick={() => onSelect(country)}
            className={`MatchPintCountrySelector_item${
              selectedCountry && country.id === selectedCountry.id ? '_selected' : ''
            }`}
          >
            <img src={country.flag} alt="" />
            <p>{country.name}</p>
          </FlatButton>
        ))}
      </div>
      <div className="MatchPintCountrySelector_legend">
        <i>
          * England also gets applied to{' '}
          {displayNI ? 'Scotland and Wales' : ' Scotland, Wales and Northern Ireland.'}
        </i>
      </div>
    </>
  );
};

MatchPintCountrySelector.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedCountry: PropTypes.shape(),
  displayNI: PropTypes.bool,
  fetching: PropTypes.bool,
  fetchCountries: PropTypes.func.isRequired,
  error: PropTypes.string,
};

MatchPintCountrySelector.defaultProps = {
  selectedCountry: null,
  fetching: false,
  error: null,
  displayNI: false,
};

const mapStateToProps = state => ({
  fetching: getFetchingMPCountries(state),
  countries: getMatchpintCountries(state),
  error: getMatchpintCountriesError(state),
});

const mapDispatchToProps = dispatch => ({
  fetchCountries: () => dispatch(fetchMatchpintCountries()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MatchPintCountrySelector);
