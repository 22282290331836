/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 24/05/2018
 */
import * as constants from './constants';

export const initialState = {
  type: null,
  read: true,
  displaySticky: true,
};

export default function error(state = initialState, action = {}) {
  switch (action.type) {
    case constants.RECEIVE_GLOBAL_ERROR:
      return {
        ...state,
        type: action.errorType,
        read: false,
      };
    case constants.DISMISS_GLOBAL_ERROR:
      return {
        ...state,
        read: true,
      };
    case constants.DISPLAY_STICKY_GLOBAL_ERROR:
      return {
        ...state,
        displaySticky: true,
      };
    case constants.DISPLAY_INLINE_GLOBAL_ERROR:
      return {
        ...state,
        displaySticky: false,
      };
    default:
      return state;
  }
}
