/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 17/10/2018
 */
import React from 'react';
import PropTypes from 'prop-types';

import * as moment from 'moment';

import DatePicker from 'material-ui/DatePicker';

import withMediaProps from '../../../hocs/withMediaProps';

import ClearButton from '../../ClearButton';
import InfoIcon from '../../InfoIcon';

import './index.scss';

const DateField = ({ date, error, handleEditField, help, isPhone, name }) => (
  <div className="DatePickerEdit__item">
    <div className="DatePickerEdit__item_content">
      <span>{name}</span>
      <div className="DatePickerEdit__item_content_picker">
        <DatePicker
          className="DatePickerEdit__item_content_picker_date"
          mode={isPhone ? null : 'landscape'}
          value={date && date !== '0000-00-00' ? moment(date).toDate() : null}
          onChange={(event, newDate) => handleEditField(moment(newDate).format('YYYY-MM-DD'))}
          id={name}
        />
        {error && (
          <div className="DatePickerEdit__item_content_picker_error">
            {Array.isArray(error) ? error.join(' | ') : error}
          </div>
        )}
        <ClearButton
          className="DatePickerEdit__item_content_picker_cancel"
          clearableValue={date}
          handleCancel={() => handleEditField(null)}
        />
      </div>
    </div>
    {help && <InfoIcon tooltipPosition="bottom-left" tooltip={help} />}
  </div>
);

DateField.propTypes = {
  date: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  handleEditField: PropTypes.func.isRequired,
  help: PropTypes.string,
  isPhone: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

DateField.defaultProps = {
  date: null,
  error: null,
  help: null,
  isPhone: true,
};

export default withMediaProps(DateField);
