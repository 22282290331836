/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 26/06/2018
 */
import React from 'react';
import PropTypes from 'prop-types';

import Avatar from 'material-ui/Avatar';
import { Card, CardHeader, CardText } from 'material-ui/Card';

import './index.scss';

const HomepageSection = ({ active, homepageOrder, isPhone, name, title }) => {
  if (isPhone) {
    return (
      <Card className={`HomepageSection${!active ? ' unused' : ''}`}>
        <CardText>{name}</CardText>
      </Card>
    );
  }

  return (
    <Card className={`HomepageSection${!active ? ' unused' : ''}`}>
      <CardHeader
        avatar={homepageOrder !== null ? <Avatar>{homepageOrder}</Avatar> : null}
        title={name}
        subtitle={title !== null && title !== '' ? title : '-'}
      />
    </Card>
  );
};

HomepageSection.propTypes = {
  active: PropTypes.bool,
  isPhone: PropTypes.bool,
  name: PropTypes.string.isRequired,
  homepageOrder: PropTypes.number,
  title: PropTypes.string.isRequired,
};

HomepageSection.defaultProps = {
  active: true,
  homepageOrder: null,
  isPhone: false,
};

export default HomepageSection;
