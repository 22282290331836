import React from 'react';
import PropTypes from 'prop-types';
import AutoCompleteTextField from '../../../../components/AutoCompleteTextField';

const TeamAutoComplete = ({ editedValue, onValueChange }) => (
  <AutoCompleteTextField
    dataSource={searchText => `sports/teams?limit=-1&search=${searchText}`}
    label="teams"
    onValueChange={sportID => onValueChange(sportID)}
    searchText={editedValue?.id || editedValue}
  />
);

TeamAutoComplete.propTypes = {
  editedValue: PropTypes.number,
  onValueChange: PropTypes.func.isRequired,
};

TeamAutoComplete.defaultProps = {
  editedValue: null,
};

export default TeamAutoComplete;
