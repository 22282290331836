/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 31/10/2018
 */

/**
 * @description Exception that is raised when a form page is not correctly set up.
 */
export default class FormError extends Error {
  constructor(...args) {
    super(...args);
    Error.captureStackTrace(this, FormError);
    this.name = 'FormError';
  }
}
