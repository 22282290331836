import { getURLQuery } from '@matchpint/react-common';
import createFormPage from '../formPage';
import {
  createShowShowSaveConfirmation,
  createSetConfirmText,
  createHideShowSaveConfirmation,
  createSetSaveSuccessText,
} from '../formPage/store/actionCreatorFactory';
import { createGetShowSaveConfirmation } from '../formPage/store/selectorFactory';

const entityName = 'bustCache';

const params = {
  endpoint: 'meta/cache/bust',
  create: {
    fields: ['match', 'matchType'],
    buttonLabel: 'Bust',
    title: 'Bust some cache',
  },
  api: {
    getDescriptionAPIEndpoint: (_, queryParams) => ({
      url: `meta/cache/bust${getURLQuery({
        ...queryParams,
        formDescription: 1,
        excludeTestMemberId: true,
      })}`,
      method: 'POST',
    }),
    getCreateAPIEndpoint: body => ({
      url: 'meta/cache/bust',
      method: 'POST',
      body: { bust: body },
    }),
  },
  reduxExtension: {
    saveCreation: (saveCreationNormal, dispatch, getState) => {
      const confirm = createGetShowSaveConfirmation(entityName)(getState());
      saveCreationNormal(true, true, confirm).then(res => {
        if (!confirm && res && res.message) {
          dispatch(createShowShowSaveConfirmation(entityName)());
          return dispatch(createSetConfirmText(entityName)(res.message));
        }
        dispatch(createHideShowSaveConfirmation(entityName)());
        return res && res.message && dispatch(createSetSaveSuccessText(entityName)(res.message));
      });
    },
  },
};

const BustCache = createFormPage(entityName, params);

export default BustCache;
