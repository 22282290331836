import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDetectOutsideClick } from '@matchpint/react-common';
import { add, parse, format } from 'date-fns';

const generateTimeIntervals = (minutes, startTime = '00:00', endTime = '23:59') => {
  const result = [];
  const start = parse(startTime, 'HH:mm', new Date());
  const end = parse(endTime, 'HH:mm', new Date());
  for (let i = start; i <= end; i = add(i, { minutes })) {
    result.push({
      value: format(i, 'HH:mm'),
      key: format(i, 'HH:mm'),
    });
  }
  return result;
};

const [noneKey, closedKey, openKey, open24Key] = ['none', 'closed', 'open', 'open24'];
const states = [
  { key: noneKey, value: 'select' },
  { key: closedKey, value: 'closed' },
  { key: openKey, value: 'open' },
  { key: open24Key, value: 'open24' },
];

const OpenHoursEditor = ({
  text,
  showCopyDayButton,
  copyDayButtonAction,
  valueFrom,
  valueTo,
  setDay,
}) => {
  const [none, closed, open, open24] = states;
  const [selectedStatus, setSelectedStatus] = useState(none);

  const midnight = '00:00';
  const isClosed = valueFrom === null && valueTo === null;
  const isOpen24 = valueFrom === midnight && valueTo === midnight;

  useEffect(() => {
    if (isClosed) {
      setSelectedStatus(closed);
    }
    if (isOpen24) {
      setSelectedStatus(open24);
    }
    if (!isOpen24 && !isClosed) {
      setSelectedStatus(open);
    }
  }, [closed, isClosed, isOpen24, open, open24]);

  const onStatusSelectorHandler = val => {
    if (val === open.value) {
      setDay('09:00', '17:00');
    }
    if (val === open24.value) {
      setDay(midnight, midnight);
    }
    if (val === closed.value) {
      setDay(null, null);
    }
  };

  return (
    <div className="my-1 flex items-center">
      <span className="w-12 font-semibold uppercase">{text}</span>
      <Selector
        options={states}
        selected={selectedStatus.value}
        onValueChange={val => {
          onStatusSelectorHandler(val);
          setSelectedStatus({ ...selectedStatus, value: val });
        }}
        emptyValue={none}
        className="flex h-10 items-center"
      />

      {selectedStatus === open && (
        <>
          <Selector
            options={generateTimeIntervals(30)}
            selected={valueFrom}
            onValueChange={val => setDay(val, valueTo)}
            emptyValue={none}
            className="ml-4"
          />
          <span className="mx-2">-</span>
          <Selector
            options={generateTimeIntervals(30)}
            selected={valueTo}
            onValueChange={val => setDay(valueFrom, val)}
            emptyValue={none}
          />
        </>
      )}

      {showCopyDayButton && (
        <button
          type="button"
          onClick={copyDayButtonAction}
          className="ml-4 h-9 rounded-sm bg-primaryYellow px-2 text-xs"
        >
          Apply to Others
        </button>
      )}
    </div>
  );
};

OpenHoursEditor.propTypes = {
  text: PropTypes.string,
  valueFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showCopyDayButton: PropTypes.bool.isRequired,
  copyDayButtonAction: PropTypes.func.isRequired,
  setDay: PropTypes.func.isRequired,
};

OpenHoursEditor.defaultProps = {
  text: '',
  valueTo: '',
  valueFrom: '',
};

export default OpenHoursEditor;

const Selector = ({ options, selected, onValueChange, className, emptyValue }) => {
  const [menu, setMenu] = useState(false);
  const ref = useRef(undefined);
  useDetectOutsideClick(ref, () => setMenu(false));
  return (
    <div className={className} ref={ref}>
      <div className="relative text-xs">
        <button
          type="button"
          className="relative rounded-md border border-standardGrey py-2 pl-1 pr-10"
          onClick={() => setMenu(!menu)}
        >
          <span className="flex items-center">
            <span
              className={`ml-3 block truncate ${
                selected === emptyValue ? 'text-standardGrey' : ''
              }`}
            >
              {selected}
            </span>
          </span>
          <span
            className={`absolute inset-y-0 right-2 flex items-center text-lg ${
              menu ? '-rotate-90' : 'rotate-90'
            }`}
          >
            ▸
          </span>
        </button>

        {menu && (
          <ul className="animate-dropdown absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg">
            {options.map(
              option =>
                option !== emptyValue && (
                  <button
                    key={option.key}
                    id={option.key}
                    type="button"
                    className={`relative block w-full select-none p-3 py-2 hover:bg-veryLightGrey ${
                      option.value === selected ? 'bg-lightGrey' : ''
                    }`}
                    onClick={() => {
                      onValueChange(option.value);
                      setMenu(!menu);
                    }}
                    disabled={option.value === selected}
                  >
                    <div className="flex justify-center">
                      <span className="block whitespace-nowrap">{option.value}</span>
                    </div>
                  </button>
                ),
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

const keyValue = PropTypes.shape({
  key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

Selector.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(keyValue).isRequired,
  selected: PropTypes.oneOfType([keyValue, PropTypes.string]),
  emptyValue: keyValue.isRequired,
  onValueChange: PropTypes.func.isRequired,
};

Selector.defaultProps = {
  className: '',
  selected: null,
};
