/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */
import React from 'react';
import PropTypes from 'prop-types';

import Subheader from 'material-ui/Subheader';

import './index.scss';

const MpSectionHeader = ({ title }) => (
  <Subheader className="MpSectionHeader">{title.toUpperCase()}</Subheader>
);

MpSectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default MpSectionHeader;
