import EntityList from '../listPage/EntityList';

export default new EntityList(
  'offerCodes',
  'offers/redemption-code',
  {
    zidane: true,
  },
  undefined,
  undefined,
  'Offer redemption codes',
);
