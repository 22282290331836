/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 12/11/2018
 */

export const PAGE_TYPE_CREATE = 'create';
export const PAGE_TYPE_EDIT = 'edit';
