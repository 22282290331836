/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Card, CardHeader, CardText } from 'material-ui/Card';
import Chip from 'material-ui/Chip';

import './index.scss';

class ExpendableCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: props.expanded,
    };
    this.handleExpandChange = this.handleExpandChange.bind(this);
  }

  handleExpandChange(expanded) {
    return new Promise(resolve => {
      if (expanded !== null) {
        this.setState(
          {
            expanded,
          },
          () => {
            resolve();
          },
        );
      } else {
        resolve();
      }
    });
  }

  render() {
    const {
      children,
      className,
      expandable,
      headerChildren,
      id,
      initiallyExpanded,
      title,
      totalCount,
    } = this.props;

    const { expanded } = this.state;

    return (
      <Card
        id={id}
        className={`${className || ''} ExpendableCard`}
        expanded={expanded}
        expandable={expandable}
        initiallyExpanded={initiallyExpanded}
        onExpandChange={this.handleExpandChange}
      >
        <CardHeader
          className={`${className ? `${className}__header` : null} ExpendableCard__header`}
          title={
            <div>
              {title?.toUpperCase()}{' '}
              {totalCount ? (
                <Chip className="ExpendableCard__header_chip">{`Total : ${totalCount}`}</Chip>
              ) : null}
            </div>
          }
          actAsExpander={expandable}
          showExpandableButton={expandable}
        >
          {headerChildren}
        </CardHeader>
        <CardText
          className={`${className ? `${className}__content` : null} ExpendableCard__content`}
          expandable={expandable}
        >
          {children}
        </CardText>
      </Card>
    );
  }
}

ExpendableCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  expandable: PropTypes.bool,
  expanded: PropTypes.bool,
  headerChildren: PropTypes.node,
  id: PropTypes.string,
  initiallyExpanded: PropTypes.bool,
  title: PropTypes.string.isRequired,
  totalCount: PropTypes.number,
};

ExpendableCard.defaultProps = {
  children: null,
  className: null,
  expandable: true,
  expanded: null,
  headerChildren: null,
  id: null,
  initiallyExpanded: false,
  totalCount: null,
};

export default ExpendableCard;
