/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 30/10/2018
 */

import { request, formRequest } from '../../../../store/thunks/requests';

import {
  createDataAPIEndpoint,
  createDescriptionAPIEndpoint,
  createGetEditAPIEndpoint,
  createGetCreateAPIEndpoint,
} from '../utils/paramParsers/api';
import { getDefaultFields } from '../utils/paramParsers/create';

/**
 * @description Creates the function that will call the "fetch data" webservice.
 * @param {string} entityName
 * @param {Object} parameters
 * @return {function}
 */
export function createFetchDataAPI(entityName, parameters) {
  return function fetchAPIData(id, queryParams) {
    return async function _fetchAPIDate(dispatch) {
      // We get the endpoint's parameters based on the paramParser function.
      const { url, method, body } = createDataAPIEndpoint(entityName, parameters)(id, queryParams);
      return dispatch(request(url, { method, body }));
    };
  };
}

/**
 * @description Creates the function that will call the "fetch description" webservice.
 * @param {string} entityName
 * @param {Object} parameters
 * @return {function(): function(*): *}
 */
export function createFetchFormDescriptionAPI(entityName, parameters, id = 0, queryParams = {}) {
  return function fetchAPIFormDescription() {
    return async function _fetchFormDescription(dispatch) {
      // We get the endpoint's parameters based on the paramParser function.
      const { url, method } = createDescriptionAPIEndpoint(entityName, parameters)(id, queryParams);
      return dispatch(request(url, { method }));
    };
  };
}

/**
 * @description Creates the function that will call the "update data" webservice.
 * @param {string} entityName
 * @param {Object} parameters
 * @return {function(number, Object): function(*): *}
 */
export function createSaveFormAPI(entityName, parameters) {
  return function saveAPIForm(id, fieldsToSave) {
    return async function _saveAPIForm(dispatch) {
      // We get the endpoint's parameters based on the paramParser function.
      const { url, method, body } = createGetEditAPIEndpoint(entityName, parameters)(
        id,
        fieldsToSave,
      );
      return dispatch(formRequest(url, { method, body }));
    };
  };
}

/**
 * @description Creates the function that will call the "create new" webservice.
 * @param {string} entityName
 * @param {Object} parameters
 * @return {function(Object): function(function): *}
 */
export function createCreateNewEntityAPI(entityName, parameters) {
  return function createNewEntityAPI(fields, addConfirm) {
    return async function _createNewEntityAPI(dispatch) {
      const defaultFields = getDefaultFields(parameters);
      const { url, method, body } = createGetCreateAPIEndpoint(
        entityName,
        parameters,
      )({
        ...defaultFields,
        ...fields,
      });
      return dispatch(formRequest(`${url}${addConfirm ? '&confirm=1' : ''}`, { method, body }));
    };
  };
}
