/* eslint-disable react/prop-types */
import React from 'react';
import createFormPage from '../formPage';
import AutoInAndOutSelector from '../../../components/AutoInAndOutSelector';

const params = {
  endpoint: 'venues/data-scope',
  sections: {
    General: ['dataScopeName', 'reference'],
    Venues: [
      {
        fieldPath: 'id',
        Component: ({ editedValue }) => (
          <AutoInAndOutSelector
            enableCSVUpload
            activeItemsString="In Scopes"
            availableItemsString="Scopes available"
            formatName={data =>
              data.map(item => ({
                id: item.id,
                name: item.name,
              }))
            }
            endpoints={{
              fetchActive: `venues/?status=1&inDataScopeId=${editedValue}&`,
              fetchAvailable: `venues/?status=1&notInDataScopeId=${editedValue}&`,
              add: `venues/data-scope/${editedValue}/venues/add`,
              remove: `venues/data-scope/${editedValue}/venues/remove`,
            }}
          />
        ),
      },
    ],
    'Venues - Featured': [
      {
        fieldPath: 'id',
        Component: ({ editedValue }) => (
          <AutoInAndOutSelector
            enableCSVUpload
            activeItemsString="In Scopes"
            availableItemsString="Scopes available"
            formatName={data =>
              data.map(item => ({
                id: item.id,
                name: item.name,
              }))
            }
            endpoints={{
              fetchActive: `venues/?inFeaturedDataScopeId=${editedValue}&`,
              fetchAvailable: `venues/?status=1&inDataScopeId=${editedValue}&`,
              add: `venues/data-scope/${editedValue}/venues/add-featured`,
              remove: `venues/data-scope/${editedValue}/venues/remove-featured`,
            }}
          />
        ),
      },
    ],
  },

  create: {
    fields: ['dataScopeName', 'reference', 'scopeType'],
  },
};

export default createFormPage('venuesDataScope', params);
