import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'material-ui';

const Channels = ({ editedValue }) => {
  const [input, setInput] = React.useState('');

  return (
    <div>
      <TextField value={input} onChange={e => setInput(e.target.value)} />
      {editedValue.map(country =>
        country.channels.map(
          chan =>
            chan.autocompleteValue.toLowerCase().includes(input.toLowerCase()) && (
              <p>
                {chan.name} - {country.name}
              </p>
            ),
        ),
      )}
    </div>
  );
};

Channels.propTypes = {
  editedValue: PropTypes.arrayOf(PropTypes.shape()),
};
Channels.defaultProps = {
  editedValue: [],
};

export default Channels;
