import React from 'react';
import redemptionCodesList from './entityList';

import createListPage from '../listPage';
import tabs from '../../../routes/tabs';

import { NUMBER, STRING } from '../listPage/fieldTypes';

const offerRedemptionCodes = createListPage(
  redemptionCodesList,
  {
    id: {
      label: 'ID',
      type: NUMBER,
    },
    code: {
      label: 'ID',
      type: STRING,
    },
    offer: {
      label: 'Offer',
      type: STRING,
      renderValue: offer => (
        <p>
          {offer.title} ({offer.id})
        </p>
      ),
    },
    claimedMember: {
      label: 'Member',
      type: STRING,
      renderValue: (value, values) =>
        values.claimedMember && (
          <p>
            {values.claimedMember.firstName} {values.claimedMember.lastName} ({' '}
            {values.claimedMember.id})
          </p>
        ),
    },
  },
  {
    navTabs: tabs.offerRedemptionCodes,
  },
);

export default offerRedemptionCodes;
