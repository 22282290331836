import React from 'react';
import venueCompleter from './entityList';
import createListPage from '../listPage';
import tabs from '../../../routes/tabs';

export default createListPage(
  venueCompleter,
  {
    id: {
      label: 'id',
    },
    name: {
      label: 'name',
    },
    assignedToMember: {
      label: 'Assigned To',
      renderValue: v => (
        <p>
          {v.firstName} {v.lastName}
        </p>
      ),
    },
    client: {
      label: 'client',
    },
    status: {
      label: 'status',
    },
    createdAt: {
      label: 'Upload Date',
    },
  },
  {
    navTabs: tabs.venueCompleter,
  },
);
