import * as consts from './constants';

export function fetchFixturePending() {
  return {
    type: consts.FETCH_FIXTURE_PENDING,
  };
}

export function fetchFixtureSuccess(fixture) {
  return {
    type: consts.FETCH_FIXTURE_SUCCESS,
    payload: fixture,
  };
}

export function fetchFixtureFail() {
  return {
    type: consts.FETCH_FIXTURE_FAIL,
  };
}

export function setResultStart() {
  return {
    type: consts.UPDATE_RESULT_PENDING,
  };
}

export function setResultSuccess() {
  return {
    type: consts.UPDATE_RESULT_SUCCESS,
  };
}

export function setResultFail() {
  return {
    type: consts.UPDATE_RESULT_FAIL,
  };
}

export function updateResult(result) {
  return {
    type: consts.UPDATE_RESULT,
    payload: result,
  };
}
