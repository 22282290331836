/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 31/10/2018
 *
 */

import FormError from '../FormError';

/**
 * @description This file contains all the function that given a form's parameter object will
 *  return the different functions / value that is used in the FormPage and in the store.
 */

/**
 * @description Return the URL to access the edit form page.
 * @param entityName
 * @param endpoint
 * @return {string}
 */
export function getRouterEditPath(entityName, { endpoint }) {
  if (!endpoint || typeof endpoint !== 'string') {
    throw new FormError(`Please set a valid endpoint to render the ${entityName} form page.`);
  }

  return `${endpoint}/edit`;
}

export function getRouterCreatePath(entityName, { endpoint }) {
  if (!endpoint || typeof endpoint !== 'string') {
    throw new FormError(`Please set a valid endpoint to render the ${entityName} form page.`);
  }

  return `${endpoint}/create`;
}
