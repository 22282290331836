import * as consts from './constants';

export const initialState = {
  report: null,
  loading: false,
  available: {},
  applied: {},
};

function reportsListReducer(state = initialState, action = {}) {
  switch (action.type) {
    case consts.FETCH_REPORT_PENDING:
      return {
        ...state,
        loading: true,
      };
    case consts.FETCH_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        report: action.payload,
      };
    case consts.FETCH_REPORT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case consts.SET_AVAILABLE_FILTERS: {
      return {
        ...state,
        available: action.payload,
      };
    }
    case consts.SET_APPLIED_FILTERS: {
      const applied = {};
      Object.values(action.payload)
        .filter(elm => elm.defaultValue)
        .map(filteredElm => {
          applied[filteredElm.name] = filteredElm.defaultValue;
          return filteredElm.defaultValue;
        });
      return {
        ...state,
        applied,
      };
    }
    case consts.REPORT_ADD_FILTER: {
      const newApplied = { ...state.applied };
      // if multi is supported
      if (action.payload.isMulti) {
        // and its an array
        if (Array.isArray(newApplied[action.payload.name])) {
          // add new value to the array
          newApplied[action.payload.name] = [
            ...newApplied[action.payload.name],
            action.payload.value,
          ];
        } else {
          // otherwise create an array with 1 value
          newApplied[action.payload.name] = [action.payload.value];
        }
      } else {
        // otherwise set the value to the new value
        newApplied[action.payload.name] = action.payload.value;
      }

      return {
        ...state,
        applied: newApplied,
      };
    }
    case consts.REPORT_REMOVE_FILTER: {
      const newApplied = { ...state.applied };
      newApplied[action.payload] = null;

      return {
        ...state,
        applied: newApplied,
      };
    }
    case consts.CLEAR_REPORT: {
      return {
        ...state,
        report: initialState.report,
        available: initialState.available,
        applied: initialState.applied,
      };
    }
    default:
      return state;
  }
}

export default reportsListReducer;
