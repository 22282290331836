import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RaisedButton from 'material-ui/RaisedButton';
import Block from 'material-ui/svg-icons/content/block';
import colors from '../../../../theme/index.scss';
import MpModal from '../../../../components/MpModal/mpModal';
import './styles.scss';
import AutoCompleteTextField from '../../../../components/AutoCompleteTextField';
import { resetRoundThunk } from '../../../../store/quiz/thunks';

const ResetRounds = ({ quizId, resetRound, fullResource: { memberId } }) => {
  const [modal, setModal] = React.useState(false);
  const [round, setRound] = React.useState(null);
  const [error, setError] = React.useState(null);

  return (
    <div className="ResetAnswers">
      <AutoCompleteTextField
        dataSource={search => `games/quizzes/${quizId}/rounds?search=${search}`}
        label="Round"
        onValueChange={roundId => setRound(roundId)}
        searchText={round}
      />
      <RaisedButton
        disabled={!round}
        backgroundColor={colors.darkRed}
        icon={<Block color={colors.white} />}
        label="Reset Answers"
        onClick={() => setModal(true)}
        labelStyle={{ color: colors.white }}
      />
      <MpModal
        mainText="Are you sure?"
        visible={modal}
        onCancel={() => setModal(false)}
        onConfirm={() => {
          resetRound(memberId, round)
            .then(() => {
              setRound(null);
              setModal(false);
            })
            .catch(r => setError(r.message));
        }}
      >
        {error && <p className="ResetAnswers_error">{error}</p>}
      </MpModal>
    </div>
  );
};

ResetRounds.propTypes = {
  resetRound: PropTypes.func.isRequired,
  fullResource: PropTypes.shape({
    memberId: PropTypes.number.isRequired,
  }).isRequired,
  quizId: PropTypes.number.isRequired,
};

const mapDispatchToProps = dispatch => ({
  resetRound: (memberId, roundId) => dispatch(resetRoundThunk(memberId, roundId)),
});

export default connect(null, mapDispatchToProps)(ResetRounds);
