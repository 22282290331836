/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 29/06/2018
 */
import React from 'react';
import PropTypes from 'prop-types';

import Avatar from 'material-ui/Avatar';
import Paper from 'material-ui/Paper';

import './index.scss';

const SportSection = ({ priority, name, roundLogo }) => (
  <Paper className="SportSection">
    <Avatar style={{ marginRight: 8 }} src={roundLogo} size={30} className="SportSection__avatar" />
    <div className="SportSection__text">{name}</div>
    <div className="SportSection__priority">{priority || ''}</div>
  </Paper>
);

SportSection.propTypes = {
  priority: PropTypes.number,
  name: PropTypes.string.isRequired,
  roundLogo: PropTypes.string.isRequired,
};

SportSection.defaultProps = {
  priority: null,
};

export default SportSection;
