/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 31/07/2018
 */
import EntityList from '../listPage/EntityList';

const venuesList = new EntityList('venues', 'venues/', null, null, null, 'Venues');

export default venuesList;
