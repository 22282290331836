/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 29/06/2018
 */

import { FETCH_SPORTS_PENDING, SAVE_ORDER_PENDING } from './constants';

/**
 * @description Returns if the sports are still fetching.
 * @param state
 * @returns {boolean}
 */
export function areSportsFetching(state) {
  return state.sportOrder.fetch.status === FETCH_SPORTS_PENDING;
}

export function getEditedSports(state) {
  return state.sportOrder.fetch.sports.edited;
}

export function getFetchSportsError(state) {
  return state.sportOrder.fetch.error;
}

export function getSaveOrderSportsError(state) {
  return state.sportOrder.save.error;
}

export function hasUnStagedChanges(state) {
  const { current, edited } = state.sportOrder.fetch.sports;

  if (!current || !edited) {
    return false;
  }

  for (let i = 0; i < edited.length; i += 1) {
    if (!current[i] || current[i].id !== edited[i].id) {
      return true;
    }
  }

  return false;
}

export function isSportsOrderSaving(state) {
  return state.sportOrder.save.status === SAVE_ORDER_PENDING;
}
