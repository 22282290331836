import EntityList from '../listPage/EntityList';

export default new EntityList(
  'venueCompleterVenues',
  'venues/venue-completer/venues',
  undefined,
  undefined,
  undefined,
  'Venue Completer Venues',
);
