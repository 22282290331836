import React from 'react';
import createFormPage from '../formPage';
import tabs from '../../../routes/tabs';
import DeleteButton from './DeleteButton';

const params = {
  endpoint: 'meta/country-channels',
  sections: {
    General: [
      'countryId',
      'channelId',
      'channelGroupId',
      {
        fieldPath: 'id',
        // eslint-disable-next-line react/prop-types
        Component: ({ initialValue }) => <DeleteButton id={initialValue} />,
      },
    ],
  },
  create: {
    fields: ['countryId', 'channelId', 'channelGroupId'],
  },
  tabs: tabs.channelGroups,
};

const channelGroup = createFormPage('countryChannel', params);

export default channelGroup;
