import React, { useEffect, useState } from 'react';
import './styles.scss';
import { FlatButton } from 'material-ui';
import { connect } from 'react-redux';
import Add from 'material-ui/svg-icons/content/add-circle';
import PropTypes from 'prop-types';
import EditAnswerField from './EditAnswerField';
import {
  getAnswersForQuestion,
  deleteAnswersForQuestion,
} from '../../store/quizAnswersForQuestion/thunks';
import {
  getUpdatedAnswers,
  getAnswersError,
  getAnswersToAdd,
  getAnswersToBeUpdated,
} from '../../store/quizAnswersForQuestion/selectors';
import {
  changeValueForAnswer,
  undoChangesForAnswer,
  addAnotherAnswer,
  removeUnsavedAnswer,
} from '../../store/quizAnswersForQuestion/actions';
import MpModal from '../MpModal/mpModal';
import { getErrorMessage } from '../../utils/apiRequest/requests';
import { quizAnswerProps } from '../../utils/customPropTypes';

const QuizAnswerForQuestion = ({
  quizId,
  updatedAnswers,
  error,
  answersToAdd,
  getAnswers,
  undoSingleAnswer,
  deleteAnswer,
  removeAnswerBeforeSaving,
  updateAnswerAction,
  editedValue,
  addAnotherItem,
  answersToBeUpdated,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [deleteAnswerModal, setDeleteAnswerModal] = useState(false);
  const hasACorrectAnswer = Boolean([...updatedAnswers, ...answersToAdd].find(ans => ans.correct));

  useEffect(() => {
    getAnswers(editedValue, quizId);
  }, [editedValue, getAnswers, quizId]);

  return (
    <div className={`QuizAnswerForQuestion${!hasACorrectAnswer ? '_error' : ''}`}>
      <h4>Answers</h4>
      {updatedAnswers.length === 0 && !error && (
        <p>No answers for the question have been set yet. Click below to add some.</p>
      )}
      {updatedAnswers.map(ans => (
        <EditAnswerField
          key={ans.id}
          answer={ans}
          onChange={(field, val) => updateAnswerAction(val, field, ans.id)}
          onRemove={() => setDeleteAnswerModal(ans)}
          onUndo={
            answersToBeUpdated.find(answer => answer.id === ans.id)
              ? () => undoSingleAnswer(ans.id)
              : null
          }
        />
      ))}
      {answersToAdd.map((ans, index) => (
        <EditAnswerField
          // eslint-disable-next-line react/no-array-index-key
          key={`no ID - ${index}`}
          answer={ans}
          onChange={(field, val) => updateAnswerAction(val, field, undefined, index)}
          onRemove={() => removeAnswerBeforeSaving(index)}
        />
      ))}
      <MpModal
        mainText="Are you sure you want to delete this answer? This can not be undone."
        visible={Boolean(deleteAnswerModal)}
        onCancel={() => setDeleteAnswerModal(false)}
        onConfirm={() =>
          deleteAnswer(deleteAnswerModal.id, quizId)
            .then(() => {
              setDeleteAnswerModal(false);
              setErrorMessage(null);
            })
            .catch(r => setErrorMessage(getErrorMessage(r)))
        }
      >
        {errorMessage && <p className="QuizAnswerForQuestion_errorText">{errorMessage}</p>}
      </MpModal>
      {error && <p className="QuizAnswerForQuestion_errorText">{error}</p>}
      {!hasACorrectAnswer && <p>An answer needs to be selected as correct</p>}

      <FlatButton
        icon={<Add />}
        className="QuizAnswerForQuestion_createButton"
        onClick={() =>
          addAnotherItem('', 'text', undefined, [...updatedAnswers, ...answersToAdd].length)
        }
      >
        Add an answer
      </FlatButton>
    </div>
  );
};

QuizAnswerForQuestion.propTypes = {
  quizId: PropTypes.number.isRequired,
  updatedAnswers: PropTypes.arrayOf(quizAnswerProps).isRequired,
  error: PropTypes.string,
  answersToAdd: PropTypes.arrayOf(quizAnswerProps).isRequired,
  getAnswers: PropTypes.func.isRequired,
  undoSingleAnswer: PropTypes.func.isRequired,
  deleteAnswer: PropTypes.func.isRequired,
  removeAnswerBeforeSaving: PropTypes.func.isRequired,
  updateAnswerAction: PropTypes.func.isRequired,
  editedValue: PropTypes.number,
  addAnotherItem: PropTypes.func.isRequired,
  answersToBeUpdated: PropTypes.arrayOf(quizAnswerProps).isRequired,
};

QuizAnswerForQuestion.defaultProps = {
  error: null,
  editedValue: null,
};

const mapStateToProps = state => ({
  updatedAnswers: getUpdatedAnswers(state),
  error: getAnswersError(state),
  answersToAdd: getAnswersToAdd(state),
  answersToBeUpdated: getAnswersToBeUpdated(state),
});

const mapDispatchToProps = {
  getAnswers: getAnswersForQuestion,
  deleteAnswer: deleteAnswersForQuestion,
  addAnotherItem: addAnotherAnswer,
  updateAnswerAction: changeValueForAnswer,
  undoSingleAnswer: undoChangesForAnswer,
  removeAnswerBeforeSaving: removeUnsavedAnswer,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizAnswerForQuestion);
