//         .-.
//         ((`-) Q U O N K
//          \\
//           \\
//    .="""=._))
//   /  .,   .'
//  /__(,_.-'
// `    /|
//     /_|__
//       | `))
//       |
//      -"==

import {
  fetchPaymentInformation,
  savePaymentInformation,
  getPaymentInfoShape,
} from '../thunks/api-venues';

import {
  fetchPending,
  fetchSuccess,
  fetchError,
  fetchShapeError,
  fetchShapePending,
  fetchShapeSuccess,
  savePending,
  saveSuccess,
  saveError,
} from './actionCreator';
import { getQueryId } from '../navigation/selectors';
import { getPaymentInfo } from './selectors';

export function fetchPaymentInfo() {
  return async function _fetchPaymentInfo(dispatch, getState) {
    const venueId = getQueryId(getState());
    dispatch(fetchPending());
    return dispatch(fetchPaymentInformation(venueId))
      .then(response => {
        if (response.status === 202) {
          dispatch(fetchError(response.data.error));
          return false;
        }
        return response.getResult();
      })
      .then(result => result && dispatch(fetchSuccess(result)))
      .catch(error => {
        if (error.isServerError) {
          dispatch(fetchError(error));
          return;
        }
        throw error;
      });
  };
}

export const fetchPaymentInfoFormShape = () =>
  async function _savePaymentInfoFormShape(dispatch, getState) {
    const venueId = getQueryId(getState());
    dispatch(fetchShapePending());
    return dispatch(getPaymentInfoShape(venueId))
      .then(response => {
        if (response.status === 202) {
          dispatch(fetchShapeError(response.data.error));
          return false;
        }
        return response.getResult();
      })
      .then(r => r && dispatch(fetchShapeSuccess(r)))
      .catch(error => {
        if (error.isServerError) {
          dispatch(fetchShapeError(error));
          return;
        }
        throw error;
      });
  };

export function savePaymentInfo() {
  return async function _savePaymentInfo(dispatch, getState) {
    const venueId = getQueryId(getState());
    const paymentInfo = getPaymentInfo(getState());
    dispatch(savePending());
    return dispatch(savePaymentInformation(venueId, paymentInfo))
      .then(response => {
        if (response.status === 202) {
          dispatch(saveError(response.data.error));
          return false;
        }
        return response.getResult();
      })
      .then(r => r && dispatch(saveSuccess()))
      .catch(error => {
        if (error.isServerError) {
          dispatch(saveError(error));
          return;
        }
        throw error;
      });
  };
}
