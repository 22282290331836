/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 08/05/2018
 */
import React from 'react';
import PropTypes from 'prop-types';

import MaterialPropTypes from 'material-ui/utils/propTypes';
import IconButton from 'material-ui/IconButton';
import MaterialInfoIcon from 'material-ui/svg-icons/action/info';

import colors from '../../theme/index.scss';

const InfoIcon = ({ tooltip, tooltipPosition }) => (
  <IconButton touch tooltip={tooltip} tooltipPosition={tooltipPosition}>
    <MaterialInfoIcon color={colors.lightGrey} />
  </IconButton>
);

InfoIcon.propTypes = {
  tooltip: PropTypes.node,
  tooltipPosition: MaterialPropTypes.cornersAndCenter,
};

InfoIcon.defaultProps = {
  tooltip: null,
  tooltipPosition: 'bottom-center',
};

export default InfoIcon;
