export const UKcountryID = 54;

export const NorthernIreland = { name: 'Nothern Ireland', id: 118, flag: null };

export const fileUploadAcceptTypes = {
  video: 'video',
  image: 'image',
  csv: 'csv',
  file: 'file',
  all: '',
};
