/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 13/11/2018
 */

import FormError from '../FormError';

/**
 * @description Returns the path to an Entity's field.
 * @param entityName
 * @param field
 * @return {string[]}
 */
export function getFieldPath(entityName, field) {
  if (typeof field === 'string') {
    return field.split('.');
  }

  if (Array.isArray(field)) {
    return field;
  }

  if (typeof field !== 'object') {
    throw new FormError(`Error render rendering the field '${field}' for the form ${entityName}`);
  }

  const { fieldPath } = field;

  if (!fieldPath) {
    return [];
  }

  if (typeof fieldPath === 'string') {
    return fieldPath.split('.');
  }

  return fieldPath;
}

/**
 * @description Generates a key for a React list from a field.
 * @param entityName
 * @param field
 * @return {*}
 */
export function generateKeyFromField(entityName, field) {
  if (!field) {
    return undefined;
  }

  if (typeof field === 'object' && field.id) {
    return field.id;
  }

  const fieldPath = getFieldPath(entityName, field);

  return fieldPath.join('.');
}
