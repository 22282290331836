/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 26/06/2018
 */
import * as constants from './constants';

export function changeHomepageSections(selected, notSelected) {
  return {
    type: constants.CHANGE_HOMEPAGE,
    selected,
    notSelected,
  };
}

export function discardChanges() {
  return {
    type: constants.DISCARD_CHANGES,
  };
}

export function loadHomepageSections() {
  return {
    type: constants.FETCH_SECTIONS_PENDING,
  };
}

export function loadHomepageSuccess(sections) {
  return {
    type: constants.FETCH_SECTIONS_SUCCESS,
    sections,
  };
}

export function loadHomepageError(error) {
  return {
    type: constants.FETCH_SECTIONS_ERROR,
    error,
  };
}

export function saveHomepageOrder() {
  return {
    type: constants.SAVE_ORDER_PENDING,
  };
}

export function saveHomepageOrderSuccess() {
  return {
    type: constants.SAVE_ORDER_SUCCESS,
  };
}

export function saveHomepageOrderError(error) {
  return {
    type: constants.SAVE_ORDER_ERROR,
    error,
  };
}
