/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */
import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { connect } from 'react-redux';
import { getFormattedFieldsErrors } from '../../utils/form';
import { getDisplaySticky } from '../../store/globalError/selectors';
import { displayInline } from '../../store/globalError/actions';

const FormAlert = ({
  className,
  entityDescription,
  errorMessage,
  fieldsError,
  isDisplayedSticky,
  switchToInlineDisplay,
}) => {
  const errors = getFormattedFieldsErrors(fieldsError, entityDescription);

  if (!errorMessage && (!errors || Object.keys(errors).length === 0)) {
    return null;
  }

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyPress={switchToInlineDisplay}
      className={`${className} FormAlert ${isDisplayedSticky ? 'FormAlert__sticky' : ''}`}
      onClick={switchToInlineDisplay}
    >
      <div className="FormAlert__text" data-test-id="alertText">
        <div className="FormAlert__text_header">{errorMessage}</div>
        {errors.length > 0 && (
          <div className="FormAlert__text_subtitle">
            <ul>
              {errors.map(error => (
                <li>
                  <strong>{error.label}</strong>
                  {` - ${error.errorMessage}`}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

FormAlert.propTypes = {
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  entityDescription: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string,
    }),
  ),
  fieldsError: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  isDisplayedSticky: PropTypes.bool.isRequired,
  switchToInlineDisplay: PropTypes.func.isRequired,
};

FormAlert.defaultProps = {
  errorMessage: '',
  className: '',
  entityDescription: {},
  fieldsError: {},
};

const mapStateToProps = state => ({
  isDisplayedSticky: getDisplaySticky(state),
});

const mapDispatchToProps = dispatch => ({
  switchToInlineDisplay: () => dispatch(displayInline()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormAlert);
