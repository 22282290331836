import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FlatButton } from 'material-ui';
import moment from 'moment';
import { Card, CardHeader, CardText, CardActions } from 'material-ui/Card';
import {
  getFixture,
  getResults,
  getSubmitEnabled,
  getReadOnly,
  fixtureIsLoading,
} from '../../store/FixtureResults/selectors';
import { fetchFixture, sendFixtureResultUpdate } from '../../store/FixtureResults/thunks';
import { updateResult as updateResultAction } from '../../store/FixtureResults/actions';
import './index.scss';
import LoadingWheel from '../../components/LoadingWheel';
import MpModal from '../../components/MpModal/mpModal';
import TextField from '../../components/FormField/Text';

class scoreComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      loading: false,
    };
  }

  componentDidMount() {
    const { retrieveFixture, router } = this.props;
    retrieveFixture(router.params.fixtureId);
  }

  updateScore(result, score, id) {
    const { updateResult } = this.props;
    const newResult = result.filter(elm => elm.teamId !== id);
    newResult.push({ teamId: id, score });
    updateResult(newResult);
  }

  toggleModal() {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  render() {
    const { fixture, result, submitEnabled, updateFixtureResult, isReadOnly, isLoading } =
      this.props;
    const { loading, showModal } = this.state;
    const getValue = id =>
      result.find(elm => elm.teamId === id) ? result.find(elm => elm.teamId === id).score : '';

    return (
      <Card className="resultPage">
        {isLoading || loading ? (
          <LoadingWheel size="full-page" />
        ) : (
          <>
            {fixture && !isReadOnly && (
              <>
                <CardHeader className="resultPage__header">
                  <h1 className="text-lg">{fixture.name}</h1>
                  <p className="text-sm">
                    {`${moment(fixture.startTime).format('MMM Do YYYY, H:mm')} - ${moment(
                      fixture.endTime,
                    ).format('H:mm')}`}
                  </p>
                </CardHeader>
                <CardText className="resultPage__inputs">
                  <TextField
                    handleEditField={text => this.updateScore(result, text, fixture.homeTeam.id)}
                    name={fixture.homeTeam.name}
                    text={getValue(fixture.homeTeam.id)}
                  />
                  <h1> VS </h1>
                  <TextField
                    handleEditField={text => this.updateScore(result, text, fixture.awayTeam.id)}
                    name={fixture.awayTeam.name}
                    text={getValue(fixture.awayTeam.id)}
                  />
                </CardText>
                <CardActions className="resultPage__button">
                  <FlatButton
                    disabled={!submitEnabled}
                    label="SUBMIT"
                    onClick={() => this.toggleModal()}
                  />
                </CardActions>
                <MpModal
                  visible={showModal}
                  mainText="You are about to submit a score, this cannot be undone, are you 100% sure?"
                  subText={`${fixture.homeTeam.name} ${getValue(fixture.homeTeam.id)} - ${getValue(
                    fixture.awayTeam.id,
                  )} ${fixture.awayTeam.name}`}
                  warningText={
                    moment(fixture.endTime) > moment()
                      ? 'FIXTURE SCHEDULED ENDTIME IS LATER THAN NOW, ARE YOU SURE?'
                      : ''
                  }
                  onConfirm={() => {
                    this.toggleModal();
                    this.setState({ loading: true });
                    updateFixtureResult(fixture.id, result).then(() =>
                      this.setState({ loading: false }),
                    );
                  }}
                  onCancel={() => this.toggleModal()}
                />
              </>
            )}
            {fixture && isReadOnly && (
              <div className="resultPage__readOnly">
                <div className="resultPage__readOnly_header"> END OF THE GAME </div>
                <div className="resultPage__readOnly_table">
                  <div className="resultPage__readOnly_table_homeTeam">
                    <p className="resultPage__readOnly_table_homeTeam_name">
                      {fixture.homeTeam.name}
                    </p>
                    <p className="resultPage__readOnly_table_homeTeam_score">
                      {getValue(fixture.homeTeam.id)}
                    </p>
                  </div>
                  <div className="resultPage__readOnly_table_vs"> VS </div>
                  <div className="resultPage__readOnly_table_awayTeam">
                    <p className="resultPage__readOnly_table_awayTeam_name">
                      {fixture.awayTeam.name}
                    </p>
                    <p className="resultPage__readOnly_table_awayTeam_score">
                      {getValue(fixture.awayTeam.id)}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Card>
    );
  }
}

scoreComponent.propTypes = {
  isLoading: PropTypes.bool,
  retrieveFixture: PropTypes.func.isRequired,
  updateFixtureResult: PropTypes.func.isRequired,
  updateResult: PropTypes.func.isRequired,
  fixture: PropTypes.shape({
    id: PropTypes.number,
    homeTeam: PropTypes.shape(),
    awayTeam: PropTypes.shape(),
    abelsonId: PropTypes.number,
    legacyEventId: PropTypes.number,
    abelsonName: PropTypes.string,
    name: PropTypes.string,
    competitionId: PropTypes.number,
    sportId: PropTypes.number,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    active: PropTypes.bool,
  }),
  result: PropTypes.arrayOf(
    PropTypes.shape({
      teamId: PropTypes.number,
      score: PropTypes.number,
    }),
  ).isRequired,
  submitEnabled: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  router: PropTypes.shape().isRequired,
};

scoreComponent.defaultProps = {
  isLoading: false,
  fixture: {},
};

const mapDispatchToProps = dispatch => ({
  updateFixtureResult: (fixtureId, result) => dispatch(sendFixtureResultUpdate(fixtureId, result)),
  retrieveFixture: fixtureId => dispatch(fetchFixture(fixtureId)),
  updateResult: result => dispatch(updateResultAction(result)),
});

const mapStateToProps = state => ({
  isLoading: fixtureIsLoading(state),
  fixture: getFixture(state),
  result: getResults(state),
  submitEnabled: getSubmitEnabled(state),
  isReadOnly: getReadOnly(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(scoreComponent);
