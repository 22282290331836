/**            -                              ___
 *            |.|                          . /\_/\
 *          __]-[_________ /             .  (-<_>-)
 *         /        _____|<_          .   .  \/_\/
 *        / _   &  /               .   .
 *       / / \_ __|            .
 *     _/_/  / X   \
 *    <_/   /   ____\
 *         /___/|  /             zinedine zidane
 *         |  / ( <                --france--
 *         ( <   \ |
 *          \ |   >\
 *          _>|  (_/
 *         (__|
 *
 * MatchPint Ltd
 * @author Andreia Almeida
 * Date: 19/12/2018
 */

import competitionList from './entityList';

import createListPage from '../listPage';

import { STRING, NUMBER } from '../listPage/fieldTypes';
import tabs from '../../../routes/tabs';

const competitionListPage = createListPage(
  competitionList,
  {
    id: {
      label: 'ID',
      type: NUMBER,
    },
    sport: {
      label: 'Sport',
      renderValue: (value, values) =>
        values.sport && values.sport.name ? values.sport.name : value,
      type: STRING,
    },
    name: {
      label: 'Name',
      type: STRING,
    },
  },
  {
    navTabs: tabs.competitions,
  },
);

export default competitionListPage;
