//      ,---.
//    ,/     \__.'~\
//    |        '  . `===---      -Ed touched this file-
//    `|   ,     ,--'
//     `. /___,-'
//       |    \
//       ,\_  ,`-=

import * as c from './constants';

export const clearTranslations = () => ({
  type: c.CLEAR_TRANSLATIONS,
});

export const fetchTranslationsPending = () => ({
  type: c.FETCH_TRANSLATIONS_PENDING,
});
export const fetchTranslationsSuccess = translations => ({
  type: c.FETCH_TRANSLATIONS_SUCCESS,
  payload: translations,
});

export const fetchTranslationsError = error => ({
  type: c.FETCH_TRANSLATIONS_ERROR,
  payload: error,
});

export const updateTranslationsPending = () => ({
  type: c.UPDATE_TRANSLATIONS_PENDING,
});

export const updateTranslationsSuccess = () => ({
  type: c.UPDATE_TRANSLATIONS_SUCCESS,
});

export const updateTranslationsError = error => ({
  type: c.UPDATE_TRANSLATIONS_ERROR,
  payload: error,
});
