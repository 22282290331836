import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import BackIcon from 'material-ui/svg-icons/navigation/chevron-left';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import { Dialog } from 'material-ui';
import { getFixture } from '../../store/FixtureResults/selectors';
import { fetchFixture } from '../../store/FixtureResults/thunks';
import styles from './index.module.scss';
import LoadingWheel from '../../components/LoadingWheel';
import {
  fetchFixtureMinuteEvents,
  fetchMinuteEventsTypes,
  saveFixtureMinuteEvents,
} from '../../store/thunks/api-fixture';

const { ADD, SAVE } = { ADD: 'add', SAVE: 'save' };
const initialState = { eventMinute: '', eventTypeId: '', fixtureTeamId: '', teamName: '' };

const s = {
  addButton: 'bg-primaryYellow rounded px-4 py-2 mt-10 mx-auto outline-none',
  saveButton: 'w-full bg-primaryYellow rounded py-2 mt-6',
  closeButton: 'flex items-center justify-end rounded-full bg-veryLightGrey p-1 mb-1 ml-auto',
  inputLabel: 'font-bold opacity-90 uppercase mt-8 mb-2',
  cancelButton: 'w-full bg-veryLightGrey rounded py-2 mt-2',
};

const MinuteEventsPage = ({
  fixture,
  router,
  fetchFixt,
  fetchEventTypes,
  fetchFixtureMinEvents,
  saveEvent,
}) => {
  const [modalState, setModalState] = useState(null);
  const [events, setEvents] = useState(null);
  const [newEvent, setNewEvent] = useState(initialState);
  const [isloadingEvents, setIsLoadingEvents] = useState(true);
  const [eventTypes, setEventTypes] = useState([]);

  const allFieldsSet = Object.keys(newEvent).every(k => newEvent[k]);
  const getEventNameById = id => eventTypes.find(type => type.id === id)?.name;

  const fetchFixtureEvents = useCallback(
    () =>
      fetchFixtureMinEvents(router.params.fixtureId)
        .then(res => setEvents(res.getResult()))
        .finally(() => setIsLoadingEvents(false)),
    [fetchFixtureMinEvents, router.params.fixtureId],
  );

  const clear = () => {
    setNewEvent(initialState);
    setModalState(null);
  };

  useEffect(() => {
    fetchFixt(router.params.fixtureId);
    fetchFixtureEvents();
  }, [fetchFixt, fetchFixtureEvents, router.params.fixtureId]);

  useEffect(() => {
    if (fixture) {
      fetchEventTypes(fixture?.gameId).then(res => setEventTypes(res.getResult()));
    }
  }, [fetchEventTypes, fixture]);

  const teamOption = ({ name, fixtureTeamId }) => (
    <div className="mb-2 flex">
      <label className="w-full flex-1" htmlFor={name}>
        {name}
      </label>
      <input
        className="accent-primaryYellow"
        type="radio"
        onChange={() => setNewEvent({ ...newEvent, fixtureTeamId, teamName: name })}
        checked={newEvent?.fixtureTeamId === fixtureTeamId}
        id={name}
      />
    </div>
  );

  const confirmEventText = (title, data) => (
    <div className="mb-2 flex text-xl opacity-90">
      <p>{title}</p>
      <p className="pl-2 font-bold capitalize">{data}</p>
    </div>
  );

  return (
    <>
      <div className="flex flex-col p-4 md:p-8">
        <button type="button" className="flex items-center" onClick={router.goBack}>
          <BackIcon />
          <p className="pl-2 font-bold">BACK</p>
        </button>
        {fixture ? (
          <>
            <div className="my-8 block md:flex">
              <h1 className="pr-6 text-2xl font-semibold">{fixture.name}</h1>
              <div className="flex items-center gap-4">
                <p className="opacity-60">{moment(fixture.startTimeUtc).format('Do MMM')}</p>
                <p className="opacity-60">{moment(fixture.startTimeUtc).format('HH:mm')}</p>
              </div>
            </div>
            <div className="flex flex-col rounded shadow-md">
              <div className="flex rounded-t bg-primaryYellow px-4 py-2 shadow-sm">
                <p className="flex-1">Minute</p>
                <p className="flex-1">Type</p>
                <p className="flex-1">Team</p>
              </div>
              {events && (
                <>
                  {events.map(e => (
                    <div className="flex border-b border-lightGrey px-4 py-2" key={e.id}>
                      <p className="flex-1">{e.eventMinute}”</p>
                      <p className="flex-1 capitalize">{getEventNameById(e.eventTypeId)}</p>
                      <p className="flex-1">{e.team.name}</p>
                    </div>
                  ))}
                  {events.length === 0 && (
                    <p className="px-4 py-2 text-center">No events added yet</p>
                  )}
                  {isloadingEvents && (
                    <div className="flex w-full justify-center py-2">
                      <LoadingWheel />
                    </div>
                  )}
                </>
              )}
            </div>
            <button type="button" className={s.addButton} onClick={() => setModalState(ADD)}>
              Add event
            </button>
          </>
        ) : (
          <LoadingWheel size="full-page" />
        )}
      </div>

      <Dialog
        open={Boolean(modalState)}
        onRequestClose={clear}
        contentClassName="flex justify-center"
        paperClassName="max-w-sm flex-1"
        bodyStyle={{ color: 'black' }}
      >
        <button type="button" className={s.closeButton} onClick={clear}>
          <CloseIcon style={{ height: 20, width: 20 }} />
        </button>
        {modalState === ADD && (
          <>
            <h1 className="text-3xl font-bold">Add event</h1>
            <h3 className={s.inputLabel}>Event details</h3>
            <input
              type="number"
              placeholder="Minute"
              className={styles.input}
              value={newEvent?.eventMinute}
              onChange={e => setNewEvent({ ...newEvent, eventMinute: e.target.value })}
            />

            <div className="mt-3 rounded border border-lightGrey px-3 py-2">
              <select
                className="w-full capitalize outline-none"
                onChange={e =>
                  setNewEvent({ ...newEvent, eventTypeId: parseInt(e.target.value, 10) })
                }
              >
                <option value="" hidden>
                  Event type
                </option>
                {eventTypes.map(type => (
                  <option value={type.id} key={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>

            <h3 className={s.inputLabel}>Team</h3>
            {teamOption(fixture.homeTeam)}
            {teamOption(fixture.awayTeam)}
          </>
        )}

        {modalState === SAVE && (
          <>
            <h1 className="mb-8 text-3xl font-bold">Are you sure you want to add this event?</h1>
            {confirmEventText('Event #:', events.length + 1)}
            {confirmEventText('Minute:', newEvent.eventMinute)}
            {confirmEventText('Type:', getEventNameById(newEvent.eventTypeId))}
            {confirmEventText('Team:', newEvent.teamName)}
          </>
        )}

        <button
          type="button"
          className={`${s.saveButton} ${allFieldsSet ? '' : 'opacity-50'}`}
          disabled={!allFieldsSet}
          onClick={() => {
            if (modalState === ADD) {
              setModalState(SAVE);
            }
            if (modalState === SAVE) {
              setIsLoadingEvents(true);
              saveEvent(router.params.fixtureId, newEvent).finally(() => fetchFixtureEvents());
              clear();
            }
          }}
        >
          <p className="font-semibold">{modalState === ADD ? 'Add' : 'Add Event'}</p>
        </button>
        {modalState === SAVE && (
          <button type="button" className={s.cancelButton} onClick={clear}>
            <p className="font-semibold">No, take me back</p>
          </button>
        )}
      </Dialog>
    </>
  );
};

MinuteEventsPage.propTypes = {
  fixture: PropTypes.shape(),
  router: PropTypes.shape().isRequired,
  fetchFixt: PropTypes.func.isRequired,
  fetchEventTypes: PropTypes.func.isRequired,
  fetchFixtureMinEvents: PropTypes.func.isRequired,
  saveEvent: PropTypes.func.isRequired,
};

MinuteEventsPage.defaultProps = {
  fixture: {},
};

const mapStateToProps = state => ({
  fixture: getFixture(state),
});

const mapDispatchToProps = {
  fetchFixt: fetchFixture,
  fetchEventTypes: fetchMinuteEventsTypes,
  fetchFixtureMinEvents: fetchFixtureMinuteEvents,
  saveEvent: saveFixtureMinuteEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(MinuteEventsPage);
