import React from 'react';
import PropTypes from 'prop-types';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Line,
  LineChart,
  ResponsiveContainer,
} from 'recharts';

const BarChart = ({ data, dataKey, stroke, className }) => (
  <ResponsiveContainer height={200} width="100%">
    <LineChart data={data} className={className}>
      <CartesianGrid horizontal="true" vertical="" />
      <XAxis dataKey="date" />
      <YAxis dataKey={dataKey} tickCount={10} />
      <Tooltip />
      <Line
        type="monotone"
        dataKey={dataKey}
        stroke={stroke}
        activeDot={{ r: 6 }}
        strokeWidth={1.5}
      />
    </LineChart>
  </ResponsiveContainer>
);

BarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  dataKey: PropTypes.string.isRequired,
  className: PropTypes.string,
  stroke: PropTypes.string,
};

BarChart.defaultProps = {
  stroke: '#82ca9d',
  className: '',
};

export default BarChart;
