import authenticated from '../hocs/authenticated';
import HomePage from '../pages/HomePage';
import ErrorPage from '../pages/ErrorPage';
import { routes as listRoutes } from '../template/lists';
import { routes as formRoutes } from '../template/forms';
import SportPriority from '../pages/SportPriorityPage';
import ReportPage from '../pages/ReportsListPage/ReportPage';
import tabs from './tabs';
import FixtureResultPage from '../pages/FixtureResultPage/fixtureResultPage';
import URLShortener from '../pages/URLShortener/URLShortener';
import ReactivePrizeForm from '../pages/ReactivePrizePage/ReactivePrizePage';
import ImportPubsPage from '../pages/ImportPubsPage/ImportPubsPage';
import ImportVenueEventPage from '../pages/ImportVenueEventPage/ImportVenueEventPage';
import TopFixturesList from '../pages/TopFixturesList/TopFixturesList';
import * as perm from '../utils/permissionsForRoutes';
import AppsHomepagePage from '../pages/AppsHomepagePage';
import ChangePasswordPage from '../pages/ChangePasswordPage';
import CompetitionPriority from '../pages/CompetitionPriority/CompetitionPriority';
import AdvertReport from '../pages/AdvertReport/AdvertReport';
import Deeplinks from '../pages/Deeplinks/Deeplinks';
import VenueCompleter from '../pages/VenueCompleter/VenueCompleter';
import MinuteEventsPage from '../pages/MinuteEventsPage/MinuteEventsPage';

const routes = [
  {
    path: '/reset-password',
    indexRoute: { component: ChangePasswordPage },
  },
  {
    path: '/',
    indexRoute: { component: authenticated(HomePage, undefined, perm.home) },
  },
  {
    path: '/homepage',
    indexRoute: { component: authenticated(AppsHomepagePage, undefined, perm.homepage) },
  },
  {
    path: '/sport-priority',
    indexRoute: { component: authenticated(SportPriority, undefined, perm.sportPriority) },
  },
  {
    path: '/competition-priority',
    indexRoute: {
      component: authenticated(CompetitionPriority, undefined, perm.compeitionPriority),
    },
  },
  {
    path: '/meta/reports/:name',
    indexRoute: { component: authenticated(ReportPage, undefined, perm.reports) },
  },
  {
    path: '/sports/fixtures/v2/:fixtureId/result',
    indexRoute: { component: authenticated(FixtureResultPage, undefined, perm.fixture) },
  },
  {
    path: '/sports/fixtures/v2/:fixtureId/minute-events',
    indexRoute: { component: authenticated(MinuteEventsPage, undefined, perm.fixture) },
  },
  {
    path: '/games/reactive-prize/:gameId',
    indexRoute: { component: authenticated(ReactivePrizeForm, undefined, perm.reactivePrize) },
  },
  {
    path: '/venues/venue-completer/edit/:id',
    indexRoute: { component: authenticated(VenueCompleter, undefined, perm.venueCompleter) },
  },
  {
    path: 'import-pubs',
    indexRoute: { component: authenticated(ImportPubsPage, undefined, perm.importVenues) },
  },
  {
    path: 'import-events',
    indexRoute: { component: authenticated(ImportVenueEventPage, undefined, perm.importEvents) },
  },
  {
    path: '/big-fixtures',
    indexRoute: { component: authenticated(TopFixturesList, undefined, perm.bigFixtures) },
  },
  {
    path: `/URLShortener`,
    indexRoute: { component: authenticated(URLShortener, undefined, perm.URLShortener) },
  },
  {
    path: `/deeplinks`,
    indexRoute: { component: authenticated(Deeplinks, undefined, perm.deeplinks) },
  },
  {
    path: `/adverts/report/:id`,
    indexRoute: { component: authenticated(AdvertReport, tabs.adverts, perm.adverts) },
  },

  ...formRoutes,
  ...listRoutes,
  // For 404 page.
  {
    path: '*',
    exact: true,
    indexRoute: { component: authenticated(ErrorPage, undefined, perm.errorPage) },
  },
];

export default routes;
