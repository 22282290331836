import EntityList from '../listPage/EntityList';

export default new EntityList(
  'competitionRound',
  'sports/competitions/rounds',
  {
    zidane: true,
  },
  undefined,
  undefined,
  'Competition Rounds',
);
