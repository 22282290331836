export function fixtureIsLoading(state) {
  return state.result.loading;
}
export function getFixture(state) {
  return state.result.fixture;
}

export function getResults(state) {
  return state.result.results;
}

export function getSubmitEnabled(state) {
  return state.result.submitEnabled;
}

export function getReadOnly(state) {
  return state.result.readOnly;
}
