import promocodesList from './entityList';

import createListPage from '../listPage';

import tabs from '../../../routes/tabs';

const promocodesListPage = createListPage(
  promocodesList,
  {
    code: {
      label: 'Code',
    },
    description: {
      label: 'Description',
    },
  },
  {
    navTabs: tabs.promocode,
  },
);

export default promocodesListPage;
