import createFormPage from '../formPage';

const params = {
  endpoint: 'meta/parameters',
  sections: {
    General: [
      { fieldPath: 'name' },
      { fieldPath: 'active' },
      { fieldPath: 'description' },
      { fieldPath: 'editable' },
      { fieldPath: 'value' },
    ],
  },
};

export default createFormPage('parametros', params);
