/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 21/05/2018
 */
import { getURLQuery } from '@matchpint/react-common';
import { request } from '../../../../store/thunks/requests';

/**
 * Returns an asynchronous function that will requests to the Micro-service a list.
 * @param {string} endpoint Endpoint of the List.
 * @param {Object} defaultQueryParameters Parameters to apply to the endpoint by default.
 * @returns {function(*=): function(*): *}
 */
export function getListFromAPI(endpoint, defaultQueryParameters) {
  return function _getListFromAPI(queryParameters = {}) {
    return async function _entityList(dispatch) {
      return dispatch(
        request(
          `${endpoint}${getURLQuery({
            ...defaultQueryParameters,
            ...queryParameters,
          })}`,
          {
            method: 'GET',
          },
        ),
      );
    };
  };
}

/**
 * Returns an asynchronous function that will request to the Micro-service the
 *  filters available for this endpoint.
 * @param {string} endpoint Endpoint of the List.
 * @param {Object} defaultFilterQueryParameters Parameters to apply to the endpoint by default.
 * @returns {function(*=): function(*): *}
 */
export function getFiltersFromAPI(endpoint, defaultFilterQueryParameters) {
  return function _getFiltersFromAPI() {
    return async function _entityList(dispatch) {
      return dispatch(
        request(
          `${endpoint}${getURLQuery({
            ...defaultFilterQueryParameters,
            filtersDescription: true,
          })}`,
          {
            method: 'GET',
          },
        ),
      );
    };
  };
}
