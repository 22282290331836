/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 29/06/2018
 */

export const FETCH_SPORTS_PENDING = 'sportsOrder/fetch/pending';
export const FETCH_SPORTS_SUCCESS = 'sportsOrder/fetch/success';
export const FETCH_SPORTS_ERROR = 'sportsOrder/fetch/error';

export const CHANGE_ORDER = 'sportsOrder/changePriority';

export const DISCARD_CHANGES = 'sportsOrder/discardChanges';
export const SUCCESS_DISMISS = 'sportsOrder/success-dismiss';

export const SAVE_ORDER_PENDING = 'sportsOrder/save/pending';
export const SAVE_ORDER_SUCCESS = 'sportsOrder/save/success';
export const SAVE_ORDER_ERROR = 'sportsOrder/save/error';
