/**            -                              ___
 *            |.|                          . /\_/\
 *          __]-[_________ /             .  (-<_>-)
 *         /        _____|<_          .   .  \/_\/
 *        / _   &  /               .   .
 *       / / \_ __|            .
 *     _/_/  / X   \
 *    <_/   /   ____\
 *         /___/|  /             zinedine zidane
 *         |  / ( <                --france--
 *         ( <   \ |
 *          \ |   >\
 *          _>|  (_/
 *         (__|
 *
 * MatchPint Ltd
 * @author Andreia Almeida
 * Date: 21/01/2019
 */

import * as consts from './constants';

export function fetchReportPending() {
  return {
    type: consts.FETCH_REPORT_PENDING,
  };
}

export function fetchReportSuccess(report) {
  return {
    type: consts.FETCH_REPORT_SUCCESS,
    payload: report,
  };
}

export function fetchReportFail() {
  return {
    type: consts.FETCH_REPORT_FAIL,
  };
}

export function setAvailableFilters(filters) {
  return {
    type: consts.SET_AVAILABLE_FILTERS,
    payload: filters,
  };
}

export function setAppliedFilters(filters) {
  return {
    type: consts.SET_APPLIED_FILTERS,
    payload: filters,
  };
}

export function addFilterAction(name, value, isMulti) {
  return {
    type: consts.REPORT_ADD_FILTER,
    payload: { name, value, isMulti },
  };
}

export function removeFilterAction(name) {
  return {
    type: consts.REPORT_REMOVE_FILTER,
    payload: name,
  };
}

export function clearReport() {
  return {
    type: consts.CLEAR_REPORT,
  };
}
