import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, SelectField } from 'material-ui';
import { getURLQuery } from '@matchpint/react-common';
import { format } from 'date-fns';
import request from '../../utils/apiRequest/requests';
import LoadingWheel from '../../components/LoadingWheel';
import './styles.scss';
import BarChart from './BarChart';

const DateSelector = ({ value, label, onChange, min, max }) => (
  <div className="AdvertReport_datesSection_section">
    <p>{label}</p>
    <input
      type="date"
      className="AdvertReport_datesSection_section_date"
      value={value}
      onChange={onChange}
      min={min}
      max={max}
    />
  </div>
);

DateSelector.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.string,
  max: PropTypes.string,
};

DateSelector.defaultProps = {
  min: '',
  max: '',
};

const StatSection = ({ title, stat, dataArr, stroke }) => (
  <div className="AdvertReport_statContainer">
    <p className="AdvertReport_subTitle">{title}</p>
    {dataArr?.length > 0 ? (
      <>
        <p className="AdvertReport_stat">{stat?.toLocaleString()}</p>
        <BarChart
          data={dataArr}
          dataKey={title}
          stroke={stroke}
          className="AdvertReport_barChart"
        />
      </>
    ) : (
      <p>No data available</p>
    )}
  </div>
);

StatSection.propTypes = {
  title: PropTypes.string.isRequired,
  stroke: PropTypes.string,
  stat: PropTypes.number.isRequired,
  dataArr: PropTypes.arrayOf(PropTypes.shape()),
};

StatSection.defaultProps = {
  dataArr: [],
  stroke: '#82ca9d',
};

const AdvertReport = ({ params: { id } }) => {
  const [data, setData] = useState({});
  const [period, setPeriod] = useState(7);
  const [loading, setLoading] = useState(true);

  const formatDate = d => format(d, 'yyyy-MM-dd');
  const custom = 'custom';
  const todayDate = formatDate(new Date());
  const [dates, setDates] = useState({ fromDate: todayDate, toDate: todayDate });
  const areDatesOk = !dates?.fromDate || !dates?.toDate || dates?.fromDate <= dates?.toDate;

  useEffect(() => {
    if (areDatesOk) {
      request(
        `adverts/report/${id}${getURLQuery({
          period,
          fromDate: period === custom && dates?.fromDate ? dates?.fromDate : undefined,
          toDate: period === custom && dates?.toDate ? dates?.toDate : undefined,
        })}`,
      )
        .then(r => r.getResult())
        .then(r => setData(r))
        .finally(() => setLoading(false));
    }
  }, [period, dates?.fromDate, dates?.toDate, areDatesOk, id]);

  if (loading) {
    return <LoadingWheel />;
  }

  const periods = [
    { text: 'Custom date', value: custom },
    { text: 'All time', value: undefined },
    { text: 'Today', value: 0 },
    { text: 'Last 24 hours', value: 1 },
    { text: 'Last 7 days', value: 7 },
    { text: 'Last 28 days', value: 28 },
    { text: 'Last 90 days', value: 90 },
  ];
  return (
    <div className="AdvertReport">
      <p className="AdvertReport_title">{data?.advert?.name}</p>
      <SelectField
        className="AdvertReport_select"
        onChange={(_, __, selected) => setPeriod(selected)}
        value={period}
      >
        {periods.map(val => (
          <MenuItem key={val?.text} value={val?.value} primaryText={val?.text} />
        ))}
      </SelectField>
      {period === custom && (
        <>
          <div className="AdvertReport_datesSection">
            <DateSelector
              label="From date"
              value={dates?.fromDate}
              onChange={({ currentTarget: { value } }) =>
                setDates(d => ({ ...d, fromDate: value }))
              }
              max={formatDate(new Date())}
            />
            <DateSelector
              label="To date"
              value={dates?.toDate}
              onChange={({ currentTarget: { value } }) => setDates(d => ({ ...d, toDate: value }))}
              min={dates?.fromDate}
              max={formatDate(new Date())}
            />
          </div>
          {!areDatesOk && (
            <p className="AdvertReport_datesSection_error">
              Start date must be earlier than end date
            </p>
          )}
        </>
      )}
      <div className="AdvertReport_section">
        <StatSection
          title="impressions"
          stat={data?.impressions}
          dataArr={data?.data}
          stroke="#8884d8"
        />
        <StatSection title="clicks" stat={data?.clicks} dataArr={data?.data} stroke="#82ca9d" />
      </div>
    </div>
  );
};

AdvertReport.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default AdvertReport;
