import tiersList from './entityList';

import createListPage from '../listPage';
import { STRING } from '../listPage/fieldTypes';

import tabs from '../../../routes/tabs';

const tiersListPage = createListPage(
  tiersList,
  {
    id: {
      label: 'Id',
      type: STRING,
      style: {
        width: '20%',
      },
    },
    name: {
      label: 'Name',
      type: STRING,
      style: {
        width: '20%',
      },
    },
    countryId: {
      label: 'Country',
      renderValue: (value, values) => values && values.country && values.country.name,
      style: {
        width: '20%',
      },
    },
    referenceTierId: {
      label: 'Reference tier',
      renderValue: (value, values) =>
        values && values.referenceTier && values.referenceTier.autocompleteValue,
      style: {
        width: '20%',
      },
    },
    groupCode: {
      label: 'Group code',
      type: STRING,
      style: {
        width: '20%',
      },
    },
  },
  {
    navTabs: tabs.tiers,
  },
);

export default tiersListPage;
