/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 26/10/2018
 */

import createFormPage from '../formPage';
import RolesForMembers from './component/rolesForMembers';
import PasswordReset from './component/PasswordReset';
import MemberActions from './component/memberActions';
import CICReset from './component/CICReset';

const params = {
  endpoint: 'members',
  sections: {
    General: [
      'firstName',
      'lastName',
      'email',
      'phoneNumber',
      {
        id: 'devices-for-member',
        fieldPath: 'id',
        Component: MemberActions,
      },
      {
        id: 'password-reset',
        fieldPath: 'id',
        Component: PasswordReset,
      },
      {
        id: 'CIC game reset',
        fieldPath: 'id',
        Component: CICReset,
      },
    ],
    Roles: [
      {
        id: 'roles-for-members',
        Component: RolesForMembers,
      },
    ],
  },
  create: {
    fields: ['firstName', 'lastName', 'email', 'active'],
  },
};

const memberPage = createFormPage('members', params);

export default memberPage;
