import React from 'react';
import './styles.scss';

const AccessRestrictedPage = () => (
  <div className="AccessRestrictedPage">
    <h1>Access restricted</h1>
    <p>Unfortunately you do not have the rights to view this page.</p>
    <p>
      If you believe this to be a mistake please{' '}
      <a href="mailto:dev@matchpint.co.uk">let the tech team know</a>
    </p>
  </div>
);

export default AccessRestrictedPage;
