import React from 'react';
import { parseISO, format } from 'date-fns';
import ScoreIcon from 'material-ui/svg-icons/action/assignment-turned-in';
import AdvertList from './entityList';
import createListPage from '../listPage';
import tabs from '../../../routes/tabs';
import { getDefaultRenderItemSideButtons } from '../listPage/utils';
import ListPageIconButton from '../listPage/components/IconButton';

const renderMatchesSideButton = (advert, comp) => {
  const defaultButtons = getDefaultRenderItemSideButtons(comp)(advert);

  defaultButtons.push(
    <ListPageIconButton key="stats" icon={<ScoreIcon />} link={`adverts/report/${advert.id}`} />,
  );

  return defaultButtons;
};

const Adverts = createListPage(
  AdvertList,
  {
    id: { label: 'id' },
    name: { label: 'Name' },
    startTimeUtc: {
      label: 'Start Date',
      renderValue: v => <p>{format(parseISO(v), 'dd/MM/yyyy HH:mm')}</p>,
    },

    targetTerritory: { label: 'Country' },
  },
  {
    renderItemButtons: fix => renderMatchesSideButton(fix, AdvertList),
    navTabs: tabs.adverts,
  },
);

export default Adverts;
