import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DnDContext from '../../components/DragAndDrop/Context';
import DnDList from '../../components/DragAndDrop/List';
import FormAlert from '../../components/FormAlert';
import LoadingWheel from '../../components/LoadingWheel';
import MpSectionHeader from '../../components/MpSectionHeader';
import SaveBar from '../../components/SaveBar';
import SportSection from '../../components/SportSection';
import './index.scss';
import MatchPintCountrySelector from '../../components/MatchPintCountrySelector/MatchPintCountrySelector';
import {
  getFetchSportsError,
  getSaveOrderSportsError,
  getEditedSports,
  hasUnStagedChanges,
  isSportsOrderSaving,
  areSportsFetching,
} from '../../store/sportsOrder/selectors';
import {
  changeOrder,
  discardChanges,
  fetchSports,
  saveSportsOrder,
} from '../../store/sportsOrder/thunks';

const SportPriorityPage = ({
  getSports,
  discard,
  hasChanges,
  isLoading,
  saveOrder,
  sports,
  changeSportOrder,
  errorFetch,
  errorSave,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    if (selectedCountry) {
      getSports(selectedCountry.id);
    }
  }, [getSports, selectedCountry]);

  const onReorder = (listId, indexStart, indexEnd) => {
    const state = DnDContext.onReOrderDefault({ sports }, listId, indexStart, indexEnd);
    changeSportOrder(state.sports);
  };

  if (errorSave || errorFetch) {
    return (
      <FormAlert className="SportPriorityPage__error" errorMessage={errorSave || errorFetch} />
    );
  }

  return (
    <div className="SportPriorityPage">
      <MatchPintCountrySelector
        onSelect={country => setSelectedCountry(country)}
        selectedCountry={selectedCountry}
      />
      {isLoading && <LoadingWheel size="full-page" />}
      {selectedCountry && (
        <DnDContext className="SportPriorityPage__DnD_container" onReorder={onReorder}>
          <DnDList
            droppableId="sports"
            header={<MpSectionHeader title="Sports Priority" />}
            renderItem={(item, index) => (
              <SportSection name={item.name} roundLogo={item.roundLogo} priority={index + 1} />
            )}
            items={sports}
          />
        </DnDContext>
      )}
      <SaveBar
        onDiscardAction={discard}
        onSaveAction={() => saveOrder(selectedCountry.id)}
        open={hasChanges}
      />
    </div>
  );
};

SportPriorityPage.propTypes = {
  changeSportOrder: PropTypes.func.isRequired,
  discard: PropTypes.func.isRequired,
  errorFetch: PropTypes.string,
  errorSave: PropTypes.string,
  getSports: PropTypes.func.isRequired,
  hasChanges: PropTypes.bool,
  isLoading: PropTypes.bool,
  saveOrder: PropTypes.func.isRequired,
  sports: PropTypes.arrayOf(PropTypes.shape()),
};

SportPriorityPage.defaultProps = {
  errorFetch: null,
  errorSave: null,
  hasChanges: false,
  isLoading: false,

  sports: null,
};

const mapStateToProps = state => ({
  errorFetch: getFetchSportsError(state),
  errorSave: getSaveOrderSportsError(state),
  hasChanges: hasUnStagedChanges(state),
  isLoading: isSportsOrderSaving(state) || areSportsFetching(state),
  sports: getEditedSports(state),
});

const mapDispatchToProps = {
  changeSportOrder: changeOrder,
  discard: discardChanges,
  getSports: countryId => fetchSports(countryId),
  saveOrder: countryId => saveSportsOrder(countryId),
};

export default connect(mapStateToProps, mapDispatchToProps)(SportPriorityPage);
